import React, {useContext, useEffect, useState} from 'react';
import ViewStreamIcon from "@material-ui/icons/ViewStreamRounded";
import {bankIcon, BarCode, PosReceipt, upiIcon} from "../../assets";
import {format} from "date-fns";
import Modal from "react-modal";
import ArrowRight from "@material-ui/icons/KeyboardArrowRightRounded";
import nullPayment from "../../assets/images/payroll.png";
import {getInstructorPayouts} from "../../database";
import {UserContext} from "../../context";
import nounBook from "../../assets/images/classroom/noun_book_5021.svg";
import CheckCircleIcon from "../../assets/images/icons/check-circle.svg";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import "./style.scss";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {useHistory} from "react-router-dom";

let statuses2 = {
  processed: "Success",
  processing: "Processing",
  reversed: "Failure",
};

let statuses = {
  processed: { title: "success", icon: <img src={CheckCircleIcon} /> },
  processing: { title: "processing", icon: <HourglassEmptyRoundedIcon /> },
  reversed: { title: "error", icon: <CancelRoundedIcon /> },
};

export default function PaymentDetails() {
  const [payoutList, setPayoutList] = useState(null);
  const [user] = useContext(UserContext).user;
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [payoutDetails, setPayoutDetails] = useState({
    orderStatus: "",
    orderId: "",
    amount: 0,
    timestamp: 0,
  });

  // TODO: Implement Infinite Loading for Payouts
  useEffect(() => {
    if(!user || !user?.uid) return;
    getInstructorPayouts({
      instructorId: user?.uid,
      limit: 20,
      callback: (data) => setPayoutList(data),
    });
  }, [user]);


  const PayoutStatus = ({ order }) => (
    <h4 className="payout-status">
      <span className={order?.status}>
        {statuses[order?.status].icon} {statuses[order?.status].title}
      </span>{" "}
      <span>• {format(new Date(order?.notes?.payout_ts), "p")}</span>
    </h4>
  );

  return (
    <div className="tutor-earnings-wrapper page">
      <div className="tutor-payout-info-container">
        <div className="tutor-payment-list">
          <h1>
            <div>
              <ArrowBackIcon onClick={() => history.goBack()} style={{color: 'var(--color-text)'}} />
              <span>Payment Details</span>
            </div>
          </h1>
          <div className="list-wrapper">
            {payoutList?.map((order, i) => (
              <div
                className="payment-row"
                key={i}
                style={{ animationDelay: `${0.05 * i}s` }}
                onClick={() => {
                  setOpenModal(true);
                  setPayoutDetails({
                    amount: order.amount,
                    orderStatus: order.status,
                    orderId: order.id,
                    timestamp: order.notes?.payout_ts,
                  });
                }}
              >
                <div className="payment-details">
                  <div className="upi-icon">
                    {order.mode === "UPI" ? (
                      <img src={upiIcon} alt="upi" />
                    ) : (
                      <img src={bankIcon} alt="bank" />
                    )}
                  </div>
                  <div className="payout-head">
                    <h2>
                      {format(new Date(order?.notes?.payout_ts), "MMM-d")}{" "}
                      Payment
                    </h2>
                    <PayoutStatus order={order} />
                  </div>
                </div>
                <h3>₹ {order.amount}</h3>
                <button>
                  <ArrowRight />
                </button>
              </div>
            ))}
            {payoutList === null && (
              <div className="no-payroll">
                <img src={nullPayment} />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        className="pustack-care-modal pustack-payout"
        shouldCloseOnOverlayClick={true}
        overlayClassName="new-post-modal-overlay"
        onRequestClose={() => setOpenModal(false)}
      >
        <div className="pos-full-receipt fadeIn">
          <img
            src={PosReceipt}
            className="pos-receipt"
            alt="receipt"
            draggable={false}
          />
          <div className="pos-receipt-details">
            <div className="receipt-head">
              <img src={nounBook} alt="logo" />
              <h1>PuStack Payout</h1>
            </div>

            <div className="receipt-details">
              <div className="receipt-line">
                <h2>Amount Released</h2>
                <h4>₹ {payoutDetails.amount}</h4>
              </div>
              <div className="receipt-line">
                <h2>Date &amp; Time</h2>
                <h4>{format(payoutDetails.timestamp, "MMM do yyyy, p")}</h4>
              </div>
              <div className="receipt-line">
                <h2>Status</h2>
                <h4>{statuses2[payoutDetails.orderStatus]}</h4>
              </div>
            </div>

            <div className="receipt-bar">
              <img src={BarCode} alt="barcode" draggable={false} />
              <h4>{payoutDetails.orderId}</h4>
              <p>Thank you for your service! 😄</p>
            </div>
          </div>

          <div className="order-completed-btns">
            <button
              className="close-receipt"
              onClick={() => setOpenModal(false)}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
