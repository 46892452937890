import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { BlazeExternalContextProvider } from "../../context";
import { BlazeExternalPage } from "../../containers";
import AccountSettings from "../../containers/global/navbar/account-settings";
import "./style.scss";
import {useMediaQuery} from "react-responsive";
import LinkIcon from '@material-ui/icons/Link';

export default function BlazeExternalScreen() {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [, setShowMenuItem] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const showMenuItemFn = (flag) => setShowMenuItem(!flag);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PuStack Tutor - Blaze</title>
      </Helmet>

      {!isSmallScreen && <div className="tutor-dashboard-navbar blaze">
        <div className="tutor-dashboard-title">
          <h1>My Sessions</h1>
        </div>
        <div>
          {/*<LinkIcon className="p-1 mr-2 bg-cyan-500 text-white rounded-full" />*/}
          <AccountSettings profileVisibility={true}/>
        </div>
      </div>}
      <div className="blaze">
        <BlazeExternalPage
          isMobileOpen={isMobileOpen}
          showMenuItem={showMenuItemFn}
          handleDrawerToggle={() => setIsMobileOpen(false)}
        />
      </div>
    </>
  );
}
