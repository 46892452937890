import React from 'react';
import {BlazeExternalPage, Navbar} from "../../containers";
import {BlazeExternalContextProvider} from '../../context'
import {useLocation} from "react-router-dom";

export default function Sessions() {
  const location = useLocation();

  return <>
    {location.pathname.includes('sessions') && <Navbar onlyBottom={true} />}
    <BlazeExternalPage
      onlySidebar={location.pathname.includes('sessions')}
      isMobileOpen={false}
      showMenuItem={() => {}}
      // handleDrawerToggle={() => setIsMobileOpen(false)}
      handleDrawerToggle={() => {}}
    />
  </>
}
