import React, { useContext, useState } from "react";
import StarRatings from "react-star-ratings";
import { ArrowBackIos } from "@material-ui/icons";
import { BlazeExternalContext } from "../../../context";
import { starPath } from "../../../helpers";

import "./style.scss";

const BlazeExternalPerformance = () => {
  const [rating, setRating] = useState(5);
  const [, setOpenPages] = useContext(BlazeExternalContext).openPages;

  // const changeRating = (newRating) => setRating(newRating);
  return (
    <div className="blaze__performance__wrapper">
      <div className="blaze__performance__head">
        <ArrowBackIos onClick={() => setOpenPages(false)} />{" "}
        <h1>Performance</h1>
      </div>
      <div className="blaze__performance__body">
        <div className="blaze__performance__summary">
          <StarRatings
            rating={rating}
            starRatedColor="#F49483"
            starHoverColor="#F49483"
            // changeRating={changeRating}
            numberOfStars={5}
            starDimension="20px"
            starSpacing="2px"
            name="rating"
            svgIconPath={starPath}
            svgIconViewBox="0 0 207.802 207.748"
          />
          <h1>Excellent</h1>
        </div>

        <div className="all__topics">
          {Array(5)
            .fill(1)
            .map((_, i) => (
              <div className="topic__container">
                <h2>Topic {i + 1}</h2>
                <StarRatings
                  rating={rating}
                  starRatedColor="#F49483"
                  starHoverColor="#F49483"
                  // changeRating={changeRating}
                  numberOfStars={5}
                  starDimension="20px"
                  starSpacing="2px"
                  name="rating"
                  svgIconPath={starPath}
                  svgIconViewBox="0 0 207.802 207.748"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BlazeExternalPerformance;
