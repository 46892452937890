import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {getInstructorSkills, getSkillsRating} from "../../database";
import {db, rdb} from "../../firebase_config";
import {newMsgAudio} from "../../assets";
import {getMonthActiveUsers, getNewBees, listenToOnlineUsers} from "../../database/connect";
import ActiveUsersScreen from "../../components/ActiveUsersScreen";
import axios from 'axios';

export const UserContext = createContext();


let audio = new Audio(newMsgAudio);

class Avatar {
  images = {};

  genUniqueColor(name) {
    const hRange = [0, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];

    const getHashOfString = (str) => {
      if(!str) return '';
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      hash = Math.abs(hash);
      return hash;
    };
    const normalizeHash = (hash, min, max) => {
      return Math.floor((hash % (max - min)) + min);
    };

    const generateHSL = () => {
      const hash = getHashOfString(name);
      const h = normalizeHash(hash, hRange[0], hRange[1]);
      const s = normalizeHash(hash, sRange[0], sRange[1]);
      const l = normalizeHash(hash, lRange[0], lRange[1]);
      return [h, s, l];
    };

    const HSLtoString = (hsl) => {
      return `hsl(${hsl[0]}deg, ${hsl[1]}%, ${hsl[2]}%)`;
    };

    return HSLtoString(generateHSL());
  }
  gen(name, uid = 'random-uid') {
    const str = this.genUniqueColor(uid);
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 64 64" version="1.1"><rect fill="${str}" cx="32" width="64" height="64" cy="32" r="32"/><text x="50%" y="50%" style="color: #fff; line-height: 1;font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;" alignment-baseline="middle" text-anchor="middle" font-size="32" font-weight="400" dy=".1em" dominant-baseline="middle" fill="#fff">${name[0]}</text></svg>
    `;
    const blob = new Blob([svg], {type: 'image/svg+xml'});
    return URL.createObjectURL(blob);
  }
  get(name = "S", uid) {
    if(!uid) return this.gen(name, uid);
    // if(this.images[uid]) {
    //   return this.images[uid];
    // }
    this.images[uid] = this.gen(name, uid);
    return this.images[uid];
  }
}

const avatarObj = new Avatar();

export function getAvatarImage(name, uid) {
  return avatarObj.get(name, uid)
}

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [pushyData, setPushyData] = useState(null);
  const [referrerId, setReferrerId] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isExternal, setIsExternal] = useState(false);
  const [hasSessions, setHasSessions] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [closeCarePage, setCloseCarePage] = useState(true);
  const [adminVideos, setAdminVideos] = useState(null);
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [showBlazeMain, setShowBlazeMain] = useState(false);
  const [isUserProTier, setIsUserProTier] = useState(false);
  const [blazeCallAlert, setBlazeCallAlert] = useState(false);
  const [openPustackCare, setOpenPuStackCare] = useState(false);
  const [unreadAnswerCount, setUnreadAnswerCount] = useState(0);
  const [closeInstallApp, setCloseInstallApp] = useState(false);
  const [openMenuSettings, setOpenMenuSettings] = useState(false);
  const [unreadCareMsgCount, setUnreadCareMsgCount] = useState(0);
  const [sectionHighlighted, setSectionHighlighted] = useState(0);
  const [openBlazeCallModal, setOpenBlazeCallModal] = useState(false);
  const [checkedLoggedInStatus, setCheckedLoggedInStatus] = useState(false);
  const [instructorSkills, setInstructorSkills] = useState(null);
  const [instructorSkillsRating, setInstructorSkillsRating] = useState(null);
  const [earningVideos, setEarningVideos] = useState(null);
  const [dashboardVideos, setDashboardVideos] = useState(null);
  const [acceptedBlazeSessionList, setAcceptedBlazeSessionList] = useState(null);
  const [blazeUnreadCount, setBlazeUnreadCount] = useState({});
  const [newMessage, setNewMessage] = useState(false);
  const [profileUser, setProfileUser] = useState(null);
  const [permissions, setPermissions] = useState({
    camera: false,
    microphone: false,
  });
  const [tutorEarnings, setTutorEarnings] = useState(null);
  const [openNameModal, setOpenNameModal] = useState(false);

  const [activeUsers, setActiveUsers] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(null);
  const [newUsers, setNewUsers] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [showDrawer, setShowDrawer] = useState(null);

  const paginatedList = useRef(null);
  const onlinePaginatedList = useRef(null);
  const newPaginatedList = useRef(null);
  const [noMoreActiveUsers, setNoMore] = useState(false);
  const [noMoreNewUsers, setNoMoreNewUsers] = useState(false);
  const [noMoreOnlineUsers, setNoMoreOnlineUsers] = useState(true);

  const [avatarImage, setAvatarImage] = useState(null);

  useEffect(() => {
    if(!user || !user.is_instructor) return;
    listenToOnlineUsers(async (list) => {
      console.log('list - ', list);

      onlinePaginatedList.current = list;

      await list.initialLoad();

      console.log('list.transformedList - ', list.transformedList);

      if(onlinePaginatedList.current?.noMore) {
        setNoMoreOnlineUsers(true);
      } else {
        setNoMoreOnlineUsers(false);
        await list.fetchMore();
      }

      await list.transformList();

      setOnlineUsers(list.transformedList);
    });

    getNewBees().then(async (list) => {
      newPaginatedList.current = list;

      await list.initialLoad();

      if(newPaginatedList.current?.noMore) {
        setNoMoreNewUsers(true);
      } else {
        await list.fetchMore();
      }

      await list.transformList();
      console.log('list.transformedList - ', list.transformedList);

      setNewUsers(list.transformedList);
    })

    getMonthActiveUsers(0).then(async (list) => {
      console.log('list - ', list);
      paginatedList.current = list;

      await list.initialLoad();

      if(paginatedList.current?.noMore) {
        setNoMore(true);
      } else {
        await list.fetchMore();
      }

      await list.transformList();

      setActiveUsers(list.transformedList);
    })
  }, [user]);

  useEffect(() => {
    if(!showDrawer) setActiveTab(0);
  }, [showDrawer])

  const activeUsersScreen = (<ActiveUsersScreen fetchMore={async (setNoMore) => {
    if(!paginatedList.current) return;
    await paginatedList.current?.fetchMore();

    if(paginatedList.current?.noMore) {
      setNoMore(true);
    }

    await paginatedList.current?.transformList();

    setActiveUsers(paginatedList.current.transformedList);
  }} fetchMoreOnlineUsers={async (setNoMoreOnlineUsers) => {
    if(!onlinePaginatedList.current) return;
    await onlinePaginatedList.current?.fetchMore();

    if(onlinePaginatedList.current?.noMore) {
      setNoMoreOnlineUsers(true);
    }

    await onlinePaginatedList.current?.transformList();

    setOnlineUsers(onlinePaginatedList.current.transformedList);
  }} fetchMoreNewUsers={async (setNoMoreNewUsers) => {
    if(!newPaginatedList.current) return;
    await newPaginatedList.current?.fetchMore();

    console.log('newPaginatedList.current - ', newPaginatedList.current);

    if(newPaginatedList.current?.noMore) {
      console.log('is it coming in no More - ');
      setNoMoreNewUsers(true);
    }

    await newPaginatedList.current?.transformList();

    setNewUsers(newPaginatedList.current.transformedList);
  }} noMoreNewUsers={noMoreNewUsers} noMoreActiveUsers={noMoreActiveUsers} noMoreOnlineUsers={noMoreOnlineUsers} handleBackButton={() => setShowDrawer(null)} />);



  useEffect(() => {
    db.collection('admin_videos')
      .doc('pustack_tutor_app')
      .get()
      .then(async (snapshot) => {
        if(snapshot.exists) {
          const data = snapshot.data();
          setAdminVideos(data.videos);
          let videoList1 = [];
          for(let i = 0; i < data.videos.home_video_list.length; i++) {
            let c = data.videos.home_video_list[i];
            // const {url} = await getVimeoURL(c.video_id);
            videoList1.push({
              ...c,
              // url
            })
          }
          setDashboardVideos(videoList1);
          let videoList2 = [];
          for(let i = 0; i < data.videos.earning_video_list.length; i++) {
            let c = data.videos.earning_video_list[i];
            // const {url} = await getVimeoURL(c.video_id);
            videoList2.push({
              ...c,
              // url
            })
          }
          setEarningVideos(videoList2);
        }
      })
  }, []);

  function listenToAcceptedSessions(sessions, unreadSessions, userID) {
    if(!sessions || !unreadSessions) return;
    const keys = Object.keys(unreadSessions);
    const expiredKeys = keys.filter(key => {
      const inSessionList = sessions.some(c => c.id === key);
      return !inSessionList;
    });
    expiredKeys.forEach(key => {
      if(unreadSessions[key] > 0) {
        setBlazeUnreadCount(c => ({...c, [key]: 0}));
        rdb.ref('/users/' + userID + '/pustack_tutor_app/unread_blaze_message_count/' + key).set(0);
      }
    })
  }

  useEffect(() => {
    if(!user?.uid) return;
    listenToAcceptedSessions(acceptedBlazeSessionList, blazeUnreadCount, user?.uid)
  }, [acceptedBlazeSessionList, user, blazeUnreadCount])

  useEffect(() => {
    if(!user?.uid) return;
    const blazeTotalMessagesCount = (userID) => {

      let prevValues = {};
      rdb.ref('/users/' + userID + '/pustack_tutor_app/unread_blaze_message_count').on('value', (snapshot) => {
        console.log('snapshot.val() = ', snapshot);
        for(let i in snapshot.val()) {
          let val = snapshot.val()[i];
          const prevVal = prevValues[i];
          if(val > 0 && prevVal !== val) {
            console.log('val - ', val, prevVal, i);
            setNewMessage(i);
            setTimeout(() => setNewMessage(false), 100);
          }
        }
        prevValues = snapshot.val() ?? {};
        if(snapshot.val()) setBlazeUnreadCount(snapshot.val());
      })
    };
    blazeTotalMessagesCount(user?.uid);
  }, [user?.uid]);

  useEffect(() => {
    if(newMessage && window.location.pathname !== ('/blaze/chat/' + newMessage)) {
      audio.currentTime = 0;
      audio.play();
      setNewMessage(false);
    }
  }, [newMessage])

  const getInstructorSkillsFn = () => {
    getInstructorSkills({
      instructorId: user?.uid,
      callback: (_skills) => setInstructorSkills(_skills),
    });
  };
  const getSkillsRatingFn = async () => {
    const res = await getSkillsRating({ instructorId: user?.uid });

    setInstructorSkillsRating(res);
  };

  useEffect(() => {
    if(user?.uid) {
      getInstructorSkillsFn();
      getSkillsRatingFn();
    }
  }, [user?.uid]);

  return (
    <UserContext.Provider
      value={{
        user: [user, setUser],
        openMenu: [openMenu, setOpenMenu],
        openChat: [openChat, setOpenChat],
        pushyData: [pushyData, setPushyData],
        tier: [isUserProTier, setIsUserProTier],
        isExternal: [isExternal, setIsExternal],
        referrerId: [referrerId, setReferrerId],
        hasSessions: [hasSessions, setHasSessions],
        permissions: [permissions, setPermissions],
        totalSeconds: [totalSeconds, setTotalSeconds],
        isInstructor: [isInstructor, setIsInstructor],
        openSearchBox: [openSearchBox, setOpenSearchBox],
        closeCarePage: [closeCarePage, setCloseCarePage],
        tutorEarnings: [tutorEarnings, setTutorEarnings],
        showBlazeMain: [showBlazeMain, setShowBlazeMain],
        blazeCallAlert: [blazeCallAlert, setBlazeCallAlert],
        closeInstallApp: [closeInstallApp, setCloseInstallApp],
        openPustackCare: [openPustackCare, setOpenPuStackCare],
        openMenuSettings: [openMenuSettings, setOpenMenuSettings],
        unreadAnswerCount: [unreadAnswerCount, setUnreadAnswerCount],
        unreadCareMsgCount: [unreadCareMsgCount, setUnreadCareMsgCount],
        sectionHighlighted: [sectionHighlighted, setSectionHighlighted],
        openBlazeCallModal: [openBlazeCallModal, setOpenBlazeCallModal],
        instructorSkills: [instructorSkills, setInstructorSkills],
        instructorSkillsRating: [instructorSkillsRating, setInstructorSkillsRating],
        checkedLoggedInStatus: [
          checkedLoggedInStatus,
          setCheckedLoggedInStatus,
        ],
        acceptedBlazeSessionList: [acceptedBlazeSessionList, setAcceptedBlazeSessionList],
        earningVideos: [earningVideos],
        dashboardVideos: [dashboardVideos],
        blazeUnreadCount: [blazeUnreadCount, setBlazeUnreadCount],
        showDrawer: [showDrawer, setShowDrawer],
        profileUser: [profileUser, setProfileUser],
        activeUsers: [activeUsers, setActiveUsers],
        onlineUsers: [onlineUsers, setOnlineUsers],
        newUsers: [newUsers, setNewUsers],
        activeTab: [activeTab, setActiveTab],
        openNameModal: [openNameModal, setOpenNameModal],
        activeUsersScreen,
        avatarImage,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
