import React from "react";
import { UserAuth } from "../../../containers";

import "./style.scss";
import {Helmet} from "react-helmet";

export default function SignIn() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PuStack Tutor</title>
      </Helmet>
      <UserAuth />
    </>
  );
}
