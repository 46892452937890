import React, {useContext, useMemo} from "react";
import RightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import "./style.scss";
import {UserContext} from "../../../context";
import {getAvatarImage} from "../../../context/global/user-context";
import Avatar from "../../global/Avatar";

const OngoingCard = ({ title, gradient, studentImage, studentName, studentId, skill, sessionId }) => {
  const [blazeUnreadCount] = useContext(UserContext).blazeUnreadCount;

  const unreadMsgCount = useMemo(() => {
    if(blazeUnreadCount && blazeUnreadCount[sessionId]) {
      return blazeUnreadCount[sessionId]
    }
    return 0;
  }, [blazeUnreadCount])

  return (
    <div className="ongoing-card fadeIn">
      <div className="ongoing-card-bg" />
      <div className="card-content">
        <div
          className="card-neon-line"
          style={{
            backgroundColor: gradient[0],
            boxShadow: `4px 0 14px 3px ${gradient[1]}80`,
          }}
        />
        <h1>{title}</h1>
        {unreadMsgCount > 0 && (
          <div className="unread-messages">
            <h6>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</h6>
          </div>
        )}
      </div>
      <div className="card-divider"></div>
      <div className="student-details">
        <div className="student-inner">
          <div className="student-img">
            <Avatar
              className="image__student"
              src={studentImage}
              name={studentName}
              uid={studentId}
              alt="Pustack User"
            />
          </div>

          <section>
            <div>
              <h4 className="capitalize">{studentName ? studentName : "Student"}</h4>
            </div>
            <h6>Class {skill?.split("_")[1]}th Student</h6>
          </section>
        </div>
        <button>
          <RightRoundedIcon />
        </button>
      </div>
    </div>
  );
};

export default OngoingCard;
