import { db, functions } from "../../firebase_config";
import { castIndianTime, formatCallDuration } from "../../helpers";
import axios from "axios";
import { formatDateDoc } from "../blaze/fetch-data";
import { transformDateTimeObject } from "../../helpers/functions/getIndianTime";
import { defaultPic } from "../../assets";

export const getBlazeRequests = ({ instructorSkills, callback }) => {
  let removedFromArray = [];
  db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("session_status", "==", "outstanding")
    .where("skill", "in", instructorSkills)
    .orderBy("requested_ts", "desc")
    .limit(10)
    .onSnapshot((snapshot) => {
      const requests = [];
      let docs = snapshot.docs.sort(
        (a, b) => b.data().requested_ts - a.data().requested_ts
      );
      console.log("snapshot - ", snapshot);
      const changes = snapshot.docChanges();

      changes.forEach((item) => {
        if (item.type === "removed")
          removedFromArray.push({ ref: item.doc.ref, ...item.doc.data() });
      });

      docs.map((item) => {
        return requests.push({ ref: item.ref, ...item.data() });
      });

      // Check for the threshold value

      callback(requests, removedFromArray);
    });
};

export const getBlazeExternalCompleted = async ({ instructorId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("session_status", "==", "completed")
    .where("instructor_id", "==", instructorId)
    .orderBy("completed_ts", "desc")
    .limit(20)
    .get()
    .then((snapshot) => {
      const requests = [];
      snapshot.docs.map((item) =>
        requests.push({ ref: item.ref, ...item.data() })
      );
      return requests;
    })
    .catch((err) => console.log(err));
};

export const getTutorAccepted = ({ instructorId, limit = 50, callback }) => {
  return db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("session_status", "==", "accepted")
    .where("instructor_id", "==", instructorId)
    .orderBy("last_message_ts", "desc")
    .limit(limit)
    .onSnapshot((snapshot) => {
      const requests = [];
      snapshot.docs.map((item) =>
        requests.push({ ref: item.ref, ...item.data() })
      );
      console.log("requests - ", requests);
      callback(requests);
    });
};

export const getOverallSkills = () => {
  return db
    .collection("blaze_dev")
    .doc("collections")
    .collection("overall_skills")
    .get()
    .then((snapshot) => {
      let skills = {};
      snapshot.docs.map(
        (item) => (skills = { ...skills, [item.id]: item.data() })
      );
      return skills;
    })
    .catch((err) => console.log(err));
};

export const addInstructorSkill = async ({ instructorId, skillId, skill }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("instructor_skills")
    .doc(skillId)
    .set({ ...skill })
    .then(() => true)
    .catch((err) => console.log(err));
};

export const postToWorkplace = async (uid) => {
  return axios.post(
    "https://us-central1-avian-display-193502.cloudfunctions.net/onTutorApplication",
    { uid }
  );
};

export const getInstructorSkills = ({ instructorId, callback }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("instructor_skills")
    .onSnapshot((snapshot) => {
      let skills = [];

      if (snapshot.docs.length > 0) {
        snapshot.docs.map((item) => skills.push(item.data()));
      }
      console.log("skills - ", skills[0]);
      callback(skills);
    });
};

export const getSkillsRating = async ({ instructorId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("stats")
    .doc("skill_wise_rating")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else return null;
    })
    .catch(() => null);
};

export const deleteInstructorSkills = async ({
  instructorId,
  skillsToDelete,
}) => {
  try {
    skillsToDelete.map(
      async (skillId) =>
        await db
          .collection("blaze_dev")
          .doc("collections")
          .collection("instructors")
          .doc(instructorId)
          .collection("instructor_skills")
          .doc(skillId)
          .delete()
    );
    return true;
  } catch (err) {
    return false;
  }
};

export const handleAcceptSession = async ({
  studentId,
  instructorId,
  instructorName,
  instructorPhoto,
  reference,
}) => {
  if (studentId === instructorId) {
    return "You cannot accept your own session";
  }

  const flag = await reference
    .get()
    .then((doc) => doc.data().session_status === "accepted");

  if (flag) {
    return "Session Already Accepted";
  }

  const instructorRating = await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("stats")
    .doc("skill_wise_rating")
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().average_rating;
      } else return 5;
    })
    .catch(() => 5);

  const ist = await castIndianTime(true);

  await reference.update({
    session_status: "accepted",
    accepted_ts: ist.serverISOString,
    instructor_id: instructorId,
    instructor_name: "Prof. " + instructorName.split(" ")[0],
    instructor_profile_pic: instructorPhoto ?? defaultPic,
    instructor_rating: instructorRating,
    last_message_ts: ist.serverISOString,
    last_message_sender_id: instructorId,
  });

  let onSessionAccept = functions.httpsCallable("onSessionAccept");

  onSessionAccept({
    student_id: studentId,
    session_id: reference.id,
  });

  return "updated";
};

export const getDeviceTokens = async ({ studentId, isStudent = false }) => {
  let webToken = "";
  let androidToken = "";

  await db
    .collection("user_tokens")
    .doc(studentId)
    .get()
    .then((result) => {
      if (result.exists) {
        const tokens = result.data()?.tokens;

        console.log("tokens - ", tokens);

        let key = isStudent ? "pustack_app" : "pustack_tutor_app";

        // @ts-ignore
        function getToken(nonWeb = false) {
          let cond = (item) =>
            nonWeb ? item.platform !== "web" : item.platform === "web";

          let tokenArr = tokens.filter(
            (item) => cond(item) && item.last_seen && item.app === key
          );
          let keyToCheck = "last_seen";

          if (!(tokenArr.length > 0)) {
            tokenArr = tokens.filter((item) => cond(item) && item.app === key);
            keyToCheck = "created_ts";
          }

          const tokenItem = tokenArr.sort(
            (a, b) => b[keyToCheck] - a[keyToCheck]
          )[0];

          if (!tokenItem)
            console.log(
              `No token found for the ${
                nonWeb ? "Android/IOS" : "Web"
              } Platform`
            );

          return {
            token: tokenItem?.token,
            ios_pushkit_token: tokenItem?.ios_pushkit_token,
          };
        }

        webToken = getToken();

        androidToken = getToken(true);
        // tokens.map((item) => {
        //   if (item.platform === "web") webToken = item.token;
        //   if (item.platform === "android") {
        //     androidToken = item.token;
        //   }
        // });
      }
    })
    .catch(() => ["", ""]);

  return [
    { token: webToken.token },
    {
      token: androidToken.token,
      ios_pushkit_token: androidToken.ios_pushkit_token,
    },
  ];
};

export const getAllDeviceTokens = async ({ studentId, isStudent = false }) => {
  let tokens = [];
  let pushkitTokens = [];

  await db
    .collection("user_tokens")
    .doc(studentId)
    .get()
    .then((result) => {
      if (result.exists) {
        const _tokens = result.data()?.tokens;

        console.log("tokens - ", _tokens);

        let key = isStudent ? "pustack_app" : "pustack_tutor_app";

        // @ts-ignore
        function getToken(isIos = false) {
          let cond = (item) =>
            isIos ? item.platform === "ios" : item.platform !== "ios";

          let tokenArr = _tokens.filter(
            (item) => cond(item) && item.app === key
          );

          return tokenArr.map((c) => (isIos ? c.ios_pushkit_token : c.token));
        }

        tokens = getToken();

        pushkitTokens = getToken(true);
        // tokens.map((item) => {
        //   if (item.platform === "web") webToken = item.token;
        //   if (item.platform === "android") {
        //     androidToken = item.token;
        //   }
        // });
      }
    })
    .catch(() => ["", ""]);

  return [tokens, pushkitTokens];
};

export const createCallDocument = async ({
  sessionId,
  meetingId,
  studentBalance,
  platform,
  aggregatedDuration,
  aggregatedEarnings,
}) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set({
      active_call_duration: 0,
      billed_amount: 0,
      instructor_platform: platform,
      meeting_id: meetingId,
      status: "initial",
      student_platform: "",
      session_aggregated_duration: aggregatedDuration ? aggregatedDuration : 0,
      session_aggregated_earnings: aggregatedEarnings ? aggregatedEarnings : 0,
      // initial_student_balance: studentBalance,
    })
    .then(() => true)
    .catch(() => false);
};

export const getStudentBalance = async ({ studentId }) => {
  return await db
    .collection("users")
    .doc(studentId)
    .collection("meta")
    .doc(studentId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().balance;
      } else return 0;
    })
    .catch(() => 0);
};

export const listenToCallDoc = ({ sessionId, meetingId, callback }) => {
  let unsubscribe = db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .onSnapshot((snapshot) => {
      let data = snapshot?.data();

      callback(data, unsubscribe, snapshot.ref);
    });
};

export const getCallStartTs = async ({ sessionId, meetingId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().call_start_ts;
      }
    })
    .catch(() => null);
};

export const startHandoverInstructor = async ({
  sessionId,
  meetingId,
  isSmallScreen,
}) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set(
      {
        status: "handover_instructor",
        instructor_platform: isSmallScreen ? "mweb" : "web",
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

export const completeHandoverStudent = async ({ sessionId, meetingId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set({ status: "engaged" }, { merge: true })
    .then(() => true)
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const updateCallDocumentInstructor = async ({
  sessionId,
  meetingId,
  status,
  duration,
  reason = null,
}) => {
  const ist = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(ist);
  db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("chats")
    .doc(meetingId)
    .set(
      {
        message_type: "call_event",
        event_description:
          status === "completed"
            ? `Call Duration • ${formatCallDuration(duration)}`
            : `Missed Call`,
        event_type: status === "completed" ? status : "missed",
        sent_on: ist.serverISOString,
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);

  db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set(
      {
        // active_call_duration: duration,
        status: status,
        sent_on: ist.serverISOString,
        reason_for_call_end: reason,
      },
      { merge: true }
    );
};

export const updateStudentEngagementByInstructor = async ({
  studentId,
  activeCallId,
  activeSessionId,
  isEngaged,
}) => {
  const ist = await castIndianTime(true);
  db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .set(
      {
        call_state: activeCallId
          ? {
              is_engaged: isEngaged,
              call_id: activeCallId,
              session_id: activeSessionId,
              expires_on: ist.add(30000).toISOString(),
            }
          : null,
      },
      { merge: true }
    );
};

export const updateRtmTokenInSession = ({ sessionId, token }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .set({ rtm_token: token }, { merge: true });
};

export const completeHandoverInstructor = async ({ sessionId, meetingId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .collection("calls")
    .doc(meetingId)
    .set({ status: "engaged" }, { merge: true })
    .then(() => true)
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const getInstructorActivity = ({ instructorId, callback }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .onSnapshot((response) => {
      if (response.exists) {
        callback(response.data());
      } else {
        callback(null);
      }
    });
};

export const updateInstructorStatus = ({
  isEngaged,
  instructorId,
  activeCallId,
  activeSessionId,
}) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .set(
      {
        call_state: activeCallId
          ? {
              is_engaged: isEngaged,
              call_id: activeCallId,
              session_id: activeSessionId,
            }
          : null,
      },
      { merge: true }
    );
};

export const getInstructorEarnings = ({ instructorId, callback }) => {
  try {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("earnings")
      .onSnapshot((data) => {
        if (data.docs.length > 0) {
          const len = data.docs.length;
          let arr = [];
          for (let i = 0; i < len; i++) {
            arr.push({ ...data.docs[i].data(), id: data.docs[i].id });
          }

          callback(arr);
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const getInstructorPayouts = ({ instructorId, limit = 5, callback }) => {
  try {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("payouts")
      .where("reference_id", "==", instructorId)
      .orderBy("created_at", "desc")
      .limit(limit)
      .onSnapshot((data) => {
        if (data.docs.length > 0) {
          const len = data.docs.length;
          let arr = [];
          for (let i = 0; i < len; i++) {
            arr.push(data.docs[i].data());
          }

          callback(
            arr.sort((a, b) => b?.notes?.payout_ts - a?.notes?.payout_ts)
          );
        }
      });
  } catch (err) {
    console.log(err);
  }
};

export const fetchStats = (instructorId, { year, month }, callback) => {
  return db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("engagement_stats")
    .doc(year.toString())
    .collection("monthly_engagement")
    .doc(formatDateDoc({ year, month, date: 1 }, true, true))
    .onSnapshot((snapshot) => {
      callback(snapshot.data());
    });
};

export const getInstructorEngagementFor30days = ({
  instructorId,
  prevYear,
  prevMonth,
  currentYear,
  currentMonth,
  prevCallback,
  currCallback,
}) => {
  console.log(
    "currentMonth - prevMonth - ",
    currentYear,
    currentMonth,
    prevYear,
    prevMonth
  );
  try {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("engagement_stats")
      .doc(currentYear)
      .collection("monthly_engagement")
      .doc(currentMonth)
      .onSnapshot((doc) => {
        console.log("doc.data() - ", doc.data());
        currCallback(doc.data());
        // if (doc.exists) {
        // }
      });

    db.collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("engagement_stats")
      .doc(prevYear)
      .collection("monthly_engagement")
      .doc(prevMonth)
      .onSnapshot((doc) => {
        console.log("doc.data() - ", doc.data());
        prevCallback(doc.data());
        // if (doc.exists) {
        // }
      });
  } catch (err) {
    console.log(err);
  }
};

export const getInstructorLifetimeEngagement = async ({ instructorId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("engagement_stats")
    .get()
    .then((data) => {
      let arr = [];
      const len = data.docs.length;

      if (len) {
        for (let i = 0; i < len; i++) {
          arr.push({ ...data.docs[i].data(), id: data.docs[i].id });
        }
      }
      return arr;
    })
    .catch(() => []);
};

export const updateBlazeMeetingSetting = ({
  instructorId,
  blazeMeetingSetting,
}) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .set(
      { blaze_meeting_setting: { ...blazeMeetingSetting } },
      { merge: true }
    );
};

export const getInstructorMediaPermissions = ({ instructorId, callback }) => {
  try {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (doc.data()?.blaze_meeting_setting) {
            callback({
              camera:
                doc.data()?.blaze_meeting_setting?.is_video_muted || false,
              microphone:
                doc.data()?.blaze_meeting_setting?.is_audio_muted || false,
            });
          } else {
            callback({
              camera: false,
              microphone: false,
            });
          }
        } else {
          callback({
            camera: false,
            microphone: false,
          });
        }
      });
  } catch (err) {
    console.log(err);
  }
};
