import React, {useContext, useState, useRef, useEffect, useMemo} from "react";
import Modal from "react-modal";
import Lottie from "lottie-react-web";
import Menu from "@material-ui/core/Menu";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToApp from "@material-ui/icons/ExitToApp";
import CheckCircle from "@material-ui/icons/CheckCircle";
import MoreVert from "@material-ui/icons/MoreVert";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import roundedVideoCamera from "../../../assets/blaze/rounded_video_camera.svg";
import {motion} from "framer-motion";
import {
  HourglassFullRounded, PlayArrow,
  RotateLeft, Timelapse, TimerRounded,
  VideoCall,
} from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import { TextareaAutosize } from "@material-ui/core";
import {
  endSession,
  getRtmToken,
  sendBlazeChat,
  getCallStartTs,
  isStudentEngaged,
  handleAcceptSession,
  startHandoverInstructor,
  getBlazeReservationChats,
  blazeDecreaseMessageCount,
  listenToOutstandingSession,
  getMoreBlazeReservationChats,
  getLatestBlazeReservationChats,
  getReceiverUnreadCount,
} from "../../../database";
import {
  UserContext,
  ThemeContext,
  BlazeExternalContext,
} from "../../../context";
import BlazeExternalCall from "../../blazeCall";
import pdfIcon from "../../../assets/images/pdf.svg";
import doubtIcon from "../../../assets/images/icons/doubt.svg";
import SendIcon from "../../../assets/blaze/sendIcon";
import noFilesIcon from "../../../assets/images/icons/no_files.svg";
import noImagesIcon from "../../../assets/images/icons/no_images.svg";
import imageGallery from "./../../../assets/images/blaze/imageGallery.svg";
import circularProgress from "../../../assets/lottie/circularProgress.json";
import {
  castIndianTime,
  formatCallDurationShort,
  getGrade,
  getIndianTime,
  groupBlazeMessages,
  monthToStringFormatter, randomAvatar, sortedISOString, starPath, wait,
} from "../../../helpers";

import { ReservationChats, ModalGallery } from "../../../components";
import { showSnackbar } from "../../../components/doubts_forum/snackbar/functions";
import {functions, rdb} from "../../../firebase_config";
import "./style.scss";
import {EndSessionModal} from "../../blaze/blazereservation";
import {ModalContext} from "../../../context/global/ModalContext";
import {fetchIndianTime} from "../../../helpers/functions/getIndianTime";
import {queryStudentEngagement, queryStudentAvailableDoubtCount, usedMinutesForToday} from "../../../database/blaze/fetch-data";
import StarRatings from "react-star-ratings";
import {useHistory, useLocation} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {TimeIcon} from "@material-ui/pickers/_shared/icons/TimeIcon";
import {getAvatarImage} from "../../../context/global/user-context";
import Avatar from "../../../components/global/Avatar";
import CancelIcon from "@material-ui/icons/Cancel";

export default function BlazeExternalReservations({
  setIsChatOpen,
  completedSessions,
  acceptedSessions,
  setCompletedSessions,
}) {
  const [user] = useContext(UserContext).user;
  const [isDark] = useContext(ThemeContext).theme;
  const [, setPushyData] = useContext(UserContext).pushyData;
  // const [isProTier] = useContext(UserContext).tier;
  const [, setActiveTab] = useContext(BlazeExternalContext).tabIdx;
  const [, setCallStartTs] = useContext(BlazeExternalContext).callStartTs;
  const [, setConfirmationModalData] = useContext(ModalContext).state;
  const [, setSessionId] = useContext(BlazeExternalContext).sessionId;
  const [sessionSelected, setSessionSelected] =
    useContext(BlazeExternalContext).sessionSelected;
  const [instructorActivity] =
    useContext(BlazeExternalContext).instructorActivity;
  const [openChat, setOpenChat] = useContext(BlazeExternalContext).openChat;
  const [currentSessionUnreadCount] =
    useContext(BlazeExternalContext).currentSessionUnreadCount;
  const [pdfs, setPdfs] = useState([]);
  const [images, setImages] = useState([]);
  const [chatText, setChatText] = useState("");
  const [animate, setAnimate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreChat, setMoreChat] = useState(false);
  const [chatsData, setChatsData] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [chatImages, setChatImages] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);
  const [expandExit, setExpandExit] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openPickers, setOpenPickers] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [emptyChat, setEmptyChat] = useState(false);
  const [chatDocuments, setChatDocuments] = useState(null);
  const [openBlazeCall, setOpenBlazeCall] = useState(false);
  const [hideAcceptBtn, setHideAcceptBtn] = useState(false);
  const [receiverUnreadCount, setReceiverUnreadCount] = useState(0);
  const [doubtsLeftOfStudent, setDoubtsLeftOfStudent] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [endSessionLoading, setEndSessionLoading] = useState(false);

  const topicCmsPath = sessionSelected?.topic_cms_path;

  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const handleClose = () => {
    setAnchorEl(null);
    setEndSessionLoading(false);
  }

  const handleSessionCompleted = async (setLoading) => {
    document.body.style.pointerEvents = 'none';
    setLoading(true);

    let ist = await castIndianTime(true);

    let sessionEndHandler = functions.httpsCallable("sessionEndHandler");

    const res = await endSession({
      sessionId: sessionSelected?.id,
      completedTs: ist.serverISOString,
      ratingTs: null,
      rating: null,
      studentId: sessionSelected?.student_id,
      instructorId: sessionSelected?.instructor_id,
      instructorName: sessionSelected?.instructor_name,
      instructorProfilePic: sessionSelected?.instructor_profile_pic,
      skill: sessionSelected?.skill,
      topic: sessionSelected?.topic,
      is_out_reach: sessionSelected?.is_outreach_session
    });

    setLoading(false);
    setExpandExit(true);
    document.body.style.pointerEvents = 'all';

    if(!res) return;


    rdb.ref('/users/' + sessionSelected?.student_id + '/pustack_app/unread_blaze_message_count/' + sessionSelected?.id).set(0);
    rdb.ref('/users/' + sessionSelected?.instructor_id + '/pustack_tutor_app/unread_blaze_message_count/' + sessionSelected?.id).set(0);

    if(!sessionSelected?.is_outreach_session) {
      sessionEndHandler({
        student_id: sessionSelected?.student_id,
        instructor_id: sessionSelected?.instructor_id,
        session_end_time: ist.serverISOString
      });
    }

    if (res) {
      let _sessionSelected = {
        ...sessionSelected,
        session_status: "completed",
        completed_ts: ist.serverISOString,
      };
      setCompletedSessions(
        sortedISOString([...completedSessions, _sessionSelected], 'completed_ts')
      );
      setSessionSelected(_sessionSelected);
      setActiveTab(1);
      handleClose();
    }
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  };

  useEffect(() => {
    if(!sessionSelected || !sessionSelected.student_id || sessionSelected?.completed_ts) return;

    queryStudentAvailableDoubtCount(sessionSelected.student_id)
      .then(doubtsLeft => {
        if(!doubtsLeft) return;
        setDoubtsLeftOfStudent(doubtsLeft.has_unlimited_doubts ? 'Unlimited' : doubtsLeft.available_doubt_count)
      })
  //
  //   queryStudentEngagement(sessionSelected.student_id)
  //     .then(({total_call_duration, upper_limit}) => {
  //       if(upper_limit === 0) {
  //         setTimeLeftOfStudent({val: -1});
  //         return;
  //       }
  //       let minutesLeft;
  //       let minuteLeftDec = (upper_limit - total_call_duration) / 60;
  //       if(minuteLeftDec < 1) {
  //         minutesLeft = {val: upper_limit - total_call_duration, unit: 'second' + (upper_limit - total_call_duration > 1 ? 's' : '')};
  //       } else {
  //         minutesLeft = {val: Math.floor(minuteLeftDec), unit: 'minute' + (Math.floor(minuteLeftDec) > 1 ? 's' : '')};
  //       }
  //       if(minutesLeft.val < 0) minutesLeft.val = 0;
  //       setTimeLeftOfStudent(minutesLeft);
  //     })
  //   // usedMinutesForToday(sessionSelected.student_id)
  //   //   .then(usedTime => {
  //   //     let minutesLeft;
  //   //     let minuteLeftDec = (480 - usedTime) / 60;
  //   //     if(minuteLeftDec < 1) {
  //   //       minutesLeft = {val: 480 - usedTime, unit: 'second' + (480 - usedTime > 1 ? 's' : '')};
  //   //     } else {
  //   //       minutesLeft = {val: Math.floor(minuteLeftDec), unit: 'minute' + (Math.floor(minuteLeftDec) > 1 ? 's' : '')};
  //   //     }
  //   //     setTimeLeftOfStudent(minutesLeft);
  //   //   });
  //   // const usedMinutes = await usedMinutesForToday(user.uid);
  }, [sessionSelected])

  const changeRef = useRef(true);

  useEffect(() => {
    setPdfs([]);
    setImages([]);
    setChatText("");
    setChatsData(null);
    setMoreChat(false);
    setIsSending(false);
    setIsAccepting(false);
    setOpenPickers(false);

    getChatsFn();
  }, [sessionSelected?.id]);

  useEffect(() => {
    if(!sessionSelected?.id || !completedSessions || !acceptedSessions) return;
    const _isCompleted = completedSessions.find(c => c.id === sessionSelected?.id);
    const _isAccepted = acceptedSessions.find(c => c.id === sessionSelected?.id);

    if (sessionSelected?.session_status === "outstanding") {
      setIsAccepted(false);
      setHideAcceptBtn(false);
      setIsCompleted(false);
    } else if(_isAccepted && !isAccepted) {
      setIsAccepted(true);
      setIsCompleted(false);
    } else if(_isCompleted && !isCompleted) {
      setIsAccepted(false);
      setIsCompleted(true);
      setActiveTab(1);
    }
  }, [acceptedSessions, isAccepted, isCompleted, completedSessions, sessionSelected]);

  
  const getLatestChats = (data) => {
    getLatestBlazeReservationChats({
      reference: sessionSelected?.ref,
      user_id: user?.uid,
      doc: data ? data[data.length - 1] : null,
      callback: (e, isModified) => {
        if(!e) {
          return setEmptyChat(true);
        }
        setEmptyChat(false);
        iterateMedia([...(data || []), ...e]);
        setChatsData(c => {
          let arr = [...(c || []), ...e];
          if(isModified) {
            let tempArr = [...c];
            tempArr[tempArr.length - 1] = e[0];
            arr = [...tempArr];
          }
          return groupBlazeMessages(arr);
        });
        // setMoreChat(true);
      },
    });
  };

  const onVisibilityChange = (e) => {
    if(document.visibilityState === 'visible') {
      setIsTabActive(true);
    } else if(document.visibilityState === 'hidden') {
      setIsTabActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (sessionSelected?.id) {
      getReceiverUnreadCount({
        sessionId: sessionSelected?.id,
        receiverId: sessionSelected?.student_id,
        callback: (count) => setReceiverUnreadCount(count),
      });
    }
  }, [sessionSelected?.id]);

  useEffect(() => {
    if (chatsData?.length > 0 && !isSending) {
      let groupedMessages = [...chatsData];

      let selfMsgCount = 0;

      for (let i = 0; i < groupedMessages?.length; i++) {
        if (groupedMessages[i]?.isByUser) {
          selfMsgCount++;
        }
      }
      selfMsgCount = Math.abs(selfMsgCount - receiverUnreadCount);

      groupedMessages = groupedMessages.map((msg) => {
        if (msg?.isByUser && selfMsgCount > 0) {
          selfMsgCount--;
          msg["hasRead"] = true;
        } else if (msg?.isByUser && selfMsgCount === 0) {
          msg["hasRead"] = false;
        }
        return msg;
      });

      setChatsData(groupedMessages);
    }
  }, [receiverUnreadCount, chatsData?.length, sessionSelected?.id, isSending]);

  useEffect(() => {
    if(!sessionSelected || !user) return;
    if (currentSessionUnreadCount > 0 && isTabActive && !openBlazeCall) {
      blazeDecreaseMessageCount(
        sessionSelected?.ref,
        currentSessionUnreadCount,
        user?.grade,
        user?.uid,
        true,
        sessionSelected?.student_id,
        sessionSelected?.id
      );
    }

    if(isSmallScreen && isTabActive && (!openBlazeCall || (openBlazeCall && openChat)) && location.pathname === ('/blaze/chat/' + sessionSelected?.id)) {
      blazeDecreaseMessageCount(
        sessionSelected?.ref,
        currentSessionUnreadCount,
        user?.grade,
        user?.uid,
        true,
        sessionSelected?.student_id,
        sessionSelected?.id
      );
    }

    if (
      openChat &&
      openBlazeCall &&
      currentSessionUnreadCount > 0 &&
      isTabActive
    ) {
      blazeDecreaseMessageCount(
        sessionSelected?.ref,
        currentSessionUnreadCount,
        user?.grade,
        user?.uid,
        true,
        sessionSelected?.student_id,
        sessionSelected?.id
      );
    }
  }, [currentSessionUnreadCount, user, sessionSelected, isTabActive, openBlazeCall, openChat, location, isSmallScreen]);

  const iterateMedia = (e) => {
    let _images = [];
    let _documents = [];

    e.forEach((chat) => {
      if (chat?.type === "image") {
        _images.push({
          timestamp: new Date(chat?.timestamp),
          url: chat?.attachment?.url,
        });
      } else if (chat?.type === "document") {
        _documents.push({
          name: chat?.attachment?.name,
          timestamp: new Date(chat?.timestamp),
          url: chat?.attachment?.url,
        });
      }
    });

    setChatImages(_images);
    if (_documents.length !== chatDocuments?.length)
      setChatDocuments(_documents);
  };

  const [onlineUsers, setOnlineUsers] = useState({});
  const [inActiveUsers, setInactiveUsers] = useState({});
  useEffect(() => {
    rdb.ref('/user_state/pustack_app').on('value', function(snap) {
      const value = snap.val();
      if(!value) return;

      console.log('value - ', value);

      setOnlineUsers(value.active_users ? value.active_users : {});
      setInactiveUsers(value.inactive_users ? value.inactive_users : {});
    })
    // rdb.ref('/user_state/pustack_app/inactive_users').on('value', function(snap) {
    //   const value = snap.val();
    //   setInactiveUsers(value ? value : {});
    // })
  }, [])

  const handleAccept = async () => {
    setIsAccepting(true);

    const msg = await handleAcceptSession({
      studentId: sessionSelected?.student_id,
      instructorId: user?.uid,
      sessionId: sessionSelected?.id,
      instructorName: user?.name,
      instructorPhoto: user?.profile_url,
      reference: sessionSelected?.ref,
    });

    setIsAccepting(false);

    if (msg !== "updated") {
      return showSnackbar(msg, "info");
    }

    setHideAcceptBtn(true);
    setActiveTab(0);
    setTimeout(() => {
      setAnimate(true);
      setIsAccepted(true);
    }, 500);
  };

  const handleMessageSubmit = async () => {
    if(isSending) return;
    setIsSending(true);
    setChatText("");
    await sendBlazeChat({
      user: user,
      text: chatText,
      reference: sessionSelected?.ref,
      images: images,
      pdfs: pdfs,
      session_id: sessionSelected?.id,
      receiver_id: sessionSelected?.student_id,
      session_topic: sessionSelected?.topic,
      student_grade: getGrade(sessionSelected?.skill),
      type: images.length > 0 || pdfs.length > 0 ? "attachment" : "text",
    });
    setImages([]);
    setPdfs([]);
    setChatText("");
    setIsSending(false);
    changeRef.current = true;

    if(emptyChat) {
      getChatsFn();
      setEmptyChat(false);
    }
  };

  const imageSelectionHandler = (e) => {
    const { files } = e.target;

    let _images = [];

    let _num =
      files.length + images.length > 1
        ? images.length - files.length > 0
          ? images.length - files.length
          : 1
        : files.length;

    if (files.length + images.length > 1) {
      showSnackbar("Only 1 image is allowed at a time", "info");
    }

    if (images.length !== 1) {
      for (let i = 0; i < _num; i++) {
        _images[i] = {
          url: URL.createObjectURL(files[i]),
          ext: files[i].name.split(".").slice(-1)[0],
          name: files[i].name,
        };
      }
      setImages(images.concat(_images));
    }
    e.target.value = "";
    setOpenPickers(false);
  };

  const handleImageDelete = () => setImages([]);
  const handlePdfDelete = () => setPdfs([]);

  const pdfSelectionHandler = (e) => {
    const { files } = e.target;

    let _pdfs = [];

    let _num =
      files.length + pdfs.length > 1
        ? pdfs.length - files.length > 0
          ? pdfs.length - files.length
          : 1
        : files.length;

    if (files.length + pdfs.length > 1) {
      showSnackbar("Only 1 pdf is allowed at a time", "info");
    }

    if (pdfs.length !== 1) {
      for (let i = 0; i < _num; i++) {
        _pdfs[i] = {
          url: URL.createObjectURL(files[i]),
          ext: files[i].name.split(".").slice(-1)[0],
          name: files[i].name,
          size: parseFloat(files[i].size / 1024 / 1024).toFixed(2),
        };
      }
      setPdfs(pdfs.concat(_pdfs));
    }
    e.target.value = "";
    setOpenPickers(false);
  };

  const handleBlazeCallClose = () => {
    setOpenBlazeCall(false);
    setOpenChat(false);
    setPushyData(null);
  };

  const handleCallStudent = async () => {
    const res = await isStudentEngaged({
      studentId: sessionSelected?.student_id,
    });
    if (res) {
      return showSnackbar("Student is busy in another blaze session", "info");
    }
    setOpenBlazeCall(true);
  };

  const getTotalCallDuration = () => {
    let totalDuration = sessionSelected?.aggregated_duration;

    return formatCallDurationShort(totalDuration);
  };

  const getChatsFn = async () => {
    let _data = await getBlazeReservationChats({
      reference: sessionSelected?.ref,
      user_id: user?.uid,
      limit: 25,
    });

    if (_data?.length === 25) setTimeout(() => setMoreChat(true), 500);
    else setMoreChat(false);

    iterateMedia(_data);
    setChatsData(groupBlazeMessages(_data));
    getLatestChats(_data);
  };

  const getMoreChatsFn = async (
    chats,
    setIsFetching,
    setAutoScroll,
    firstChild,
    fetchedLength
  ) => {
    let _data = await getMoreBlazeReservationChats({
      reference: sessionSelected?.ref,
      user_id: user?.uid,
      doc: chats[0],
    });

    if (_data?.length < 10) {
      setMoreChat(false);
    } else {
      setMoreChat(true);
    }

    fetchedLength.current = _data.length;

    let finalData = [..._data, ...chats];

    iterateMedia(finalData);
    firstChild.current.scrollIntoView();

    setChatsData(groupBlazeMessages(finalData));
    setIsFetching(false);
    setTimeout(() => {
      setAutoScroll(true);
    }, 100);
  };

  const handleHandover = async () => {
    const response = await startHandoverInstructor({
      sessionId: instructorActivity?.sessionId,
      meetingId: instructorActivity?.meetingId,
      isSmallScreen: isSmallScreen,
    });

    const _callStartTs = await getCallStartTs({
      sessionId: instructorActivity?.sessionId,
      meetingId: instructorActivity?.meetingId,
    });

    setCallStartTs(_callStartTs);

    const rtmToken = await getRtmToken({
      sessionId: instructorActivity?.sessionId,
    });

    if (response && rtmToken) {
      setPushyData({
        session_id: instructorActivity?.sessionId,
        meeting_id: instructorActivity?.meetingId,
        rtm_token: rtmToken,
      });

      setOpenBlazeCall(true);
    }
  };

  useEffect(() => {
    if (sessionSelected?.session_status === "outstanding" && user?.uid) {
      listenToOutstandingSession({
        sessionId: sessionSelected?.id,
        callback: (session, unsubscribe) => {
          if (
            session?.session_status === "accepted" &&
            session?.instructor_id !== user?.uid
          ) {
            setIsChatOpen(false);
            setSessionId(null);
            unsubscribe();
          }
        },
      });
    }
  }, [sessionSelected?.session_status, user?.uid]);

  const underTopicHeading = useMemo(() => {
    if(!sessionSelected) return null;
    let a = sessionSelected.skill;
    let arr = a.split('_');
    arr = arr.map(a => a.slice(0, 1).toUpperCase() + a.slice(1));
    arr[1] += 'th';
    let newArr = arr.slice(0, 2);
    newArr.push(arr.at(-1));
    return newArr.join(' ');
  }, [sessionSelected])

  const imageStudentRef = useRef(null);
  useEffect(() => {
    imageStudentRef.current = randomAvatar(sessionSelected?.student_name ?? 'N', 'flex-shrink-0')
  }, [sessionSelected?.student_name])

  return (
    <div className="blaze__external__reservations">
      <div className="reservations__body">
        <div className="reservations__chat__body">
          {isSmallScreen && (
            <div className="reservations__head" style={{height: '70px'}}>
              <div className="student__head">
                <ArrowBackIcon onClick={() => history.push('/sessions')} />
                <div>
                  <h1 className="capitalize" style={{whiteSpace: 'nowrap', width: '46vw', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 0}}>{sessionSelected?.topic}</h1>
                  {underTopicHeading && <p className="capitalize" style={{color: 'var(--color-text)', opacity: 0.5, marginTop: '3px', fontSize: '12px'}}>{underTopicHeading}</p>}
                </div>
              </div>

              <div className="reservations__options">
                {user?.uid === sessionSelected?.instructor_id && (!isCompleted &&
                  (!isAccepted ? (
                    <button
                      onClick={handleAccept}
                      className={hideAcceptBtn && "hide__accept__btn"}
                    >
                      {isAccepting ? (
                        <Lottie
                          options={{
                            animationData: circularProgress,
                            loop: true,
                          }}
                        />
                      ) : (
                        <span>Accept</span>
                      )}
                    </button>
                  ) : !instructorActivity?.isEngaged ? (
                    <div className={animate ? "popIn" : ""}>
                      <button onClick={handleCallStudent} className="call__btn">
                        <img
                          src={roundedVideoCamera}
                          alt="vc"
                          draggable={false}
                        />
                        <span>Call</span>
                      </button>
                    </div>
                  ) : instructorActivity?.sessionId === sessionSelected?.id ? (
                    <div className="popIn">
                      <button onClick={handleHandover} className="call__btn">
                        <img
                          src={roundedVideoCamera}
                          alt="vc"
                          draggable={false}
                        />
                        <span>JOIN</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )))}

                {user?.uid === sessionSelected?.instructor_id && isAccepted && (
                  <ExitToApp
                    aria-label="more"
                    aria-controls="session-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setConfirmationModalData({
                        open: true,
                        Children: <EndSessionModal
                          title="End Session"
                          description="Are you sure you want to end this session?"
                          onConfirm={handleSessionCompleted}
                        />
                      })
                    }}
                    style={{
                      fill: "red",
                      cursor: "pointer",
                    }}
                  />
                )}

                {sessionSelected?.completed_ts &&
                  <StarRatings
                    key={"star_new_" + sessionSelected?.rating}
                    rating={sessionSelected?.rating ?? 0}
                    starRatedColor="#fec107"
                    starHoverColor="#fec107"
                    numberOfStars={5}
                    starDimension="18px"
                    starSpacing="2.5px"
                    name="rating"
                    svgIconPath={starPath}
                    svgIconViewBox="0 0 207.802 207.748"
                  />
                }

                <Menu
                  id={isDark ? "session-menu-dark" : "session-menu"}
                  anchorEl={anchorEl}
                  // keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{ style: { maxHeight: 48 * 4.5, width: "20ch" } }}
                >
                  <MenuItem key={0} onClick={() => {
                    handleClose();
                    setConfirmationModalData({
                      open: true,
                      Children: <EndSessionModal
                        title="End Session"
                        description="Are you sure you want to end this session?"
                        onConfirm={handleSessionCompleted}
                      />
                    })
                  }}>
                    End Session
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}
          <div className="reservations__head">
            <div className="student__head">
              <Avatar
                lastSeen={inActiveUsers[sessionSelected?.student_id]}
                isOnline={onlineUsers[sessionSelected?.student_id]}
                className="image__student"
                src={sessionSelected?.student_profile_pic}
                key={sessionSelected?.id}
                name={sessionSelected?.student_name}
                uid={sessionSelected?.student_id}
                alt="Pustack User"
              />
              <h1 className="capitalize">
                {sessionSelected?.student_name
                  ? sessionSelected?.student_name
                  : "No Name"}
              </h1>
              {!isSmallScreen && doubtsLeftOfStudent != null && <div className="student__talk-time">
                <div>
                  <p className="head">Doubts Left</p>
                  <p className="value">{doubtsLeftOfStudent}</p>
                </div>
                <div className="icon-div">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    x="0"
                    y="0"
                    fill="#1FBFFF"
                    enableBackground="new 0 0 100 100"
                    viewBox="0 0 100 100"
                    className="doubt_count w-7 h-7 text-white border-none"
                  >
                    <path d="M55.1 2.5C36.7 2.5 21.6 16.6 20 34.7c-.1.9-.3 1.8-.8 2.7l-9 13.7c-1.7 2.6.2 6.1 3.3 6.1h5.2c.6 0 1.2.5 1.2 1.2v14.2c0 3.3 2.6 5.9 5.9 5.9h10.6c.9 0 1.7.8 1.7 1.7v14.3c0 1.8 1.5 3.2 3.3 3.1l30.3-2.7c1.6-.1 2.8-1.5 2.8-3.1V71.6c0-1.4.6-2.6 1.7-3.5 8.6-7.4 14.2-18.7 14.2-30.3 0-19.5-15.8-35.3-35.3-35.3zm-.3 67.9c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3zm12.7-27.3c-2.1 2.8-4.1 4.3-5.7 5.3-1.7 1.2-2.5 1.7-2.7 4.6-.1 1.2-1.2 2.2-2.4 2.2h-3.8c-1.4 0-2.5-1.2-2.4-2.6.5-6.6 3.1-8.9 6.3-11.2 1.3-.9 2.4-1.7 3.7-3.4 1.2-1.7 1.4-3.9.4-5.9-.7-1.3-2.4-3.6-6.6-3.6-5.5 0-7.2 3.5-7.7 6.2-.2 1.2-1.2 2-2.4 2h-3.8c-1.5 0-2.6-1.3-2.4-2.8 1-7.2 6.2-14.1 16.3-14.1 6.4 0 11.7 3.1 14.4 8.3 2.4 5 1.9 10.7-1.2 15z"></path>
                  </svg>
                </div>
              </div>}
            </div>

            {isSmallScreen ? (
              doubtsLeftOfStudent != null && <div className="student__talk-time">
                <div>
                  <p className="head">Doubts Left</p>
                  <p className="value">{doubtsLeftOfStudent}</p>
                </div>
                <div className="icon-div">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    x="0"
                    y="0"
                    fill="#1FBFFF"
                    enableBackground="new 0 0 100 100"
                    viewBox="0 0 100 100"
                    className="doubt_count w-7 h-7 text-white border-none"
                  >
                    <path d="M55.1 2.5C36.7 2.5 21.6 16.6 20 34.7c-.1.9-.3 1.8-.8 2.7l-9 13.7c-1.7 2.6.2 6.1 3.3 6.1h5.2c.6 0 1.2.5 1.2 1.2v14.2c0 3.3 2.6 5.9 5.9 5.9h10.6c.9 0 1.7.8 1.7 1.7v14.3c0 1.8 1.5 3.2 3.3 3.1l30.3-2.7c1.6-.1 2.8-1.5 2.8-3.1V71.6c0-1.4.6-2.6 1.7-3.5 8.6-7.4 14.2-18.7 14.2-30.3 0-19.5-15.8-35.3-35.3-35.3zm-.3 67.9c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3zm12.7-27.3c-2.1 2.8-4.1 4.3-5.7 5.3-1.7 1.2-2.5 1.7-2.7 4.6-.1 1.2-1.2 2.2-2.4 2.2h-3.8c-1.4 0-2.5-1.2-2.4-2.6.5-6.6 3.1-8.9 6.3-11.2 1.3-.9 2.4-1.7 3.7-3.4 1.2-1.7 1.4-3.9.4-5.9-.7-1.3-2.4-3.6-6.6-3.6-5.5 0-7.2 3.5-7.7 6.2-.2 1.2-1.2 2-2.4 2h-3.8c-1.5 0-2.6-1.3-2.4-2.8 1-7.2 6.2-14.1 16.3-14.1 6.4 0 11.7 3.1 14.4 8.3 2.4 5 1.9 10.7-1.2 15z"></path>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="reservations__options">
                {user?.uid === sessionSelected?.instructor_id && (!isCompleted &&
                  (!isAccepted ? (
                    <button
                      onClick={handleAccept}
                      className={hideAcceptBtn && "hide__accept__btn"}
                    >
                      {isAccepting ? (
                        <Lottie
                          options={{
                            animationData: circularProgress,
                            loop: true,
                          }}
                        />
                      ) : (
                        <span>Accept</span>
                      )}
                    </button>
                  ) : !instructorActivity?.isEngaged ? (
                    <div className={animate ? "popIn" : ""}>
                      <button onClick={handleCallStudent} className="call__btn">
                        <img
                          src={roundedVideoCamera}
                          alt="vc"
                          draggable={false}
                        />
                        <span>Call</span>
                      </button>
                    </div>
                  ) : instructorActivity?.sessionId === sessionSelected?.id ? (
                    <div className="popIn">
                      <button onClick={handleHandover} className="call__btn">
                        <img
                          src={roundedVideoCamera}
                          alt="vc"
                          draggable={false}
                        />
                        <span>JOIN</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )))}

                {user?.uid === sessionSelected?.instructor_id && isAccepted && (
                  <button className={"call__btn danger"} onClick={() => {
                    if(expandExit) return;
                    setExpandExit(true);
                  }}>
                    <motion.div initial={{opacity: 1, display: 'block'}} animate={{opacity: expandExit ? 0 : 1, display: expandExit ? 'none' : 'block'}} className="flex items-center" style={{filter: 'invert(0)', width: 'unset', height: 'unset'}}>
                      <ExitToApp
                        aria-label="more"
                        aria-controls="session-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          // setExpandExit(true);
                          // handleClick(e)
                          // setConfirmationModalData({
                          //   open: true,
                          //   Children: <EndSessionModal
                          //     title="End Session"
                          //     description="Are you sure you want to end this session?"
                          //     onConfirm={handleSessionCompleted}
                          //   />
                          // })
                        }}
                        style={{
                          fill: "white",
                          cursor: "pointer",
                        }}
                      />
                    </motion.div>
                    <motion.span initial={{width: 0, x: 0}} animate={{width: expandExit ? "unset" : 0, x: expandExit ? -3 : 0}} className="overflow-hidden whitespace-nowrap">End Session</motion.span>
                    <motion.div initial={{width: 0, x: 0}} animate={{width: endSessionLoading ? "30px" : expandExit ? 'unset' : 0, x: 4}} className="flex items-center overflow-hidden">
                      <motion.div className={"px-1"} onClick={() => handleSessionCompleted(setEndSessionLoading)}>
                        {endSessionLoading ? <Lottie options={{animationData: circularProgress, loop: true}} style={{padding: 0, width: '20px'}}/> :
                        <CheckCircle style={{filter: 'invert(1)'}} className="text-white hover:text-gray-400" />}
                      </motion.div>
                      <motion.div className={"px-1 pr-0"} onClick={() => setExpandExit(false)}>
                        <CancelIcon style={{filter: 'invert(1)'}} className="text-white hover:text-gray-400" />
                      </motion.div>
                    </motion.div>
                  </button>
                )}

                {sessionSelected?.completed_ts &&
                  <StarRatings
                    key={"star_new_" + sessionSelected?.rating}
                    rating={sessionSelected?.rating ?? 0}
                    starRatedColor="#fec107"
                    starHoverColor="#fec107"
                    numberOfStars={5}
                    starDimension="18px"
                    starSpacing="2.5px"
                    name="rating"
                    svgIconPath={starPath}
                    svgIconViewBox="0 0 207.802 207.748"
                  />
                }

                {/*<Menu*/}
                {/*  id={isDark ? "session-menu-dark" : "session-menu"}*/}
                {/*  className='end-session'*/}
                {/*  anchorEl={anchorEl}*/}
                {/*  // keepMounted*/}
                {/*  open={Boolean(anchorEl)}*/}
                {/*  onClose={handleClose}*/}
                {/*  PaperProps={{ style: { maxHeight: 48 * 4.5, width: "20ch" } }}*/}
                {/*>*/}

                {/*  <div className="flex flex-col">*/}
                {/*    /!*<h2 className="p-3">End Session?</h2>*!/*/}
                {/*    <div className="grid grid-cols-2">*/}
                {/*      <button className="text-green-500 p-2 bg-gray-200 hover:bg-gray-300" onClick={async () => {*/}
                {/*        await handleSessionCompleted(setEndSessionLoading);*/}
                {/*      }}>*/}
                {/*        {!endSessionLoading ? (*/}
                {/*          <div className="flex items-center justify-center">*/}
                {/*            <CheckCircle />*/}
                {/*            <span>Yes, End Session</span>*/}
                {/*          </div>*/}
                {/*          ) :*/}
                {/*        <Lottie options={{animationData: circularProgress, loop: true}} style={{padding: 0, width: '20px'}}/>}*/}
                {/*      </button>*/}
                {/*      <button className="text-red-500 p-2  bg-gray-100 hover:bg-gray-300" onClick={handleClose}>*/}
                {/*        <div className="flex items-center justify-center">*/}
                {/*          <CancelIcon />*/}
                {/*          <span>Cancel</span>*/}
                {/*        </div>*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</Menu>*/}
              </div>
            )}
          </div>
          <div className="reservation__chat">
            <div
              className={
                isAccepted
                  ? "reservation__chat__inner"
                  : "reservation__chat__inner completed"
              }
              onClick={() => setOpenPickers(false)}
            >
              {chatsData && (
                <ReservationChats
                  chats={chatsData}
                  moreChat={moreChat}
                  key={sessionSelected?.id}
                  sessionId={sessionSelected?.id}
                  senderImage={sessionSelected?.student_profile_pic}
                  senderId={sessionSelected?.student_id}
                  senderName={sessionSelected?.student_name}
                  getMoreChatsFn={getMoreChatsFn}
                />
              )}
            </div>
            <div className="blaze__chat__image__preview fadeOutDown">
              {images.length > 0 &&
                images.map((image) => (
                  <div className="image__preview">
                    <CloseIcon
                      onClick={handleImageDelete}
                      className="imagePreviewDialog_closeIcon"
                    />
                    <img src={image?.url} alt="X" draggable={false} />
                  </div>
                ))}
              {pdfs.length > 0 && (
                <div className="pdf__preview">
                  <CloseIcon
                    onClick={handlePdfDelete}
                    className="imagePreviewDialog_closeIcon"
                  />
                  <img src={pdfIcon} alt="X" draggable={false} />
                  <span>{pdfs[0].size} MB</span>
                </div>
              )}
            </div>

            {isAccepted && !isCompleted && (
              <div className="user__chat__input">
                <div
                  className={openPickers ? "show__pickers" : "close__pickers"}
                >
                  <label className="blaze__image" htmlFor="blaze-image-picker">
                    <img
                      className="blaze__input__image"
                      src={imageGallery}
                      alt="chatImageInput"
                    />
                  </label>
                  <input
                    accept="image/*"
                    type="file"
                    id="blaze-image-picker"
                    style={{ display: "none" }}
                    onChange={imageSelectionHandler}
                    disabled={!isAccepted}
                  />

                  <label className="blaze__pdf" htmlFor="blaze-pdf-picker">
                    <PictureAsPdfRoundedIcon />
                  </label>
                  <input
                    accept="application/pdf"
                    type="file"
                    id="blaze-pdf-picker"
                    style={{ display: "none" }}
                    onChange={pdfSelectionHandler}
                    disabled={!isAccepted}
                  />
                </div>
                <div
                  className={isDark ? "blaze__picker dark" : "blaze__picker"}
                  onClick={() =>
                    pdfs.length === 0 &&
                    images.length === 0 &&
                    setOpenPickers(!openPickers)
                  }
                >
                  <AddRoundedIcon
                    className={
                      openPickers ? "rotate__forward" : "rotate__backward"
                    }
                  />
                </div>

                {topicCmsPath && <div
                  className={isDark ? "blaze__picker left dark" : "blaze__picker left"}
                  onClick={() => {
                    history.push('/classroom?item_id=' + topicCmsPath);
                  }}
                >
                  <PlayArrow
                    className={
                      "rotate__backward"
                    }
                  />
                </div>}

                <div className="textarea__wrapper"
                  style={{
                    marginLeft: topicCmsPath ? '99px' : '48px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TextareaAutosize
                    className="livesession__commentSectionInput blazeres__input"
                    rowsMax={chatText === "" ? 1 : 4}
                    aria-label="maximum height"
                    autoFocus
                    value={chatText}
                    placeholder={
                      !isAccepted ? "Session has ended." : "Write here..."
                    }
                    disabled={
                      !isAccepted || images.length > 0 || pdfs.length > 0
                    }
                    onChange={(event) => {
                      // if(event.target.value.length > 350) return;
                      changeRef.current && setChatText(event.target.value)
                    }}
                    onClick={() => setOpenPickers(false)}
                    onKeyDown={(e) => {
                      if (
                        chatText.trim() !== "" ||
                        images.length !== 0 ||
                        pdfs.length !== 0
                      ) {
                        if (e.key === "Enter" && !e.shiftKey) {
                          changeRef.current = false;
                          handleMessageSubmit();
                        } else {
                          changeRef.current = true;
                        }
                      }
                    }}
                  />
                </div>
                <button
                  onClick={handleMessageSubmit}
                  className="livesession__commentSectionButton blazeres__btn"
                  disabled={
                    chatText.trim() === "" &&
                    images.length === 0 &&
                    pdfs.length === 0
                  }
                  aria-label="blazeres__btn"
                >
                  {isSending ? (
                    <div className="circular__progress__lottie">
                      <Lottie
                        options={{
                          animationData: circularProgress,
                          loop: true,
                        }}
                      />
                    </div>
                  ) : (
                    <SendIcon
                      className="blaze-send-icon"
                      color={
                        chatText.trim() === "" &&
                        images.length === 0 &&
                        pdfs.length === 0
                          ? "grey"
                          : "var(--color-highlight)"
                      }
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="about__reservation">
          <div className="reservation__sidebar__wrapper">
            <div className="reservation__sidebar__container">
              <div className="reservation__details__wrapper">
                <h1>{sessionSelected?.topic}</h1>
              </div>
              <div className="reservation__sidebar__about__wrapper">
                <div className="reservation__sidebar__header">
                  <h3>Student Activity</h3>
                  <h4>
                    <RotateLeft />{" "}
                    <p>{sessionSelected?.student_refund_count || 0} Refunds</p>
                  </h4>
                  <h4>
                    <VideoCall />{" "}
                    <p>{sessionSelected?.student_session_count} Sessions</p>
                  </h4>
                  <br />
                  <h3>This Session</h3>
                  {sessionSelected && (
                    <h4>
                      <HourglassFullRounded />
                      <p>{getTotalCallDuration()}</p>
                    </h4>
                  )}
                </div>
              </div>
            </div>

            <div className="reservation__sidebar__container">
              <div className="reservation__sidebar__header">
                <h3>Files</h3>
                <p>Shared here in this session.</p>
              </div>
              <div
                className="reservation__sidebar__content"
                style={{
                  overflowY: chatDocuments !== null ? "auto" : "inherit",
                }}
              >
                {(chatDocuments === null || chatDocuments?.length === 0) && (
                  <div className="sidebar__placeholder">
                    <div className="sidebar__placeholder__icon">
                      <img
                        src={noFilesIcon}
                        alt="No Documents | PuStack"
                        draggable={false}
                      />
                    </div>
                    <div className="sidebar__placeholder__text">
                      No documents present.
                    </div>
                  </div>
                )}
                {chatDocuments?.map((chat, index) => (
                  <div className="reservation__sidebar__item__file" key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${chat?.url}`}
                    >
                      <img
                        src={pdfIcon}
                        alt="Pustack Document"
                        className="attachment__icon"
                      />
                      <div className="file__details">
                        <div className="file__name">{chat?.name}</div>
                        <div className="file__date">
                          {chat?.timestamp?.getDate()}{" "}
                          {monthToStringFormatter(chat?.timestamp?.getMonth())},{" "}
                          {chat?.timestamp?.getFullYear()}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="reservation__sidebar__container media-images">
              <div className="reservation__sidebar__header">
                <h3>Media</h3>
                <p>Shared here in this session.</p>
              </div>
              <div className="reservation__sidebar__content image__content">
                {(chatImages === null || chatImages?.length === 0) && (
                  <div className="sidebar__placeholder">
                    <div className="sidebar__placeholder__icon">
                      <img src={noImagesIcon} alt="No Documents | PuStack" />
                    </div>
                    <div className="sidebar__placeholder__text">
                      No media present.
                    </div>
                  </div>
                )}
                {chatImages?.map((e, index) => (
                  <div
                    key={index}
                    className="reservation__sidebar__item__img"
                    onClick={() => setisModalOpen(true)}
                  >
                    <img
                      src={e.url}
                      alt="Pustack Document"
                      className="attachment__image"
                      key={e.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {chatImages !== null && isModalOpen && (
        <ModalGallery
          body={chatImages.map((e) => e.url)}
          onClose={() => setisModalOpen(false)}
        />
      )}
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleBlazeCallClose}
        ariaHideApp={false}
        overlayClassName="new-post-modal-overlay"
        isOpen={openBlazeCall}
        className={isDark ? "skills__modal dark" : "skills__modal"}
      >
        <BlazeExternalCall
          closeModal={handleBlazeCallClose}
          sessionSelected={sessionSelected}
          chatData={chatsData}
          moreChat={moreChat}
          getMoreChatsFn={getMoreChatsFn}
          // timeLeftOfStudent={timeLeftOfStudent}
        />
      </Modal>
    </div>
  );
}
