import {useEffect} from "react";


// Caches given Story[] using HTMLImageElement and HTMLVideoElement
const cacheContent = async (contents) => {
  const promises = contents.map((content) => {
    return new Promise(function (resolve, reject) {
      if(!content) return;
      const video = document.createElement('video');
      video.src = content;
      video.onloadeddata = resolve;
      video.onerror = reject;

      console.log('Instagram Story | src - ', content);
    })
  })

  await Promise.all(promises);
}

// Keeps track of urls that have been loaded
const urlsLoaded = new Set();

// Pushes urls to urlsLoaded
const markUrlsLoaded = (contents) => {
  contents.forEach((content) => {
    urlsLoaded.add(content)
  })
}


// Returns true if given Story should be preloaded
const shouldPreload = (content) => {
  if (!content.url) return false
  if (urlsLoaded.has(content.url)) return false
  if (content.preloadResource !== undefined) return content.preloadResource
  return content.type !== 'video';
}

// Preloads images and videos from given Story[] using a cursor and preloadCount
// Preload count is the number of images/videos to preload after the cursor
// Cursor is the current index to start preloading from
export const usePreLoader = (contents, cursor, preloadCount) => {
  useEffect(() => {
    const start = cursor + 1;
    const end = cursor + preloadCount + 1;

    const toPreload = contents
      .slice(start, end);

    markUrlsLoaded(toPreload)
    cacheContent(toPreload)
  }, [cursor, preloadCount, contents])
}
