import React, {useState, useRef, createContext, useEffect, useContext, useCallback} from 'react';

import {
  fetchLectureItem,
  fetchLectureHeaderItem,
  getChapterLastEngagementData,
  getUserLatestEngagement,
} from "../../database";
import { UserContext } from "../global/user-context";
import { PustackProContext } from "../global/PustackProContext";
import useQuery from "../../hooks/query/useQuery";
import {ClassroomState} from './ClassroomState';

export const ClassroomContext = createContext();

export const ClassroomContextProvider = (props) => {
  const [videoID, setVideoID] = useState(null);
  const [tabsData, setTabsData] = useState(null);
  const [notesLink, setNotesLink] = useState(null);
  const [chapterName, setChapterName] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [lecturesData, setLecturesData] = useState(null);
  const [classroomData, setClassroomData] = useState(null);
  const [nextItem, setNextItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [lectureItem, setLectureItem] = useState(null);
  const [activeTabId, setActiveTabId] = useState(null);
  const [lectureItems, setLectureItems] = useState(null);
  const [classroomSubject, setClassroomSubject] = useState(null);
  const [classroomGrade, setClassroomGrade] = useState(null);
  const [classroomChapter, setClassroomChapter] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [videoSeeking, setVideoSeeking] = useState(true);
  const [chapterEngagement, setChapterEngagement] = useState(null);
  const [chapterEngagementMap, setChapterEngagementMap] = useState(null);
  const [lastEngagement, setLastEngagement] = useState(null);
  const [lastActivityMap, setLastActivityMap] = useState(null);
  const [showOnlyLogo, setShowOnlyLogo] = useState(true);
  const [isNotes, setIsNotes] = useState(false);
  const [completionStatusByChapter, setCompletionStatusByChapter] =
    useState(null);
  const [allChaptersCompletionStatus, setAllChaptersCompletionStatus] =
    useState(null);
  const [userLatestEngagement, setUserLatestEngagement] = useState(null);
  // const [subjectMeta, setSubjectMeta] = useState(null);
  const [_user] = useContext(UserContext).user;
  const [user, setLinkedGrade] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const [lectureTier, setLectureTier] = useState(false);
  const [beaconBody, setBeaconBody] = useState(null);
  const [lastEngagementFound, setLastEngagementFound] = useState(false);
  const [isUserProTier] = useContext(UserContext).tier;
  const [, setIsSliderOpen] = useContext(PustackProContext).value;
  const query = useQuery();

  const lectureItemIdQuery = query.get('lecture_item_id');
  const lectureHeaderItemIdQuery = query.get('lecture_header_item_id');

  const classroomStateRef = useRef(null);

  useEffect(() => {
    // Check if any of the required dependencies (classroomChapter, classroomSubject, user) are undefined or null.
    if (!classroomChapter || !classroomSubject || !user) {
      // If any of the dependencies are missing, exit the useEffect early.
      return;
    }

    // Create a new ClassroomState instance and assign it to classroomStateRef.current.
    // This state represents the classroom's current state, including user, chapter, and subject information.
    classroomStateRef.current = new ClassroomState({
      userId: user?.uid,
      grade: user?.grade,
      chapterId: classroomChapter,
      subjectId: classroomSubject,
    });
  }, [classroomChapter, classroomSubject, user]);

  function updateLectureDetails() {
    const returnedLectureItem = classroomStateRef.current.getCurrentLectureItemDetails();
    // shouldSetActiveItem && setActiveItem(returnedLectureItem.activeItem);
    setLectureItem(returnedLectureItem.lectureItem);
    setNextItem(returnedLectureItem.nextItem)
    setVideoID(returnedLectureItem.youtubeId);
    setNotesLink(returnedLectureItem.notesLink);
    setChapterName(returnedLectureItem.chapterName);
    setPlaying(true);
    setShowOnlyLogo(false);
    setVideoSeeking(false);
    setActiveTabIndex(returnedLectureItem.activeTabIndex);
    setActiveTabId(returnedLectureItem.activeTabId);
  }

  const handleActiveItemChange = useCallback(async () => {
    if(!activeItem || !tabsData) return;

    // Attempt to update the classroom state with new tabs data and populate details for an active item.
    try {
      // Update the classroom state with the provided tabsData.
      classroomStateRef.current.updateTabsData(tabsData);

      // Asynchronously populate details for the active item, which may involve making API calls.
      await classroomStateRef.current.populateActiveItem(activeItem);

      // Populate the next lecture item
      classroomStateRef.current.populateNextLectureItem();

      // Call the updateLectureDetails function, which likely updates the UI with lecture details.
      updateLectureDetails();
    } catch (e) {
      console.log('e - ', e);
      // If an error occurs during the above operations, handle it here.
      // If the classroom state indicates that engagement data is being used,
      // attempt to set the active item to the first lecture item.

      // Call the setFirstLectureItem method to set the active item.
      const _activeItem = await classroomStateRef.current.setFirstLectureItem();

      // Update the active item, possibly to recover from an error.
      setActiveItem(_activeItem);
    }
  }, [activeItem, tabsData]);

  useEffect(() => {
    handleActiveItemChange().then();
  }, [handleActiveItemChange]);

  useEffect(() => {
    // Check if tabsData is undefined or null. If so, exit the function as there is no data to work with.
    if (!tabsData) {
      return;
    }

    // Define an asynchronous function named "run" to encapsulate the following logic.
    async function run() {
      // Update the classroom state with the provided tabsData.
      classroomStateRef.current.updateTabsData(tabsData);

      // Check if both lectureItemIdQuery and lectureHeaderItemIdQuery are falsy (undefined, null, or empty strings).
      if (!lectureItemIdQuery && !lectureHeaderItemIdQuery) {
        // If both IDs are not provided, set the active item to the default lecture item.

        // Call the setDefaultLectureItem method to set the active item.
        const _activeItem = await classroomStateRef.current.setFirstLectureItem();

        // Update the active item, possibly as a default behavior.
        setActiveItem(_activeItem);

        // Exit the function, as the default item has been set.
        return;
      }

      // If either lectureItemIdQuery or lectureHeaderItemIdQuery is provided (not falsy), set the active item accordingly.

      // Call the setLectureItemByIds method to set the active item based on the provided IDs.
      const _activeItem = await classroomStateRef.current.setLectureItemByIds(lectureItemIdQuery, lectureHeaderItemIdQuery);

      // Check if _activeItem is truthy (not null or undefined) before updating the active item.
      _activeItem && setActiveItem(_activeItem);
    }

    run();
  }, [lectureItemIdQuery, tabsData, lectureHeaderItemIdQuery])

  return (
    <ClassroomContext.Provider
      value={{
        videoID: [videoID, setVideoID],
        tabsData: [tabsData, setTabsData],
        tabId: [activeTabId, setActiveTabId],
        activeTabIndex: [activeTabIndex, setActiveTabIndex],
        lectures: [lecturesData, setLecturesData],
        lectureItems: [lectureItems, setLectureItems],
        lectureTier: [lectureTier, setLectureTier],
        activeItem: [activeItem, setActiveItem],
        nextItem: [nextItem, setNextItem],
        lectureItem: [lectureItem, setLectureItem],
        classroomData: [classroomData, setClassroomData],
        classroomSubject: [classroomSubject, setClassroomSubject],
        classroomChapter: [classroomChapter, setClassroomChapter],
        chapterName: [chapterName, setChapterName],
        notesLink: [notesLink, setNotesLink],
        isNotes: [isNotes, setIsNotes],
        playing: [playing, setPlaying],
        videoSeeking: [videoSeeking, setVideoSeeking],
        chapterEngagement: [chapterEngagement, setChapterEngagement],
        chapterEngagementMap: [chapterEngagementMap, setChapterEngagementMap],
        lastEngagement: [lastEngagement, setLastEngagement],
        lastActivityMap: [lastActivityMap, setLastActivityMap],
        completionStatusByChapter: [
          completionStatusByChapter,
          setCompletionStatusByChapter,
        ],
        userLatestEngagement: [userLatestEngagement, setUserLatestEngagement],
        allChaptersCompletionStatus: [
          allChaptersCompletionStatus,
          setAllChaptersCompletionStatus,
        ],
        tabIndex: [tabIndex, setTabIndex],
        beaconBody: [beaconBody, setBeaconBody],
        showOnlyLogo: [showOnlyLogo, setShowOnlyLogo],
        classroomGrade: [classroomGrade, setClassroomGrade],
        linkedGrade: [user, setLinkedGrade]
      }}
    >
      {props.children}
    </ClassroomContext.Provider>
  );
};

export default ClassroomContextProvider;
