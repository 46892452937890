import React, {useState, useContext, useEffect, useRef, useMemo, useCallback} from "react";
import Switch from "react-switch";
import Switch2 from "@material-ui/core/Switch";

import { format } from "date-fns";
import firebase from "firebase/app";
import Timer from "react-compound-timer";
import Icon from "@material-ui/core/Icon";
import Fade from "@material-ui/core/Fade";
import StarRatings from "react-star-ratings";
import PhoneInput from "react-phone-input-2";
import Drawer from "@material-ui/core/Drawer";
import Resizer from "react-image-file-resizer";
import { useMediaQuery } from "react-responsive";
import Snackbar from "@material-ui/core/Snackbar";
import SwipeableViews from "react-swipeable-views";
import WhatsApp from "@material-ui/icons/WhatsApp";
import { ChevronRight } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {db, firebaseApp, functions} from "../../../../firebase_config";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Dialog, Menu } from "@material-ui/core";
import {
  logOut,
  signInWithPhone,
  submitPhoneNumberAuthCode,
  updatePhoneNumber,
  removeFcmToken,
} from "../../../../services/auth";
import {
  updateUserName,
  updateAppRating,
  isStudentEngaged,
  updateProfileImage,
  updateBlazeMeetingSetting,
} from "../../../../database";
import {
  ThemeContext,
  UserContext,
} from "./../../../../context";

import {
  CheckIconGreen,
  logo as PustackLogo,
  logoDark as PustackLogoDark,
  goldSim,
  goldCard,
  defaultPic,
  PustackLogoGold,
  settingsIcon,
  smartPhone,
  memberSince,
  studentCare,
} from "../../../../assets";
import "react-phone-input-2/lib/style.css";
import PuStackCareMobile from "./../../pustack-care-mobile";
import logoutIcon from "../../../../assets/images/icons/logout.png";
import PdfPreview from "./../../../../components/newsfeed/pdf-preview";
import circularProgress from "../../../../assets/lottie/circularProgress.json";

import {
  starPath,
  privacyPolicy,
  loadingWrapper,
  termsOfService, randomAvatar, capitalize,
} from "../../../../helpers";
import "./style.scss";
import Lottie from "lottie-react-web";
import {firebaseFunctionBaseURL} from "../../../../helpers/constants";
import {getAvatarImage} from "../../../../context/global/user-context";
import Avatar from "../../../../components/global/Avatar";

const CheckGreenIcon = () => (
  <Icon>
    <img
      alt="check-green"
      src={CheckIconGreen}
      style={{ width: "100%" }}
      draggable={false}
    />
  </Icon>
);

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value); // update the state to force render
};

const pages = ['account', 'settings', 'change-phone']

const AccountSettings = ({ profileVisibility }) => {
  const forceUpdate = useForceUpdate();
  const [permissionState, setPermissionState] =
    useContext(UserContext).permissions;

  const [user, setUser] = useContext(UserContext).user;
  const [openMenu, setOpenMenu] = useContext(UserContext).openMenu;
  const [openMenuSettings, setOpenMenuSettings] =
    useContext(UserContext).openMenuSettings;
  const [openChat, setOpenChat] = useContext(UserContext).openChat;
  // const [, setOpenPustackCare] = useContext(UserContext).openPustackCare;
  const [closeCarePage, setCloseCarePage] =
    useContext(UserContext).closeCarePage;

  const [isDark, setIsDark] = useContext(ThemeContext).theme;
  const timerRef = useRef(null);

  const [plural, setPlural] = useState(true);
  const [message, setMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [spaceKey, setSpaceKey] = useState(false);
  const [hasRated, setHasRated] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [allowNext, setAllowNext] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [resendCode, setResendCode] = useState(true);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [documentToShow, setDocumentToShow] = useState("");
  const [otpCode, setOtpCode] = useState(Array(6).fill(""));
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [openFriendsDrawer, setOpenFriendsDrawer] = useState(false);

  const [orderId, setOrderId] = useState(null);
  const [refillAmount, setRefillAmount] = useState("");
  const [orderStatus, setOrderStatus] = useState(false);
  const [openRefillPage, setOpenRefillPage] = useState(false);
  const [processingOrder, setProcessingOrder] = useState(false);
  const [processingRefill, setProcessingRefill] = useState(false);
  const [orderSuccessfull, setOrderSuccessfull] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(
    user?.phone_country_code + user?.phone_number
  );
  const [hidePoweredBy, setHidePoweredBy] = useState(false);
  const [profileName, setProfileName] = useState(user?.name);
  const [openFriendsList, setOpenFriendsList] = useState(false);
  const [countryCode, setCountryCode] = useState(user?.phone_country_code);
  const [_activeIndex, _setActiveIndex] = useState(openMenuSettings ? 1 : 0);
  const [profileImage, setProfileImage] = useState({ url: user?.profile_url });
  const [rating, setRating] = useState(user?.app_rating ? user?.app_rating : 0);
  const [lifetimeEarnings, setLifetimeEarnings] = useState(0);
  const [tutorEarnings] = useContext(UserContext).tutorEarnings;

  const history = useHistory();
  const location = useLocation();
  let recaptchaRef = useRef(null);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const activeIndex = useMemo(() => {
    if(!isSmallScreen) return _activeIndex
    const url = new URLSearchParams(location.search);
    const page = url.get('page');
    return pages.findIndex(c => c === page);
  }, [location, _activeIndex, isSmallScreen]);

  const setActiveIndex = useCallback((index) => {
    if(!isSmallScreen) {
      _setActiveIndex(index);
      return
    };
    const pageValue = pages[index];
    history.push({
      pathname: history.location.pathname,
      search: '?page=' + pageValue
    })
  }, [history])

  const setIsOpen = useCallback((open) => {
    if(!isSmallScreen) return;
    if(open) {
      history.push({
        pathname: history.location.pathname,
        search: '?page=account'
      });
    } else {
      history.push({
        pathname: history.location.pathname,
        search: ''
      });
    }
  }, [isSmallScreen, history])

  useEffect(() => {
    setPhoneNumber(user?.phone_country_code + user?.phone_number);
    !isSmallScreen && setActiveIndex(openMenuSettings ? 1 : 0);
    setRating(user?.app_rating ? user?.app_rating : 0);
    setProfileName(user?.name);
    setProfileImage({ url: user?.profile_url });
  }, [
    user?.profile_url,
    user?.phone_country_code,
    user?.phone_number,
    user?.name,
    user?.app_rating,
    isSmallScreen
  ]);

  const handleSnackClose = () => {
    setOpenSnack(false);
  };

  const isStudentBusy = async () => {
    if (!user?.uid) return true;

    const isBusy = await isStudentEngaged({
      studentId: user?.uid,
    });

    return isBusy;
  };

  const changeRating = async (newRating) => {
    const _rating = rating;
    setRating(newRating);

    if (newRating !== _rating) {
      const isUpdated = await updateAppRating(user?.uid, newRating);

      if (isUpdated) {
        const updatedUser = {
          ...user,
          app_rating: newRating,
          has_rated_app: true,
        };
        console.log('setUser - ')
        setUser(updatedUser);
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: updatedUser?.uid,
            grade: updatedUser?.grade,
            name: updatedUser?.name,
            profile_url: updatedUser?.profile_url,
          })
        );

        setHasRated(true);
        setTimeout(() => setHasRated(false), 2500);
      } else {
        setRating(_rating);
      }
    }
  };

  const onLogOutBtnClick = async () => {
    let fcmToken = localStorage.getItem("fcmToken");
    await removeFcmToken(user?.uid, fcmToken);

    let logout_success = await logOut();
    console.log('logout_success - ', logout_success);
    if (logout_success) {

      console.log('loadingWrapper() - ');
      loadingWrapper();

      localStorage.clear();
      localStorage.setItem("hideCookie", true);
      setUser(null);

      window.location = "/";
    }

  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setActiveIndex(0), 500);
    setProfileName(user?.name);
    setPhoneNumber(user?.phone_country_code + user?.phone_number);
    setCountryCode(user?.phone_country_code);
    setOtpSent(false);
    setOtpError(false);
    setOtpCode(Array(6).fill(""));
    setSendingOtp(false);
    setPhoneNumberError("");
    setOpenMenuSettings(false);
    setOpenFriendsList(false);
    setOpenRefillPage(false);
    setOrderStatus(false);
    setOrderId(null);
    setProcessingRefill(false);
    setProcessingOrder(false);
    setRefillAmount("");
    setOrderSuccessfull(false);
  };

  const handleDrawerClose = () => {
    history.push(currentPath);
    setIsOpen(false);
    setProfileName(user?.name);
    setPhoneNumber(user?.phone_country_code + user?.phone_number);
    setCountryCode(user?.phone_country_code);
    setOtpError(false);
    setOtpCode(Array(6).fill(""));
    setOtpSent(false);
    setSendingOtp(false);
    setPhoneNumberError("");
    setOpenMenuSettings(false);
    setOpenFriendsList(false);
    setOrderStatus(false);
    setOpenRefillPage(false);
    setOrderStatus(false);
    setOrderId(null);
    setProcessingRefill(false);
    setProcessingOrder(false);
    setRefillAmount("");
    setOrderSuccessfull(false);
  };

  useEffect(() => {
    if (openMenu) {
      let currentTarget = document.getElementById("userMenuAnchor");
      handleClick({ currentTarget });
      setOpenMenu(false);
    }
  }, [openMenu]);

  useEffect(() => {
    setProfileImage({ url: user?.profile_url });
  }, [user?.profile_url]);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const updateUserFullName = async () => {
    const isUpdated = await updateUserName(user?.uid, profileName.trim());

    if (isUpdated) {
      const updatedUser = { ...user, name: profileName.trim() };

      console.log('setUser - ')
      setUser(updatedUser);
      localStorage.setItem(
        "user",
        JSON.stringify({
          uid: updatedUser?.uid,
          grade: updatedUser?.grade,
          name: updatedUser?.name,
          profile_url: updatedUser?.profile_url,
        })
      );

      setMessage("Your name has been updated");
      setOpenSnack(true);
      setTimeout(() => setOpenSnack(false), 2500);
    } else {
      setProfileName(user?.name);
    }
  };

  const resizeProfilePic = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        "JPEG",
        70,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });

  const profileImageSelectionHandler = async (e) => {
    const { files } = e.target;

    const profilePic = await resizeProfilePic(files[0]);

    let _image = {
      url: URL.createObjectURL(profilePic),
      ext: files[0].name.split(".").slice(-1)[0],
    };

    setProfileImage(_image);
    const [url, isUpdated] = await updateProfileImage(_image, user?.uid);

    if (isUpdated && url) {
      const updatedUser = { ...user, profile_url: url };

      console.log('setUser - ')
      setUser(updatedUser);
      localStorage.setItem(
        "user",
        JSON.stringify({
          uid: updatedUser?.uid,
          grade: updatedUser?.grade,
          name: updatedUser?.name,
          profile_url: updatedUser?.profile_url,
        })
      );

      setMessage("Your image has been updated");
      setOpenSnack(true);
      setTimeout(() => setOpenSnack(false), 2500);
    } else {
      setProfileImage({ url: user?.profile_url });
    }
  };

  useEffect(() => {
    console.log('timerRef.current - ', timerRef.current);
  }, [timerRef.current])

  const handleSendingOTP = async () => {
    console.log('timerRef - ', timerRef);
    setPhoneNumberError("");
    setOtpCode(Array(6).fill(""))
    setOtpError(null);
    setSendingOtp(true);

    const isPhoneExist = functions.httpsCallable('checkIfPhoneExists');
    const response = await isPhoneExist({
      phone_number: '+' + phoneNumber
    })
    if(response.data) {
      setSendingOtp(false);
      setPhoneNumberError("Phone number is already registered");
      return;
    }
    console.log('isPhoneExist - ', response);


    if (phoneNumber.length > 8) {

      await signInWithPhone(
        "+" + phoneNumber,
        countryCode,
        setActiveIndex,
        2,
        setSendingOtp,
        error => {
          console.log('error - ', error, error.length);
          if(error?.length > 0) {
            setPhoneNumberError(error)
            setSendingOtp(false);
            setOtpSent(false);
          }
        },
        null,
        null,
        null,
        null,
        () => {
          console.log('cb - ');
          setOtpSent(true);
        }
      );
    } else {
      setPhoneNumberError("Invalid phone number");
      setTimeout(() => setPhoneNumberError(""), 5000);
    }
  };

  const handlePhoneNumberChange = async () => {
    const _userData = {
      ...user,
      phone_number: phoneNumber.slice(countryCode.length),
      phone_country_code: countryCode,
    };

    const updated = await updatePhoneNumber(
      user?.uid,
      phoneNumber.slice(countryCode.length),
      countryCode
    );

    if (updated) {
      console.log('setUser - ')
      setUser(_userData);
      localStorage.setItem(
        "user",
        JSON.stringify({
          uid: _userData?.uid,
          grade: _userData?.grade,
          name: _userData?.name,
          profile_url: _userData?.profile_url,
        })
      );

      setActiveIndex(1);
      setOtpError(false);
      setPhoneNumber(_userData?.phone_country_code + _userData?.phone_number);
      setCountryCode(_userData?.phone_country_code);
      setOtpError(false);
      setOtpCode(Array(6).fill(""));
      setOtpSent(false);
      setSendingOtp(false);
      setPhoneNumberError("");
    }
  };

  const handleOTPChange = async (e, i) => {
    const { maxLength, value } = e.target;
    forceUpdate();

    if (spaceKey) return;

    otpCode[i] = value;
    setOtpCode(otpCode);

    const code = otpCode?.join("");

    if (code.length === 6) {
      const [_, res, errorMessage] = await submitPhoneNumberAuthCode(code, null, null, true);

      if (res) {
        setOtpError(false);
        setAllowNext(true);
      } else {
        setOtpError(errorMessage);
        setAllowNext(false);
        navigator && navigator.vibrate && navigator.vibrate([10, 10]);
      }
    } else {
      setOtpError(false);
      setAllowNext(false);
    }

    if (value.length >= maxLength && i < 5) {
      const nextField = document.querySelector(
        `div > input[name=input${i + 1}]`
      );
      if (nextField !== null) nextField.focus();
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.key === " ") {
      setSpaceKey(true);
    } else if (e.key === "Backspace" || e.key === "Delete") {
      forceUpdate();

      if (otpCode[i]?.length === 0 && i > 0 && i <= 5) {
        const prevField = document.querySelector(
          `div > input[name=input${i - 1}]`
        );
        if (prevField !== null) prevField.focus();
      }
    } else {
      setSpaceKey(false);
    }
  };

  const isOpen = useMemo(() => {
    if(!isSmallScreen) return false;
    const url = new URLSearchParams(location.search);
    const page = url.get('page');
    const isValidPage = pages.findIndex(c => c === page);
    if(isValidPage < 0) return false;

    return true;
  }, [location, isSmallScreen])

  useEffect(() => {
    if (openMenuSettings && !isSmallScreen) setActiveIndex(1);
  }, [openMenuSettings, isSmallScreen]);

  useEffect(() => {
    if (activeIndex === 2 && !window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        `recaptcha-container`,
        {
          size: "invisible",
          callback: (response) => console.log({ response }),
          "expired-callback": () => window.recaptchaVerifier.clear(),
        },
        firebaseApp
      );
    }
  }, [activeIndex]);

  // useEffect(() => {
  //   if (isOpen && isSmallScreen) {
  //     history.listen((_, action) => {
  //       if (action === "POP") {
  //         if (activeIndex === 2) {
  //           setActiveIndex(1);
  //           history.push("/?page=settings");
  //         } else if (activeIndex === 1) {
  //           setActiveIndex(0);
  //           history.push("/?page=account");
  //         } else if (activeIndex === 0) {
  //           handleDrawerClose();
  //           history.push("/");
  //         }
  //       }
  //     });
  //   }
  // }, [activeIndex]);

 

  

  useEffect(() => {
    const orderStatusListener = () => {
      if (orderId !== null) {
        const doc = db.collection("orders").doc(orderId);
        setProcessingOrder(true);

        doc.onSnapshot((docSnapshot) => {
          const { transaction_status } = docSnapshot.data();

          if (
            transaction_status === "authorization_failed" ||
            transaction_status === "payment_capture_failed"
          ) {
            setOrderSuccessfull(false);
            setTimeout(() => setOrderStatus(true), 1000);
            setProcessingRefill(false);
          }
          if (transaction_status === "payment_captured") {
            setOrderSuccessfull(true);
            setTimeout(() => setOrderStatus(true), 1000);
            setProcessingRefill(false);
          }
        });
      }
    };

    orderStatusListener();
  }, [orderId]);

  const handlePermissionsChange = async (event) => {
    const { name, value } = event.target;

    let blazeMeetingSetting = null;

    if (name === "camera") {
      if (value === "false") {
        setPermissionState({ ...permissionState, [name]: true });
        blazeMeetingSetting = { is_video_muted: true };
      } else {
        setPermissionState({ ...permissionState, [name]: false });
        blazeMeetingSetting = { is_video_muted: false };
      }
    } else {
      if (value === "false") {
        setPermissionState({ ...permissionState, [name]: true });
        blazeMeetingSetting = { is_audio_muted: true };
      } else {
        setPermissionState({ ...permissionState, [name]: false });
        blazeMeetingSetting = { is_audio_muted: false };
      }
    }

    if (blazeMeetingSetting) {
      updateBlazeMeetingSetting({
        instructorId: user?.uid,
        blazeMeetingSetting,
      });
    }

    // navigator.getUserMedia(
    //   options,
    //   (stream) => {
    //     stream.getTracks().forEach((x) => x.stop());
    //     setPermissionState({ ...permissionState, [name]: true });
    //   },
    //   (err) => {
    //     setPermissionState({ ...permissionState, [name]: false });
    //   }
    // );
  };

  // useEffect(() => {
  //   // getAllPermissions();
  // }, []);

  // const permissionsNames = ["camera", "microphone"];

  // const getAllPermissions = async () => {
  //   let allPermissions = { ...permissionState };

  //   await Promise.all(
  //     permissionsNames.map(async (permissionName) => {
  //       try {
  //         let permission;
  //         switch (permissionName) {
  //           case "push":
  //             permission = await navigator.permissions.query({
  //               name: permissionName,
  //               userVisibleOnly: true,
  //             });
  //             break;
  //           default:
  //             permission = await navigator.permissions.query({
  //               name: permissionName,
  //             });
  //         }

  //         if (permission.state === "granted") {
  //           allPermissions = { ...allPermissions, [permissionName]: true };
  //         } else {
  //           allPermissions = { ...allPermissions, [permissionName]: false };
  //         }
  //       } catch (e) {
  //         console.log({ e });
  //         allPermissions = { ...allPermissions, [permissionName]: false };
  //       }

  //       setPermissionState(allPermissions);
  //     })
  //   );
  // };

  const formatDate = (date) => {
    const splitted = date?.split("_");
    const year = splitted[0];
    const month = splitted[1] - 1;
    const d = splitted[2];
    return format(new Date(year, month, d), "MMM, do yyyy");
  };

  const last30Days = () => {
    const today = new Date();
    let dates = [];
    for (let i = 0; i < 30; i++) {
      const priorDate = new Date().setDate(today.getDate() - i);
      const date = new Date(priorDate).getDate();
      const month = new Date(priorDate).getMonth() + 1;
      const year = new Date(priorDate).getFullYear();
      dates.push(year + "_" + month + "_" + date);
    }

    return dates.reverse();
  };

  useEffect(() => {
    if (tutorEarnings?.length > 0) {
      let _earnings = 0;
      let _pendingEarnings = 0;

      const _last30Days = last30Days();
      let _daySessionsCount = [];
      let _dayEarnings = [];

      let _dailyEarnings = {};

      let _chartData = [];

      tutorEarnings.map((order) => {
        const dailyEarnings = order?.daily_earnings;
        _dailyEarnings = { ..._dailyEarnings, ...dailyEarnings };

        for (let k in dailyEarnings) {
          _daySessionsCount.push({
            date: k,
            sessions: Object.keys(dailyEarnings[k]).length,
          });

          let dayWiseData = dailyEarnings[k];

          let oneDayEarning = 0;

          for (let session in dayWiseData) {
            _earnings += dayWiseData[session]?.amount || 0;
            oneDayEarning += dayWiseData[session]?.amount || 0;

            if (
              dayWiseData[session]?.status &&
              dayWiseData[session]?.status !== "accounted"
            ) {
              _pendingEarnings += dayWiseData[session]?.amount;
            }
          }
          _dayEarnings.push({ date: k, earnings: oneDayEarning });
        }
      });

      for (let i = 0; i < 30; i++) {
        let date = _last30Days[i];

        if (_dailyEarnings[date]) {
          const dayEarnings = _dayEarnings.filter((d) => d.date === date)[0]
            .earnings;

          const daySessions = _daySessionsCount.filter(
            (d) => d.date === date
          )[0].sessions;

          _chartData.push({
            date: formatDate(date),
            hours: dayEarnings / (100 * 60),
            sessions: daySessions,
            earnings: dayEarnings,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setLifetimeEarnings(_earnings);
    }
  }, [tutorEarnings]);

  let userImageRef = useRef(null);
  useEffect(() => {
    userImageRef.current = randomAvatar(user?.name);
  }, [user?.name])

  return (
    <div>
      {user ? (
        <div className="account-settings">
          <div ref={recaptchaRef}>
            <div id="recaptcha-container" />
          </div>
          {profileVisibility && (
            <div
              className="user__menu__btn"
              id="userMenuAnchor"
              onClick={(e) =>
                isSmallScreen ? setIsOpen(true) : handleClick(e)
              }
              referrerPolicy="no-referrer"
            >
              <Avatar
                className="navigation-profile-img"
                src={user?.profile_url}
                name={user?.name}
                uid={user?.uid}
                alt="dp"
                draggable={false}
              />
              <div className="user__name">{capitalize(user?.name?.trim().split(" ")[0])}</div>
            </div>
          )}
          <Menu
            id={isDark ? "dark-menu" : "light-menu"}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={!processingRefill && handleClose}
            style={{ top: "0px" }}
            className={
              _activeIndex === 0 || openFriendsDrawer
                ? openFriendsList
                  ? "menu-primary list-opened"
                  : "menu-primary"
                : openRefillPage
                ? "menu-refill"
                : "menu-secondary"
            }
          >
            {!isOpen && !isSmallScreen && (
              <SwipeableViews
                axis={"x"}
                scrolling={"false"}
                index={_activeIndex}
                style={{ outline: "none", width: "320px" }}
                ignoreNativeScroll={true}
                disabled={true}
              >
                <div
                  className={(_activeIndex === 0
                    ? "profile-page"
                    : "profile-page settings-page")}
                >
                  <div className="page-title-wrapper">
                    <h2 className="page-title">Account</h2>
                    <img
                       onClick={() => {
                         setActiveIndex(1);
                         // router.push(
                         // 	`${
                         // 		currentPath === "/"
                         // 			? ""
                         // 			: "/" + currentPath.replace(/\//g, "")
                         // 	}/?page=settings`
                         // );
                       }}
                       src={settingsIcon}
                       alt="SI"
                       draggable={false}
                    />
                  </div>
                  <div className="page-content">
                    <div className="user-card">
                      <img
                        className="user-card-background"
                        src={goldCard}
                        alt="user-card-bg"
                        draggable={false}
                      />
                      <Avatar
                        className="user-profile-picture"
                        src={user?.profile_url}
                        name={user?.name}
                        onClick={() => setActiveIndex(1)}
                        uid={user?.uid}
                        alt="userdp"
                        draggable={false}
                      />
                      <img
                        src={PustackLogoGold}
                        alt="gold"
                        className="pustack-gold"
                        draggable={false}
                      />
                      <img
                        className={
                          !user?.is_external_instructor
                            ? "user-card-sim"
                            : "user-card-sim external"
                        }
                        src={goldSim}
                        alt="user-card-sim"
                        draggable={false}
                      />

                      {
                        <div className="wallet-balance">
                          <h2>Lifetime Earnings</h2>
                          <h6>
                            ₹{" "}
                            {lifetimeEarnings
                              ? lifetimeEarnings.toLocaleString("en-IN")
                              : 0}
                          </h6>
                        </div>
                      }
                      <div className="user-card-details">
                        <div className="user-tier-wrapper flex flex-col justify-end">
                          <div className="user-tier">
                            <h5 className="user-tier-tag">TUTOR</h5>
                          </div>
                          <div className="user-details">
                            <h6>{user?.name}</h6>
                          </div>
                          {/*<div className="user-details phone-number">*/}
                          {/*  <h6>*/}
                          {/*    {"+" +*/}
                          {/*      user?.phone_country_code +*/}
                          {/*      user?.phone_number}*/}
                          {/*  </h6>*/}
                          {/*</div>*/}
                        </div>
                        <div className={"user-validity"}>
                          <img
                            src={memberSince}
                            alt="member-since"
                            draggable={false}
                          />
                          <h6>{new Date(user?.tutor_signup_ts).getFullYear()}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="user-experience">
                      <div
                        className={
                          isDark ? "user-star-rate dark" : "user-star-rate"
                        }
                      >
                        {!hasRated ? (
                          <>
                            <h6>Rate Experience</h6>
                            <StarRatings
                              name="rating"
                              rating={rating}
                              numberOfStars={5}
                              starSpacing="2px"
                              starDimension="20px"
                              svgIconPath={starPath}
                              starHoverColor="#fec107"
                              starRatedColor="#fec107"
                              changeRating={changeRating}
                              svgIconViewBox="0 0 207.802 207.748"
                            />
                          </>
                        ) : (
                          <div className="fadeIn">
                            <h5>Thank you for rating us</h5>
                            <span role="img" aria-label="smiley">
                              😊
                            </span>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          isDark
                            ? "user-student-care dark"
                            : "user-student-care"
                        }
                      >
                        <a
                          href="https://api.whatsapp.com/send?phone=16504794112"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WhatsApp />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="user-related-section">
                    <div
                      className={
                        isDark ? "meeting-defaults dark" : "meeting-defaults"
                      }
                    >
                      <h3>Meeting Settings</h3>

                      <div className="defaults__wrapper">
                        <div className="defaults__options">
                          <h4>Turn off my Video</h4>

                          <Switch2
                            checked={permissionState.camera}
                            value={permissionState.camera}
                            onChange={handlePermissionsChange}
                            name="camera"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </div>
                        <div className="defaults__options microphone">
                          <h4>Mute my Microphone</h4>
                          <Switch2
                            checked={permissionState.microphone}
                            value={permissionState.microphone}
                            onChange={handlePermissionsChange}
                            name="microphone"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={isDark ? "theme-toggle dark" : "theme-toggle"}
                    >
                      <h6>Theme</h6>
                      <h5>Light</h5>
                      <h4>Dark</h4>
                      <div className={isDark ? "switch dark" : "switch"}>
                        <Switch
                          onChange={() => setIsDark(!isDark)}
                          checked={isDark}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor={"#161616"}
                          offColor={"#fff"}
                          width={70}
                          checkedHandleIcon={
                            <div className="switch-knob"></div>
                          }
                          uncheckedHandleIcon={
                            <div className="switch-knob"></div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="poweredbySection account-page">
                    <img
                      className="powered__by__icon"
                      src={isDark ? PustackLogoDark : PustackLogo}
                      alt="pustack-logo"
                      draggable={false}
                    />
                  </div>
                  <div
                    className={
                      isDark ? "dropdown__footer dark" : "dropdown__footer"
                    }
                  >
                    <p>
                      <p
                        onClick={() => {
                          setDocumentToShow(termsOfService);
                          setShowPDF(true);
                        }}
                      >
                        Terms of Service
                      </p>{" "}
                      <span>•</span>{" "}
                      <p
                        onClick={() => {
                          setDocumentToShow(privacyPolicy);
                          setShowPDF(true);
                        }}
                      >
                        Privacy Policy
                      </p>
                    </p>
                  </div>
                </div>

                {openRefillPage ? (
                  <div className="profile-page">
                    <div
                      className={
                        isDark
                          ? "page-title-wrapper dark"
                          : "page-title-wrapper"
                      }
                    >
                      <h2 className="page-title">
                        <ArrowBackIcon
                          onClick={() => {
                            setActiveIndex(0);
                            setTimeout(() => setOpenRefillPage(false), 350);
                            setOrderStatus(false);
                            setOrderId(null);
                            setProcessingRefill(false);
                            setProcessingOrder(false);
                            setRefillAmount("");
                            setOrderSuccessfull(false);
                          }}
                        />
                        Add Wallet Balance
                      </h2>
                    </div>
                  </div>
                ) : (
                  <div className="profile-page">
                    <div
                      className={
                        isDark
                          ? "page-title-wrapper dark"
                          : "page-title-wrapper"
                      }
                    >
                      <h2 className="page-title">
                        <ArrowBackIcon onClick={() => setActiveIndex(0)} />{" "}
                        Settings
                      </h2>
                    </div>
                    <div
                      className={
                        isDark
                          ? "page-content-settings dark"
                          : "page-content-settings"
                      }
                    >
                      <label htmlFor="profile-input">
                        <Avatar
                          className="user-profile-picture"
                          src={profileImage?.url}
                          name={user?.name}
                          uid={user?.uid}
                          alt="userdp"
                          draggable={false}
                        />
                        <h4>Change Profile Photo</h4>
                      </label>
                      <input
                        id="profile-input"
                        type="file"
                        accept="image/*"
                        onChange={profileImageSelectionHandler}
                      />
                    </div>

                    <div
                      className={
                        isDark ? "user-settings dark" : "user-settings"
                      }
                    >
                      <div className="user-settings-form-wrapper">
                        <div className="user-settings-form">
                          <form onSubmit={e => {
                            e.preventDefault();
                            !(['', user?.name].includes(profileName?.trim() ?? '')) && updateUserFullName()
                          }}>
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              id="name"
                              placeholder="Your name"
                              value={profileName}
                              onChange={(e) => setProfileName(e.target.value)}
                            />
                          </form>
                          <h5></h5>
                          {!(['', user?.name].includes(profileName?.trim() ?? '')) && (
                            <h6 onClick={updateUserFullName}>Update</h6>
                          )}
                        </div>
                        <div className="user-settings-form">
                          <form onClick={() => setActiveIndex(2)}>
                            <label htmlFor="name">Phone</label>
                            <PhoneInput
                              value={`+${user?.phone_country_code}${user?.phone_number}`}
                              placeholder="+91 XXXXX-XXXXX"
                              disableDropdown={true}
                              disabled={true}
                            />
                            <CheckGreenIcon />
                          </form>

                          <ChevronRight />
                        </div>
                      </div>
                      <h6>
                        <span
                          onClick={() => {
                            setDocumentToShow(termsOfService);
                            setShowPDF(true);
                          }}
                        >
                          Terms of Service
                        </span>
                      </h6>
                      <h6>
                        <span
                          onClick={() => {
                            setDocumentToShow(privacyPolicy);
                            setShowPDF(true);
                          }}
                        >
                          Privacy Policy
                        </span>
                      </h6>
                      <h6>
                        <span onClick={onLogOutBtnClick}>
                          Log Out {user?.email}
                        </span>
                      </h6>
                    </div>
                    <div className="poweredbySection">
                      <img
                        className="powered__by__icon"
                        src={isDark ? PustackLogoDark : PustackLogo}
                        alt="pustack-logo"
                        draggable={false}
                      />
                      <p className="poweredBy">Powered By PuStack Education</p>
                    </div>
                  </div>
                )}
                <div className="profile-page phone-update">
                  <div
                    className={
                      isDark ? "page-title-wrapper dark" : "page-title-wrapper"
                    }
                  >
                    <h2 className="page-title">
                      <ArrowBackIcon
                        onClick={() => {
                          setActiveIndex(1);
                          setOtpError(false);
                          setPhoneNumber(
                            user?.phone_country_code + user?.phone_number
                          );
                          setCountryCode(user?.phone_country_code);
                          setOtpError(false);
                          setOtpCode(Array(6).fill(""));
                          setOtpSent(false);
                          setSendingOtp(false);
                          setPhoneNumberError("");
                        }}
                      />{" "}
                      Phone Number
                    </h2>
                  </div>
                  {activeIndex === 2 && (
                    <div className={isDark ? "page-phone dark" : "page-phone"}>
                      <PhoneInput
                        country="in"
                        value={`+${user?.phone_country_code}${user?.phone_number}`}
                        preferredCountries={["us", "ae", "sg", "my", "in"]}
                        placeholder="+91 XXXXX-XXXXX"
                        inputProps={{ autoFocus: true }}
                        isValid={(_, country) => {
                          setCountryCode(country?.dialCode);
                          return true;
                        }}
                        onChange={(phone) => {
                          setPhoneNumber(phone);
                          setOtpSent(false);
                        }}
                      />

                      {user?.phone_country_code + user?.phone_number !==
                        phoneNumber && !otpSent && (
                          sendingOtp ?
                            <Lottie
                              options={{
                                animationData: circularProgress,
                                loop: true,
                              }}
                            /> :
                            <h4 onClick={() => !otpSent && handleSendingOTP()}>
                              Update
                            </h4>
                        )}
                    </div>
                  )}

                  {phoneNumberError && !otpSent && (
                    <div className="error__message">
                      <p>{phoneNumberError}</p>
                    </div>
                  )}

                  {/*<div ref={(ref) => (recaptchaRef = ref)}>*/}
                  {/*  <div id="recaptcha-container" />*/}
                  {/*</div>*/}

                  {otpSent && (
                    <div
                      className={
                        isDark
                          ? "otp-verification-modal dark"
                          : "otp-verification-modal"
                      }
                    >
                      <div className="wrapper">
                        <div className="otp-verification-modal-inner">
                          <img src={smartPhone} alt=""/>
                          <h5>OTP Verification</h5>
                          <h6>
                            <span>Enter OTP sent to</span> +{phoneNumber}
                          </h6>
                          <div
                            className={
                              otpError
                                ? "otp-verification error"
                                : "otp-verification"
                            }
                          >
                            <div>
                              {Array(6)
                                .fill(0)
                                .map((_, i) => (
                                  <div key={i}>
                                    <input
                                      name={`input${i}`}
                                      type="tel"
                                      value={otpCode[i]}
                                      maxLength={1}
                                      autoFocus={i === 0}
                                      autoComplete="off"
                                      onChange={(e) => handleOTPChange(e, i)}
                                      onKeyDown={(e) => handleKeyDown(e, i)}
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                          {otpError && <h3>{otpError || 'Verification failed, try again.'}</h3>}
                          <div
                            className={
                              resendCode ? "resend__code grey" : "resend__code"
                            }
                          >
                            <Timer
                              ref={timerRef}
                              initialTime={30100}
                              direction="backward"
                              checkpoints={[
                                {
                                  time: 1500,
                                  callback: () => setPlural(false),
                                },
                                {
                                  time: 500,
                                  callback: () => setResendCode(false),
                                },
                              ]}
                            >
                              {({ start, reset }) => (
                                <h4
                                  onClick={() => {
                                    if (!resendCode) {
                                      setPlural(true);
                                      reset();
                                      start();
                                      setResendCode(true);
                                      handleSendingOTP();
                                    }
                                  }}
                                >
                                  Resend OTP
                                  {resendCode && (
                                    <>
                                      ? Tap in <Timer.Seconds /> second
                                      {plural && "s"}
                                    </>
                                  )}
                                </h4>
                              )}
                            </Timer>
                          </div>
                          <button
                            disabled={!allowNext}
                            onClick={() => handlePhoneNumberChange()}
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </SwipeableViews>
            )}
          </Menu>

          <Drawer
            variant="temporary"
            open={isOpen}
            id={isDark ? "dark-menu" : "light-menu"}
            anchor={"right"}
            onClose={handleDrawerClose}
            className={
              isDark ? "account-setting-slider dark" : "account-setting-slider"
            }
            ModalProps={{ keepMounted: true }}
          >
            <SwipeableViews
              axis={"x"}
              scrolling={"false"}
              index={activeIndex}
              style={{ outline: "none", width: "100vw" }}
              ignoreNativeScroll={true}
              disabled={true}
            >
              <div>
                <div
                  className={"flex flex-col " +
                  (activeIndex === 0
                      ? "profile-page"
                      : "profile-page settings-page")
                  }
                >
                  <div
                    className={
                      isDark ? "page-title-wrapper dark" : "page-title-wrapper"
                    }
                  >
                    <h2 className="page-title">
                      <ArrowBackIcon onClick={handleDrawerClose} />
                    </h2>
                    <img
                      onClick={() => {
                        setActiveIndex(1);
                      }}
                      src={settingsIcon}
                      alt="SI"
                      draggable={false}
                    />
                  </div>
                  <div className="page-content flex flex-col flex-1">
                    <div className="user-card">
                      <img
                        className={"user-card-background"}
                        src={goldCard}
                        alt="user-card-background"
                        draggable={false}
                      />
                      <Avatar
                        className="user-profile-picture"
                        src={user?.profile_url}
                        name={user?.name}
                        onClick={() => {
                          setActiveIndex(1);
                        }}
                        uid={user?.uid}
                        alt="userdp"
                        draggable={false}
                      />
                      <img
                        src={PustackLogoGold}
                        alt="gold"
                        className="pustack-gold"
                        draggable={false}
                      />
                      <img
                        className={
                          !user?.is_external_instructor
                            ? "user-card-sim"
                            : "user-card-sim external"
                        }
                        src={goldSim}
                        alt="user-card-sim"
                        draggable={false}
                      />
                      {!user?.is_external_instructor && (
                        <div
                          className="wallet-balance"
                          onClick={async () => {
                            const isBusy = await isStudentBusy();
                            if (!isBusy) {
                              setOpenRefillPage(true);
                              setActiveIndex(1);
                            } else {
                              setMessage("You are busy in a blaze session");
                              setOpenSnack(true);
                              setTimeout(() => setOpenSnack(false), 2500);
                            }
                          }}
                        >
                          <h2>Wallet Balance</h2>
                          <h6>
                            ₹{" "}
                            {user?.balance
                              ? user?.balance?.toLocaleString("en-IN")
                              : 0}
                          </h6>
                        </div>
                      )}
                      <div className="user-card-details">
                        <div className="user-tier-wrapper">
                          <div className="user-tier">
                            <h5 className="user-tier-tag">TUTOR</h5>
                          </div>
                          <div className="user-details">
                            <h6>{user?.name}</h6>
                          </div>
                          <div className="user-details">
                            <h6>
                              {"+" +
                                user?.phone_country_code +
                                user?.phone_number}
                            </h6>
                          </div>
                        </div>
                        <div className={"user-validity"}>
                          <img
                            src={memberSince}
                            alt="member-since"
                            draggable={false}
                          />
                          <h6>{new Date(user?.tutor_signup_ts).getFullYear()}</h6>
                        </div>
                      </div>
                    </div>

                    <div className="user-scrollable flex flex-col flex-1">
                      <div className="user-experience">
                        <div
                          className={
                            isDark ? "user-star-rate dark" : "user-star-rate"
                          }
                        >
                          {!hasRated ? (
                            <>
                              <h6>Rate Experience</h6>
                              <StarRatings
                                rating={rating}
                                starRatedColor="#fec107"
                                starHoverColor="#fec107"
                                changeRating={changeRating}
                                numberOfStars={5}
                                starDimension="20px"
                                starSpacing="2px"
                                name="rating"
                              />
                            </>
                          ) : (
                            <div className="fadeIn">
                              <h5>Thank you for rating us</h5>
                              <span role="img" aria-label="smiley">
                                😊
                              </span>
                            </div>
                          )}
                        </div>

                        <div
                          className={
                            isDark
                              ? "user-student-care dark"
                              : "user-student-care"
                          }
                        >
                          <a
                            href="https://api.whatsapp.com/send?phone=16504794112"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <WhatsApp />
                          </a>
                        </div>
                      </div>
                      <div className="user-related-section">
                        <div
                          className={
                            isDark ? "theme-toggle dark" : "theme-toggle"
                          }
                        >
                          <h6>Theme</h6>
                          <h5>Light</h5>
                          <h4>Dark</h4>
                          <div className={isDark ? "switch dark" : "switch"}>
                            <Switch
                              onChange={() => setIsDark(!isDark)}
                              checked={isDark}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor={"#161616"}
                              offColor={"#fff"}
                              width={70}
                              checkedHandleIcon={
                                <div className="switch-knob"></div>
                              }
                              uncheckedHandleIcon={
                                <div className="switch-knob"></div>
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex-1" />
                      <div
                        className={
                          isDark ? "dropdown__footer dark" : "dropdown__footer"
                        }
                      >
                        <img
                          className="w-32"
                          src={isDark ? PustackLogoDark : PustackLogo}
                          alt="pustack-logo"
                          draggable={false}
                        />
                        <p>
                          <p
                            onClick={() => {
                              setDocumentToShow(termsOfService);
                              navigator.vibrate(5);
                              setShowPDF(true);
                            }}
                          >
                            Terms of Service
                          </p>{" "}
                          <span>•</span>{" "}
                          <p
                            onClick={() => {
                              setDocumentToShow(privacyPolicy);
                              navigator.vibrate(5);
                              setShowPDF(true);
                            }}
                          >
                            Privacy Policy
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="profile-page">
                  <div
                    className={
                      isDark ? "page-title-wrapper dark" : "page-title-wrapper"
                    }
                  >
                    <h2 className="page-title">
                      <ArrowBackIcon
                        onClick={() => {
                          navigator.vibrate(5);
                          setActiveIndex(0);
                        }}
                      />
                      Settings
                    </h2>
                  </div>
                  <div
                    className={
                      isDark
                        ? "page-content-settings dark"
                        : "page-content-settings"
                    }
                  >
                    <label htmlFor="profile-input">
                      <Avatar
                        className="user-profile-picture"
                        src={profileImage?.url}
                        name={user?.name}
                        uid={user?.uid}
                        alt="userdp"
                        draggable={false}
                      />
                      <h4>Change Profile Photo</h4>
                    </label>
                    <input
                      id="profile-input"
                      type="file"
                      accept="image/*"
                      onChange={profileImageSelectionHandler}
                    />
                  </div>

                  <div
                    className={isDark ? "user-settings dark" : "user-settings"}
                  >
                    <div className="user-settings-form-wrapper">
                      <div className="user-settings-form">
                        <form onSubmit={e => {
                          e.preventDefault();
                          !(['', user?.name].includes(profileName?.trim() ?? '')) && updateUserFullName()
                        }}>
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Your name"
                            value={profileName}
                            onChange={(e) => setProfileName(e.target.value)}
                            onMouseDown={() => setHidePoweredBy(true)}
                            onMouseLeave={() =>
                              setTimeout(() => setHidePoweredBy(false), 1000)
                            }
                          />
                        </form>
                        <h5></h5>

                        {!(['', user?.name].includes(profileName?.trim() ?? '')) && (
                          <h6 onClick={updateUserFullName}>Update</h6>
                        )}
                      </div>
                      <div className="user-settings-form">
                        <form
                          onClick={() => {
                            setActiveIndex(2);
                          }}
                        >
                          <label htmlFor="name">Phone</label>

                          <PhoneInput
                            value={`+${user?.phone_country_code}${user?.phone_number}`}
                            placeholder="+91 XXXXX-XXXXX"
                            disableDropdown={true}
                            disabled={true}
                            onClick={() => {
                              setActiveIndex(2);
                            }}
                          />
                          <CheckGreenIcon />
                        </form>
                        <h5></h5>

                        <ChevronRight />
                      </div>
                    </div>
                    <h6>
                      <span
                        onClick={() => {
                          setDocumentToShow(termsOfService);
                          setShowPDF(true);
                        }}
                      >
                        Terms of Service
                      </span>
                    </h6>
                    <h6>
                      <span
                        onClick={() => {
                          setDocumentToShow(privacyPolicy);
                          setShowPDF(true);
                        }}
                      >
                        Privacy Policy
                      </span>
                    </h6>
                    <h6>
                      <span onClick={onLogOutBtnClick}>
                        Log Out {user?.email}
                      </span>
                    </h6>
                  </div>
                  {!hidePoweredBy && (
                    <div className="poweredbySection">
                      <img
                        className="powered__by__icon"
                        src={isDark ? PustackLogoDark : PustackLogo}
                        alt="pustack-logo"
                        draggable={false}
                      />
                      <p className="poweredBy">Powered By PuStack Education</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="profile-page">
                <div
                  className={
                    isDark ? "page-title-wrapper dark" : "page-title-wrapper"
                  }
                >
                  <h2 className="page-title">
                    <ArrowBackIcon
                      onClick={() => {
                        setActiveIndex(1);
                        setOtpError(false);
                        setPhoneNumber(
                          user?.phone_country_code + user?.phone_number
                        );
                        setCountryCode(user?.phone_country_code);
                        setOtpError(false);
                        setOtpCode(Array(6).fill(""));
                        setOtpSent(false);
                        setSendingOtp(false);
                        setPhoneNumberError("");
                      }}
                    />{" "}
                    Phone Number
                  </h2>
                </div>
                {isOpen && activeIndex === 2 && (
                  <div className={isDark ? "page-phone dark" : "page-phone"}>
                    {/*<div ref={(ref) => (recaptchaRef = ref)}>*/}
                    {/*  <div id="recaptcha-container" />*/}
                    {/*</div>*/}
                    <PhoneInput
                      country="in"
                      value={`+${user?.phone_country_code}${user?.phone_number}`}
                      preferredCountries={["us", "ae", "sg", "my", "in"]}
                      placeholder="+91 XXXXX-XXXXX"
                      inputProps={{ autoFocus: activeIndex === 2 }}
                      isValid={(_, country) => {
                        setCountryCode(country?.dialCode);
                        return true;
                      }}
                      onChange={(phone) => {
                        setPhoneNumber(phone);
                        setOtpSent(false);
                      }}
                    />

                    {user?.phone_country_code + user?.phone_number !==
                      phoneNumber && !otpSent && (
                        sendingOtp ?
                          <Lottie
                            options={{
                              animationData: circularProgress,
                              loop: true,
                            }}
                          /> :
                          <h4 onClick={() => !otpSent && handleSendingOTP()}>
                            Update
                          </h4>
                      )}
                  </div>
                )}

                {phoneNumberError && !otpSent && (
                  <div className="error__message">
                    <p>{phoneNumberError}</p>
                  </div>
                )}

                {/*<div ref={(ref) => (recaptchaRef = ref)}>*/}
                {/*  <div id="recaptcha-container" />*/}
                {/*</div>*/}

                {otpSent && (
                  <div
                    className={
                      isDark
                        ? "otp-verification-modal dark"
                        : "otp-verification-modal"
                    }
                  >
                    <div className="wrapper">
                      <div className="otp-verification-modal-inner">
                        <img src={smartPhone} alt=""/>
                        <h5>OTP Verification</h5>
                        <h6>
                          <span>Enter OTP sent to</span> +{phoneNumber}
                        </h6>
                        <div
                          className={
                            otpError
                              ? "otp-verification error"
                              : "otp-verification"
                          }
                        >
                          <div>
                            {Array(6)
                              .fill(0)
                              .map((_, i) => (
                                <div key={i}>
                                  <input
                                    name={`input${i}`}
                                    type="tel"
                                    value={otpCode[i]}
                                    maxLength={1}
                                    autoFocus={i === 0}
                                    autoComplete="off"
                                    onChange={(e) => handleOTPChange(e, i)}
                                    onKeyDown={(e) => handleKeyDown(e, i)}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                        {otpError && <h3>{otpError || 'Verification failed, try again.'}</h3>}
                        <div
                          className={
                            resendCode ? "resend__code grey" : "resend__code"
                          }
                        >
                          <Timer
                            ref={timerRef}
                            initialTime={30100}
                            direction="backward"
                            checkpoints={[
                              {
                                time: 1500,
                                callback: () => setPlural(false),
                              },
                              {
                                time: 500,
                                callback: () => setResendCode(false),
                              },
                            ]}
                          >
                            {({ start, reset }) => (
                              <h4
                                onClick={() => {
                                  if (!resendCode) {
                                    setPlural(true);
                                    reset();
                                    start();
                                    setResendCode(true);
                                    handleSendingOTP();
                                  }
                                }}
                              >
                                Resend OTP
                                {resendCode && (
                                  <>
                                    ? Tap in <Timer.Seconds /> second
                                    {plural && "s"}
                                  </>
                                )}
                              </h4>
                            )}
                          </Timer>
                        </div>
                        <button
                          disabled={!allowNext}
                          onClick={() => handlePhoneNumberChange()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </SwipeableViews>
          </Drawer>

          <Drawer
            variant="temporary"
            open={openChat}
            anchor={"right"}
            onClose={() => {
              setOpenChat(false);
              setCloseCarePage(true);
            }}
            className={isDark ? "dark" : ""}
            ModalProps={{ keepMounted: true }}
          >
            {!closeCarePage && <PuStackCareMobile />}
          </Drawer>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={openSnack}
            onClose={handleSnackClose}
            message={message}
            key={"bottom" + "center"}
            TransitionComponent={Fade}
            className={isDark ? "snackbar snackbarDark" : "snackbar"}
          />

          <Dialog open={showPDF} onClose={() => setShowPDF(false)}>
            {showPDF && (
              <PdfPreview
                pdf={documentToShow}
                onClose={() => setShowPDF(false)}
              />
            )}
          </Dialog>
        </div>
      ) : (
        <>
          <div className="header__right">
            <div className="header__info">
              <Link to="/signin">
                <button className="answers__bottomButton">Signin</button>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountSettings;
