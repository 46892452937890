export { default as logo } from "./images/logo.png";
export { default as logoDark } from "./images/logoDark.png";
export { default as logoDark2 } from "./images/logoDark2.png";
export { default as logoLight2 } from "./images/logoLight2.png";
export { default as proLogo } from "./images/proLogo.png";
export { default as proLogoDark } from "./images/proLogoDark.png";
export { default as upiIcon } from "./images/upi.png";
export { default as bankIcon } from "./images/icons/bank.png";

export { default as Youtube } from "./images/youtube.svg";
export { default as YoutubeDark } from "./images/youtube_dark.svg";
export { default as TrendingLogo } from "./images/trending_logo.svg";

export { default as PustackLogoSVG } from "./images/pustack_logo.svg";

export { default as ImageError } from "./images/image_error.svg";
export { default as Calculator } from "./images/calculator.svg";
export { default as English } from "./images/english.svg";
export { default as EnglishSelected } from "./images/english_selected.svg";
export { default as Maths } from "./images/maths.svg";
export { default as MathsSelected } from "./images/maths_selected.svg";
export { default as Science } from "./images/science.svg";
export { default as SST } from "./images/sst.svg";
export { default as SSTSelected } from "./images/sst_selected.svg";

export { default as Google } from "./images/google.svg";
export { default as Facebook } from "./images/facebook.png";
export { default as Facebook2 } from "./images/facebook2.png";
export { default as Apple } from "./images/apple.svg";

export { default as CheckIcon } from "./images/icons/check.svg";
export { default as CheckIconGreen } from "./images/icons/check_green.svg";
export { default as LockIcon } from "./images/icons/lock.svg";
export { default as DropDownIcon } from "./images/icons/triangle.svg";

export { default as Error } from "./lottie/error.json";

export { default as PingRing } from "./sounds/ping_ring.mp3";
export { default as newMsgAudio } from "./sounds/comment_notification.mp3";

export { default as Pos } from "./walletRefill/pos.svg";
export { default as BarCode } from "./walletRefill/bar_code.svg";
export { default as PosBack } from "./walletRefill/pos_back.svg";
export { default as PosReceipt } from "./walletRefill/pos_receipt.svg";
export { default as PosOriginal } from "./walletRefill/pos_original.svg";
export { default as PosReceiptOriginal } from "./walletRefill/pos_receipt_original.svg";

export { default as goldSim } from "./images/userMenu/sim.svg";
export { default as goldCard } from "./images/userMenu/gold.jpg";

export const defaultPic = 'https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/users%2Fuser_profile%2Fdefault%2Fimage.jpg?alt=media&token=da277c04-0b39-49a3-8b90-4d6e93129a93';

export { default as PustackLogoGold } from "./images/pustack_gold.png";
export { default as settingsIcon } from "./images/userMenu/setting.svg";
export { default as smartPhone } from "./images/userMenu/smartphone.svg";
export { default as memberSince } from "./images/userMenu/member_since.svg";
export { default as astronaut } from "./images/userMenu/pro_person.png";
export { default as planet1 } from "./images/userMenu/pro_planet_blue.svg";
export { default as planet2 } from "./images/userMenu/pro_planet_purple.svg";
export { default as planet3 } from "./images/userMenu/pro_planet_yellow.svg";
export { default as studentCare } from "./images/userMenu/customer_service.svg";
export { default as videoPlaying } from "./lottie/video_playing.json";
export { default as nounBook } from "./images/classroom/noun_Book_5020.svg";
export { default as VideoCameraRounded } from "./blaze/videoCameraRounded";
export { default as OpenInFull } from "./blaze/openInFull";
export { default as NotesSVG } from "./images/pdf.svg";
export { default as CloseFull } from "./blaze/closeFull";

export { default as DotsLoading } from "./lottie/dot-loading.json";
export { default as CheckSuccess } from "./lottie/check-mark-success.json";
export { default as circularProgress } from "./lottie/circularProgress.json";

export { default as landingHero } from "./images/landing/Tutor-landing-hero.png";
export { default as teacher1 } from "./images/landing/teacher1.png";
export { default as teacher2 } from "./images/landing/teacher2.png";
export { default as teacher3 } from "./images/landing/teacher3.png";
export { default as teacher4 } from "./images/landing/teacher4.png";
export { default as walletCoins } from "./images/landing/wallet-coins.png";
export { default as workTerms } from "./images/landing/work-terms.png";
export { default as lappy } from "./images/landing/lappy.png";
