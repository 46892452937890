import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { useMediaQuery } from "react-responsive";
import LoginBar from "../login-bar";
import AccountSettings from "./account-settings";
import { logo as logoLight, logoDark } from "../../../assets";
import appLogo from "../../../assets/images/icons/pustack_app_logo.svg";
import { proLogo as proLogoLight, proLogoDark } from "../../../assets";
import tutorWhiteLogo from '../../../assets/images/auth/pustack_tutor_white_logo.png'
import tutorLogo from '../../../assets/images/auth/pustack_tutor_color_logo.png'
import { UserContext, ThemeContext } from "../../../context";
import {
  blazeUnreadMesagesNotification,
  unreadAnswerNotification,
} from "../../../database";
import "./style.scss";
import MobileMenuItems from "../mobile-menu-items";

export default function Navbar({ onlyBottom, setMobileOpen = () => {}, handleMenuClick = () => {}, showMenuItem = true }) {
  const [user] = useContext(UserContext).user;
  const [isUserProTier] = useContext(UserContext).tier;
  const [showBlazeMain] = useContext(UserContext).showBlazeMain;

  const [, setUnreadAnswerCount] = useContext(UserContext).unreadAnswerCount;

  const [isDarkMode] = useContext(ThemeContext).theme;
  const [blazeNewMsg, setBlazeNewMsg] = useState(false);
  const [menuVisibility, setMenuVisibility] = useState(true);
  const [profileVisibility, setProfileVisibility] = useState(true);
  const [blazeDarkMode, setBlazeDarkMode] = useState(false);
  const [closeInstallApp, setCloseInstallApp] =
    useContext(UserContext).closeInstallApp;

  const isSmallScreen = useMediaQuery({ query: "(max-width: 720px)" });
  const isSmallScreen2 = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletScreen = useMediaQuery({ query: "(max-width: 1200px)" });

  const pathName = window.location.pathname;
  useEffect(() => {
    const menuShownAt = (path) => {
      return (
        (isSmallScreen ? !path.includes("/blaze") : true) &&
        (isSmallScreen ? !path.includes("/doubts") : true) &&
        (isSmallScreen ? !path.includes("/newsfeed") : true) &&
        (isSmallScreen ? !path.includes("/classes") : true)
      );
    };

    const paths = ["/doubts", "/blaze", "/newsfeed", "/classes"];

    const profileShownAt = (path) => {
      return paths.every((item) => !path.includes(item));
    };

    setMenuVisibility(menuShownAt(pathName));
    isSmallScreen && setProfileVisibility(profileShownAt(pathName));
  }, [pathName]);

  useEffect(() => {
    if (isSmallScreen2) {
      if (showBlazeMain && pathName.includes("/blaze")) {
        setBlazeDarkMode(true);
      } else setBlazeDarkMode(false);
    }
  }, [pathName, showBlazeMain, isSmallScreen2]);

  useEffect(() => {
    // blazeUnreadMesagesNotification({
    //   grade: user?.grade,
    //   userId: user?.uid,
    //   callback: setBlazeNewMsg,
    //   isExternal: user?.is_external_instructor || false,
    // });
  }, [user?.grade, user?.is_external_instructor]);

  useEffect(() => {
    // unreadAnswerNotification({
    //   grade: user?.grade,
    //   userId: user?.uid,
    //   callback: setUnreadAnswerCount,
    // });
  }, [user?.grade]);

  return (
    onlyBottom ? <div className="header_mobileMenuItems">
        <MobileMenuItems
          setMenuItem={() => {}}
          selectedMenuItem={() => {}}
          blazeNewMsg={blazeNewMsg}
        />
        {!closeInstallApp && (
          <div className="app-install">
            <h6
              onClick={() => {
                setCloseInstallApp(true);
                localStorage.setItem("closeInstallApp", JSON.stringify(true));
              }}
            >
              X
            </h6>
            <img src={appLogo} alt="app" />
            <div>
              <h5>PuStack App</h5>
              <p>Get the best of PuStack Experience</p>
            </div>
            <div className="install-btn">
              <a
                href="https://play.google.com/store/apps/details?id=com.pustack.android.pustack"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  setCloseInstallApp(true);
                  localStorage.setItem(
                    "closeInstallApp",
                    JSON.stringify(true)
                  );
                }}
              >
                INSTALL APP
              </a>
            </div>
          </div>
        )}
      </div> :
    <div
      className={
        blazeDarkMode || isDarkMode ? "headerContainer dark" : "headerContainer"
      }
    >
      {!user && <LoginBar />}
      {!onlyBottom && <div
        className={
          showMenuItem || !isSmallScreen
            ? "header"
            : "header liveSessionsHeader"
        }
      >
        <div className="header__left">
          {isTabletScreen && !isSmallScreen && (
            <div>
              <MenuIcon
                onClick={() => {
                  setMobileOpen(true)
                  handleMenuClick();
                }}
                style={{
                  color: "var(--color-text)",
                  cursor: "pointer",
                  visibility: menuVisibility ? "visible" : "hidden",
                }}
              />
            </div>
          )}

          <div
            className={
              menuVisibility
                ? "header__logo"
                : "header__logo header__logo__left"
            }
          >
            <Link to="/">
              <img
                className="header__leftImage"
                src={
                  showMenuItem || !isSmallScreen
                    ? blazeDarkMode || isDarkMode
                      ? tutorWhiteLogo
                      : tutorLogo
                    : tutorWhiteLogo
                }
                alt="PuStack"
              />
            </Link>
          </div>
        </div>

        <AccountSettings profileVisibility={profileVisibility}/>
      </div>}
      {isSmallScreen && showMenuItem && (
        <div className="header_mobileMenuItems">
          <MobileMenuItems
            setMenuItem={() => {}}
            selectedMenuItem={() => {}}
            blazeNewMsg={blazeNewMsg}
          />
          {!closeInstallApp && (
            <div className="app-install">
              <h6
                onClick={() => {
                  setCloseInstallApp(true);
                  localStorage.setItem("closeInstallApp", JSON.stringify(true));
                }}
              >
                X
              </h6>
              <img src={appLogo} alt="app" />
              <div>
                <h5>PuStack App</h5>
                <p>Get the best of PuStack Experience</p>
              </div>
              <div className="install-btn">
                <a
                  href="https://play.google.com/store/apps/details?id=com.pustack.android.pustack"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    setCloseInstallApp(true);
                    localStorage.setItem(
                      "closeInstallApp",
                      JSON.stringify(true)
                    );
                  }}
                >
                  INSTALL APP
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
