import React, { useContext } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import BarChart from "../barChart";
import { BlazeExternalContext } from "../../../context";
import "./style.scss";

const BlazeExternalEarnings = () => {
  const [, setOpenPages] = useContext(BlazeExternalContext).openPages;
  return (
    <div className="blaze__earnings__wrapper">
      <div className="blaze__earnings__head">
        <ArrowBackIos onClick={() => setOpenPages(false)} />{" "}
        <h1>Earnings</h1>
      </div>

      <div className="blaze__earnings__body">
        <div className="blaze__earnings__card">
          <div className="earnings__top">
            <h4>₹5,000</h4>
            <h4>47 calls</h4>
            <h4>1,000 min</h4>
          </div>
          <div className="earnings__graph">
            <BarChart />
          </div>
        </div>
        <div className="blaze__earnings__card">
          <div className="earnings__top">
            <h4>₹5,000</h4>
            <h4>47 calls</h4>
            <h4>1,000 min</h4>
          </div>
          <div className="earnings__graph">
            <BarChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlazeExternalEarnings;
