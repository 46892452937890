import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { TutorDashboard, TutorSidebar } from "../../containers";
import "./style.scss";

export default function Home() {
  const [open, setOpen] = useState(false);
  return (
    <div className="tutor-home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>PuStack Tutor</title>
      </Helmet>

      <TutorDashboard openSidebar={() => setOpen(true)} />

      <Drawer
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{ keepMounted: true }}
        className="home-sidebar"
      >
        <TutorSidebar />
      </Drawer>
    </div>
  );
}
