import React, {useContext, useState} from 'react';
import Share from "@material-ui/icons/Share";
import Send from "@material-ui/icons/Send";
import {ThemeContext, UserContext} from "../../context";
import useBlazeSession from "../../hooks/useBlazeSession";
import {Drawer} from "@material-ui/core";
import {useMediaQuery} from "react-responsive";
import BlazeExternalCard from "../../containers/blazeExternal/blazeCard";
import circularProgress from "../../assets/lottie/circularProgress.json";
import Lottie from "lottie-react-web";
import {db} from "../../firebase_config";
import {sendBlazeChat} from "../../database";
import {getGrade} from "../../helpers";
import {useHistory} from "react-router-dom";

export default function ShareLecture({lectureItem, tipItem, practiceItem}) {
  // user from the UserContext
  const sessions = useBlazeSession('accepted');
  const [user] = useContext(UserContext).user;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const [isDark] = useContext(ThemeContext).theme;
  const [selectedSession, setSelectedSession] = useState();
  const [sharing, setSharing] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);

  async function handleLecture(lectureItem) {
    if(sharing || !selectedSession || !lectureItem) return;
    setSharing(true);

    console.log('selectedSession - ', selectedSession, lectureItem);

    // return;

    const categorySnapshot = await db.collection('cms_data')
      .doc(lectureItem.grade_id)
      .collection('scope')
      .doc(lectureItem.grade_id + '_learn')
      .collection('category')
      .doc(lectureItem.category_id)
      .get();

    const categoryData = categorySnapshot.data();
    if(!categoryData) return;

    const currentSubject = categoryData._meta.find((subject) => subject.subject_id === lectureItem.subject_id);
    if(!currentSubject) return;

    const currentChapter = currentSubject.chapters?.find((chapter) => chapter.chapter_id === lectureItem.chapter_id);
    if(!currentChapter) return;



    return {
      _meta: {
        category_id: lectureItem.category_id,
        chapter_hex_color: currentChapter.hex_color,
        chapter_id: lectureItem.chapter_id,
        description: currentChapter.description,
        grade_id: lectureItem.grade_id,
        illustration_art: currentChapter.illustration_art,
        lecture_header_item_id: lectureItem.lecture_header_item_name ? lectureItem.id : null,
        lecture_item_id: lectureItem.lecture_header_item_name ? lectureItem.lecture_item_id : lectureItem.id,
        lecture_name: lectureItem.lecture_header_item_name ?? lectureItem.lecture_item_name,
        subject_id: lectureItem.subject_id,
        tab_id: lectureItem.tab_id,
      },
      attachment_name: null,
      attachment_type: 'lecture',
      attachment_url: null,
      thumbnail_url: null,
    }
  }

  async function handleTip(tipItem) {
    if(sharing || !selectedSession || !tipItem) return;
    setSharing(true);

    const tipId = tipItem.tip_id;
    const categoryId = tipId.split('_').slice(0, 4).join('_');
    const gradeId = getGrade(tipId);

    return {
      _meta: {
        category_id: categoryId,
        chapter_hex_color: '#fff',
        description: tipItem.description,
        grade_id: gradeId,
        illustration_art: tipItem.banner_image,
        lecture_name: tipItem.tip_name,
        lecture_type: 'tip',
        tip_item_id: tipItem.tip_id,
      },
      attachment_name: null,
      attachment_type: 'lecture',
      attachment_url: null,
      thumbnail_url: null,
    }
  }

  async function handlePractice(practiceItem) {
    if(sharing || !selectedSession || !practiceItem) return;
    setSharing(true);

    const isHeaderItem = practiceItem.exam_header_item_id;

    const examItemId = isHeaderItem ? practiceItem.exam_header_item_id.split('_').slice(0, -1).join('_') : practiceItem.exam_item_id;
    const examHeaderItemId = isHeaderItem ? practiceItem.exam_header_item_id : null;
    const categoryId = examItemId.split('_').slice(0, 4).join('_');
    const examId = examItemId.split('_').slice(0, 5).join('_');
    const gradeId = getGrade(examId);
    const lectureName = isHeaderItem ? practiceItem.lecture_header_item_name : practiceItem.exam_item_name;

    console.log('practiceItem - ', practiceItem);
    // return null;
    return {
      _meta: {
        category_id: categoryId,
        chapter_hex_color: "#fff",
        description: "",
        exam_header_item_id: examHeaderItemId,
        exam_id: examId,
        exam_item_id: examItemId,
        grade_id: gradeId,
        illustration_art: `https://firebasestorage.googleapis.com/v0/b/avian-display-193502.appspot.com/o/cms_data%2F${gradeId}%2Fscope%2F${gradeId}_practice%2Fcategory%2F${categoryId}%2Fexam%2F${examId}%2Fmain_thumbnail.png?alt=media&token=fbab5bd4-6121-4c46-a5b7-db498cedef63`,
        lecture_name: lectureName,
        lecture_type: 'practice'
      },
      attachment_name: null,
      attachment_type: 'lecture',
      attachment_url: null,
      thumbnail_url: null,
    }
  }

  async function handleShare() {
    let attachment = false;


    console.log('practiceItem - ', practiceItem);

    if(lectureItem) {
      attachment = await handleLecture(lectureItem);
    }

    if(tipItem) {
      attachment = await handleTip(tipItem);
    }

    if(practiceItem) {
      attachment = await handlePractice(practiceItem);
    }

    if(!attachment) return;

    // console.log('sendBlazeChat - ', {
    //   user,
    //   text: '',
    //   reference: selectedSession.reference,
    //   session_id: selectedSession.id,
    //   receiver_id: selectedSession.student_id,
    //   session_topic: selectedSession.topic,
    //   student_grade: getGrade(selectedSession.skill),
    //   type: 'attachment',
    //   attachmentObj: attachment
    // })
    //
    // return;

    const chatId = await sendBlazeChat({
      user,
      text: '',
      reference: selectedSession.reference,
      session_id: selectedSession.id,
      receiver_id: selectedSession.student_id,
      session_topic: selectedSession.topic,
      student_grade: getGrade(selectedSession.skill),
      type: 'attachment',
      attachmentObj: attachment
    })

    console.log('chatId - ', chatId);

    setSharing(false);

    handleClose();
    history.push('/blaze/chat/' + selectedSession.id);
  }

  function handleClose() {
    setSharing(false);
    setOpen(false);
  }

  return (
    <div className="z-50">
      {(lectureItem || tipItem || practiceItem) && <div onClick={() => setOpen(true)}
            className="z-50 fixed right-10 bottom-10 text-white bg-red-600 rounded-full h-14 w-14 cursor-pointer flex items-center justify-center">
        <Share/>
      </div>}
      <Drawer
        variant="temporary"
        // open={}
        open={open}
        // anchor={isSmallScreen ? (isiPad ? "right" : "bottom") : "right"}
        anchor={"right"}
        // disableBackdropClick={true}
        // disableEscapeKeyDown={true}
        onClose={handleClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{className: isSmallScreen ? 'w-full h-screen pt-6 pb-0' : 'w-96 h-screen pt-6', style: {
            backgroundColor: '#18181b'
          }}}
        // BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}
      >
        <div className="h-full flex flex-col">
          <div className="px-5 overflow-auto flex-1 dark">
            {sessions && (
              sessions.map(session => (
                <BlazeExternalCard
                  onClick={() => {
                    // closeDrawer();
                    // setIsChatOpen(true);
                    // setOpenPages(false);
                    // setSessionId(session?.id);
                    // setSessionSelected(session);
                    setSelectedSession(session);
                  }}
                  noLink
                  // ref={n => targetRefCB(n, session?.id)}
                  isSessionSelected={selectedSession?.id === session?.id}
                  sessionId={session?.id}
                  key={session?.id}
                  topic={session?.topic}
                  type={"accepted"}
                  skill={session?.skill}
                  studentName={session?.student_name}
                  studentImage={session?.student_profile_pic}
                  studentId={session?.student_id}
                  gradient={session?.subject_color_gradient}
                />
              ))
            )}
          </div>
          <div className="w-full h-20 flex justify-center items-center bg-transparent">
            <div className="w-[80%] mx-auto">
              {!selectedSession ? <p className="text-white text-center text-sm text-opacity-30 font-sans">Select a session to share this lecture</p> :
              <button onClick={handleShare} className="blue-gradient-button h-10 flex justify-center items-center gap-2 text-white rounded-lg w-full">
                {sharing ? <Lottie options={{animationData: circularProgress, loop: true}} style={{padding: 0, width: '26px'}}/> :
                <>
                  <Send />
                  <span className="-mb-0.5">Share</span>
                </>}
              </button>}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
