import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ArrowBackIos, Delete, Edit } from "@material-ui/icons";
import { showSnackbar } from "../../../helpers";
import {
  addInstructorSkill,
  deleteInstructorSkills,
  getOverallSkills,
} from "../../../database";
import {
  BlazeExternalContext,
  ThemeContext,
  UserContext,
} from "../../../context";
import AddIcon from "../../../assets/blaze/addIcon";

import "./style.scss";

const BlazeExternalSettings = () => {
  const [, setOpenPages] = useContext(BlazeExternalContext).openPages;
  const [state, setState] = useState({ camera: false, microphone: false });

  const [disabled, setDisabled] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [skillGrade, setSkillGrade] = useState("");
  const [allSkills, setAllSkills] = useState(null);
  const [skillSubject, setSkillSubject] = useState("");
  const [skillCategory, setSkillCategory] = useState("");
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [instructorSkills, setInstructorSkills] =
    useContext(BlazeExternalContext).skills;

  const gradesList = [{ value: "class_9", name: "Class 9" }];
  const categoryIdx = {
    Maths: 0,
    Science: 1,
    SST: 2,
    English: 3,
    General: 4,
  };

  const [user] = useContext(UserContext).user;
  const [isDark] = useContext(ThemeContext).theme;

  const handleChange = async (event) => {
    const { name } = event.target;
    let options = {};

    if (name === "camera") {
      options = { video: true, audio: false };
    } else {
      options = { audio: true, video: false };
    }

    navigator.getUserMedia(
      options,
      (stream) => {
        stream.getTracks().forEach((x) => x.stop());
        setState({ ...state, [name]: true });
      },
      (err) => {
        console.log(err);
        setState({ ...state, [name]: false });
      }
    );
  };

  useEffect(() => {
    getAllPermissions();
  }, []);

  useEffect(() => {
    getOverallSkillsFn();
  }, []);

  useEffect(() => {
    const res = isBtnDisabled();
    setDisabled(res);
  }, [skillCategory, skillGrade, skillSubject]);

  const permissionsNames = ["camera", "microphone"];

  const getAllPermissions = async () => {
    let allPermissions = { ...state };
    await Promise.all(
      permissionsNames.map(async (permissionName) => {
        try {
          let permission;
          switch (permissionName) {
            case "push":
              permission = await navigator.permissions.query({
                name: permissionName,
                userVisibleOnly: true,
              });
              break;
            default:
              permission = await navigator.permissions.query({
                name: permissionName,
              });
          }

          if (permission.state === "granted") {
            allPermissions = { ...allPermissions, [permissionName]: true };
          } else {
            allPermissions = { ...allPermissions, [permissionName]: false };
          }
        } catch (e) {
          allPermissions = { ...allPermissions, [permissionName]: false };
        }

        setState(allPermissions);
      })
    );
  };

  const getOverallSkillsFn = async () => {
    const res = await getOverallSkills();
    setAllSkills(res);
  };

  const handleSkillModalClose = () => {
    setOpenSkillModal(false);
    setSkillCategory("");
    setSkillSubject("");
    setSkillGrade("");
  };

  const isBtnDisabled = () => {
    if (skillCategory === "Maths") return false;
    else if (skillCategory !== "Maths" && skillSubject) return false;

    return true;
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleAddSkill = async () => {
    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    if (_skillId) {
      try {
        let skillToAdd = {
          category: skillCategory,
          grade: skillGrade,
          id: _skillId,
          is_skippable:
            allSkills[skillGrade]?.skip_subjects[categoryIdx[skillCategory]],
          reason: null,
          status: "requested",
          subject: skillCategory === "Maths" ? null : skillSubject,
        };

        const res = await addInstructorSkill({
          instructorId: user?.uid,
          skillId: _skillId,
          skill: skillToAdd,
        });

        if (res) {
          setInstructorSkills([...instructorSkills, skillToAdd]);
          handleSkillModalClose();
        } else console.log("failed");
      } catch (er) {
        console.log(er);
      }
    }
  };

  const addSkillToDelete = (skill) => {
    setSkillsToDelete([...skillsToDelete, skill.id]);
  };

  const cancelSkillDelete = (skill) => {
    setSkillsToDelete([...skillsToDelete.filter((item) => item !== skill.id)]);
  };

  const isSelectedToDelete = (skillId) => {
    return skillsToDelete.filter((item) => item === skillId).length === 1;
  };

  const handleSkillsDelete = async () => {
    const res = await deleteInstructorSkills({
      instructorId: user?.uid,
      skillsToDelete,
    });
    if (res) {
      showSnackbar("Skills have been deleted", "success");
      setInstructorSkills(
        instructorSkills.filter((item) => !skillsToDelete.includes(item.id))
      );
      setSkillsToDelete([]);
    } else {
      showSnackbar("Could not delete the selected skills", "error");
      setSkillsToDelete([]);
    }
  };

  const isSkillAlreadyAdded = (subject) => {
    return (
      instructorSkills?.filter((item) => item.subject === subject).length === 1
    );
  };

  const isMathsSkillAdded = () => {
    return (
      instructorSkills?.filter((item) => item.category === "Maths").length === 1
    );
  };

  return (
    <div className="blaze__settings__wrapper">
      <div className="blaze__settings__head">
        <ArrowBackIos onClick={() => setOpenPages(false)} /> <h1>Settings</h1>
      </div>
      <div className="blaze__settings__body">
        <div className="settings__skills__wrapper">
          <div className="skills__head">
            <h1>Skills</h1>
            {skillsToDelete?.length > 0 ? (
              <button className="popIn" onClick={handleSkillsDelete}>
                Delete
              </button>
            ) : (
              <button onClick={() => setOpenSkillModal(true)}>
                <Edit />
                Add
              </button>
            )}
          </div>
          <div>
            <table className="skills__table">
              {instructorSkills?.map((item, i) => (
                <tr
                  className="skills__row fadeIn"
                  style={{ animationDelay: `${0.15 * (i + 1)}s` }}
                  key={item.id}
                >
                  <td className={isSelectedToDelete(item.id) && "fadeOutItem"}>
                    <h1>{item.grade.replace("_", " ")}</h1>
                  </td>
                  <td className={isSelectedToDelete(item.id) && "fadeOutItem"}>
                    <h1>{item.subject ? item.subject : item.category}</h1>
                  </td>

                  <td className={isSelectedToDelete(item.id) && "fadeOutItem"}>
                    <button
                      onClick={() =>
                        !isSelectedToDelete(item.id) && addSkillToDelete(item)
                      }
                    >
                      <Delete />
                    </button>
                  </td>
                  {isSelectedToDelete(item.id) && (
                    <td className="popIn cancel__delete">
                      <CancelIcon onClick={() => cancelSkillDelete(item)} />
                    </td>
                  )}
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="settings__documents__wrapper">
          <div className="documents__head">
            <h1>Documents</h1>
          </div>
          <div className="documents__body">
            <div
              className="document__name"
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <h1>10TH MARKSHEET</h1>
              <IconButton className={expanded ? "rotateTop" : "rotateBottom"}>
                <ExpandMoreIcon />
              </IconButton>
            </div>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <div className="document__form">
                <div className="document__text__input__wrapper">
                  <input
                    id="roll-number"
                    type="text"
                    value=""
                    className="document__text__input"
                    placeholder="Roll Number"
                  />
                </div>

                <button className="document__submit__btn">Submit</button>
              </div>
            </Collapse>
            <div className="add__document">
              <label htmlFor="tenth">
                <h4>
                  <AddIcon /> Add Document
                </h4>
              </label>
              <input
                id="tenth"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="settings__defaults__wrapper">
          <div className="defaults__head">
            <h1>Classroom Defaults</h1>
          </div>
          <div className="defaults__wrapper">
            <div className="defaults__options">
              <h4>Camera</h4>
              <Switch
                checked={state.camera}
                onChange={handleChange}
                name="camera"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <div className="defaults__options">
              <h4>Microphone</h4>
              <Switch
                checked={state.microphone}
                onChange={handleChange}
                name="microphone"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleSkillModalClose}
        ariaHideApp={false}
        overlayClassName="new-post-modal-overlay"
        isOpen={openSkillModal}
        className={isDark ? "skills__modal dark" : "skills__modal"}
      >
        {openSkillModal && (
          <div className="skills__modal__inner">
            <div className="modal__head">
              <h1>Add Skills</h1>
              <CloseIcon onClick={handleSkillModalClose} />
            </div>

            <div className="grades__list__wrapper">
              <h1>Select Grade</h1>
              <div className="grades__list">
                {gradesList.map((item) => (
                  <span
                    key={item.value}
                    onClick={() => {
                      setSkillGrade(item.value);
                      setSkillCategory("");
                      setSkillSubject("");
                    }}
                    className={
                      item.value === skillGrade ? "chip selected" : "chip"
                    }
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            {skillGrade && (
              <div className="categories__list__wrapper">
                <h1>Select Category</h1>
                <div className="categories__list">
                  {allSkills[skillGrade]?.categories.map(
                    (item, i) =>
                      !(isMathsSkillAdded() && item === "Maths") && (
                        <span
                          key={i}
                          onClick={() => {
                            setSkillCategory(item);
                            setSkillSubject("");
                          }}
                          className={
                            item === skillCategory ? "chip selected" : "chip"
                          }
                        >
                          {item}
                        </span>
                      )
                  )}
                </div>
              </div>
            )}
            {skillCategory &&
              !allSkills[skillGrade]?.skip_subjects[
                categoryIdx[skillCategory]
              ] && (
                <div className="subjects__list__wrapper">
                  <h1>Select Subject</h1>
                  <div className="subjects__list">
                    {allSkills[skillGrade].subjects[skillCategory].map(
                      (item, i) =>
                        !isSkillAlreadyAdded(item) && (
                          <span
                            key={i}
                            className={
                              item === skillSubject ? "chip selected" : "chip"
                            }
                            onClick={() => setSkillSubject(item)}
                          >
                            {item}
                          </span>
                        )
                    )}
                  </div>
                </div>
              )}

            <button
              className="add__skill__btn"
              disabled={disabled}
              type="submit"
              onClick={() => handleAddSkill()}
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BlazeExternalSettings;
