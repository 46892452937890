import React, { useCallback, useEffect, useRef } from "react";
import Plyr from "plyr";
import { useMediaQuery } from "@material-ui/core";
import { logoDark, nounBook, circularProgress } from "../assets";
import Lottie from "lottie-react-web";
import PlayArrow from "@material-ui/icons/PlayArrow";

const LecturePlayer = (props) => {
  const {
    setVideoSeeking,
    setPlaying,
    isLoggedOutUser,
    setActiveItem,
    setLastEngagement,
    setVideoDuration,
    nextItem,
  } = props;
  const isSmallScreen = useMediaQuery("(max-width: 500px)");
  const videoElRef = useRef(null);
  const plyrRef = useRef(null);
  const [ended, setEnded] = React.useState(false);
  const [isNextItemRingVisible, setIsNextItemRingVisible] =
    React.useState(false);
  const ranRef = useRef(false);

  useEffect(() => {
    if (ranRef.current) return;
    ranRef.current = true;
    if (videoElRef.current) {
      if (plyrRef.current) plyrRef.current.destroy();
      plyrRef.current = new Plyr(videoElRef.current, {
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "fullscreen",
        ],
        hideControls: isSmallScreen,
        autoplay: true,
        debug: true,
      });
      // window.plyr = plyrRef.current;
    }

    return () => {
      if (plyrRef.current) plyrRef.current.destroy();
    };
  }, [isSmallScreen]);

  // This useEffect is for handling the events of the player
  useEffect(() => {
    function handleSeeking() {
      setVideoSeeking(true);
    }
    function handleSeeked() {
      setVideoSeeking(false);
    }
    function handlePause() {
      setPlaying(false);
    }
    function handleCanPlay() {
      setVideoSeeking(false);
    }
    function handleEnd() {
      plyrRef.current.pause();
      // If the player is in fullscreen, then exit it
      if (plyrRef.current.fullscreen.active) plyrRef.current.fullscreen.exit();
      setEnded(true);
      // Check if the nextItem is valid
      const isNextItemValid = props.nextItem && props.nextItem.item;
      // If the nextItem is valid, then we play it
      if (isNextItemValid) {
        setIsNextItemRingVisible(true);
      }
    }
    function handlePlay() {
      setPlaying(true);
    }
    function handleLoadedData() {
      setVideoDuration(plyrRef.current.duration);
    }
    function handleReady() {
      plyrRef.current.play();
      setVideoDuration(plyrRef.current.duration);
      setPlaying(true);
    }
    plyrRef.current.on("seeking", handleSeeking);
    plyrRef.current.on("seeked", handleSeeked);
    plyrRef.current.on("pause", handlePause);
    plyrRef.current.on("canplay", handleCanPlay);
    plyrRef.current.on("ended", handleEnd);
    plyrRef.current.on("play", handlePlay);
    plyrRef.current.on("loadeddata", handleLoadedData);
    plyrRef.current.on("ready", handleReady);
    return () => {
      plyrRef.current.off("seeking", handleSeeking);
      plyrRef.current.off("seeked", handleSeeked);
      plyrRef.current.off("pause", handlePause);
      plyrRef.current.off("canplay", handleCanPlay);
      plyrRef.current.off("ended", handleEnd);
      plyrRef.current.off("play", handlePlay);
      plyrRef.current.off("loadeddata", handleLoadedData);
      plyrRef.current.off("ready", handleReady);
    };
  }, [
    isLoggedOutUser,
    props.itemId,
    props.engagementRef,
    props.nextItem,
    props.videoSeeking,
    setPlaying,
    setVideoDuration,
    setVideoSeeking,
  ]);

  // This useEffect is for setting up the player source
  useEffect(() => {
    if (!plyrRef.current) return;
    plyrRef.current.source = {
      type: "video",
      sources: [
        {
          src: props.video_id,
          provider: "youtube",
        },
      ],
    };
  }, [props.video_id]);
  const handleCancelNext = useCallback(() => {
    setIsNextItemRingVisible(false);
    plyrRef.current.currentTime = 0;
  }, []);
  const handlePlayNext = useCallback(() => {
    setIsNextItemRingVisible(false);
    setActiveItem({
      parent:
        nextItem === null || nextItem === void 0 ? void 0 : nextItem.parent,
      item: nextItem === null || nextItem === void 0 ? void 0 : nextItem.item,
    });
    setLastEngagement &&
      setLastEngagement({
        lecture_type:
          nextItem === null || nextItem === void 0
            ? void 0
            : nextItem.lectureType,
        lecture_header_item_index:
          nextItem === null || nextItem === void 0
            ? void 0
            : nextItem.headerItemIndex,
        lecture_item_index:
          nextItem === null || nextItem === void 0
            ? void 0
            : nextItem.itemIndex,
        tab_index:
          nextItem === null || nextItem === void 0 ? void 0 : nextItem.tabIndex,
      });
    setEnded(false);
  }, [nextItem, setActiveItem, setLastEngagement]);

  return (
    <div className="classroom-player-wrapper">
      {/*Video Player*/}
      <video className="youtube-player plyr__video-embed" ref={videoElRef} />

      {/*Video Seeking Element*/}
      {plyrRef.current?.seeking && (
        <div
          className="classroom__video__seeking"
          onClick={() => isSmallScreen && setVideoSeeking(false)}
        >
          <div className="lottie__progress">
            <Lottie options={{ animationData: circularProgress, loop: true }} />
          </div>
          <div className="classroom__video__branding">
            <img
              height={100}
              width={100}
              src={nounBook}
              alt="pustack logo"
              draggable={false}
            />
          </div>
        </div>
      )}

      {/*Next Item Ring*/}
      {isNextItemRingVisible && (
        <ProgressRing
          radius={40}
          stroke={4}
          nextItemName={nextItem?.childName}
          handlePlayNext={handlePlayNext}
          handleCancelNext={handleCancelNext}
        />
      )}
    </div>
  );
};
const ProgressRing = (props) => {
  const { radius, stroke, handlePlayNext, handleCancelNext, nextItemName } =
    props;
  const intervalRef = useRef(null);
  const [progress, setProgress] = React.useState(0);
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const clearTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);
  const startTimer = useCallback(() => {
    function handleInterval() {
      // If intervalRef already exists then clear this and stop the new instance right here
      if (intervalRef.current) {
        clearTimer();
        return;
      }
      // Track the progress
      setProgress((currentProgress) => {
        const newProgress = currentProgress + 2.5;
        // Checking if the progress is greater than 99, then we finish the timer
        if (newProgress > 99) {
          handlePlayNext();
          clearTimer();
        }
        return newProgress;
      });
    }
    intervalRef.current = setInterval(handleInterval, 125);
  }, [clearTimer, handlePlayNext]);
  useEffect(() => {
    // Starting the timer for playing the next item
    startTimer();
  }, [startTimer]);
  return (
    <div className="classroom__end__placeholder" id="videoEndPlaceholder">
      <img
        height={100}
        width={100}
        className="placeholder__logo"
        src={logoDark}
        alt="PuStack"
        draggable={false}
      />
      <div className="up__next">UP NEXT</div>
      <div className="next__video__name">{nextItemName}</div>
      <div
        className="progress__play"
        onClick={() => {
          clearTimer();
          handlePlayNext();
        }}
      >
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke="white"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <div className="progress__content">
          {/*<i className="fas fa-play" />*/}
          <PlayArrow />
        </div>
      </div>

      <button
        onClick={() => {
          // if (interval !== null) clearInterval(interval);
          // this.setState({ ended: false, interval: null, progress: 0 });
          clearTimer();
          handleCancelNext();
        }}
        className="cancel__btn"
      >
        Cancel
      </button>
    </div>
  );
};
export default LecturePlayer;
