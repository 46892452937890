import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {Navbar, TutorBanking, TutorSidebar} from "../../containers";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

import "./styles.scss";
import {useMediaQuery} from "react-responsive";

const Banking = () => {
  const [open, setOpen] = useState(false);
  const isMobileScreen = useMediaQuery({ query: "(max-width: 500px)" });

  return (
    <div className={isMobileScreen ? 'banking-mobile' : ''}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Earnings - PuStack Tutor</title>
      </Helmet>

      {isMobileScreen && <Navbar onlyBottom={true} handleMenuClick={() => setOpen(true)}/>}

      <TutorBanking />

      <Hidden xsDown implementation="js">
        <Drawer
          variant="temporary"
          open={open}
          onClose={() => setOpen(false)}
          ModalProps={{ keepMounted: true }}
        >
          <TutorSidebar />
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Banking;
