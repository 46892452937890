import {useContext, useEffect, useState} from "react";
import {UserContext} from "../context";
import {db} from "../firebase_config";

export default function useBlazeSession(type = 'accepted') {
  const [sessions, setSessions] = useState(null);
  const [user, setUser] = useContext(UserContext).user;

  useEffect(() => {
    if (!user) return;

    const unsubscribe = db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .where("instructor_id", "==", user.uid)
      .where("session_status", "==", type)
      .orderBy('last_message_ts', "desc")
      .onSnapshot((snapshot) => {
        let _sessions = [];

        if (snapshot.docs.length === 0) {
        } else {
          for (let i = 0; i < snapshot.docs.length; i++) {
            let _currentDocument = snapshot.docs[i].data();

            _sessions.push({
              ..._currentDocument,
              reference: snapshot.docs[i].ref,
            });
          }
        }

        setSessions(_sessions);
      });

    return () => {
      unsubscribe();
    };
  }, [user, type]);

  return sessions;
}
