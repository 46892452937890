import firebase from "firebase/app";
import { db } from "../../firebase_config";
import "firebase/firestore";

export const fetchChaptersForSubject = async () => {
  return await db
    .collection("cms_data")
    .doc("class_9")
    .collection("scope")
    .doc("class_9_learn")
    .collection("category")
    .doc("class_9_learn_socialscience")
    .get()
    .then((doc) => {
      if (doc.exists) {
        let chapters = [];

        let _chapters_map = doc.data()._meta[0].chapters;

        _chapters_map.sort((a, b) => a.serial_order > b.serial_order);

        for (var i = 0; i < _chapters_map?.length; i++) {
          chapters.push(_chapters_map[i]);
        }


        return chapters;
      }

      return null;
    });
};

export const getSubjectMeta = async ({ grade, subjectCode }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_learn`)
    .collection("category")
    .doc(`${grade}_learn_${subjectCode}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let _categories = [];

        let _categories_map = doc.data()._meta;

        _categories_map.forEach((category) => {
          _categories.push({
            description: category.description,
            code: category.generic_name,
            hex_color: category.hex_color,
            name: category.subject_name,
            illustration_art: category.illustration_art,
            chapters: category.chapters,
            serial_order: category.serial_order,
            updated_on: category.updated_on,
          });
        });

        return _categories;
      }

      return null;
    });
};

export const getSubjectMeta2 = async ({ grade, subjectCode }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_learn`)
    .collection("category")
    .doc(`${grade}_learn_${subjectCode}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let _categories = [];

        let _categories_map = doc.data()._meta;

        _categories_map.forEach((category) => {
          _categories.push({
            description: category.description,
            code: category.generic_name,
            hex_color: category.hex_color,
            name: category.subject_name,
            illustration_art: category.illustration_art,
            chapters: category.chapters,
            serial_order: category.serial_order,
            updated_on: category.updated_on,
          });
        });

        return { categories: _categories, description: doc.data().description };
      }

      return null;
    });
};

export const getTipsMeta = async ({ grade, subjectCode }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_tips`)
    .collection("category")
    .doc(`${grade}_tips_${subjectCode}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let _categories = [];

        let _categories_map = doc.data()._meta;

        _categories_map.forEach((category) => {
          _categories.push({
            description: category.description,
            code: category.generic_name,
            name: category.tip_name,
            banner_image: category.banner_image,
            serial_order: category.serial_order,
            side_image_url: category.side_image_url,
            tip_id: category.tip_id,
            tier: category.tier,
          });
        });

        return _categories.sort((a, b) => a.serial_order - b.serial_order);
      }

      return null;
    });
};

export const getPracticeMeta = async ({ grade, subjectCode }) => {
  return await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_practice`)
    .collection("category")
    .doc(`${grade}_practice_${subjectCode}`)
    .get()
    .then((doc) => {
      if (doc.exists) {
        let _categories = [];

        let _categories_map = doc.data()._meta;

        _categories_map.forEach((category) => {
          _categories.push({
            description: category.description,
            code: category.generic_name,
            name: category.exam_name,
            main_thumbnail_url: category.main_thumbnail_url,
            mini_thumbnail_url: category.mini_thumbnail_url,
            serial_order: category.serial_order,
            exam_id: category.exam_id,
            exam_item_count: category.exam_item_count,
            exam_image_url: category.exam_image_url,
            exam_number: category.exam_number,
          });
        });

        return _categories.sort((a, b) => a.serial_order - b.serial_order);
      }

      return null;
    });
};

export const getSubjectCodes = async ({ grade }) => {
  const snapshot = await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_learn`)
    .collection("category")
    .get();

  return snapshot.docs.map((doc) => doc.data());
};

export const getTipsCodes = async ({ grade }) => {
  const snapshot = await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_tips`)
    .collection("category")
    .get();

  return snapshot.docs.map((doc) => doc.data());
};

export const getPracticeCodes = async ({ grade }) => {
  const snapshot = await db
    .collection("cms_data")
    .doc(grade)
    .collection("scope")
    .doc(`${grade}_practice`)
    .collection("category")
    .get();

  return snapshot.docs.map((doc) => doc.data());
};

export const getMainCarouselData = async ({ grade, tier_type }) => {
  const snapshot = await db
    .collection("main_carousel")
    .where("grade_id", "in", ["all", grade])
    .get();

  return snapshot.docs.map((doc) => doc.data());
};
