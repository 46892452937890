import React, {useContext} from 'react';
import {Navbar} from "../../containers";
import "./style.scss";
import {DashboardSkillsList} from "../../components";
import {UserContext} from "../../context";

export default function SkillsPage() {
  const [instructorSkills, setInstructorSkills] = useContext(UserContext).instructorSkills;
  const [instructorSkillsRating] = useContext(UserContext).instructorSkillsRating;

  return (
    <>
      <Navbar onlyBottom={true} />
      <div className="skills-page">
        <DashboardSkillsList
          ratings={instructorSkillsRating}
          skillSet={instructorSkills}
          setInstructorSkills={setInstructorSkills}
        />
      </div>
    </>
  )
}
