import React from "react";
import { Area, Tooltip, AreaChart, ResponsiveContainer, XAxis } from "recharts";
import { curveCardinal } from 'd3-shape';

const MiniChart = ({
  data,
  type,
  color,
  setTypeData,
  setChartDate,
  onMouseLeave,
}) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      setChartDate(label);
      setTypeData(payload[0].payload[type]);
      return null;
    }
    return null;
  };

  const cardinal = curveCardinal.tension(0.2);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 25,
          right: 10,
          left: 10,
          bottom: 10,
        }}
        syncId="pustack"
      >
        <defs>
          <linearGradient id={`grad${type}`} x1="0" y1="0" x2="0" y2="0">
            <stop offset="5%" stopColor={color} stopOpacity={0.85} />
            <stop offset="95%" stopColor={color} stopOpacity={0.2} />
          </linearGradient>
        </defs>

        <Tooltip key={type} content={<CustomTooltip />} />
        <XAxis hide={true} tick={false} tickLine={false} dataKey="date" />
        <Area
          type={cardinal}
          dataKey={type}
          stackId="1"
          stroke={color}
          strokeWidth={3}
          fill={`url(#grad${type})`}
          activeDot={{ strokeWidth: 0, r: 0 }}
          onMouseLeave={onMouseLeave}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default MiniChart;
