import React from 'react';

export { showSnackbar } from "./functions/show-snackbar";
export { quillToReact } from "./functions/quill";
export { reactToQuill } from "./functions/quill";
export { quillToPlainText } from "./functions/quill";
export { isValidYouTubeUrl } from "./functions/youtube";
export { youTubeGetID } from "./functions/youtube";
export { monthToStringFormatter } from "./functions/monthToStringFormatter";
export { hourToStringFormatter } from "./functions/hourToStringConverter";
export { getIndianTime, castIndianTime } from "./functions/getIndianTime";
export { shadeColor } from "./functions/shadeColor";
export { careFormattedDate } from "./functions/careFormattedDate";
export { formatCallDuration, formatCallDurationShort } from "./functions/formatCallDuration";
export { groupCareMessages } from "./functions/groupCareMessages";
export { makeUniqueId } from "./functions/makeUniqueId";
export { makeUniqueNumericId } from "./functions/makeUniqueNumericId";
export { rtmTokenBuilder } from "./functions/rtmTokenBuilder";
export { formatBillDuration } from "./functions/formatBillDuration";
// -------------------- doubt forum

export {
  getDateStringServ,
  formatTime,
  generateUrlFromString,
  generateRandomString,
  splitLongString,
  numReaderFriendlyFormat,
  futureformatTime,
} from "./doubts_forum/utils";

// -------------------- newsfeed

export {
  getVideoCover,
  debounce,
  linkParser,
  getYoutubeID,
  removeAtIndex,
} from "./newsfeed/utils";

export { getDateInYYYYMMDDFormat, loadingWrapper } from "./functions/utils";

// =================> Blaze
export {
  createBlazeOrder,
  rescheduleBlazeReservationByUser,
} from "./blaze/cloud-functions";

//
export { loadScript } from "./loadScript";

// links

export { privacyPolicy, termsOfService } from "./links";

export { groupBlazeMessages, getGrade } from "./blaze/groupBlazeMessages";
export {
  pickAgentCloudFunction,
  updateChatImage,
  updateStudentCareMeta,
} from "./pustack-care";

export { starPath, agoraAppID, VAPIDKEY, miniChartData } from "./constants";
export {
  PUSHY_SECRET_API_KEY,
  FCM_AUTH_HEADER,
  firebaseAPiKey,
  firebaseDbURL,
} from "./constants";

export { subjectColors } from "./global";

export class Validate {

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isNotNull(value) {
    return value !== null;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isNotUndefined(value) {
    return value !== undefined;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isDefined(value) {
    return Validate.isNotNull(value) && Validate.isNotUndefined(value)
  }

  /**
   * @description It checks for the corresponding keys in the provided object that these are not null/undefined.
   * @param obj {Object<keys>}
   * @param keys {Array<string>}
   * @returns {boolean}
   */
  static requiredAll(obj, keys) {
    if(!obj) return false;
    let isValid = true;
    for(let key of keys) {
      if(!Validate.isDefined(obj[key])) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }
}

export class AppValidate {

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isNotNull(value) {
    return value !== null;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isNotUndefined(value) {
    return value !== undefined;
  }

  /**
   *
   * @param value
   * @returns {boolean}
   */
  static isDefined(value) {
    return Validate.isNotNull(value) && Validate.isNotUndefined(value)
  }

  /**
   * @description It checks for the corresponding keys in the provided object that these are not null/undefined.
   * @param obj {Object<keys>}
   * @param keys {Array<string>}
   * @returns {boolean}
   */
  static requiredAll(obj, keys) {
    if(!obj) return false;
    let isValid = true;
    for(let key of keys) {
      if(!Validate.isDefined(obj[key])) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }
}

export const updateUserToIndexedDB = (userId) => {
  const openRequest = window.indexedDB.open('pustack-db', 1);
  openRequest.onupgradeneeded = function(e) {
    const db = e.target.result;
    db.createObjectStore("pustack-db-store", {
      "autoIncrement": true
    });
  }
  openRequest.onsuccess = function(e) {
    const instance = e.target.result;

    const transaction = instance.transaction('pustack-db-store', 'readwrite');
    const objectStore = transaction.objectStore('pustack-db-store');
    const getKeyRequest = objectStore.put(userId, 'user');
    getKeyRequest.onsuccess = function(e1) {
      console.log('Updated the user to indexedDB');
    }
    getKeyRequest.onerror = function() {
      console.log('Failed to update the user to indexedDB');
    }
  }
  openRequest.onerror = function() {
    console.log('Failed to open the indexedDB database');
  }
}

export const removeFcmTokenFromIndexedDb = function() {
  return new Promise((res, rej) => {
    const openRequest = window.indexedDB.open('firebase-messaging-database');
    openRequest.onsuccess = function(e) {
      const instance = e.target.result;

      const transaction = instance.transaction('firebase-messaging-store', 'readwrite');
      const objectStore = transaction.objectStore('firebase-messaging-store');
      const getKeyRequest = objectStore.getAllKeys();
      getKeyRequest.onsuccess = function(e1) {
        const keys = e1.target.result;
        const deleteRequest = objectStore.delete(keys[0]);

        deleteRequest.onsuccess = function() {
          res(true);
        }

        deleteRequest.onerror = function() {
          res(false);
        }
      }
      getKeyRequest.onerror = function() {
        res(false);
      }
    }
    openRequest.onerror = function() {
      res(false);
    }
  })
}

export const isNameValid = (str = "") => {
  if(!(str.trim().length > 0)) return;
  let regex = "^[a-zA-Z ]*$";
  return !!str.match(regex);
}


export const sortedISOString = function(arr, nestedKey = null, inAsc = false) {
  const arrSorted = arr.sort((a, b) => {
    let keys = [a, b];
    if(nestedKey) keys = [a[nestedKey], b[nestedKey]];
    if (isNaN(keys[0])) return -1;
    return 0;
  });

  return arrSorted.sort((a, b) => {
    let keys = [a, b];
    if(nestedKey) keys = [a[nestedKey], b[nestedKey]];
    if(keys[0] < keys[1]) return 1 * (inAsc ? -1 : 1);
    if(keys[0] > keys[1]) return -1 * (inAsc ? -1 : 1);
    return 0;
  })
}

export function humanizeTime(_seconds, isLastSeen) {
  const seconds = Math.round(_seconds);
  if(seconds === 0) return '0s';
  let obj = {
    h: 0,
    m: 0,
    s: 0
  }
  let minutesDec = seconds / 60;
  let hoursDec = minutesDec / 60;
  if(isLastSeen) {
    minutesDec = Math.floor(seconds / 60);
    hoursDec = Math.floor(minutesDec / 60);
  }
  let daysDec = Math.floor(hoursDec / 24);
  let weeksDec = Math.floor(daysDec / 7);

  // Check if it has minutes
  if(minutesDec < 1) {
    obj.s = seconds;
  } else {
    obj.s = seconds % 60;
  }

  // Check if it has hours
  if(hoursDec < 1) {
    obj.m = Math.floor(seconds / 60);
  } else {
    obj.h = Math.floor(seconds / 3600);
    let leftSeconds = seconds % 3600;
    let leftMinutes = leftSeconds / 60;
    if(leftMinutes < 1) {
      obj.m = 0;
      obj.s = seconds % 60;
    } else {
      obj.m = Math.floor(leftMinutes);
      obj.s = seconds % 60;
    }
  }
  obj.d = daysDec;
  obj.w = weeksDec;

  return isLastSeen ? formatLastSeen(obj) : formatHumanizeHas(obj);
}

function formatLastSeen(obj) {
  if(obj.w > 0) return obj.w + ' w';
  if(obj.d > 0) return obj.d + ' d';
  if(obj.h > 0) return obj.h + ' h';
  if(obj.m > 0) return obj.m + ' m';
  if(obj.s >= 0) return '< 1 m';
}

function formatHumanizeHas(obj) {
  let str = ''
  if(obj.h > 0) {
    str += obj.h + 'h ';
  }
  if(obj.m > 0) {
    str += obj.m + 'm ';
  }
  if(obj.s > 0) {
    str += obj.s + 's ';
  }

  return str;
}

export const getClassName = (grade) => {
  const splitted = grade.split("_");

  return (
    splitted[0].charAt(0).toUpperCase() +
    splitted[0].slice(1) +
    " " +
    splitted[1]
  );
};

const colors = [
  '#F0F8FF',
  '#FAEBD7',
  '#00FFFF',
  '#7FFFD4',
  '#F5F5DC',
  '#5F9EA0',
  '#DC143C',
  '#9ACD32',
  '#228B22',
  '#4682B4',
  '#6495ED',
  '#4169E1',
  '#7B68EE',
  '#FFFACD',
  '#DA70D6',
  '#F4A460',
  '#B0C4DE',
  '#808080'
];

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomAvatar = (_name, className = '') => {
  const name = _name ?? 'S';
  return (
    <div
      className={"relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 " + (className)} style={{background: colors[randomIntFromInterval(0, colors.length)]}}>
      <span className="font-medium mt-1/2 dark:text-gray-300 uppercase">{name[0]}</span>
    </div>
  )
}

export function formatFirstWord(inputString) {
  // Split the input string into an array of words
  const words = inputString.split(" ");

  // Take the first word
  const firstWord = words[0];

  // Capitalize the first letter and make the rest lowercase
  return firstWord.charAt(0).toUpperCase() + firstWord.slice(1).toLowerCase();
}

export const wait = time => new Promise((res) => setTimeout(res, time));

export const openTermsOfService = () => window.open(window.location.origin + '/terms_of_service', '_blank');

export const openPrivacyPolicy = () => window.open(window.location.origin + '/privacy_policy', '_blank');

export const capitalize = (a) => {
  if(!a) return 'No Word';
  return a.split(' ').reduce((acc, cur, ind) => {
    if(cur.length === 0) return acc;
    let newStr = cur.substr(0, 1).toUpperCase() + cur.substr(1).toLowerCase()
    acc.push(newStr);
    return acc
  }, []).join(' ')
}

export const baseUrl = () => {
  if(process.env.NODE_ENV === "production") return 'https://us-central1-avian-display-193502.cloudfunctions.net'
  // return 'http://localhost:5001/avian-display-193502/us-central1'
  return 'https://us-central1-avian-display-193502.cloudfunctions.net'
}
