import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
  useHistory,
} from "react-router-dom";
import { newMsgAudio } from "./assets";
import { Snackbar } from "./components";
import { logOut } from "./services";
import { version } from "./../package.json";
import { TutorSidebar } from "./containers";
import { useMediaQuery } from "react-responsive";
import {
  castIndianTime,
  loadingWrapper,
  removeFcmTokenFromIndexedDb,
  updateUserToIndexedDB,
  VAPIDKEY,
} from "./helpers";
import { LastLocationProvider } from "react-router-last-location";
import { messaging, isMessagingSupported, db, auth } from "./firebase_config";
import {
  BlazeExternalScreen,
  SkillsScreen,
  BlazeRequestedSessions,
  TutorHome,
  Banking,
  SignIn,
} from "./pages";
import {
  setDeviceToken,
  userImportantData,
  getCurrentVersion,
  getCareMessageCount,
  getInstructorMediaPermissions,
  getInstructorEarnings,
} from "./database";
import {
  UserContext,
  ThemeContext,
  ClassroomContextProvider,
  SubjectModalContextProvider,
  PageContextProvider,
  IntroContextProvider,
  SidebarContextProvider,
  BlazeExternalContextProvider,
  TipsContextProvider,
  PracticeContextProvider,
} from "./context";

import "./styles/App.scss";
import ModalContextProvider from "./context/global/ModalContext";
import AppModal from "./components/global/modal";
import ClassroomScreen from "./pages/classroom";
import { refreshNotificationTokens } from "./database/global/database";
import Sessions from "./pages/sessions";
import PaymentDetails from "./pages/paymentDetails";
import MobileUserAuth from "./pages/auth/mobile";
import firebase from "firebase";
import SwipeableViews from "react-swipeable-views";
import ProfileScreen from "./components/ProfileScreen";
import { Drawer } from "@material-ui/core";
import TutorConnectDrawer from "./components/TutorConnectDrawer";
import AskNameModal from "./components/global/ask-name-modal";
import TipsScreen from "./pages/tips";
import PracticeScreen from "./pages/practice";
import StoryModal from "./components/StoryModal";
import StoryCarousel from "./components/story/StoryCarousel";

export default function App() {
  const [checkedLoggedInStatus, setCheckedLoggedInStatus] =
    useContext(UserContext).checkedLoggedInStatus;

  const history = useHistory();

  const [user, setUser] = useContext(UserContext).user;
  const [, setIsUserProTier] = useContext(UserContext).tier;
  const [, setPushyData] = useContext(UserContext).pushyData;
  const [, setIsExternal] = useContext(UserContext).isExternal;
  const [, setPermissions] = useContext(UserContext).permissions;
  const [, setIsInstructor] = useContext(UserContext).isInstructor;
  const [isDarkMode, setIsDarkMode] = useContext(ThemeContext).theme;
  const [, setBlazeCallAlert] = useContext(UserContext).blazeCallAlert;
  const [, setOpenNameModal] = useContext(UserContext).openNameModal;
  const [, setUnreadCareMsgCount] = useContext(UserContext).unreadCareMsgCount;
  const [closeInstallApp, setCloseInstallApp] =
    useContext(UserContext).closeInstallApp;
  const [openPustackCare, setOpenPustackCare] =
    useContext(UserContext).openPustackCare;
  const [, setTutorEarnings] = useContext(UserContext).tutorEarnings;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    isSmallScreen &&
      window.addEventListener("beforeinstallprompt", (e) => e.preventDefault());
  }, [isSmallScreen]);

  useEffect(() => {
    if (!user?.uid) return;
    const unsub = db
      .collection("users")
      .doc(user?.uid)
      .onSnapshot((snapshot) => {
        if (
          snapshot.exists &&
          (!snapshot.data().name || snapshot.data().name?.trim().length === 0)
        ) {
          setOpenNameModal(true);
        } else {
          setOpenNameModal(false);
        }
      });

    return () => {
      unsub();
    };
  }, [user]);

  useEffect(() => {
    console.log("user:90 - ", user);
  }, [user]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        localStorage.clear();
        localStorage.removeItem("user");
        localStorage.setItem("hideCookie", true);
        setUser(null);
        console.log("loadingWrapper() - ");
        // loadingWrapper();
        if (window.location.pathname !== "/") window.location.replace("/");
        return;
      }
      const snapshot = await db.collection("users").doc(user.uid).get();
      if (!snapshot.exists) {
        setUser(null);
        if (window.location.pathname !== "/") window.location.replace("/");
        return;
      }
      const data = snapshot.data();
      if (
        data &&
        data.has_submitted_skills &&
        data.is_external_instructor &&
        data.is_tutor_verified &&
        data.is_instructor
      ) {
        console.log("setUser - ");
        setUser(data);
        localStorage.setItem(
          "user",
          JSON.stringify({
            uid: data?.uid,
            grade: data?.grade,
            name: data?.name,
            profile_url: data?.profile_url,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (!user?.uid) return;

    async function run() {
      try {
        updateUserToIndexedDB(user?.uid);
      } catch (e) {
        console.log("Error in updating the user id in indexedDB");
      }

      document.removeEventListener("mousedown", run);

      // console.groupCollapsed('FCM Token Operation');
      await messaging.requestPermission();

      // Refresh the token list
      console.log("Starting refreshing token operation");
      const res = await refreshNotificationTokens(user?.uid);
      console.log("Refresh notification response - ", res);

      const ist = await castIndianTime();

      // Fetch the token and compare it with the local token
      const snapshot = await db.doc("user_tokens/" + user?.uid).get();
      const localToken = await messaging.getToken({ vapidKey: VAPIDKEY });

      let tokens = [];

      if (snapshot.exists) {
        tokens = snapshot.data().tokens;
      }

      const token = tokens.find(
        (c) =>
          c.token === localToken &&
          c.platform === "web" &&
          c.app === "pustack_tutor_app"
      );

      // If the token is there
      if (token) {
        console.log("Token is present and valid, updating the last_seen");
        tokens = tokens.map((c) => {
          if (
            c.token === localToken &&
            c.platform === "web" &&
            c.app === "pustack_tutor_app"
          ) {
            return {
              ...c,
              last_seen: +ist,
            };
          }
          return c;
        });
        localStorage.setItem("fcmToken", token.token);
        // Update the last_seen
        await db
          .doc("user_tokens/" + user?.uid)
          .set({ tokens }, { merge: true });
        console.log("Updated the last_seen of the token");
        console.groupEnd();
      } else {
        // If not then delete the previous token
        // await messaging.deleteToken();
        // Get the token using getToken()
        console.log("Token was expired");
        console.log("Removing FCM token from indexedDB");
        await removeFcmTokenFromIndexedDb();
        const token = await messaging.getToken({ vapidKey: VAPIDKEY });

        await messaging.onTokenRefresh({
          nextOrObserver: async function (token) {
            localStorage.setItem("fcmToken", token);
            await db.doc("user_tokens/" + user?.uid).set(
              {
                tokens: firebase.firestore.FieldValue.arrayUnion({
                  platform: "web",
                  last_seen: +ist,
                  created_ts: +ist,
                  app: "pustack_tutor_app",
                  token,
                }),
              },
              { merge: true }
            );
          },
        });

        localStorage.setItem("fcmToken", token);
        console.log("New Token fetched and storing it to the server");
        // Store it to the server
        await db.doc("user_tokens/" + user?.uid).set(
          {
            tokens: firebase.firestore.FieldValue.arrayUnion({
              platform: "web",
              last_seen: +ist,
              created_ts: +ist,
              app: "pustack_tutor_app",
              token,
            }),
          },
          { merge: true }
        );

        console.log("Stored the new FCM token to the server");
        console.groupEnd();
      }

      messaging.onMessage((payload) => {
        if (payload.data && payload.data.belongs_to) {
          if (payload.data.belongs_to !== user?.uid) return;
        }

        console.log("message is here - ", payload);
        let data = payload.data;

        if (data?.notification_type === "session_ping") {
          setPushyData(data);
          setBlazeCallAlert(true);
        } else if (data?.notification_type === "dismiss_ping") {
          setBlazeCallAlert(false);
        }

        if (data?.notification_type === "redirect") {
          history.push(data.url);
        }

        if (data?.notification_type === "dismiss_ringer") {
          setBlazeCallAlert(false);
        }
      });
    }

    navigator.serviceWorker.addEventListener("message", (e) => {
      console.log("e = ", e);
    });

    navigator.serviceWorker.onmessage = function (e) {
      if (e.data.type === "ping_expired" || e.data.type === "dismiss_ping") {
        setBlazeCallAlert(false);
      } else if (e.data.type === "session_ping") {
        setBlazeCallAlert(true);
        setPushyData(e.data.payload.data);
      }

      if (e.data.type === "redirect") {
        console.log("e = ", e);
        history.push(e.data.url);
      }

      if (e.data.type === "dismiss_ringer") {
        setBlazeCallAlert(false);
      }
    };

    const fcmToken = localStorage.getItem("fcmToken");
    if (fcmToken) {
      run();
    } else {
      document.addEventListener("mousedown", run);
    }

    return () => {
      document.removeEventListener("mousedown", run);
    };
  }, [user]);

  // useEffect(() => {
  //   if(!user?.uid) return;
  //   if (isMessagingSupported) {
  //     messaging
  //       .getToken({ vapidKey: VAPIDKEY })
  //       .then((token) => {
  //         if (token) {
  //           const localToken = localStorage.getItem("fcmToken");
  //
  //           if (localToken && localToken === token) {
  //             // console.log(localToken);
  //           } else {
  //             setDeviceTokenFn(token);
  //             localStorage.setItem("fcmToken", token);
  //           }
  //         } else console.log("No registration token available");
  //       })
  //       .catch((err) => console.log(err));
  //
  //     messaging.onMessage((payload) => {
  //       let data = payload.data;
  //
  //       if (data?.notification_type === "session_ping") {
  //         setPushyData(data);
  //         setBlazeCallAlert(true);
  //       } else {
  //         setBlazeCallAlert(false);
  //       }
  //     });
  //   }
  // }, [user?.uid]);

  useEffect(() => {
    getRelatedAppsFn();
  }, [user]);

  useEffect(() => {}, [user?.uid]);

  const getRelatedAppsFn = async () => {
    try {
      const relatedApps = await navigator.getInstalledRelatedApps();
      relatedApps.forEach(() => {
        // user.uid === "ZVKaCvui8gauClweVWPUAqaq3Ht2" && alert(app.platform);
        // console.log(app.id, app.platform, app.url);
      });
    } catch (er) {
      console.log(er);
    }
  };

  useEffect(() => {
    if (!user || !user.uid) return;
    refreshNotificationTokens(user?.uid);
  }, [user]);

  useEffect(() => {
    let unsub = function () {};
    if (localStorage.getItem("user")) {
      const _user = JSON.parse(localStorage.getItem("user"));
      console.log("setUser - ");
      setUser(_user);

      if (_user) {
        try {
          unsub = setUserImportantDataFn(_user.uid);
          setUnreadMsgCountFn(_user);
        } catch (error) {
          console.log(error);
          setUser(null);
        }
      }

      if (localStorage.getItem("isUserPro")) {
        setIsUserProTier(localStorage.getItem("isUserPro") === "true");
      }

      if (localStorage.getItem("closeInstallApp")) {
        setCloseInstallApp(true);
      }

      if (localStorage.getItem("isInstructor")) {
        setIsInstructor(localStorage.getItem("isInstructor") === "true");
      }

      if (localStorage.getItem("isExternalInstructor")) {
        setIsExternal(localStorage.getItem("isExternalInstructor") === "true");
      }
    } else {
      setCheckedLoggedInStatus(true);

      if (user?.uid) {
        unsub = setUserImportantDataFn(user?.uid);
      }
    }

    return () => {
      unsub();
    };
  }, [user?.uid]);

  useEffect(() => {
    let unsub = function () {};
    if (user?.uid) {
      unsub = db.doc("users/" + user.uid).onSnapshot((snapshot) => {
        const conditions =
          !snapshot.exists ||
          (snapshot.exists && !snapshot.data().is_tutor_verified) ||
          (snapshot.exists && snapshot.data().is_deleted);
        if (conditions) {
          logOut();
          setUser(null);
          console.log("loadingWrapper() - ");
          loadingWrapper();
          localStorage.clear();
          localStorage.setItem("hideCookie", true);
          window.location = "/";
        }
      });
    }

    return () => {
      unsub();
    };
  }, [user?.uid]);

  const setUserImportantDataFn = (uid) => {
    const res = userImportantData(uid);

    getInstructorMediaPermissions({
      instructorId: uid,
      callback: (res) => setPermissions(res),
    });
    setCheckedLoggedInStatus(true);

    try {
      return res.onSnapshot((snapshot) => {
        if (snapshot.data() || "") {
          setIsUserProTier(snapshot.data()?.tier === "pro");
          setIsInstructor(snapshot.data()?.is_instructor);
          setIsExternal(snapshot.data()?.is_external_instructor || false);
          let _user = JSON.parse(localStorage.getItem("user"));
          _user = { ..._user, ...snapshot.data() };
          console.log("setUser - ");
          setUser(_user);

          let loggingOut = false;

          if (
            snapshot.data()?.is_tutor_verified === false ||
            snapshot.data().is_instructor === false
          ) {
            loggingOut = logOut();
            if (loggingOut) {
              setUser(null);
              console.log("loadingWrapper() - ");
              loadingWrapper();
              localStorage.clear();
              localStorage.setItem("hideCookie", true);
              window.location = "/";
            }
          }

          if (!loggingOut) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                uid: _user?.uid,
                grade: _user?.grade,
                name: _user?.name,
                profile_url: _user?.profile_url,
              })
            );

            localStorage.setItem(
              "isUserPro",
              JSON.stringify(snapshot.data()?.tier === "pro")
            );

            localStorage.setItem(
              "isInstructor",
              JSON.stringify(snapshot.data()?.is_instructor)
            );

            localStorage.setItem(
              "isExternalInstructor",
              JSON.stringify(snapshot.data()?.is_external_instructor || false)
            );
          }
        } else {
          if (navigator.onLine && process.env === "production") {
            setUser(null);
            setIsUserProTier(false);
            setIsInstructor(false);
            localStorage.clear();
            localStorage.setItem("hideCookie", true);
            window.location = "/";
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setUnreadMsgCountFn = (_user) => {
    getCareMessageCount({ userId: _user?.uid }).onSnapshot(
      (snapshot) => {
        const count = snapshot.data()?.unread_care_message_count;

        setUnreadCareMsgCount(count);

        if (count > 0) {
          !isSmallScreen && setOpenPustackCare(true);
          if (!openPustackCare) {
            let audio = new Audio(newMsgAudio);
            audio.play();
          }
        }
      },
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") getCurrentVersionFn();
  }, []);

  const getCurrentVersionFn = async () => {
    const currentVersion = await getCurrentVersion();

    const version = localStorage.getItem("appVersion");
    if (version && version !== currentVersion.version) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
      console.log("not latest version");
      localStorage.setItem("appVersion", currentVersion.version);
      window.location.reload();
    } else {
      if (!version) localStorage.setItem("appVersion", currentVersion.version);
      console.log("latest version deployed");
    }
  };

  const setDeviceTokenFn = (token) => {
    if (user?.uid) setDeviceToken(token, user?.uid);
  };

  useEffect(() => {
    if (localStorage.getItem("pustack-dark-theme") === "true") {
      try {
        setIsDarkMode(true);
      } catch (error) {
        setIsDarkMode(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) document.body.style.background = "rgb(16,16,16)";
    else document.body.style.background = "#fff";
  }, [isDarkMode]);

  useEffect(() => {
    if (user?.uid && user?.is_external_instructor) {
      getInstructorEarnings({
        instructorId: user?.uid,
        callback: (data) => setTutorEarnings(data),
      });
    }
  }, [user?.uid, user?.is_external_instructor]);

  console.log("user - ", user);

  return (
    <Router>
      <LastLocationProvider>
        <div
          className={
            isDarkMode
              ? `app dark${
                  isSmallScreen && !closeInstallApp ? " closePopup" : ""
                }`
              : `app${isSmallScreen && !closeInstallApp ? " closePopup" : ""}`
          }
        >
          <Snackbar />
          <ModalContextProvider>
            <IntroContextProvider>
              {!user && checkedLoggedInStatus && (
                <Switch>
                  <Route exact path="/">
                    {isSmallScreen ? <MobileUserAuth /> : <SignIn />}
                  </Route>
                  <Redirect to="/" />
                </Switch>
              )}

              <BlazeExternalContextProvider>
                {user && checkedLoggedInStatus && (
                  <ClassroomContextProvider>
                    <TipsContextProvider>
                      <PracticeContextProvider>
                        <SubjectModalContextProvider>
                          {user && checkedLoggedInStatus && !isSmallScreen && (
                            <TutorSidebar />
                          )}
                          <SidebarContextProvider>
                            <PageContextProvider>
                              <Switch>
                                <Route exact path="/">
                                  <TutorHome />
                                </Route>
                                {!isSmallScreen && (
                                  <Route
                                    exact
                                    path={["/blaze", "/blaze/chat/:id"]}
                                  >
                                    <BlazeExternalScreen />
                                  </Route>
                                )}
                                {isSmallScreen && (
                                  <Route exact path={"/blaze"}>
                                    <BlazeRequestedSessions />
                                  </Route>
                                )}
                                {isSmallScreen && (
                                  <Route exact path="/skills">
                                    <SkillsScreen />
                                  </Route>
                                )}
                                {isSmallScreen && (
                                  <Route exact path="/outreach">
                                    <TutorConnectDrawer />
                                  </Route>
                                )}
                                {isSmallScreen && (
                                  <Route exact path="/earnings/payouts">
                                    <PaymentDetails />
                                  </Route>
                                )}
                                {isSmallScreen && (
                                  <Route
                                    exact
                                    path={["/sessions", "/blaze/chat/:id"]}
                                  >
                                    <Sessions />
                                  </Route>
                                )}
                                <Route exact path="/earnings">
                                  <Banking />
                                </Route>
                                <Route exact path="/classroom">
                                  <ClassroomScreen />
                                </Route>
                                <Route exact path="/classroom">
                                  <ClassroomScreen />
                                </Route>
                                <Route exact path="/tips">
                                  <TipsScreen />
                                </Route>
                                <Route exact path="/practice">
                                  <PracticeScreen />
                                </Route>
                                <Redirect to="/" />
                              </Switch>
                              <AppModal />
                              <AskNameModal />
                              <StoryModal />
                              {/*<StoryCarousel />*/}
                              {!isSmallScreen && <TutorConnectDrawer />}
                            </PageContextProvider>
                          </SidebarContextProvider>
                        </SubjectModalContextProvider>
                      </PracticeContextProvider>
                    </TipsContextProvider>
                  </ClassroomContextProvider>
                )}
              </BlazeExternalContextProvider>
            </IntroContextProvider>
          </ModalContextProvider>
        </div>
      </LastLocationProvider>
    </Router>
  );
}
//
//
// const log = {
//   id: '',
//   status: '',
//   level: '',
//   action: '',
//   item_id: '',
//   timestamp: '',
//   children: [
//     {
//       id: '',
//       status: '',
//       timestamp: '',
//       item_id: '',
//       level: '',
//       children: [
//
//       ]
//     },
//     {
//       id: '',
//       status: '',
//       timestamp: '',
//       item_id: '',
//       level: ''
//     },
//   ]
// }
