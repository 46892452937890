import React, {useState, createContext, useEffect} from "react";
import {db} from "../../firebase_config";

export const BlazeExternalContext = createContext();

const BlazeExternalContextProvider = (props) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [openPage, setOpenPage] = useState(0);
  const [openChat, setOpenChat] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [openPages, setOpenPages] = useState(false);
  const [callStartTs, setCallStartTs] = useState(null);
  const [sessionSelected, setSessionSelected] = useState(null);
  const [instructorSkills, setInstructorSkills] = useState(null);
  const [instructorActivity, setInstructorActivity] = useState(null);
  const [currentSessionUnreadCount, setCurrentSessionUnreadCount] = useState(0);
  const [blazeUnreadCount, setBlazeUnreadCount] = useState({});
  const [freePeriod, setFreePeriod] = useState(480);
  const [maxTimePerDay, setMaxTimePerDay] = useState(600);

  useEffect(() => {
    async function run() {
      const snapshot = await db.collection('blaze_dev/collections/admin').doc('config')
        .get();

      if(snapshot.exists) {
        if(snapshot.data().grace_period) setFreePeriod(snapshot.data().max_time_per_day);
        if(snapshot.data().max_time_per_day) setMaxTimePerDay(snapshot.data().max_time_per_day + snapshot.data().grace_period);
      }
    }

    run();
  }, [])

  return (
    <BlazeExternalContext.Provider
      value={{
        tabIdx: [tabIdx, setTabIdx],
        openPage: [openPage, setOpenPage],
        openChat: [openChat, setOpenChat],
        openPages: [openPages, setOpenPages],
        sessionId: [sessionId, setSessionId],
        callStartTs: [callStartTs, setCallStartTs],
        skills: [instructorSkills, setInstructorSkills],
        sessionSelected: [sessionSelected, setSessionSelected],
        instructorActivity: [instructorActivity, setInstructorActivity],
        currentSessionUnreadCount: [
          currentSessionUnreadCount,
          setCurrentSessionUnreadCount,
        ],
        blazeUnreadCount: [blazeUnreadCount, setBlazeUnreadCount],
        freePeriod: [freePeriod, setFreePeriod],
        maxTimePerDay: [maxTimePerDay, setMaxTimePerDay]
      }}
    >
      {props.children}
    </BlazeExternalContext.Provider>
  );
};

export default BlazeExternalContextProvider;
