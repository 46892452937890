import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { Link } from "react-router-dom";
import {
  BlazeExternalContext,
  UserContext,
  ThemeContext,
} from "../../../context";
import RightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import roundedVideoCamera from "../../../assets/blaze/rounded_video_camera.svg";
import unAssigned from "../../../assets/images/unassigned.png";
import { listenToUnreadMessages } from "../../../database";

import "./style.scss";
import {getAvatarImage} from "../../../context/global/user-context";
import BlazeCardBgDark from "../../../assets/blaze/blazeCardBgDark";
import BlazeCardBgLight from "../../../assets/blaze/blazeCardBgLight";
import StarRatings from "react-star-ratings";
import {randomAvatar, starPath} from "../../../helpers";
import Avatar from "../../../components/global/Avatar";

const getSubject = (arr) => {
  let sub = "";
  arr.map((w) => (sub = sub + " " + w));
  return sub.slice(1);
};

const subjectName = (skill) => {
  let splitted = skill?.split("_");

  if (splitted?.length > 0) {
    return splitted.length === 3 ? splitted[2] : getSubject(splitted.slice(3));
  }
};

const BlazeExternalCard = React.forwardRef(({
  type,
  topic,
  skill,
  onClick,
  gradient,
  sessionId,
  rating,
  studentName,
  studentId,
  studentImage,
  isSessionSelected,
  noLink,
}, ref) => {
  const [, setCurrentSessionUnreadCount] =
    useContext(BlazeExternalContext).currentSessionUnreadCount;
  // const [unreadMsgCount, setUnreadMsgCount] = useState(0);

  const [blazeUnreadCount] = useContext(UserContext).blazeUnreadCount;
  const [isDark] = useContext(ThemeContext).theme;

  const unreadMsgCount = useMemo(() => {
    if(type === 'accepted' && blazeUnreadCount && blazeUnreadCount[sessionId]) {
      return blazeUnreadCount[sessionId]
    }
    return 0;
  }, [blazeUnreadCount])

  // useEffect(() => {
  //   if (type === "accepted") {
  //     listenToUnreadMessages({
  //       sessionId,
  //       userId: user?.uid,
  //       callback: (count) => {
  //         setUnreadMsgCount(count);
  //       },
  //     });
  //   }
  // }, [sessionId]);

  const imageStudentRef = useRef(null);
  useEffect(() => {
    imageStudentRef.current = randomAvatar(studentName ?? 'S', 'flex-shrink-0')
  }, [studentName])

  useEffect(() => {
    if (unreadMsgCount >= 0 && isSessionSelected) {
      setCurrentSessionUnreadCount(unreadMsgCount);
    }
  }, [unreadMsgCount, isSessionSelected]);

  const CardItem = (children) => noLink ? <>{children}</> : <Link to={`/blaze/chat/${sessionId}`}>{children}</Link>

  return (
    <div
      className={`blaze-card ${isSessionSelected ? "selected" : ""} fadeIn`}
      onClick={() => {
        onClick()
      }}
      ref={ref}
      style={{boxShadow: isSessionSelected ? `${gradient[1]}8f 0px 0px 6px 2px` : 'none'}}
    >
      {CardItem(<>
        <div className="blaze-card-bg">
          {" "}
          {isDark ? (
            <BlazeCardBgDark
              color1={gradient[1]}
              color2={gradient[0]}
              sessionId={sessionId}
              key={topic}
            />
          ) : (
            <BlazeCardBgLight
              color1={gradient[1]}
              color2={gradient[0]}
              key={topic}
              sessionId={sessionId}
            />
          )}{" "}
        </div>
        <div className={"blaze-card-content"} style={{borderColor: isSessionSelected ? (gradient[0]) : '#88888826'}}>
          <div
            className={`card-${type}`}
            style={{
              backgroundColor: gradient[0],
              boxShadow: `4px 0 14px 3px ${gradient[1]}80`,
            }}
          ></div>
          <div className="blaze-card-inner">
            {unreadMsgCount > 0 && (
              <div className="unread-messages">
                <h6>{unreadMsgCount > 9 ? "9+" : unreadMsgCount}</h6>
              </div>
            )}
            <div className="blaze-card-details">
              <div className="blaze-topic">
                <img
                  src={roundedVideoCamera}
                  alt="vid"
                  className="video-cam"
                  draggable={false}
                />{" "}
                <div>
                  <h2>{topic}</h2>
                  <h5>
                    Class {skill?.split("_")[1]}th{" "}
                    {subjectName(skill) === "maths"
                      ? "Mathematics"
                      : subjectName(skill)}
                  </h5>
                </div>
              </div>

              {type !== "outstanding" && <div className="card-divider"></div>}
              {type !== "outstanding" && (
                <div className="student-details">
                  <div className="student-inner">
                    <div className="student-img">
                      <Avatar
                        className="image__student"
                        src={studentImage}
                        name={studentName}
                        uid={studentId}
                        draggable={false}
                        alt="Pustack User"
                      />
                    </div>
                    <section>
                      <div>
                        <h4 className="capitalize">{studentName ? studentName : "Student"}</h4>
                      </div>
                      <h6>Class {skill?.split("_")[1]}th Student</h6>
                    </section>
                  </div>
                  {(type === 'completed' && Boolean(rating)) && <StarRatings
                    rating={rating}
                    starRatedColor="#fec107"
                    starHoverColor="#fec107"
                    numberOfStars={5}
                    starDimension="16px"
                    starSpacing="2.5px"
                    name="rating"
                    svgIconPath={starPath}
                    svgIconViewBox="0 0 207.802 207.748"
                  />}

                  {/*<button>*/}
                  {/*  <RightRoundedIcon />*/}
                  {/*</button>*/}
                </div>
              )}
            </div>
          </div>
        </div>
      </>)}
    </div>
  );
});

export default BlazeExternalCard;
