import React, { useContext } from "react";
import {
  BlazeExternalEarnings,
  BlazeExternalPerformance,
  BlazeExternalSettings,
} from "../../../components";
import {
  // UserContext,
  BlazeExternalContext,
} from "../../../context";
import "./style.scss";

const BlazeExternalLanding = () => {
  // const [user] = useContext(UserContext).user;
  const [openPage] = useContext(BlazeExternalContext).openPage;
  const [openPages] = useContext(BlazeExternalContext).openPages;

  return (
    <div className="blaze__external__landing__wrapper">
      <div className="blaze__external">
        {openPages && openPage === 0 && (
          <div>
            <BlazeExternalEarnings />
          </div>
        )}
        {openPages && openPage === 1 && (
          <div>
            <BlazeExternalPerformance />
          </div>
        )}
        {openPages && openPage === 2 && (
          <div>
            <BlazeExternalSettings />
          </div>
        )}
      </div>
    </div>
  );
};

export default BlazeExternalLanding;
