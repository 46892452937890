import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { db, rdb } from "../../firebase_config";
import { castIndianTime } from "../../helpers";
import { STORY_STATUS } from "../../containers/home/sidebar";
import { ShareStory, storyConfigSchema } from "./StoryState";

export const SubjectModalContext = createContext();

export async function getBunnyCDNConfig() {
  const storyConfig = await db.doc("admin/story_config").get();

  const storyConfigData = storyConfigSchema.safeParse(storyConfig.data());

  if (!storyConfigData.success) {
    console.error("Story config data is not valid", storyConfigData.error);
    return null;
  }

  return storyConfigData.data.bunny_cdn;
}

export async function fetchStoryItems(storyId, first) {
  const stories = [];
  const bunnyCDNConfig = await getBunnyCDNConfig();
  console.log("Bunny CDN Debug | storyItems - ", bunnyCDNConfig);
  if (!bunnyCDNConfig) return [];
  await db
    .collection("stories/" + storyId + "/items")
    .get()
    .then(async (querySnapshot) => {
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        // const url = 'https://api.vimeo.com/videos/' + doc.data().video_id;
        // const response = await axios.get(url, {
        //   headers: {
        //     'Authorization': 'Bearer eb3aa30f683094b5e51d077a9b8bbff5'
        //   }
        // });
        // const _url = response.data.play.progressive.find(c => c.rendition === "720p")?.link ?? response.data.play.progressive[0].link;
        const _url = `https://${bunnyCDNConfig.host_name}/${
          doc.data().video_id
        }/play_240p.mp4`;
        // const _url = `https://video.bunnycdn.com/play/${bunnyCDNConfig.library_id}/${doc.data().video_id}`;
        stories.push({
          url: _url,
          type: "video",
          id: doc.id,
          preloadResource: true,
          uploadProgress: first && i === 0 ? 80 : 100,
          isTransCoding: false,
          created_on: doc.data().created_on,
          video_id: doc.data().video_id,
          grade_id: doc.data().grade_id,
        });
      }
      // querySnapshot.forEach((doc) => {
      //   console.log(doc.id, " => ", doc.data());
      //
      // });
    });
  stories.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
  return stories;
}

async function createSubjectCollection(name, storyObject) {
  const config = await getBunnyCDNConfig();
  if (!config) return null;
  const response = await fetch(
    `https://video.bunnycdn.com/library/${config.library_id}/collections`,
    {
      method: "POST",
      headers: {
        AccessKey: config.api_key,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
      }),
    }
  );
  const data = await response.json();

  const collectionId = data.guid;

  const story = {
    ...storyObject,
    id: collectionId,
  };

  await db.collection("stories").doc(collectionId).set(story);

  return story;
}

async function fetchBunnyCDNCollections() {
  const config = await getBunnyCDNConfig();
  if (!config) return null;
  const response = await fetch(
    `https://video.bunnycdn.com/library/${config.library_id}/collections`,
    {
      method: "GET",
      headers: {
        AccessKey: config.api_key,
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.items;
}

async function fetchStories() {
  const stories = [];
  await db
    .collection("stories")
    .get()
    .then(async (querySnapshot) => {
      for (let i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        // const items = await fetchStoryItems(doc.id, i === 0);
        stories.push({
          ...doc.data(),
          fetched: false,
          items: [],
        });
      }
    });

  stories.sort((a, b) => {
    const aLatestItemTime = Math.max(...a.items.map((item) => item.created_on));
    const bLatestItemTime = Math.max(...b.items.map((item) => item.created_on));

    return bLatestItemTime - aLatestItemTime;
  });

  return stories;
}

export const storyEventNames = {
  uploadStoryProgress: "uploadStoryProgress",
  transcoding: "transcoding",
  storyViewed: "storyViewed",
};

class StoryEvent {
  event;
  constructor() {
    this.event = new CustomEvent("storyEvent", { detail: {} });
  }

  addEventListener(eventName, callback) {
    // this.eventListeners.push(callback);
    window.addEventListener(eventName, callback);
  }

  removeEventListener(eventName, callback) {
    // this.eventListeners.push(callback);
    window.removeEventListener(eventName, callback);
  }

  dispatchEvent(eventName, data) {
    dispatchEvent(new CustomEvent(eventName, { detail: data }));
  }
}

const hasSameGradeId = (gradeIds, targetGradeIds) => {
  const _gradeIds = gradeIds.split(",").filter((c) => c.trim() !== "");
  const _targetGradeIds = targetGradeIds
    .split(",")
    .filter((c) => c.trim() !== "");

  return _targetGradeIds.every((gradeId) => _gradeIds.includes(gradeId));
};

const mergeGradeIds = (gradeIds, targetGradeIds) => {
  const _gradeIds = gradeIds.split(",").filter((c) => c.trim() !== "");
  const _targetGradeIds = targetGradeIds
    .split(",")
    .filter((c) => c.trim() !== "");

  return [...new Set([..._gradeIds, ..._targetGradeIds])].join(",");
};

export const SubjectModalContextProvider = (props) => {
  //------------------------------------ constants hooks

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [subjectCode, setSubjectCode] = useState(null);
  const [subjectName, setSubjectName] = useState(null);
  const [tabData, setTabData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFlag, setActiveFlag] = useState(true);
  const [showStoryModal, setShowStoryModal] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [storyVideoIndices, setStoryVideoIndices] = useState({});
  const [uploadStoryFile, setUploadStoryFile] = useState(null);
  const shareStoryClassRef = useRef(null);
  const [stories, setStories] = useState(null);
  const storyEventRef = useRef(null);
  const [cursor, setCursor] = useState(0);
  const [subjectCollections, setSubjectCollections] = useState(null);
  const [bunnyCDNConfig, setBunnyCDNConfig] = useState(null);

  // const stories = mockStories;

  // function setStories() {}

  // const stories = useMemo(() => {
  //   return _stories ? _stories.filter(story => story.items?.length > 0) : null;
  // }, [_stories]);

  console.log("Vimeo debug | ||| || stories - ", stories);

  useEffect(() => {
    getBunnyCDNConfig().then((config) => {
      setBunnyCDNConfig(config);
    });
    fetchBunnyCDNCollections().then((collections) => {
      setSubjectCollections(collections);
    });
  }, []);

  useEffect(() => {
    storyEventRef.current = new StoryEvent();
    // setStories(mockStories);
    fetchStories().then((_stories) => {
      console.log("Setting story - ");
      setStories(_stories);
    });
  }, []);

  const uploadStory = useCallback(
    async (shareStoryClass) => {
      console.log("Vimeo debug = ", shareStoryClass instanceof ShareStory);
      if (!(shareStoryClass instanceof ShareStory)) return;

      let story = stories.find(
        (c) => c.item_name === shareStoryClass.getSubjectName()
      );

      if (!story) {
        story = await createSubjectCollection(
          shareStoryClass.getSubjectName(),
          shareStoryClass.getStoryObject()
        );
        // const ref = db.collection('stories').doc();
        // story = {
        //   ...(shareStoryClass.getStoryObject()),
        //   id: ref.id,
        // }
        // await ref.set(story);
      }

      setStories((stories) => {
        let _storyObject = stories.find(
          (c) => c.item_name === shareStoryClass.getSubjectName()
        );
        if (!_storyObject) _storyObject = story;
        const itemObject = shareStoryClass.fetchStoryObject();
        const newStories = stories.filter(
          (story) => story.id !== _storyObject.id
        );
        const newItems =
          _storyObject.items?.filter(
            (item) => item.id !== itemObject.id && item.id !== null
          ) ?? [];
        return [
          { ..._storyObject, items: [itemObject, ...newItems] },
          ...newStories,
        ];
      });

      storyEventRef.current.dispatchEvent(storyEventNames.uploadStoryProgress, {
        status: STORY_STATUS.LOADING,
        message: "Uploading story",
        storyName: shareStoryClass.getSubjectName(),
      });

      const link = await shareStoryClass.createBunnyCDNUploadLink();

      await shareStoryClass.uploadBunnyCDNVideo(link, onProgress, onComplete);
      // const link = await shareStoryClass.createUploadLink();
      //
      // await shareStoryClass.uploadVideo(link, onProgress, onComplete);

      function onProgress(progress) {
        console.log("progress - ", progress);
        storyEventRef.current.dispatchEvent(
          storyEventNames.uploadStoryProgress,
          {
            status: STORY_STATUS.IS_UPLOADING,
            message: "Uploading story",
            progress: progress.percentage,
            storyName: shareStoryClass.getSubjectName(),
          }
        );

        // setStories(stories => {
        //   const _storyObject = stories.find(c => c.item_name === shareStoryClass.getSubjectName());
        //   const itemObject = shareStoryClass.fetchStoryObject();
        //   const newStories = stories.filter(story => story.id !== _storyObject.id);
        //   const newItems = _storyObject.items?.filter(item => item.id !== itemObject.id && item.id !== null) ?? [];
        //   return [{..._storyObject, items: [itemObject, ...(newItems)]}, ...newStories];
        // })
      }

      async function onComplete() {
        console.log("complete");

        const _storyObject = stories.find(
          (c) => c.item_name === shareStoryClass.getSubjectName()
        );
        const itemObject = shareStoryClass.fetchStoryObject();

        storyEventRef.current.dispatchEvent(
          storyEventNames.uploadStoryProgress,
          {
            status: STORY_STATUS.IS_TRANSCODING,
            message: "Transcoding story",
            progress: 100,
            storyName: shareStoryClass.getSubjectName(),
            storyId: shareStoryClass.getSubjectCollectionId(),
          }
        );

        const ist = await castIndianTime(true);

        // Check if the story has the selected grade
        const isStoryHasGrade = hasSameGradeId(
          _storyObject.grade_id,
          shareStoryClass.getGradeId()
        );

        if (!isStoryHasGrade) {
          const newGradeId = mergeGradeIds(
            _storyObject.grade_id,
            shareStoryClass.getGradeId()
          );
          await db.doc("stories/" + _storyObject.id).set(
            {
              grade_id: newGradeId,
            },
            { merge: true }
          );
        }

        console.log("stories/" + _storyObject.id + "/items/" + itemObject.id);

        await db
          .doc("stories/" + _storyObject.id + "/items/" + itemObject.id)
          .set({
            created_on: ist.serverISOString,
            grade_id: shareStoryClass.getGradeId(),
            video_duration: parseInt(shareStoryClass.getVideoDuration()),
            video_id: shareStoryClass.getVideoId(),
            video_title: shareStoryClass.getVideoName(),
          });

        // setStories(stories => {
        //   const _storyObject = stories.find(c => c.item_name === shareStoryClass.getSubjectName());
        //   const itemObject = shareStoryClass.fetchStoryObject();
        //   const newStories = stories.filter(story => story.id !== _storyObject.id);
        //   const newItems = _storyObject.items?.filter(item => item.id !== itemObject.id && item.id !== null) ?? [];
        //   return [{..._storyObject, items: [itemObject, ...(newItems)]}, ...newStories];
        // })

        shareStoryClass.checkForTranscoding(async (link) => {
          console.log("Setting story - ");
          setStories((stories) => {
            const _storyObject = stories.find(
              (c) => c.item_name === shareStoryClass.getSubjectName()
            );
            const itemObject = shareStoryClass.fetchStoryObject();
            const newStories = stories.filter(
              (story) => story.id !== _storyObject.id
            );
            const newItems =
              _storyObject.items?.filter(
                (item) =>
                  item.id !== itemObject.id &&
                  item.id !== null &&
                  item.id !== undefined
              ) ?? [];
            return [
              { ..._storyObject, items: [itemObject, ...newItems] },
              ...newStories,
            ];
          });

          storyEventRef.current.dispatchEvent(
            storyEventNames.uploadStoryProgress,
            {
              status: STORY_STATUS.LOADED,
              message: "View story",
              isTransCoding: false,
              storyName: shareStoryClass.getSubjectName(),
            }
          );
        });
      }
    },
    [stories]
  );

  return (
    <SubjectModalContext.Provider
      value={{
        data: [data, setData],
        isOpen: [isOpen, setIsOpen],
        subjectCode: [subjectCode, setSubjectCode],
        subjectName: [subjectName, setSubjectName],
        tabData: [tabData, setTabData],
        activeTab: [activeTab, setActiveTab],
        activeFlag: [activeFlag, setActiveFlag],
        showStoryModal: [showStoryModal, setShowStoryModal],
        stories: [stories, setStories],
        selectedStoryIndex: [selectedStoryIndex, setSelectedStoryIndex],
        storyVideoIndices: [storyVideoIndices, setStoryVideoIndices],
        uploadStoryFile: [uploadStoryFile, setUploadStoryFile],
        subjectCollections: [subjectCollections, setSubjectCollections],
        bunnyCDNConfig: [bunnyCDNConfig, setBunnyCDNConfig],
        storyCursor: [cursor, setCursor],
        shareStoryClassRef: shareStoryClassRef,
        storyEventRef: storyEventRef,
        uploadStory,
      }}
    >
      {props.children}
    </SubjectModalContext.Provider>
  );
};

export default SubjectModalContextProvider;
