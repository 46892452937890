import React, {useState, useEffect, useRef, useContext, useMemo, useCallback} from "react";
import firebase from "firebase/app";
import Lottie from "lottie-react-web";
import Timer from "react-compound-timer";
import { useHistory } from "react-router";
import { UserContext } from "../../context";
import PhoneInput from "react-phone-input-2";
import Checkbox from "@material-ui/core/Checkbox";
import image1 from '../../assets/images/auth/get_paid.svg';
import image2 from '../../assets/images/auth/enjoy_freedom.svg';
import image3 from '../../assets/images/auth/teach_and_engage.svg';
import image4 from '../../assets/images/auth/track_and_improve.svg';
import SwipeableViews from "react-swipeable-views";
import {appleProvider, auth, db, facebookProvider, firebaseApp, googleProvider} from "../../firebase_config";
import FormGroup from "@material-ui/core/FormGroup";
import {
  signInWithPhone, submit,
  submitFacebook,
  submitGoogleEmail,
  submitPhoneNumberAuthCode,
} from "../../services/auth";
import {
  addInstructorSkill,
  deleteUserFromDatabase,
  getOverallSkills,
  getUserInfoById,
  uploadFinalUserInfo, uploadUserInfo,
} from "../../database";
import { ButtonBase, Dialog } from "@material-ui/core";
import {version} from '../../../package.json';
import {
  Google,
  Facebook,
  Apple,
  landingHero,
  lappy,
  walletCoins,
  workTerms,
  teacher1,
  teacher2,
  teacher3,
  teacher4, defaultPic,
} from "../../assets";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PdfPreview from "../../components/newsfeed/pdf-preview";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import flyToMoon from "../../assets/images/landing/flytomoon.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import tutorLogo from '../../assets/images/auth/pustack_tutor_color_logo.png';
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import circularProgress from "../../assets/lottie/circularProgress.json";
import {castIndianTime, isNameValid, loadingWrapper, privacyPolicy, termsOfService} from "../../helpers";
import "./style.scss";
import {postToWorkplace} from "../../database/blazeExternal";
import PustackFooter from "../../components/global/pustack-footer";
import ExitToApp from "@material-ui/icons/ExitToApp";
import woman from "../../assets/images/auth/woman.svg";
import whiteLogo from "../../assets/images/auth/pustack_tutor_white_logo.png";
import {getVimeoURL} from "../../helpers/global";
import {carouselData} from "../../pages/auth/mobile";
import {ArrowRightIcon} from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import VimeoPlayer from "../../components/global/vimeo-player";
import useAppleDevice from "../../hooks/isAppleDevice";

const PasswordRoundedIcon = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch star-svg"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    data-testid="PasswordRoundedIcon"
  >
    <path d="M3 17h18c.55 0 1 .45 1 1s-.45 1-1 1H3c-.55 0-1-.45-1-1s.45-1 1-1zm-.5-4.43c.36.21.82.08 1.03-.28l.47-.82.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.49-.84h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H5.3l.47-.82c.21-.36.09-.82-.27-1.03-.36-.2-.82-.08-1.03.28L4 8.47l-.47-.82c-.21-.36-.67-.48-1.03-.28-.36.21-.48.67-.27 1.03l.47.82h-.95c-.41 0-.75.34-.75.75s.34.75.75.75h.95l-.48.83c-.2.36-.08.82.28 1.02zm8 0c.36.21.82.08 1.03-.28l.47-.82.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.48-.83h.95c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-.96l.47-.82c.21-.36.08-.82-.27-1.03-.36-.21-.82-.08-1.02.27l-.48.82-.47-.82c-.21-.36-.67-.48-1.02-.27-.36.21-.48.67-.27 1.03l.47.82h-.96c-.41-.01-.75.33-.75.74s.34.75.75.75h.95l-.48.83c-.2.36-.08.82.28 1.02zM23 9.97c0-.41-.34-.75-.75-.75h-.95l.47-.82c.21-.36.08-.82-.27-1.03-.36-.21-.82-.08-1.02.27l-.48.83-.47-.82c-.21-.36-.67-.48-1.02-.27-.36.21-.48.67-.27 1.03l.47.82h-.95c-.42-.01-.76.33-.76.74s.34.75.75.75h.95l-.48.83c-.21.36-.08.82.28 1.02.36.21.82.08 1.03-.28l.47-.82.48.83c.21.36.67.48 1.03.28.36-.21.48-.66.28-1.02l-.48-.83h.95c.4-.01.74-.35.74-.76z"></path>
  </svg>
);

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value); // update the state to force render
};

class SignUp {

}

const UserAuth = () => {
  let history = useHistory();
  const forceUpdate = useForceUpdate();

  const [otpCode, setOtpCode] = useState([]);
  const [activeTab, setActiveTab] = useState(0); //5 tabs
  const [activeVideoTab, _setActiveVideoTab] = useState(0);
  const [showPDF, setShowPDF] = useState(false);
  const [spaceKey, setSpaceKey] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [skillGrade, setSkillGrade] = useState("");
  const [allSkills, setAllSkills] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [phoneExists, setPhoneExists] = useState(false);
  const [gmailExists, setGmailExists] = useState(false);
  const [skillSubjects, setSkillSubjects] = useState([]);
  const [existingPhone, setExistingPhone] = useState("");
  const [skillCategory, setSkillCategory] = useState("");
  const [documentToShow, setDocumentToShow] = useState("");
  const [processStarted, setProcessStarted] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [askForName, setAskForName] = useState(false);
  const [fullName, setFullName] = useState('');
  const [submittingName, setIsSubmittingName] = useState(false);
  const [gradeSubjectCount, setGradeSubjectCount] = useState(null);
  const [categorySubjectCount, setCategorySubjectCount] = useState(null);
  const [socialProcessStarted, setSocialProcessStarted] = useState([
    false,
    false,
    false,
  ]);
  const [plural, setPlural] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [resendCode, setResendCode] = useState(true);
  const [checkingOtp, setCheckingOtp] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);
  const [googleMailAddress, setGoogleMailAddress] = useState("");
  const [tempEmailUserData, setTempEmailUserData] = useState(null);
  const [tempPhoneUserData, setTempPhoneUserData] = useState(null);
  const [fbPendingCredentials, setFbPendingCredentials] = useState("");
  const [existingAccountError, setExistingAccountError] = useState(null);

  const [finalSubmitting, setFinalSubmitting] = useState(false);

  const [, setUser] = useContext(UserContext).user;
  const [, setIsUserProTier] = useContext(UserContext).tier;
  const [, setIsInstructor] = useContext(UserContext).isInstructor;
  const [, setIsExternal] = useContext(UserContext).isExternal;
  const [isVerified, setIsVerified] = useState(false);
  const [videoList, setVideoList] = useState(null);
  const [enableSwitch, setEnableSwitch] = useState(true);

  const isAppleDevice = useAppleDevice();

  const setActiveVideoTab = (val) => {
    if(!enableSwitch) return;
    _setActiveVideoTab(val);
  }

  useEffect(() => {
    if(!videoList && activeTab === 4) {
      db.collection('admin_videos')
        .doc('pustack_tutor_app')
        .get()
        .then(async (snapshot) => {
          if(snapshot.exists) {
            const data = snapshot.data();
            let videoList = [];
            for(let i = 0; i < data.videos.introduction_video_list.length; i++) {
              let c = data.videos.introduction_video_list[i];
              // const {url} = await getVimeoURL(c.video_id);
              videoList.push({
                ...c,
                // url
              })
            }
            setVideoList(videoList);
          }
        })
    }
  }, [activeTab, videoList])

  useEffect(() => {
    if(activeTab === 3) {
      const _user = auth.currentUser;
      db.collection('blaze_dev')
        .doc('collections')
        .collection('instructors')
        .doc(_user.uid)
        .set({
          "call_state": null,
        }, {merge: true})
        .catch(console.log)

      db.collection('users')
        .doc(_user.uid)
        .set({
          is_external_instructor: true
        }, {merge: true})
        .catch(console.log)
    }
  }, [activeTab])

  const tabHeading = [
    "Enter Phone Number",
    "Enter OTP to proceed",
    "I would like to",
    askForName ? "My Name is" : "Add Skills",
    "Thank You",
  ];

  const reset = (message = "") => {
    setOtpCode(Array(6).fill(""));
    setPhoneNumberError(message);
    setPhoneNumber("+91");
    setSocialProcessStarted(false, false, false);
    setOtpError(false);
    setSendingOtp(false);
    setCheckingOtp(false);
    setTempUserData(null);
    setTempEmailUserData(null);
    setTempPhoneUserData(null);
    setEmailError(null);
    setActiveTab(0);
  }

  useEffect(() => {
    if(askForName || !auth.currentUser?.uid) return;
    let unsub = function() {};
    unsub = db.collection('users')
      .doc(auth.currentUser?.uid)
      .onSnapshot(snapshot => {
        if(!snapshot.exists || (snapshot.exists && snapshot.data().is_deleted)) {
          return reset('User was deleted, Please contact PuStack support')
        }
        if(snapshot.exists) {
          const data = snapshot.data();
          // ActiveTab = 4 is Congratulations Screen
          if(activeTab === 4 && auth.currentUser?.uid) {
            console.log('data - ', data);
            if(!data.name) {
              setFullName('');
              setIsSubmittingName(false);
              console.log('setAskForName - ');
              return setAskForName(() => {
                setActiveTab(3);
                return auth.currentUser?.uid;
              })
            }
            if(data.is_tutor_verified && data.is_instructor) {
              setIsVerified(data.is_tutor_verified);
              setTimeout(() => {
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    uid: data?.uid,
                    grade: data?.grade,
                    name: data?.name,
                    profile_url: data?.profile_url,
                  })
                );
                localStorage.setItem("isInstructor", JSON.stringify(data.is_instructor));
                localStorage.setItem(
                  "isExternalInstructor",
                  JSON.stringify(data.is_external_instructor)
                );
                localStorage.setItem("isUserPro", JSON.stringify(data.tier !== "free"));
                console.log('setUser - ')
                setUser(data);
                setIsUserProTier(data.tier !== "free");
                setIsInstructor(data.is_instructor);
                setIsExternal(data.is_external_instructor);
                console.log('loadingWrapper() - ');
                // loadingWrapper();
                history.push("/");
              }, 100);
            }
            if(!data.has_submitted_skills) {
              setActiveTab(3);
            }
          }
          // ActiveTab = 3 is Congratulations Screen
          if(activeTab === 3 && auth.currentUser?.uid) {
            if(!data.name && data.uid) {
              setFullName('');
              setIsSubmittingName(false);
              console.log('setAskForName - ');
              return setAskForName(() => {
                setActiveTab(3);
                return auth.currentUser?.uid;
              })
            }
            if(data.has_submitted_skills && data.name) {
              setActiveTab(4);
            }
          }
        }
      })

    return () => {
      unsub();
    }
  }, [activeTab, askForName])


  const gradesList = useMemo(() => {
    if(!allSkills) return [];
    const grades = Object.keys(allSkills);
    return grades.map(grade => ({
      value: grade,
      name: (grade[0].toUpperCase() + grade.slice(1)).split('_').join(' '),
      number: +grade.split('_')[1]
    })).sort((a, b) => a.number - b.number)
  }, [allSkills])

  let recaptchaRef = useRef();

  const categoryIdx = {
    Maths: 0,
    Science: 1,
    SST: 2,
    English: 3,
    General: 4,
  };

  const [pauseAnimate, setPauseAnimate] = useState(false);
  const [mouseHovering, setMouseHovering] = useState(false);
  const [increaseOpacity, setIncreaseOpacity] = useState([false, false, false]);

  useEffect(() => {
    activeTab === 3 && allSkills === null && getOverallSkillsFn();
  }, [activeTab]);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      `recaptcha-container`,
      {
        size: "invisible",
        callback: () => {},
        "expired-callback": () => window.recaptchaVerifier.clear(),
      },
      firebaseApp
    );
  }, []);

  const getOverallSkillsFn = async () => {
    const res = await getOverallSkills();

    [{value: 'class_9'}, {value: 'class_10'}].map(({ value: grade }) => {
      res?.[grade] && (res[grade].subjects.Maths = ["Maths"]);
    });
    console.log('res = ', res);
    setAllSkills(res);
  };

  const isBtnDisabled = skillSubjects?.length === 0;

  const getSubjectId = (skillSubject, skillGrade) => {
    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    return _skillId;
  };

  const selectSubject = (event, skillSubject) => {
    const { checked } = event.target;

    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    if (_skillId) {
      let skillToAdd = {
        category: skillCategory,
        grade: skillGrade,
        id: _skillId,
        is_skippable:
          allSkills[skillGrade]?.skip_subjects[categoryIdx[skillCategory]],
        reason: null,
        status: "accepted",
        subject: skillCategory === "Maths" ? null : skillSubject,
      };

      if (checked) {
        const _skillSubjects = [...skillSubjects, skillToAdd];

        setSkillSubjects(_skillSubjects);
        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillGrade}_${skillCategory}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillGrade && item.category === skillCategory
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillGrade]: (gradeSubjectCount?.[skillGrade] || 0) + 1,
        });
      } else {
        const _skillSubjects = [
          ...skillSubjects.filter((sub) => sub.id !== skillToAdd.id),
        ];

        setSkillSubjects(_skillSubjects);

        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillGrade}_${skillCategory}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillGrade && item.category === skillCategory
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillGrade]: (gradeSubjectCount?.[skillGrade] || 0) - 1,
        });
      }
    }
  };

  const selectSubject2 = (event, skillToAdd) => {
    const { checked } = event.target;

    if (skillToAdd) {
      if (!checked) {
        const _skillSubjects = [
          ...skillSubjects.filter((sub) => sub.id !== skillToAdd.id),
        ];

        setSkillSubjects(_skillSubjects);

        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillToAdd.grade}_${skillToAdd.category}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillToAdd.grade &&
              item.category === skillToAdd.category
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillToAdd.grade]: (gradeSubjectCount?.[skillToAdd.grade] || 0) - 1,
        });
      }
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.key === " ") {
      setSpaceKey(true);
    } else if (e.key === "Backspace" || e.key === "Delete") {
      if (otpCode[i]?.length === 0 && i > 0 && i <= 5) {
        const prevField = document.querySelector(
          `div > input[name=input${i - 1}]`
        );
        if (prevField !== null) prevField.focus();
      }
    }
  };

  const signInPhoneFn = async (nextTab) => {
    if (phoneNumber.length > 6) {
      setSendingOtp(true);
      setExistingPhone(phoneNumber);
      await signInWithPhone(
        phoneNumber,
        countryCode,
        setActiveTab,
        nextTab,
        setSendingOtp,
        setPhoneNumberError,
        tempEmailUserData,
        setTempEmailUserData,
        setPhoneExists
      );
    } else {
      setPhoneNumberError("Invalid phone number");
      setSocialProcessStarted([false, false, false]);

      setTimeout(() => setPhoneNumberError(""), 6000);
    }
  };

  const handleOTPChange = async (e, i) => {
    const { maxLength, value } = e.target;
    setOtpError(false);
    setOtpErrorMsg("");
    if (value.length >= maxLength && i < 5) {
      const nextField = document.querySelector(
        `div > input[name=input${i + 1}]`
      );
      if (nextField !== null) nextField.focus();
    }

    if (!spaceKey) {
      otpCode[i] = value;
      setOtpCode(otpCode);
      forceUpdate();
    }
    const code = otpCode?.join("");
    if (code.length === 6) {
      await submitOtp();
    }
  };

  const submitOtp = async () => {
    // return setActiveTab(2);

    const code = otpCode?.join("");

    if (code.length === 6) {
      setCheckingOtp(true);

      let user, res, errorCode;
      try {
        [user, res, errorCode] = await submitPhoneNumberAuthCode(
          code,
          fbPendingCredentials,
          googleMailAddress,
          false
        );
      } catch (err) {
        return;
      }

      setTempPhoneUserData(user);
      if (res) {
        setOtpError(false);

        let [
          name,
          profile_url,
          role,
          email,
          is_instructor,
          tutor_signup_ts,
          phone_number,
          has_rated_app,
          app_rating,
          pro_expiration_date,
          has_submitted_skills,
          tier,
          grade,
          phone_country_code,
          is_external_instructor,
          is_tutor_verified,
          is_deleted
        ] = await getUserInfoById(user.uid);

        if(is_deleted) {
          return reset('User was deleted, Please contact PuStack support')
        }

        if(!phone_number) {
          let time_now = await uploadUserInfo(
            user?.uid,
            name ?? user?.displayName,
            email ?? null,
            user?.photoURL ?? null,
            false, // setting user as student
            user?.phoneNumber?.slice(countryCode?.length + 1),
            countryCode
          );
          phone_number = user?.phoneNumber?.slice(countryCode?.length + 1);
          phone_country_code = countryCode;
        }
        setCheckingOtp(false);

        setCheckingOtp(false);

        if (!email || !phone_number) {
          setActiveTab(activeTab + 1);
        } else {

          const data = {
            email: email,
            uid: user.uid,
            name: name,
            grade: grade,
            is_instructor: is_instructor,
            is_external_instructor: is_external_instructor,
            phone_country_code: phone_country_code,
            phone_number: phone_number,
            profile_url: profile_url,
            role: role,
            tutor_signup_ts: tutor_signup_ts,
            has_rated_app: has_rated_app ? has_rated_app : false,
            app_rating: app_rating ? app_rating : 0,
            pro_expiration_date: pro_expiration_date,
          };

          if(!data.name || !(data.name?.trim()?.length > 0)) {
            console.log('setAskForName - ');
            setAskForName(data.uid);
            return setActiveTab(3);
          }

          if (
            !is_tutor_verified
            || !is_instructor
          ) {
            if(!has_submitted_skills) {
              setTempUserData(() => {
                setActiveTab(3);
                return data;
              });
              return;
            }

            // setActiveTab(0);
            // setOtpCode(Array(6).fill(""));
            // setPhoneNumberError("Your verification is still in progress.");
            // setSocialProcessStarted([false, false, false]);
            // setTimeout(() => setPhoneNumberError(""), 6000);
            setActiveTab(4);
            return;
          }

          // if (!is_external_instructor) {
          //   setActiveTab(0);
          //   setOtpCode(Array(6).fill(""));
          //   setPhoneNumberError("This platform is only for tutors.");
          //   setSocialProcessStarted([false, false, false]);
          //   setTimeout(() => setPhoneNumberError(""), 6000);
          //   return;
          // }

          setTimeout(() => {
            localStorage.setItem(
              "user",
              JSON.stringify({
                uid: data?.uid,
                grade: data?.grade,
                name: data?.name,
                profile_url: data?.profile_url,
              })
            );
            localStorage.setItem("isInstructor", JSON.stringify(is_instructor));
            localStorage.setItem(
              "isExternalInstructor",
              JSON.stringify(is_external_instructor)
            );
            localStorage.setItem("isUserPro", JSON.stringify(tier !== "free"));
            console.log('setUser - ')
            setUser(data);
            setIsUserProTier(tier !== "free");
            setIsInstructor(is_instructor);
            setIsExternal(is_external_instructor);
            console.log('loadingWrapper() - ');
            // loadingWrapper();
            history.push("/");
          }, 500);

          return;
        }
      } else {
        setOtpError(true);
        setCheckingOtp(false);
        setOtpErrorMsg(errorCode);
      }
    } else {
      setOtpError(false);
    }
  };

  const setData = async (user, askForPhone) => {
    let data = {};
    // setAccountExists(false);

    console.log('data - ', data, user);

    if (user?.email) {
      let [
        name,
        profile_url,
        role,
        email,
        is_instructor,
        tutor_signup_ts,
        phone_number,
        has_rated_app,
        app_rating,
        pro_expiration_date,
        has_submitted_skills,
        tier,
        grade,
        phone_country_code,
        is_external_instructor,
        is_tutor_verified,
        is_deleted
      ] = await getUserInfoById(user?.uid);

      if(is_deleted) {
        return reset('User was deleted, Please contact PuStack support')
      }


      let time_now = false;

      if (!email || !phone_number) {
        time_now = await uploadUserInfo(
          user?.uid,
          user?.displayName,
          user?.email,
          user?.photoURL,
          false, // setting user as student
          phoneNumber?.slice(countryCode?.length + 1),
          countryCode
        );
        // setPhoneRoute(false)

        if (time_now) {
          data = {
            app_rating: null,
            app_rating_history: [],
            email: user?.email,
            grade: null,
            has_rated_app: false,
            is_instructor: false,
            is_external_instructor: true,
            name: user?.displayName,
            phone_country_code: askForPhone ? null : countryCode,
            phone_number: askForPhone ? null : phone_number,
            pro_expiration_date: null,
            profile_url: user?.photoURL,
            role: "External Instructor",
            tutor_signup_ts: time_now,
            tier: "free",
            uid: user?.uid,
          };

          setTempUserData({ ...data });
          setTempEmailUserData(user);
          setProcessStarted(false);

          setTimeout(() => {
            if(askForPhone) {
              setPhoneNumberError(null);
              setPhoneNumber('+91');
              setOtpCode(Array(6).fill(""));
              setOtpError(null);
              setOtpErrorMsg(null);
              setCountryCode('');
              // setAccountExists(true);
              return setActiveTab(1);
            }
            setActiveTab(activeTab + 1)
          }, 250);

          setSocialProcessStarted([false, false, false]);
        }
      } else {
        const data = {
          email: email,
          uid: user.uid,
          name: name,
          grade: grade,
          is_instructor: is_instructor,
          is_external_instructor: is_external_instructor,
          phone_country_code: phone_country_code,
          phone_number: phone_number,
          profile_url: profile_url,
          role: role,
          tutor_signup_ts: tutor_signup_ts,
          has_rated_app: has_rated_app ? has_rated_app : false,
          app_rating: app_rating ? app_rating : 0,
          pro_expiration_date: pro_expiration_date,
        };

        if(!data.name || !(data.name?.trim()?.length > 0)) {
          console.log('setAskForName - ');
          setAskForName(data.uid);
          return setActiveTab(3);
        }

        if (
          !is_tutor_verified || !is_instructor
        ) {
          if(!has_submitted_skills) {
            setTempUserData(() => {
              setActiveTab(3);
              return data;
            });
            return;
          }

          // setActiveTab(0);
          // setOtpCode(Array(6).fill(""));
          // setPhoneNumberError("Your verification is still in progress.");
          // setSocialProcessStarted([false, false, false]);
          // setTimeout(() => setPhoneNumberError(""), 6000);
          setActiveTab(4);
          return;
        }

        // if (!is_external_instructor) {
        //   setActiveTab(0);
        //   setOtpCode(Array(6).fill(""));
        //   setPhoneNumberError("This platform is only for tutors.");
        //   setSocialProcessStarted([false, false, false]);
        //   setTimeout(() => setPhoneNumberError(""), 6000);
        //   return;
        // }

        setTimeout(() => {
          localStorage.setItem(
            "user",
            JSON.stringify({
              uid: data?.uid,
              grade: data?.grade,
              name: data?.name,
              profile_url: data?.profile_url,
            })
          );
          localStorage.setItem("isInstructor", JSON.stringify(is_instructor));
          localStorage.setItem(
            "isExternalInstructor",
            JSON.stringify(is_external_instructor)
          );
          localStorage.setItem("isUserPro", JSON.stringify(tier !== "free"));
          console.log('setUser - ')
          setUser(data);
          setIsUserProTier(tier !== "free");
          setIsInstructor(is_instructor);
          setIsExternal(is_external_instructor);
          console.log('loadingWrapper() - ');
          // loadingWrapper();
          history.push("/");
        }, 2000);
      }
    } else {
      setEmailError("The facebook account doesn't have an email");
      setTimeout(() => {
        setEmailError(null);
        submit(
          facebookProvider,
          user,
          setPhoneNumberError,
          setSocialProcessStarted,
          setExistingAccountError,
          handleAuth,
          setData
        ).then();
      }, 2000);

      setSocialProcessStarted([false, false, false]);

      setTempEmailUserData(user);
    }
  };

  const submitGoogleEmailFn = () => {
    submitGoogleEmail({
      tempPhoneUserData,
      setActiveTab,
      countryCode,
      activeTab,
      setTempUserData,
      setPhoneNumberError,
      setSocialProcessStarted,
      setExistingAccountError,
      setProcessStarted,
    });
  };

  const handleAuth = useCallback(async (data) => {
    let _user = {
      ...data,
      grade: null,
      phone_number: phoneNumber.replace("+", "").slice(countryCode?.length),
      phone_country_code: countryCode,
      profile_url: data.profile_url && data.profile_url.trim().length > 0 ? data.profile_url : defaultPic,
      source: "web " + version,
    };
    setTempUserData(_user);

    await uploadFinalUserInfo(_user?.uid, _user);

    if(!data.name || !(data.name?.trim()?.length > 0)) {
      console.log('setAskForName - ');
      setAskForName(data.uid);
      return setActiveTab(3);
    }

    setActiveTab(3);
    // const _data = {
    //   email: data.email,
    //   uid: data.uid,
    //   name: data.name,
    //   grade: data.grade,
    //   is_instructor: false,
    //   is_external_instructor: true,
    //   profile_url: data.profile_url,
    //   role: data.role,
    //   tutor_signup_ts: data.tutor_signup_ts,
    //   has_rated_app: data.has_rated_app ? data.has_rated_app : false,
    //   app_rating: data.app_rating ? data.app_rating : 0,
    //   phone_number: phoneNumber.replace("+", "").slice(countryCode?.length),
    //   phone_country_code: countryCode,
    //   tier: data.tier
    // };
    //
    // setTimeout(() => {
    //   localStorage.setItem(
    //     "user",
    //     JSON.stringify({
    //       uid: _data?.uid,
    //       grade: _data?.grade,
    //       name: _data?.name,
    //       profile_url: _data?.profile_url,
    //     })
    //   );
    //   localStorage.setItem("isInstructor", JSON.stringify(_data.is_instructor));
    //   localStorage.setItem(
    //     "isExternalInstructor",
    //     JSON.stringify(_data.is_external_instructor)
    //   );
    //   localStorage.setItem("isUserPro", JSON.stringify(_data.tier !== "free"));
    //   setUser(_data);
    //   setIsUserProTier(_data.tier !== "free");
    //   setIsInstructor(_data.is_instructor);
    //   setIsExternal(_data.is_external_instructor);
    //   // loadingWrapper();
    //   // history.push("/");
    // }, 2000);

    setTimeout(() => setProcessStarted(false), 200);
  }, [phoneNumber, countryCode])

  const finalSubmission = async () => {
    if(!skillSubjects || !(skillSubjects.length > 0)) return;
    if (!finalSubmitting) {
      setFinalSubmitting(true);
      let _user = { uid: auth.currentUser.uid };
      // let updated = await uploadFinalUserInfo(_user?.uid, _user);

      // if (updated) {
      for (let i = 0; i < skillSubjects.length; i++) {
        const skill = skillSubjects[i];
        await addInstructorSkill({
          instructorId: _user?.uid,
          skillId: skill.id,
          skill,
        });
        let time_now = await castIndianTime();
        await db.doc('users/' + _user?.uid).set({has_submitted_skills: true, is_tutor_verified: false, tutor_signup_ts: +time_now}, {merge: true})
      }
      await postToWorkplace(_user?.uid);
      setFinalSubmitting(false);
      // setActivTab(4);
      // }
    }
  };

  useEffect(() => {
    if (existingAccountError) {
      setOtpCode(Array(6).fill(""));
      setOtpError(false);
      setOtpErrorMsg("");
      setPhoneNumberError("Some problem occured, please try again.");
      setSocialProcessStarted([false, false, false]);
      setTimeout(() => setPhoneNumberError(""), 6000);
    }
  }, [existingAccountError]);

  useEffect(() => {
    const container = document.getElementById("flyToMoonDiv");

    const inner = document.getElementById("flyToMoon");

    let counter = 0;
    const updateRate = 10;
    const isTimeToUpdate = () => counter++ % updateRate === 0;

    const update = function (event) {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / inner.offsetHeight / 2).toFixed(2),
        (mouse.x / inner.offsetWidth / 2).toFixed(2)
      );
    };

    const updateTransformStyle = function (x, y) {
      let style = `rotateX(${x * 50}deg) rotateY(${y * 30}deg)`;
      inner.style.transform = style;
      inner.style.mozTransform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };

    const onMouseEnterHandler = (event) => {
      update(event);
    };

    const onMouseLeaveHandler = () => {
      inner.style = "";
    };

    const onMouseMoveHandler = (event) => {
      if (isTimeToUpdate()) {
        update(event);
      }
    };

    container.onmouseenter = onMouseEnterHandler;
    container.onmouseleave = onMouseLeaveHandler;
    container.onmousemove = onMouseMoveHandler;

    // Mouse
    const mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function (event) {
        let e = event || window.event;
        this.x = e.clientX - this._x;
        this.y = (e.clientY - this._y) * -1;
      },
      setOrigin: function (e) {
        this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
        this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
      },
      show: function () {
        return "(" + this.x + ", " + this.y + ")";
      },
    };
    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);
  }, []);

  return (
    <div className="user-auth" id="flyToMoonDiv">
      <div className="user-auth-nav">
        <div>
          <img src={tutorLogo} alt="logo" draggable={false} />
        </div>

        <div className="student-login">
          <a href="https://www.pustack.com">Student Login</a>
        </div>
      </div>
      <div className="rocket-overlay" id="flyToMoon">
        <img alt="rocket" src={flyToMoon} draggable={false} />
      </div>

      <div className="landing-hero">
        {/*<img src={landingHero} alt="hero" draggable={false} />*/}

        <div
          className={pauseAnimate ? "hero-image fadeOut" : "hero-image"}
          key={`${pauseAnimate}`}
        >
          {carouselData.map(item => (
            <div key={item.id} className={"hero-image-div" + (" hero-image-" + item.id)}>
              <img src={item.image} alt="teacher1" />
              <div className="hero-image-text-container">
                <h1 style={{color: item.color}}>{item.heading}</h1>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
          {/*<div className="hero-image-div hero-image-1">*/}
          {/*  <img src={image1} alt="teacher1" />*/}
          {/*  <div className="hero-image-text-container">*/}
          {/*    <h1>Teach & Engage</h1>*/}
          {/*    <p>Engage with students and help them understand better.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="hero-image-div hero-image-2">*/}
          {/*  <img src={image2} alt="teacher2" />*/}
          {/*  <div className="hero-image-text-container">*/}
          {/*    <h1>Teach & Engage</h1>*/}
          {/*    <p>Engage with students and help them understand better.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="hero-image-div hero-image-3">*/}
          {/*  <img src={image3} alt="teacher3" />*/}
          {/*  <div className="hero-image-text-container">*/}
          {/*    <h1>Teach & Engage</h1>*/}
          {/*    <p>Engage with students and help them understand better.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="hero-image-div hero-image-4">*/}
          {/*  <img src={image4} alt="teacher4" />*/}
          {/*  <div className="hero-image-text-container">*/}
          {/*    <h1>Teach & Engage</h1>*/}
          {/*    <p>Engage with students and help them understand better.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<img src={image1} alt="teacher1" className="hero-image-1" />*/}
          {/*<img src={image2} alt="teacher2" className="hero-image-2" />*/}
          {/*<img src={image3} alt="teacher3" className="hero-image-3" />*/}
          {/*<img src={image4} alt="teacher4" className="hero-image-4" />*/}
        </div>

        <div className="hero-image">
          {increaseOpacity[0] ? (
            <img src={image1} alt="teacher2" className="show-img" />
          ) : (
            <img src={image1} alt="teacher2" className="fadeOut" />
          )}
          {increaseOpacity[2] ? (
            <img src={image2} alt="teacher3" className="show-img" />
          ) : (
            <img src={image2} alt="teacher3" className="fadeOut" />
          )}
          {increaseOpacity[1] ? (
            <img src={image3} alt="teacher4" className="show-img" />
          ) : (
            <img src={image3} alt="teacher4" className="fadeOut" />
          )}
        </div>

        {/*<div*/}
        {/*  className="teach-div"*/}
        {/*  onMouseEnter={() => {*/}
        {/*    setPauseAnimate(true);*/}
        {/*    setIncreaseOpacity([true, false, false]);*/}
        {/*  }}*/}
        {/*  onMouseLeave={() => {*/}
        {/*    setMouseHovering(false);*/}
        {/*    setPauseAnimate(false);*/}
        {/*    setIncreaseOpacity([false, false, false]);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className="increaseWidth teach"*/}
        {/*    key={pauseAnimate}*/}
        {/*    style={{ MozAnimationPlayState: pauseAnimate && "paused" }}*/}
        {/*  />*/}
        {/*  <div>*/}
        {/*    <img src={lappy} alt="lappy" draggable={false} />*/}
        {/*  </div>*/}
        {/*  <h4>Teach Students</h4>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  className="work-div"*/}
        {/*  onMouseEnter={() => {*/}
        {/*    setPauseAnimate(true);*/}
        {/*    setIncreaseOpacity([false, true, false]);*/}
        {/*  }}*/}
        {/*  onMouseLeave={() => {*/}
        {/*    setPauseAnimate(false);*/}
        {/*    setIncreaseOpacity([false, false, false]);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className="increaseWidth work"*/}
        {/*    key={pauseAnimate}*/}
        {/*    style={{ MozAnimationPlayState: pauseAnimate && "paused" }}*/}
        {/*  />*/}
        {/*  <div>*/}
        {/*    <img src={workTerms} alt="work-terms" draggable={false} />*/}
        {/*  </div>*/}
        {/*  <h4>Work on your own terms</h4>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  className="paid-div"*/}
        {/*  onMouseEnter={() => {*/}
        {/*    setPauseAnimate(true);*/}
        {/*    setIncreaseOpacity([false, false, true]);*/}
        {/*  }}*/}
        {/*  onMouseLeave={() => {*/}
        {/*    setPauseAnimate(false);*/}
        {/*    setIncreaseOpacity([false, false, false]);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className="increaseWidth paid"*/}
        {/*    key={pauseAnimate}*/}
        {/*    style={{ MozAnimationPlayState: pauseAnimate && "paused" }}*/}
        {/*  />*/}
        {/*  <div>*/}
        {/*    <img src={walletCoins} alt="coins" draggable={false} />*/}
        {/*  </div>*/}
        {/*  <h4>Get Paid Instantly</h4>*/}
        {/*</div>*/}
      </div>
      <div className={"user-auth-container" + (activeTab === 4 ? " final" : "")}>
        {
          activeTab !== 4 && <div className="user-auth-head">
            {activeTab > 0 && activeTab !== 3 && (
              <ArrowLeftIcon
                className="back-btn fadeIn"
                onClick={() => {

                  forceUpdate();
                  setOtpCode(Array(6).fill(""));
                  setOtpError(false);
                  setOtpErrorMsg("");
                  setSocialProcessStarted([false, false, false]);
                  setPhoneNumber(countryCode);
                  setTempEmailUserData(null);
                  setExistingPhone("");
                  setPhoneExists(false);
                  setSkillSubjects([]);
                  setSkillCategory("");
                  setCategorySubjectCount(null);
                  setGradeSubjectCount(null);

                  if (activeTab === 4 || activeTab == 3) {
                    return setActiveTab(0);
                  }

                  if (tempEmailUserData && activeTab > 1) {
                    tempEmailUserData.delete();
                    deleteUserFromDatabase(tempEmailUserData?.uid);
                  }
                  setTempEmailUserData(null);

                  tempPhoneUserData && tempPhoneUserData.delete();
                  setTempPhoneUserData(null);

                  if (activeTab === 2 || activeTab === 3) {
                    return setActiveTab(0);
                  }

                  setActiveTab(activeTab - 1);
                }}
              />
            )}{" "}
            <h1 key={activeTab} className="fadeIn">
              <span>{tabHeading[activeTab]}</span>
            </h1>
            {activeTab === 3 && skillSubjects?.length > 0 && (
              <button
                onClick={() => {
                  setSkillSubjects([]);
                  setGradeSubjectCount(null);
                  setCategorySubjectCount(null);
                  setSkillGrade("");
                  setSkillCategory("");
                }}
                className="fadeIn"
              >
                Reset
              </button>
            )}
          </div>
        }
        <SwipeableViews
          axis={"x"}
          index={activeTab}
          scrolling={"false"}
          ignoreNativeScroll={true}
          disabled={true}
          style={{height: '100%'}}
        >
          <div className="user-auth-inner">
            <div className="registration-page">
              <div className="phone-number">
                <div ref={recaptchaRef}>
                  <div id="recaptcha-container" className="recaptcha" />
                </div>

                <div className="avatar-rounded">
                  <PersonRoundedIcon />
                </div>

                <div className="phone-input">
                  <PhoneInput
                    country="in"
                    value={phoneNumber}
                    isValid={(_, country) => {
                      setCountryCode(country?.dialCode);
                      return true;
                    }}
                    onChange={(phone) => {
                      setPhoneNumber("+" + phone);
                      setPhoneNumberError("");
                    }}
                    placeholder="Phone Number"
                    preferredCountries={["us", "ae", "sg", "my", "in"]}
                    inputProps={{ autoFocus: true }}
                  />
                  {phoneNumber.length === 0 && <p>Phone Number</p>}
                </div>
                {phoneNumberError && (
                  <div className="phone-error-message">
                    <ErrorOutlineIcon />
                    {phoneNumberError}
                  </div>
                )}
                <button
                  id="sign-in-with-phone"
                  onClick={() => {
                    if (!sendingOtp) {
                      signInPhoneFn(1);
                    }
                  }}
                >
                  {sendingOtp ? (
                    <Lottie
                      options={{ animationData: circularProgress, loop: true }}
                    />
                  ) : (
                    "Send OTP"
                  )}
                </button>
              </div>

              <div className="agreements">
                <h6>Having trouble?</h6>
                <div>
                  <h5>
                    Reach us at{" "}
                    <a
                      href="mailto:help@pustack.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      help@pustack.com
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="user-auth-inner">
            <div className="otp-tab">
              <p className="sent-otp">
                Code is sent to{" "}
                {existingPhone.length > 6 &&
                  "X".repeat(existingPhone.length - 5) +
                  existingPhone.slice(
                    existingPhone.length - 4,
                    existingPhone.length
                  )}
              </p>
              <form>
                <div className={otpError ? "otp-input otp-error" : "otp-input"}>
                  {phoneExists && (
                    <h5 className="already-exists">Account already exists.</h5>
                  )}
                  <div>
                    <div key={activeTab}>
                      {activeTab === 1 &&
                        Array(6)
                          .fill(0)
                          .map((_, i) => (
                            <div key={i}>
                              <input
                                name={`input${i}`}
                                type={"tel"}
                                value={otpCode[i]}
                                maxLength={1}
                                autoComplete="off"
                                onChange={(e) => handleOTPChange(e, i)}
                                onKeyDown={(e) => handleKeyDown(e, i)}
                              />
                            </div>
                          ))}
                    </div>
                  </div>
                  {otpErrorMsg && <h6>
                    <WarningRoundedIcon/> {otpErrorMsg}
                  </h6>}
                </div>
                <div
                  className={resendCode ? "resend__code grey" : "resend__code"}
                >
                  {activeTab === 1 && (
                    <Timer
                      initialTime={30100}
                      direction="backward"
                      checkpoints={[
                        { time: 1500, callback: () => setPlural(false) },
                        { time: 1100, callback: () => setResendCode(false) },
                      ]}
                    >
                      {({ start, reset }) => (
                        <>
                          <h4
                            onClick={() => {
                              if (!resendCode) {
                                reset();
                                start();
                                forceUpdate();
                                setPlural(true);
                                signInPhoneFn(1);
                                setOtpError(false);
                                setOtpErrorMsg("");
                                setResendCode(true);
                                setOtpCode(Array(6).fill(""));
                              }
                            }}
                          >
                            Resend Code{resendCode ? "?" : ""}
                          </h4>
                          {resendCode ? (
                            <h6>
                              Tap resend in <Timer.Seconds />{" "}
                              {plural ? "seconds" : "second"}
                            </h6>
                          ) : (
                            <h6
                              onClick={() => {
                                if (!resendCode) {
                                  setPlural(true);
                                  reset();
                                  start();
                                  setResendCode(true);
                                  signInPhoneFn();
                                }
                              }}
                            >
                              Tap here
                            </h6>
                          )}
                        </>
                      )}
                    </Timer>
                  )}
                </div>
                <div className="continue-btn">
                  <ButtonBase onClick={submitOtp}>
                    {checkingOtp ? (
                      <Lottie
                        style={{height: '20px'}}
                        options={{
                          animationData: circularProgress,
                          loop: true,
                        }}
                      />
                    ) : (
                      "Verify OTP"
                    )}
                  </ButtonBase>
                </div>
              </form>
            </div>
          </div>
          <div className="user-auth-inner">
            <div className="avatar-rounded social-accounts">
              <div>
                <PersonRoundedIcon />
                <EmailRoundedIcon className="email-svg" />
              </div>
            </div>
            <div className="social-accounts">
              <button
                className="google"
                onClick={() => {
                  if (socialProcessStarted.every((k) => k === false)) {
                    setSocialProcessStarted([false, true, false]);
                    submit(
                      googleProvider,
                      tempPhoneUserData,
                      setEmailError,
                      setSocialProcessStarted,
                      setExistingAccountError,
                      handleAuth,
                      setData
                    ).then();
                    // submitGoogleEmail({
                    //   userData: tempPhoneUserData,
                    //   countryCode,
                    //   setActiveTab,
                    //   activeTab,
                    //   setTempUserData,
                    //   setPhoneNumberError,
                    //   setSocialProcessStarted,
                    //   setExistingAccountError,
                    //   setProcessStarted,
                    // });
                  } else {
                    setEmailError("One Process has already started");
                    setTimeout(() => setEmailError(""), 4000);
                  }
                }}
              >
                <img src={Google} draggable={false} alt="g" />
                <h6>
                  Continue with Google{" "}
                  {socialProcessStarted[1] && (
                    <Lottie
                      options={{
                        animationData: circularProgress,
                        loop: true,
                      }}
                    />
                  )}
                </h6>
              </button>
              {/*<button*/}
              {/*  className="facebook"*/}
              {/*  onClick={() => {*/}
              {/*    if (socialProcessStarted.every((item) => item === false)) {*/}
              {/*      setSocialProcessStarted([true, false, false]);*/}
              {/*      submit(*/}
              {/*        facebookProvider,*/}
              {/*        tempPhoneUserData,*/}
              {/*        setEmailError,*/}
              {/*        setSocialProcessStarted,*/}
              {/*        setExistingAccountError,*/}
              {/*        handleAuth,*/}
              {/*        setData*/}
              {/*      ).then();*/}
              {/*    } else {*/}
              {/*      setEmailError("One Process has already started");*/}
              {/*      setTimeout(() => setEmailError(""), 4000);*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <img src={Facebook} draggable={false} alt="fb" />*/}
              {/*  <h6>*/}
              {/*    Continue with Facebook*/}
              {/*    {socialProcessStarted[0] && (*/}
              {/*      <Lottie*/}
              {/*        options={{*/}
              {/*          animationData: circularProgress,*/}
              {/*          loop: true,*/}
              {/*        }}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  </h6>*/}
              {/*</button>*/}
              {isAppleDevice && <button
                className="apple"
                onClick={() => {
                  if (socialProcessStarted.every((item) => item === false)) {
                    setSocialProcessStarted([false, false, true]);
                    submit(
                      appleProvider,
                      tempPhoneUserData,
                      setEmailError,
                      setSocialProcessStarted,
                      setExistingAccountError,
                      handleAuth,
                      setData
                    ).then();
                  } else {
                    setEmailError("One Process has already started");
                    setTimeout(() => setEmailError(""), 4000);
                  }
                }}
              >
                <img src={Apple} draggable={false} alt="fb" />
                <h6>
                  Continue with Apple
                  {socialProcessStarted[2] && (
                    <Lottie
                      options={{
                        animationData: circularProgress,
                        loop: true,
                      }}
                    />
                  )}
                </h6>
              </button>}
            </div>
            {emailError && (
              <div className="error-message fadeIn">{emailError}</div>
            )}
            <div className="social-agreements">
              <h6>By continuing, you agree to our</h6>
              <div>
                <h5
                  onClick={() => {
                    setDocumentToShow(termsOfService);
                    setShowPDF(true);
                  }}
                >
                  Terms of Service
                </h5>{" "}
                <h5
                  onClick={() => {
                    setDocumentToShow(privacyPolicy);
                    setShowPDF(true);
                  }}
                >
                  Privacy Policy
                </h5>
              </div>
            </div>
          </div>
          {askForName ? <div className="user-auth-inner">
            {/*<h1>*/}
            {/*  <span>My Full Name is</span>*/}
            {/*</h1>*/}
            {/*<div className="avatar-rounded social-accounts">*/}
            {/*  <div>*/}
            {/*    <PersonRoundedIcon />*/}
            {/*    <EmailRoundedIcon className="email-svg" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <input onChange={e => setFullName(e.target.value)} value={fullName} className="enter-full-name" type="text" autoComplete={"off"} name="fullName"/>
            <button
              onClick={async () => {
                if(submittingName || !askForName || !isNameValid(fullName)) return;
                setIsSubmittingName(true);
                await db.collection('users')
                  .doc(askForName)
                  .set({
                    name: fullName.trim()
                  }, {merge: true});

                console.log('setAskForName - ');
                setAskForName(false);
              }}
              className={!isNameValid(fullName) ? 'disabled' : ""}
              disabled={!isNameValid(fullName)}
            >
              {submittingName ? (
                <Lottie
                  options={{ animationData: circularProgress, loop: true }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div> :
          <div className="skills-modal-inner user-auth-inner">
            <div className="skills-modal-inner-group">
              <div className="grades-list-wrapper">
                <h1>Select Grade</h1>
                <div className="grades-list">
                  {allSkills ? (
                    gradesList.map((item) => (
                      <ButtonBase
                        key={item.value}
                        onClick={() => {
                          setSkillGrade(item.value);
                          item.value !== skillGrade && setSkillCategory("");
                        }}
                        className={
                          item.value === skillGrade ? "chip selected" : "chip"
                        }
                      >
                        {item.name}{" "}
                        {gradeSubjectCount &&
                          gradeSubjectCount?.[item.value] > 0 &&
                          `• ${gradeSubjectCount?.[item.value]}`}
                      </ButtonBase>
                    ))
                  ) : (
                    <div>
                      <ButtonBase disableRipple className="chip-loading">
                        <h1></h1>
                      </ButtonBase>
                      <ButtonBase disableRipple className="chip-loading">
                        <h1></h1>
                      </ButtonBase>
                    </div>
                  )}
                </div>
              </div>
              {skillGrade && (
                <div className="categories-list-wrapper fadeIn">
                  <h1>Select Category</h1>
                  <div className="categories-list">
                    {allSkills?.[skillGrade]?.categories.map((item, i) => (
                      <ButtonBase
                        onClick={() => setSkillCategory(item)}
                        className={
                          item === skillCategory ? "chip selected" : "chip"
                        }
                        key={i}
                      >
                        {item}{" "}
                        {categorySubjectCount &&
                          categorySubjectCount[`${skillGrade}_${item}`] > 0 &&
                          `• ${categorySubjectCount[`${skillGrade}_${item}`]}`}
                      </ButtonBase>
                    ))}
                  </div>
                </div>
              )}
              {skillCategory &&
                (!allSkills[skillGrade]?.skip_subjects[
                    categoryIdx[skillCategory]
                    ] ||
                  skillCategory === "Maths") && (
                  <div className="subjects-list-wrapper">
                    <h1>Select Subjects</h1>
                    <div className="subjects-list fadeIn">
                      <FormGroup>
                        {allSkills[skillGrade].subjects[skillCategory].map(
                          (item) => (
                            <FormControlLabel
                              checked={
                                skillSubjects?.filter(
                                  (category) =>
                                    category?.id ===
                                    getSubjectId(item, skillGrade)
                                )[0]?.id === getSubjectId(item, skillGrade)
                              }
                              control={
                                <Checkbox
                                  onChange={(e) => selectSubject(e, item)}
                                  color="primary"
                                  key={item + skillGrade}
                                />
                              }
                              label={item}
                              key={item + skillGrade}
                            />
                          )
                        )}
                      </FormGroup>
                    </div>
                  </div>
                )}
            </div>
            <div className="continue-btn">
              <p className="skill-condition">*Atleast 1 skill is required</p>
              <ButtonBase onClick={finalSubmission} disabled={isBtnDisabled}>
                {finalSubmitting ? (
                  <Lottie
                    style={{height: "20px"}}
                    options={{
                      animationData: circularProgress,
                      loop: true,
                    }}
                  />
                ) : (
                  "Submit Skills"
                )}
              </ButtonBase>
            </div>
          </div> }
          <div className="user-auth-inner final-tab">
            <h1>
              <span>Congratulations!</span>
              <ExitToApp style={{cursor: "pointer"}} onClick={() => {
                forceUpdate();
                setOtpCode(Array(6).fill(""));
                setOtpError(false);
                setOtpErrorMsg("");
                setSocialProcessStarted([false, false, false]);
                setPhoneNumber(countryCode);
                setTempEmailUserData(null);
                setExistingPhone("");
                setPhoneExists(false);
                setSkillSubjects([]);
                setSkillCategory("");
                setCategorySubjectCount(null);
                setGradeSubjectCount(null);
                setActiveTab(0);
              }} />
            </h1>
            <p>Our verification agent will contact you soon.</p>
            <div className="image">
              <img src={woman} alt=""/>
            </div>
            <div className="image-placeholder">
              <svg width="428" height="83" viewBox="0 0 428 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.5 19C260 -25.5 93.6667 21.8333 0 46.5V83C106 51.4 281.5 34.5 427.5 83V19Z" fill="#7C0709"/>
              </svg>
            </div>
            <div className="bottom-part">
              {videoList && <>
                <div className={"navigate-icon left" + (activeVideoTab === 0 ? ' disabled' : '')} onClick={() => {
                  setActiveVideoTab(c => {
                    if (c === 0) return c;
                    return c - 1;
                  });
                }}>
                  <ArrowLeftIcon/>
                </div>
                <div className={"navigate-icon right" + (activeVideoTab === videoList.length - 1 ? ' disabled' : '')} onClick={() => {
                  setActiveVideoTab(c => {
                  if(c === videoList.length - 1) return c;
                  return c + 1;
                });
                }}>
                  <ArrowRightIcon />
                </div>
              </>}
              <SwipeableViews
                axis={"x"}
                scrolling={"true"}
                index={activeVideoTab}
                onChangeIndex={(e) => setActiveVideoTab(e)}
                style={{marginTop: '10px'}}
              >
                {!videoList ? <div className="recommended-video">
                    <div className="shine"/>
                  </div> :
                  videoList.map((item, index) => (
                    <VimeoPlayer item={item} index={index} isVisible={activeVideoTab === index} setEnableSwitch={setEnableSwitch} />
                  ))}
              </SwipeableViews>

              <div className="bottom">
                <img src={whiteLogo} alt=""/>
                <div className="bottom-group">
                  <span onClick={() => {
                    setDocumentToShow(termsOfService);
                    setShowPDF(true);
                  }}>Terms of Service</span>
                  <div className="separator" />
                  <span onClick={() => {
                    setDocumentToShow(privacyPolicy);
                    setShowPDF(true);
                  }}>Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </SwipeableViews>

        <Dialog
          open={showPDF}
          onClose={() => {
            setShowPDF(false);
          }}
        >
          {showPDF && (
            <PdfPreview
              pdf={documentToShow}
              onClose={() => {
                setShowPDF(false);
              }}
            />
          )}
        </Dialog>
      </div>
      <PustackFooter />
    </div>
  );
};

export default UserAuth;
