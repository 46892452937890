import React, {useContext, useEffect, useRef, useState} from 'react';
import SwipeableViews from "react-swipeable-views";
import ProfileScreen from "./ProfileScreen";
import {Drawer} from "@material-ui/core";
import {UserContext, ThemeContext, NavbarContextProvider} from "../context";
import {useMediaQuery} from "react-responsive";
import {Helmet} from "react-helmet";
import {Navbar} from "../containers";

export default function TutorConnectDrawer() {
  const [profileUser] = useContext(UserContext).profileUser;
  const [isDark] = useContext(ThemeContext).theme;
  const [activeMainTab, setActiveMainTab] = useContext(UserContext).activeTab;
  const [showDrawer, setShowDrawer] = useContext(UserContext).showDrawer;
  const activeUsersScreen = useContext(UserContext).activeUsersScreen;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    if(isSmallScreen && !showDrawer) setShowDrawer(activeUsersScreen);
  }, [isSmallScreen, showDrawer])

  const views = (
    <SwipeableViews
      axis="x"
      index={activeMainTab}
      containerStyle={{
        transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
      }}
      onChangeIndex={(e) => setActiveMainTab(e)}
      scrolling={"false"}
      ignoreNativeScroll={true}
      disabled={true}
      style={{height: '100%'}}
      className="review-screen"
    >
      {showDrawer}
      {profileUser ? <ProfileScreen handleBackButton={() => {
        if(showDrawer) {
          setActiveMainTab(0);
        } else {
          setActiveMainTab(0);
          setShowDrawer(null);
        }
      }} /> : null}
      {/*{showDrawer}*/}
      {/*{formUser ? <FormScreen /> : <ProfileScreen handleBackButton={() => {*/}
      {/*  if(showDrawer) {*/}
      {/*    setActiveTab(0);*/}
      {/*  } else {*/}
      {/*    setActiveTab(0);*/}
      {/*    setShowDrawer(null);*/}
      {/*  }*/}
      {/*}} />}*/}
      {/*<ProfileScreen />*/}
    </SwipeableViews>
  )

  return isSmallScreen ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blaze</title>
      </Helmet>
      <NavbarContextProvider>
        <Navbar setMobileOpen={() => {}} showMenuItem={true} onlyBottom={true} />
      </NavbarContextProvider>
      {views}
    </>
  ) : (
    <Drawer
      variant="temporary"
      open={!!showDrawer}
      // open={true}
      // anchor={isSmallScreen ? (isiPad ? "right" : "bottom") : "right"}
      anchor={"right"}
      // disableBackdropClick={true}
      // disableEscapeKeyDown={true}
      onClose={() => {setShowDrawer(null)}}
      ModalProps={{ keepMounted: true }}
      PaperProps={{className: isSmallScreen ? 'w-full h-screen py-6 pb-0' : 'w-96 h-screen py-6', style: {
          backgroundColor: isDark ? '#18181b' : 'rgb(246 246 246)'
        }}}
      // BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.75)" } }}
    >
      {views}
    </Drawer>
  )
}
