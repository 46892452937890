import React, { useState, useEffect, createContext } from "react";

export const ThemeContext = createContext();

export const ThemeContextProvider = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    localStorage.setItem("pustack-dark-theme", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  useEffect(() => {
    isDarkMode ? document.body.classList.add('dark') : document.body.classList.remove('dark')
    isDarkMode ? window.localStorage.setItem('dark', 'true') : window.localStorage.setItem('dark', 'false');
  }, [isDarkMode])

  return (
    <ThemeContext.Provider value={{ theme: [isDarkMode, setIsDarkMode], isDark: [isDarkMode, setIsDarkMode] }}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
