import { z } from "zod";
import {
  fetchLectureHeaderItem,
  fetchLectureItem,
  getChapterLastEngagementData,
} from "../../database";
const lastActivityMapSchema = z.object({
  item_id: z.string().nullable(),
  header_item_id: z.string().nullable(),
  lecture_item_index: z.number(),
  lecture_header_item_index: z.number(),
  tab_index: z.number(),
});
const lectureItemData = z.object({
  id: z.string(),
  lecture_item_name: z.string(),
  lecture_item_type: z.string().nullable(),
  lecture_item_id: z.string().optional(),
  grade_id: z.string(),
  category_id: z.string(),
  chapter_id: z.string(),
  subject_id: z.string(),
  tier: z.string(),
  serial_order: z.number(),
  category_name: z.string(),
  chapter_name: z.string(),
  youtube_url: z.string(),
  notes_link: z.string().nullable(),
  image_content: z.string().nullable(),
  generic_name: z.string(),
  tab_id: z.string(),
  lecture_header_item_name: z.string().nullable(),
  lecture_header_item_type: z.string().nullable(),
  tag_images: z.string().nullable(),
});
const formedLectureItemData = z.object({
  type: z.string(),
  category: z.string(),
  chapter: z.string(),
  youtube: z.string().nullable(),
  notes: z.string().nullable(),
  item: lectureItemData,
});
const lectureHeaderItemData = z.object({
  lecture_header_item_id: z.string(),
  lecture_header_item_name: z.string(),
  lecture_header_item_type: z.string(),
});
const minifiedLectureHeaderItemData = z.object({
  lecture_header_item_id: z.string(),
  lecture_header_item_name: z.string(),
  lecture_header_item_type: z.string(),
});
const minifiedLectureItemData = z.object({
  lecture_item_id: z.string(),
  lecture_item_name: z.string(),
  lecture_item_type: z.string(),
  tier: z.string(),
  serial_order: z.number(),
  lecture_header_items: z.array(minifiedLectureHeaderItemData),
});
const tabsDataSchema = z.array(
  z.object({
    generic_name: z.string().nullable().optional(),
    tab_id: z.string(),
    tab_name: z.string(),
    lecture_items: z.array(minifiedLectureItemData),
    tab_image_url: z.string().nullable().optional(),
  })
);
/**
 * ClassroomState is a class that manages the state of the classroom
 * It tracks user information, chapter and subject details, and lecture-related data.
 */
export class ClassroomState {
  // Getter method to access the activeTabIndex property from outside the class.
  get activeTabIndex() {
    return this._activeTabIndex;
  }
  // Getter method to check if engagement data is being used.
  get isUsingEngagement() {
    return this._isUsingEngagement;
  }
  /**
   * Constructor for the ClassroomState class.
   * @param {Object} params - An object containing user, chapter, and subject information.
   */
  constructor({ userId, chapterId, subjectId, grade }) {
    this.userId = userId;
    this.chapterId = chapterId;
    this.subjectId = subjectId;
    this.grade = grade;
  }
  /**
   * Update the tabs data with the provided data after validation.
   * @param {TabsData} tabsData - The data containing lecture tabs.
   */
  updateTabsData(tabsData) {
    const _tabsData = tabsDataSchema.safeParse(tabsData);
    if (!_tabsData.success) {
      // Log a warning if the provided tabs data is invalid.
      // @ts-ignore
      console.warn("Invalid tabs data", _tabsData.error);
      return;
    }
    // Update the internal _tabsData property with the validated data.
    this._tabsData = _tabsData.data;
  }
  /**
   * Prepare an active lecture item for use based on provided IDs.
   * @param {string} lectureItemId - The ID of the lecture item.
   * @param {string | undefined} lectureHeaderItemId - The ID of the lecture header item (if available).
   * @returns {ActiveItem} - The prepared active lecture item.
   */
  prepareActiveItem(lectureItemId, lectureHeaderItemId) {
    this._activeItem = {
      item:
        lectureHeaderItemId !== null && lectureHeaderItemId !== void 0
          ? lectureHeaderItemId
          : lectureItemId,
      parent: lectureHeaderItemId ? lectureItemId : null,
    };
    return this._activeItem;
  }
  /**
   * Retrieve the last user engagement data for the classroom.
   * @returns {Promise<ActiveItem | null>} - The last active item or null if no data is available.
   */
  async getUserLastEngagement() {
    var _a;
    // Fetch the last user engagement data for the classroom.
    const data = await getChapterLastEngagementData({
      userId: this.userId,
      chapter_id: this.chapterId,
      grade: this.grade,
    });
    if (
      !data ||
      !(data === null || data === void 0 ? void 0 : data.last_activity_map)
    ) {
      return null;
    }
    // Parse and validate the last activity map data.
    const validatedActivityMap = lastActivityMapSchema.safeParse(
      data.last_activity_map
    );
    // Log a warning if the data is invalid.
    console.warn("Invalid last activity map", data.last_activity_map);
    if (!validatedActivityMap.success) {
      // @ts-ignore
      console.warn(
        "Invalid last activity map",
        data.last_activity_map,
        validatedActivityMap.error
      );
      return null;
    }
    // Update the active tab index and return the prepared active item.
    this._activeTabIndex = validatedActivityMap.data.tab_index;
    return this.prepareActiveItem(
      (_a = validatedActivityMap.data.item_id) !== null && _a !== void 0
        ? _a
        : validatedActivityMap.data.header_item_id,
      validatedActivityMap.data.item_id
        ? validatedActivityMap.data.header_item_id
        : null
    );
  }
  /**
   * Get the tab index by a provided lecture item ID.
   * @param {string} lectureItemId - The ID of the lecture item to find.
   * @returns {number} - The tab index or -1 if not found.
   */
  getTabIndexByLectureItemId(lectureItemId) {
    const tabIndex = this._tabsData.findIndex((tab) =>
      tab.lecture_items.some((item) => item.lecture_item_id === lectureItemId)
    );
    if (tabIndex === -1) {
      // Log a warning if no tab is found for the provided lecture item ID.
      console.warn("No tab found for lecture item id - ", lectureItemId);
    }
    return tabIndex;
  }
  /**
   * Set the active lecture item based on provided IDs.
   * @param {string | undefined} lectureItemId - The ID of the lecture item.
   * @param {string | undefined} lectureHeaderItemId - The ID of the lecture header item.
   * @returns {ActiveItem | null} - The active item or null if not found.
   */
  setLectureItemByIds(lectureItemId, lectureHeaderItemId) {
    // Get the tab index based on the provided lecture item ID.
    this._activeTabIndex = this.getTabIndexByLectureItemId(lectureItemId);
    // Retrieve the lecture data for the active tab.
    this._tabLectures = this._tabsData[this._activeTabIndex];
    if (this._activeTabIndex === -1) {
      // Return null if the active tab index is not found.
      return null;
    }
    // Indicate that engagement data is not being used, and return the prepared active item.
    this._isUsingEngagement = false;
    return this.prepareActiveItem(lectureItemId, lectureHeaderItemId);
  }
  /**
   * Get details of the current lecture item.
   * @returns {ReturnedLectureItem} - Details of the current lecture item.
   */
  getCurrentLectureItemDetails() {
    return {
      activeItem: this._activeItem,
      lectureItem: this._lectureItem,
      nextItem: this._nextItem,
      youtubeId: this._youtubeId,
      notesLink: this._notesLink,
      chapterName: this._chapterName,
      activeTabIndex: this._activeTabIndex,
      activeTabId: this._tabLectures.tab_id,
    };
  }
  /**
   * Set the default lecture item for the classroom.
   * @returns {Promise<ActiveItem>} - The active item, potentially from user engagement data.
   */
  async setDefaultLectureItem() {
    // Attempt to retrieve the last user engagement data.
    const engagementActiveItem = await this.getUserLastEngagement();
    if (!engagementActiveItem) {
      // If no engagement data is available, set the first lecture item as the default.
      return this.setFirstLectureItem();
    }
    // Indicate that engagement data is being used, and return the engagement active item.
    this._isUsingEngagement = true;
    return engagementActiveItem;
  }
  /**
   * Set the first lecture item as the default for the classroom.
   * @returns {ActiveItem} - The active item representing the first lecture item.
   */
  setFirstLectureItem() {
    // Set the active tab index to 0, indicating the first tab.
    this._activeTabIndex = 0;
    // Retrieve the lecture data for the first tab.
    this._tabLectures = this._tabsData[0];
    // Find the first lecture item and its associated header item.
    const lectureItem = this._tabLectures.lecture_items[0];
    const lectureHeaderItem =
      lectureItem === null || lectureItem === void 0
        ? void 0
        : lectureItem.lecture_header_items[0];
    if (
      !(lectureItem === null || lectureItem === void 0
        ? void 0
        : lectureItem.lecture_item_id)
    ) {
      // Log an error if no lecture item is found.
      console.error("No lecture item found - ", lectureItem);
      return null;
    }
    // Indicate that engagement data is not being used, and return the prepared active item.
    this._isUsingEngagement = false;
    return this.prepareActiveItem(
      lectureItem === null || lectureItem === void 0
        ? void 0
        : lectureItem.lecture_item_id,
      lectureHeaderItem === null || lectureHeaderItem === void 0
        ? void 0
        : lectureHeaderItem.lecture_header_item_id
    );
  }
  /**
   * Update tab details based on a provided item ID.
   * @param {string} itemId - The ID of the lecture item for which to update tab details.
   */
  updateTabDetailsByItemId(itemId) {
    // Get the tab index based on the provided lecture item ID.
    this._activeTabIndex = this.getTabIndexByLectureItemId(itemId);
    // Retrieve the lecture data for the active tab.
    this._tabLectures = this._tabsData[this._activeTabIndex];
    if (!this._tabLectures) {
      // Throw an error if no tab is found for the provided item ID.
      throw new Error("No tab found for item id - " + itemId);
    }
  }
  /**
   * Fetch details of a lecture item using its ID and parent ID.
   * @param {ActiveItem} activeItem - The active item representing the lecture item.
   * @returns {Promise<FormedLectureItemData>} - Details of the formed lecture item.
   */
  fetchLectureItem(activeItem) {
    if (activeItem.parent) {
      // If the lecture item has a parent, update tab details and fetch the lecture header item.
      this.updateTabDetailsByItemId(activeItem.parent);
      const tabId = this._tabLectures.tab_id;
      return fetchLectureHeaderItem({
        grade: this.grade,
        lecture_id: activeItem.item,
        parent_id: activeItem.parent,
        chapter: this.chapterId,
        tab_id: tabId,
      });
    }
    // If the lecture item doesn't have a parent, update tab details and fetch the lecture item.
    this.updateTabDetailsByItemId(activeItem.item);
    const tabId = this._tabLectures.tab_id;
    return fetchLectureItem({
      grade: this.grade,
      lecture_id: activeItem.item,
      chapter: this.chapterId,
      tab_id: tabId,
    });
  }
  /**
   * Populate details of the active lecture item based on the provided active item.
   * @param {ActiveItem} activeItem - The active item representing the lecture item.
   */
  async populateActiveItem(activeItem) {
    // Update the active item.
    this._activeItem = activeItem;
    // Fetch details of the lecture item using the active item.
    const formedLectureItem = await this.fetchLectureItem(activeItem);
    // Parse and validate the fetched lecture item data.
    const validatedLectureItem =
      formedLectureItemData.safeParse(formedLectureItem);
    if (!validatedLectureItem.success) {
      // Log a warning if the data is invalid.
      // @ts-ignore
      console.warn(
        "Invalid lecture item data",
        formedLectureItem,
        validatedLectureItem.error
      );
      return;
    }
    // Extract and update lecture item, YouTube ID, notes link, and chapter name from the data.
    const data = validatedLectureItem.data;
    this._lectureItem = data.item;
    if (data.type === "video") {
      this._youtubeId = data.youtube;
    } else {
      this._youtubeId = null;
    }
    if (data.notes) {
      this._notesLink = data.notes;
    } else {
      this._notesLink = null;
    }
    this._chapterName = data.chapter;
  }
  prepareNextItem(lectureItem, lectureHeaderItem) {
    if (lectureHeaderItem) {
      return {
        childName: lectureHeaderItem.lecture_header_item_name,
        parentName: lectureItem.lecture_item_name,
        item: lectureHeaderItem.lecture_header_item_id,
        parent: lectureItem.lecture_item_id,
        tabIndex: this._activeTabIndex,
        lectureType: lectureHeaderItem.lecture_header_item_type,
      };
    }
    return {
      childName: lectureItem.lecture_item_name,
      parentName: null,
      item: lectureItem.lecture_item_id,
      parent: null,
      tabIndex: this._activeTabIndex,
      lectureType: lectureItem.lecture_item_type,
    };
  }
  getNextItemUsingLecture(nextLecture) {
    // Check if nextLecture exists;
    if (!nextLecture) return null;
    // if the next lecture has lecture header items then set the first lecture header item as the next item
    if (nextLecture.lecture_header_items.length > 0) {
      const headerLecture = nextLecture.lecture_header_items[0];
      return this.prepareNextItem(nextLecture, headerLecture);
    }
    // else set the next lecture as the next item
    return this.prepareNextItem(nextLecture, null);
  }
  getNextLectureItem() {
    // Check if the item is at root level
    const isItemAtRoot = this._activeItem.parent === null;
    // If item is at root level, check for the next item in the tab using the serial order attach to the current lecture_item
    if (isItemAtRoot) {
      const nextLecture = this._tabLectures.lecture_items.find(
        (item) => item.serial_order === this._lectureItem.serial_order + 1
      );
      return this.getNextItemUsingLecture(nextLecture);
    }
    // If item is not at root level, check for the next item in the lecture_header_item using the serial order attach to the current lecture_header_item
    const currentLectureItem = this._tabLectures.lecture_items.find(
      (item) => item.lecture_item_id === this._activeItem.parent
    );
    const currentLectureIndex =
      currentLectureItem.lecture_header_items.findIndex(
        (item) => item.lecture_header_item_id === this._activeItem.item
      );
    // Check if the item is not found in the lecture_header_item
    if (currentLectureIndex === -1) {
      return null;
    }
    // Check if the item is last in the lecture_header_item
    const isLastItem =
      currentLectureIndex ===
      currentLectureItem.lecture_header_items.length - 1;
    // If it's not the last item then return the next item
    if (!isLastItem) {
      const nextLecture = currentLectureItem;
      const headerLecture =
        nextLecture.lecture_header_items[currentLectureIndex + 1];
      return this.prepareNextItem(nextLecture, headerLecture);
    }
    // If it's the last item then check for the next lecture item in the tab
    const nextLecture = this._tabLectures.lecture_items.find(
      (item) => item.serial_order === currentLectureItem.serial_order + 1
    );
    return this.getNextItemUsingLecture(nextLecture);
  }
  populateNextLectureItem() {
    this._nextItem = this.getNextLectureItem();
  }
}
