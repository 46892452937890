import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import firebase from "firebase/app";
import Lottie from "lottie-react-web";
import Timer from "react-compound-timer";
import { UserContext } from "../../../context";
import PhoneInput from "react-phone-input-2";
import SwipeableViews from "react-swipeable-views";
import {version} from '../../../../package.json';
import PdfPreview from "../../../components/newsfeed/pdf-preview";
import {appleProvider, auth, db, facebookProvider, firebaseApp, googleProvider} from "../../../firebase_config";
import "./style.scss";
import image1 from '../../../assets/images/auth/get_paid.svg';
import image2 from '../../../assets/images/auth/enjoy_freedom.svg';
import image3 from '../../../assets/images/auth/teach_and_engage.svg';
import image4 from '../../../assets/images/auth/track_and_improve.svg';
import logo from '../../../assets/images/auth/pustack_tutor_color_logo.png';
import whiteLogo from '../../../assets/images/auth/pustack_tutor_white_logo.png';
import { autoPlay } from 'react-swipeable-views-utils';
import rocketLaunch from '../../../assets/images/auth/rocket-launch.svg';
import vector from '../../../assets/images/auth/Vector 1.svg';
import woman from '../../../assets/images/auth/woman.svg';
import circularProgress from "../../../assets/lottie/circularProgress.json";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import {ButtonBase, Dialog} from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import {postToWorkplace} from "../../../database/blazeExternal";
import {
  castIndianTime,
  getYoutubeID,
  isNameValid,
  loadingWrapper,
  privacyPolicy,
  termsOfService
} from "../../../helpers";
import YoutubeEmbed from "../../../components/doubts_forum/youtube-embed";
import {
  Google,
  Facebook,
  landingHero,
  lappy,
  walletCoins,
  workTerms,
  teacher1,
  teacher2,
  teacher3,
  teacher4, Apple,
} from "../../../assets";
import {
  signInWithPhone, submit,
  submitFacebook,
  submitGoogleEmail,
  submitPhoneNumberAuthCode,
} from "../../../services/auth";
import {
  addInstructorSkill,
  deleteUserFromDatabase,
  getOverallSkills,
  getUserInfoById,
  uploadFinalUserInfo, uploadUserInfo,
} from "../../../database";
import {useHistory} from "react-router-dom";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Vimeo from "@u-wave/react-vimeo";
import {getVimeoURL} from "../../../helpers/global";
import VimeoPlayer from "../../../components/global/vimeo-player";
import useAppleDevice from "../../../hooks/isAppleDevice";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const carouselData = [
  {id: 1, heading: 'Teach & Engage', color: '#c53f3f', description: 'Engage with students and help them understand better.', image: image3},
  {id: 2, heading: 'Enjoy the freedom', color: '#8ecd69', description: 'Teach the subjects you are awesome at and at your own time.', image: image2},
  {id: 3, heading: 'Track & Improve', color: '#ff725e', description: 'Our tools will help you to be ever more awesome! Engage more, Earn more!', image: image4},
  {id: 4, heading: 'Get Paid', color: '#90caf9', description: 'Your earning, your rules. Tap anytime to transfer the sum to your bank account.', image: image1},
]

const viewsArr = ['engage', 'freedom', 'track', 'paid'];

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value); // update the state to force render
};

export default function MobileUserAuth() {
  const [activeTab, setActiveTab] = useState(0);
  const [activeViewTab, setActiveViewTab] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [openPhone, setOpenPhone] = useState(false);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const otpInputRef = useRef();
  const isAppleDevice = useAppleDevice();

  const [otpCode, setOtpCode] = useState([]);
  const [activeVideoTab, _setActiveVideoTab] = useState(0);
  const [showPDF, setShowPDF] = useState(false);
  const [spaceKey, setSpaceKey] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [skillGrade, setSkillGrade] = useState("");
  const [askForName, setAskForName] = useState(false);
  const [fullName, setFullName] = useState('');
  const [submittingName, setIsSubmittingName] = useState(false);
  const [allSkills, setAllSkills] = useState(null);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [phoneExists, setPhoneExists] = useState(false);
  const [gmailExists, setGmailExists] = useState(false);
  const [skillSubjects, setSkillSubjects] = useState([]);
  const [existingPhone, setExistingPhone] = useState("");
  const [skillCategory, setSkillCategory] = useState("");
  const [documentToShow, setDocumentToShow] = useState("");
  const [processStarted, setProcessStarted] = useState(false);
  const [gradeSubjectCount, setGradeSubjectCount] = useState(null);
  const [categorySubjectCount, setCategorySubjectCount] = useState(null);
  const [socialProcessStarted, setSocialProcessStarted] = useState([
    false,
    false,
    false,
  ]);
  const [plural, setPlural] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [resendCode, setResendCode] = useState(true);
  const [checkingOtp, setCheckingOtp] = useState(false);
  const [tempUserData, setTempUserData] = useState(null);
  const [googleMailAddress, setGoogleMailAddress] = useState("");
  const [tempEmailUserData, setTempEmailUserData] = useState(null);
  const [tempPhoneUserData, setTempPhoneUserData] = useState(null);
  const [fbPendingCredentials, setFbPendingCredentials] = useState("");
  const [existingAccountError, setExistingAccountError] = useState(null);

  const [finalSubmitting, setFinalSubmitting] = useState(false);
  let recaptchaRef = useRef();

  // const setUser = () => {};
  // const setIsUserProTier = () => {};
  // const setIsInstructor = () => {};
  // const setIsExternal = () => {};
  const [, setUser] = useContext(UserContext).user;
  const [, setIsUserProTier] = useContext(UserContext).tier;
  const [, setIsInstructor] = useContext(UserContext).isInstructor;
  const [, setIsExternal] = useContext(UserContext).isExternal;
  const [isVerified, setIsVerified] = useState(false);
  const [videoList, setVideoList] = useState(null);
  const [enableSwitch, setEnableSwitch] = useState(true);


  const setActiveVideoTab = (val) => {
    if(!enableSwitch) return;
    _setActiveVideoTab(val);
  }

  const reset = (message = "") => {
    setOtpCode(Array(6).fill(""));
    setPhoneNumberError(message);
    setPhoneNumber("+91");
    setSocialProcessStarted(false, false, false);
    setOtpError(false);
    setSendingOtp(false);
    setCheckingOtp(false);
    setTempUserData(null);
    setTempEmailUserData(null);
    setTempPhoneUserData(null);
    setEmailError(null);
    setActiveTab(0);
  }

  const categoryIdx = {
    Maths: 0,
    Science: 1,
    SST: 2,
    English: 3,
    General: 4,
  };

  useEffect(() => {
    if(askForName) return;
    if(activeTab === 3) {
      const _user = auth.currentUser;
      db.collection('blaze_dev')
        .doc('collections')
        .collection('instructors')
        .doc(_user.uid)
        .set({
          "active_call_id": null,
          "active_call_session_id": null,
          "call_state": null,
          "is_engaged": false
        }, {merge: true})
        .catch(console.log)

      db.collection('users')
        .doc(_user.uid)
        .set({
          is_external_instructor: true
        }, {merge: true})
        .catch(console.log)
    }
  }, [activeTab, askForName])

  useEffect(() => {
    if(!videoList && activeTab === 4) {
      db.collection('admin_videos')
        .doc('pustack_tutor_app')
        .get()
        .then(async (snapshot) => {
          if(snapshot.exists) {
            const data = snapshot.data();
            let videoList = [];
            for(let i = 0; i < data.videos.introduction_video_list.length; i++) {
              let c = data.videos.introduction_video_list[i];
              // const {url} = await getVimeoURL(c.video_id);
              videoList.push({
                ...c,
                // url
              })
            }
            setVideoList(videoList);
          }
        })
    }
  }, [activeTab, videoList])

  useEffect(() => {
    if(askForName) return;
    activeTab === 3 && allSkills === null && getOverallSkillsFn();
  }, [activeTab, askForName]);

  useEffect(() => {
    if(askForName || !auth.currentUser?.uid) return;
    let unsub = function() {};
    unsub = db.collection('users')
      .doc(auth.currentUser?.uid)
      .onSnapshot(snapshot => {
        if(!snapshot.exists || (snapshot.exists && snapshot.data().is_deleted)) {
          return reset('User was deleted, Please contact PuStack support')
        }
        if(snapshot.exists) {
          const data = snapshot.data();
          // ActiveTab = 4 is Congratulations Screen
          if(activeTab === 4 && auth.currentUser?.uid) {
            console.log('data - ', data);
            if(!data.name) {
              setFullName('');
              setIsSubmittingName(false);
              console.log('setAskForName - ');
              return setAskForName(() => {
                setActiveTab(3);
                return auth.currentUser?.uid;
              })
            }
            if(data.is_tutor_verified && data.is_instructor) {
              setIsVerified(data.is_tutor_verified);
              setTimeout(() => {
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    uid: data?.uid,
                    grade: data?.grade,
                    name: data?.name,
                    profile_url: data?.profile_url,
                  })
                );
                localStorage.setItem("isInstructor", JSON.stringify(data.is_instructor));
                localStorage.setItem(
                  "isExternalInstructor",
                  JSON.stringify(data.is_external_instructor)
                );
                localStorage.setItem("isUserPro", JSON.stringify(data.tier !== "free"));
                console.log('setUser - ')
                setUser(data);
                setIsUserProTier(data.tier !== "free");
                setIsInstructor(data.is_instructor);
                setIsExternal(data.is_external_instructor);
                console.log('loadingWrapper() - ');
                // loadingWrapper();
                history.push("/");
              }, 100);
            }
            if(!data.has_submitted_skills) {
              setActiveTab(3);
            }
          }
          // ActiveTab = 3 is Congratulations Screen
          if(activeTab === 3 && auth.currentUser?.uid) {
            if(!data.name && data.uid) {
              setFullName('');
              setIsSubmittingName(false);
              console.log('setAskForName - ');
              return setAskForName(() => {
                setActiveTab(3);
                return auth.currentUser?.uid;
              })
            }
            if(data.has_submitted_skills && data.name) {
              setActiveTab(4);
            }
          }
        }
      })

    return () => {
      unsub();
    }
  }, [activeTab, askForName])

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      `recaptcha-container`,
      {
        size: "invisible",
        callback: () => {},
        "expired-callback": () => window.recaptchaVerifier.clear(),
      },
      firebaseApp
    );
  }, []);


  const gradesList = useMemo(() => {
    if(!allSkills) return [];
    const grades = Object.keys(allSkills);
    return grades.map(grade => ({
      value: grade,
      name: (grade[0].toUpperCase() + grade.slice(1)).split('_').join(' '),
      number: +grade.split('_')[1]
    })).sort((a, b) => a.number - b.number)
  }, [allSkills])

  useEffect(() => {
    if (existingAccountError) {
      setOtpCode(Array(6).fill(""));
      setOtpError(false);
      setOtpErrorMsg("");
      setPhoneNumberError("Some problem occured, please try again.");
      setSocialProcessStarted([false, false, false]);
      setTimeout(() => setPhoneNumberError(""), 6000);
    }
  }, [existingAccountError]);

  const getOverallSkillsFn = async () => {
    const res = await getOverallSkills();

    [{value: 'class_9'}, {value: 'class_10'}].map(({ value: grade }) => {
      res?.[grade] && (res[grade].subjects.Maths = ["Maths"]);
    });
    console.log('res = ', res);
    setAllSkills(res);
  };

  const isBtnDisabled = skillSubjects?.length === 0;

  const getSubjectId = (skillSubject, skillGrade) => {
    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    return _skillId;
  };

  const selectSubject = (event, skillSubject) => {
    const { checked } = event.target;

    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    if (_skillId) {
      let skillToAdd = {
        category: skillCategory,
        grade: skillGrade,
        id: _skillId,
        is_skippable:
          allSkills[skillGrade]?.skip_subjects[categoryIdx[skillCategory]],
        reason: null,
        status: "accepted",
        subject: skillCategory === "Maths" ? null : skillSubject,
      };

      if (checked) {
        const _skillSubjects = [...skillSubjects, skillToAdd];

        setSkillSubjects(_skillSubjects);
        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillGrade}_${skillCategory}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillGrade && item.category === skillCategory
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillGrade]: (gradeSubjectCount?.[skillGrade] || 0) + 1,
        });
      } else {
        const _skillSubjects = [
          ...skillSubjects.filter((sub) => sub.id !== skillToAdd.id),
        ];

        setSkillSubjects(_skillSubjects);

        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillGrade}_${skillCategory}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillGrade && item.category === skillCategory
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillGrade]: (gradeSubjectCount?.[skillGrade] || 0) - 1,
        });
      }
    }
  };

  const selectSubject2 = (event, skillToAdd) => {
    const { checked } = event.target;

    if (skillToAdd) {
      if (!checked) {
        const _skillSubjects = [
          ...skillSubjects.filter((sub) => sub.id !== skillToAdd.id),
        ];

        setSkillSubjects(_skillSubjects);

        setCategorySubjectCount({
          ...(categorySubjectCount || {}),
          [`${skillToAdd.grade}_${skillToAdd.category}`]: _skillSubjects.filter(
            (item) =>
              item.grade === skillToAdd.grade &&
              item.category === skillToAdd.category
          ).length,
        });

        setGradeSubjectCount({
          ...(gradeSubjectCount || {}),
          [skillToAdd.grade]: (gradeSubjectCount?.[skillToAdd.grade] || 0) - 1,
        });
      }
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.key === " ") {
      setSpaceKey(true);
    } else if (e.key === "Backspace" || e.key === "Delete") {
      if (otpCode[i]?.length === 0 && i > 0 && i <= 5) {
        const prevField = document.querySelector(
          `div > input[name=input${i - 1}]`
        );
        if (prevField !== null) prevField.focus();
      }
    }
  };

  const signInPhoneFn = async (nextTab) => {
    if (phoneNumber.length > 6) {
      setSendingOtp(true);
      setExistingPhone(phoneNumber);
      await signInWithPhone(
        phoneNumber,
        countryCode,
        setActiveTab,
        nextTab,
        setSendingOtp,
        setPhoneNumberError,
        tempEmailUserData,
        setTempEmailUserData,
        setPhoneExists
      );
    } else {
      setPhoneNumberError("Invalid phone number");
      setSocialProcessStarted([false, false, false]);

      setTimeout(() => setPhoneNumberError(""), 6000);
    }
  };

  const handleOTPChange = async (e, i) => {
    const { maxLength, value } = e.target;
    setOtpError(false);
    setOtpErrorMsg("");
    if (value.length >= maxLength && i < 5) {
      const nextField = document.querySelector(
        `div > input[name=input${i + 1}]`
      );
      if (nextField !== null) nextField.focus();
    }

    if (!spaceKey) {
      otpCode[i] = value;
      setOtpCode(otpCode);
      forceUpdate();
    }
    const code = otpCode?.join("");
    if (code.length === 6) {
      await submitOtp();
    }
  };

  useEffect(() => {
    if(checkingOtp) document.body.style.pointerEvents = 'none';
    else document.body.style.pointerEvents = 'auto';
  }, [checkingOtp])

  const submitOtp = async () => {
    const code = otpCode?.join("");

    if (code.length === 6) {
      setCheckingOtp(true);

      let user, res, errorCode;
      try {
        [user, res, errorCode] = await submitPhoneNumberAuthCode(
          code,
          fbPendingCredentials,
          googleMailAddress,
          false
        );
      } catch (err) {
        return;
      }

      setTempPhoneUserData(user);
      setCheckingOtp(false);
      if (res) {
        setOtpError(false);

        let [
          name,
          profile_url,
          role,
          email,
          is_instructor,
          tutor_signup_ts,
          phone_number,
          has_rated_app,
          app_rating,
          pro_expiration_date,
          has_submitted_skills,
          tier,
          grade,
          phone_country_code,
          is_external_instructor,
          is_tutor_verified,
          is_deleted
        ] = await getUserInfoById(user.uid);

        if(is_deleted) {
          return reset('User was deleted, Please contact PuStack support')
        }

        if(!phone_number) {
          let time_now = await uploadUserInfo(
            user?.uid,
            name ?? user?.displayName,
            email ?? null,
            user?.photoURL ?? null,
            false, // setting user as student
            user?.phoneNumber?.slice(countryCode?.length + 1),
            countryCode
          );
          phone_number = user?.phoneNumber?.slice(countryCode?.length + 1);
          phone_country_code = countryCode;
        }
        setCheckingOtp(false);

        if (!email || !phone_number) {
          setActiveTab(activeTab + 1);
        } else {

          const data = {
            email: email,
            uid: user.uid,
            name: name,
            grade: grade,
            is_instructor: is_instructor,
            is_external_instructor: is_external_instructor,
            phone_country_code: phone_country_code,
            phone_number: phone_number,
            profile_url: profile_url,
            role: role,
            tutor_signup_ts: tutor_signup_ts,
            has_rated_app: has_rated_app ? has_rated_app : false,
            app_rating: app_rating ? app_rating : 0,
            pro_expiration_date: pro_expiration_date,
          };

          if(!data.name || !(data.name?.trim()?.length > 0)) {
            setAskForName(data.uid);
            return setActiveTab(3);
          }

          if (
            !is_tutor_verified || !is_instructor
          ) {
            if(!has_submitted_skills) {
              setTempUserData(() => {
                setActiveTab(3);
                return data;
              });
              return;
            }

            // setActiveTab(0);
            // setOtpCode(Array(6).fill(""));
            // setPhoneNumberError("Your verification is still in progress.");
            // setSocialProcessStarted([false, false, false]);
            // setTimeout(() => setPhoneNumberError(""), 6000);
            setActiveTab(4);
            return;
          }

          // if (!is_external_instructor) {
          //   setActiveTab(0);
          //   setOtpCode(Array(6).fill(""));
          //   setPhoneNumberError("This platform is only for tutors.");
          //   setSocialProcessStarted([false, false, false]);
          //   setTimeout(() => setPhoneNumberError(""), 6000);
          //   return;
          // }

          setTimeout(() => {
            localStorage.setItem(
              "user",
              JSON.stringify({
                uid: data?.uid,
                grade: data?.grade,
                name: data?.name,
                profile_url: data?.profile_url,
              })
            );
            localStorage.setItem("isInstructor", JSON.stringify(is_instructor));
            localStorage.setItem(
              "isExternalInstructor",
              JSON.stringify(is_external_instructor)
            );
            localStorage.setItem("isUserPro", JSON.stringify(tier !== "free"));
            console.log('setUser - ')
            setUser(data);
            setIsUserProTier(tier !== "free");
            setIsInstructor(is_instructor);
            setIsExternal(is_external_instructor);
            // console.log('loadingWrapper() - ');
            loadingWrapper();
            history.push("/");
          }, 2000);

          return;
        }
      } else {
        setOtpError(true);
        setOtpErrorMsg(errorCode);
      }
    } else {
      setOtpError(false);
    }
  };

  const setData = async (user, askForPhone) => {
    let data = {};
    // setAccountExists(false);

    console.log('data - ', data, user);

    if (user?.email) {
      let [
        name,
        profile_url,
        role,
        email,
        is_instructor,
        tutor_signup_ts,
        phone_number,
        has_rated_app,
        app_rating,
        pro_expiration_date,
        has_submitted_skills,
        tier,
        grade,
        phone_country_code,
        is_external_instructor,
        is_tutor_verified,
        is_deleted
      ] = await getUserInfoById(user?.uid);


      let time_now = false;

      if(is_deleted) {
        return reset('User was deleted, Please contact PuStack support')
      }

      if (!email || !phone_number) {
        time_now = await uploadUserInfo(
          user?.uid,
          user?.displayName,
          user?.email,
          user?.photoURL,
          false, // setting user as student
          phoneNumber?.slice(countryCode?.length + 1),
          countryCode
        );
        // setPhoneRoute(false)

        if (time_now) {
          data = {
            app_rating: null,
            app_rating_history: [],
            email: user?.email,
            grade: null,
            has_rated_app: false,
            is_instructor: false,
            is_external_instructor: true,
            name: user?.displayName,
            phone_country_code: askForPhone ? null : countryCode,
            phone_number: askForPhone ? null : phone_number,
            pro_expiration_date: null,
            profile_url: user?.photoURL,
            role: "External Instructor",
            tutor_signup_ts: time_now,
            tier: "free",
            uid: user?.uid,
          };

          setTempUserData({ ...data });
          setTempEmailUserData(user);
          setProcessStarted(false);

          setTimeout(() => {
            if(askForPhone) {
              setPhoneNumberError(null);
              setPhoneNumber('+91');
              setOtpCode(Array(6).fill(""));
              setOtpError(null);
              setOtpErrorMsg(null);
              setCountryCode('');
              // setAccountExists(true);
              return setActiveTab(1);
            }
            setActiveTab(activeTab + 1)
          }, 250);

          setSocialProcessStarted([false, false, false]);
        }
      } else {
        const data = {
          email: email,
          uid: user.uid,
          name: name,
          grade: grade,
          is_instructor: is_instructor,
          is_external_instructor: is_external_instructor,
          phone_country_code: phone_country_code,
          phone_number: phone_number,
          profile_url: profile_url,
          role: role,
          tutor_signup_ts: tutor_signup_ts,
          has_rated_app: has_rated_app ? has_rated_app : false,
          app_rating: app_rating ? app_rating : 0,
          pro_expiration_date: pro_expiration_date,
        };



        if(!data.name || !(data.name?.trim()?.length > 0)) {
          setAskForName(data.uid);
          return setActiveTab(3);
        }

        if (
          !is_tutor_verified || !is_instructor
        ) {
          if(!has_submitted_skills) {
            setTempUserData(() => {
              setActiveTab(3);
              return data;
            });
            return;
          }

          // setActiveTab(0);
          // setOtpCode(Array(6).fill(""));
          // setPhoneNumberError("Your verification is still in progress.");
          // setSocialProcessStarted([false, false, false]);
          // setTimeout(() => setPhoneNumberError(""), 6000);
          setActiveTab(4);
          return;
        }

        // if (!is_external_instructor) {
        //   setActiveTab(0);
        //   setOtpCode(Array(6).fill(""));
        //   setPhoneNumberError("This platform is only for tutors.");
        //   setSocialProcessStarted([false, false, false]);
        //   setTimeout(() => setPhoneNumberError(""), 6000);
        //   return;
        // }

        setTimeout(() => {
          localStorage.setItem(
            "user",
            JSON.stringify({
              uid: data?.uid,
              grade: data?.grade,
              name: data?.name,
              profile_url: data?.profile_url,
            })
          );
          localStorage.setItem("isInstructor", JSON.stringify(is_instructor));
          localStorage.setItem(
            "isExternalInstructor",
            JSON.stringify(is_external_instructor)
          );
          localStorage.setItem("isUserPro", JSON.stringify(tier !== "free"));
          console.log('setUser - ')
          setUser(data);
          setIsUserProTier(tier !== "free");
          setIsInstructor(is_instructor);
          setIsExternal(is_external_instructor);
          console.log('loadingWrapper() - ');
          // loadingWrapper();
          history.push("/");
        }, 2000);
      }
    } else {
      setEmailError("The facebook account doesn't have an email");
      setTimeout(() => {
        setEmailError(null);
        submit(
          facebookProvider,
          user,
          setPhoneNumberError,
          setSocialProcessStarted,
          setExistingAccountError,
          handleAuth,
          setData
        ).then();
      }, 2000);

      setSocialProcessStarted([false, false, false]);

      setTempEmailUserData(user);
    }
  };

  const submitGoogleEmailFn = () => {
    submitGoogleEmail({
      tempPhoneUserData,
      setActiveTab,
      countryCode,
      activeTab,
      setTempUserData,
      setPhoneNumberError,
      setSocialProcessStarted,
      setExistingAccountError,
      setProcessStarted,
    });
  };

  const handleAuth = useCallback(async (data) => {

    let _user = {
      ...data,
      grade: null,
      phone_number: phoneNumber.replace("+", "").slice(countryCode?.length),
      phone_country_code: countryCode,
      source: "web " + version,
    };
    setTempUserData(_user);

    await uploadFinalUserInfo(_user?.uid, _user);


    if(!data.name || !(data.name?.trim()?.length > 0)) {
      setAskForName(data.uid);
      return setActiveTab(3);
    }


    setActiveTab(3);

    // const _data = {
    //   email: data.email,
    //   uid: data.uid,
    //   name: data.name,
    //   grade: data.grade,
    //   is_instructor: false,
    //   is_external_instructor: true,
    //   profile_url: data.profile_url,
    //   role: data.role,
    //   tutor_signup_ts: data.tutor_signup_ts,
    //   has_rated_app: data.has_rated_app ? data.has_rated_app : false,
    //   app_rating: data.app_rating ? data.app_rating : 0,
    //   phone_number: phoneNumber.replace("+", "").slice(countryCode?.length),
    //   phone_country_code: countryCode,
    //   tier: data.tier
    // };
    //
    // setTimeout(() => {
    //   localStorage.setItem(
    //     "user",
    //     JSON.stringify({
    //       uid: _data?.uid,
    //       grade: _data?.grade,
    //       name: _data?.name,
    //       profile_url: _data?.profile_url,
    //     })
    //   );
    //   localStorage.setItem("isInstructor", JSON.stringify(_data.is_instructor));
    //   localStorage.setItem(
    //     "isExternalInstructor",
    //     JSON.stringify(_data.is_external_instructor)
    //   );
    //   localStorage.setItem("isUserPro", JSON.stringify(_data.tier !== "free"));
    //   setUser(_data);
    //   setIsUserProTier(_data.tier !== "free");
    //   setIsInstructor(_data.is_instructor);
    //   setIsExternal(_data.is_external_instructor);
    //   // loadingWrapper();
    //   // history.push("/");
    // }, 2000);

    setTimeout(() => setProcessStarted(false), 200);
  }, [phoneNumber, countryCode])

  const finalSubmission = async () => {
    console.log('skillSubjects - ', skillSubjects);
    if(!skillSubjects || !(skillSubjects.length > 0)) return;
    if (!finalSubmitting) {
      setFinalSubmitting(true);
      let _user = { uid: auth.currentUser.uid };
      // let updated = await uploadFinalUserInfo(_user?.uid, _user);

      // if (updated) {
      for (let i = 0; i < skillSubjects.length; i++) {
        const skill = skillSubjects[i];
        await addInstructorSkill({
          instructorId: _user?.uid,
          skillId: skill.id,
          skill,
        });
        let time_now = await castIndianTime();
        await db.doc('users/' + _user?.uid).set({has_submitted_skills: true, is_tutor_verified: false, tutor_signup_ts: +time_now}, {merge: true})
      }
      await postToWorkplace(_user?.uid);
      setFinalSubmitting(false);
      // setActiveTab(4);
      // }
    }
  };

  return (
    <div className="mobile-user-auth-container">
      <div className="mobile-user-auth">
        <SwipeableViews
          axis="x"
          index={activeTab}
          onChangeIndex={(e) => setActiveTab(e)}
          scrolling={"false"}
          ignoreNativeScroll={true}
          disabled={true}
          style={{height: '100%'}}
        >
          <>
            <img className="mobile-user-auth-logo" src={logo} alt=""/>
            <div ref={recaptchaRef}>
              <div id="recaptcha-container" className="recaptcha" />
            </div>
            <AutoPlaySwipeableViews
              axis="x"
              index={activeViewTab}
              onChangeIndex={(e) => setActiveViewTab(e)}
              scrolling={true}
              style={{height: 'auto'}}
            >
              {carouselData.map(item => (
                <div key={item.id} className="mobile-user-auth-screen">
                  <div className="mobile-user-auth-screen-image">
                    <img src={item.image} alt=""/>
                  </div>
                  <h1>{item.heading}</h1>
                  <h4>{item.description}</h4>
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <div className="mobile-user-auth-bottom">
              <div className="dots-container">
                {viewsArr.map((c, index) => (
                  <div className={"dot " + (index === activeViewTab ? c : '')} />
                ))}
              </div>
              {/*<div className={"next-icon " + (viewsArr[activeTab])} onClick={() => setActiveTab(c => {*/}
              {/*  if(c < 3) { return c + 1}*/}
              {/*  setOpenPhone(true);*/}
              {/*  return c;*/}
              {/*})}>*/}
              {/*  <ArrowBackIcon />*/}
              {/*</div>*/}
            </div>
            <div className="enter-phone-number">
              <h4>Enter Phone Number</h4>
              <PhoneInput
                country="in"
                value={phoneNumber}
                isValid={(_, country) => {
                  setCountryCode(country?.dialCode);
                  return true;
                }}
                onChange={(phone) => {
                  setPhoneNumber("+" + phone);
                  setPhoneNumberError("");
                }}
                placeholder="Phone Number"
                preferredCountries={["us", "ae", "sg", "my", "in"]}
                inputProps={{ autoFocus: true }}
              />
              {phoneNumberError && (
                <div className="phone-error-message">
                  <ErrorOutlineIcon />
                  {phoneNumberError}
                </div>
              )}
              <button
                id="sign-in-with-phone"
                onClick={() => {
                  if (!sendingOtp) {
                    signInPhoneFn(1);
                  }
                }}
              >
                {sendingOtp ? (
                  <Lottie
                    options={{ animationData: circularProgress, loop: true }}
                  />
                ) : (
                  "Send OTP"
                )}
              </button>
            </div>
          </>
          <div className="otp-tab">
            <h1>
              <ArrowLeftIcon
                className="back-btn fadeIn"
                onClick={() => {
                  // if(activeTab === 4) {
                  //   return setActiveTab(3);
                  // }

                  forceUpdate();
                  setOtpCode(Array(6).fill(""));
                  setOtpError(false);
                  setOtpErrorMsg("");
                  setSocialProcessStarted([false, false, false]);
                  setPhoneNumber(countryCode);
                  setTempEmailUserData(null);
                  setExistingPhone("");
                  setPhoneExists(false);
                  setSkillSubjects([]);
                  setSkillCategory("");
                  setCategorySubjectCount(null);
                  setGradeSubjectCount(null);

                  if (activeTab === 4 || activeTab == 3) {
                    return setActiveTab(0);
                  }

                  if (tempEmailUserData && activeTab > 1) {
                    tempEmailUserData.delete();
                    deleteUserFromDatabase(tempEmailUserData?.uid);
                  }
                  setTempEmailUserData(null);

                  tempPhoneUserData && tempPhoneUserData.delete();
                  setTempPhoneUserData(null);

                  if (activeTab === 2 || activeTab === 3) {
                    return setActiveTab(0);
                  }

                  setActiveTab(c => c - 1);
                }}
              />
              <span>Verify Phone</span>
            </h1>
            <p className="sent-otp">
              Code is sent to{" "}
              {existingPhone.length > 6 &&
                "X".repeat(existingPhone.length - 5) +
                existingPhone.slice(
                  existingPhone.length - 4,
                  existingPhone.length
                )}
            </p>
            <form>
              <div className={otpError ? "otp-input otp-error" : "otp-input"}>
                {phoneExists && (
                  <h5 className="already-exists">Account already exists.</h5>
                )}
                <div>
                  <div key={activeTab}>
                    {activeTab === 1 &&
                      Array(6)
                        .fill(0)
                        .map((_, i) => (
                          <div key={i}>
                            <input
                              ref={i === 0 ? otpInputRef : null}
                              name={`input${i}`}
                              type={"tel"}
                              value={otpCode[i]}
                              maxLength={1}
                              autoComplete="off"
                              onChange={(e) => handleOTPChange(e, i)}
                              onKeyDown={(e) => handleKeyDown(e, i)}
                            />
                          </div>
                        ))}
                  </div>
                </div>
                {otpErrorMsg && <h6>
                  <WarningRoundedIcon/> {otpErrorMsg}
                </h6>}
              </div>
              <div
                className={resendCode ? "resend__code grey" : "resend__code"}
              >
                {activeTab === 1 && (
                  <Timer
                    initialTime={30100}
                    direction="backward"
                    checkpoints={[
                      { time: 1500, callback: () => setPlural(false) },
                      { time: 1100, callback: () => setResendCode(false) },
                    ]}
                  >
                    {({ start, reset }) => (
                      <>
                        <h4
                          onClick={() => {
                            if (!resendCode) {
                              reset();
                              start();
                              forceUpdate();
                              setPlural(true);
                              signInPhoneFn(1);
                              setOtpError(false);
                              setOtpErrorMsg("");
                              setResendCode(true);
                              setOtpCode(Array(6).fill(""));
                            }
                          }}
                        >
                          Resend Code{resendCode ? "?" : ""}
                        </h4>
                        {resendCode ? (
                          <h6>
                            Tap resend in <Timer.Seconds />{" "}
                            {plural ? "seconds" : "second"}
                          </h6>
                        ) : (
                          <h6
                            onClick={() => {
                              if (!resendCode) {
                                setPlural(true);
                                reset();
                                start();
                                setResendCode(true);
                                signInPhoneFn();
                              }
                            }}
                          >
                            Tap here
                          </h6>
                        )}
                      </>
                    )}
                  </Timer>
                )}
              </div>
              <div className="continue-btn">
                <ButtonBase onClick={submitOtp}>
                  {checkingOtp ? (
                    <Lottie
                      options={{
                        animationData: circularProgress,
                        loop: true,
                      }}
                    />
                  ) : (
                    "Verify OTP"
                  )}
                </ButtonBase>
              </div>
            </form>
          </div>

          <div className="user-auth-inner">
            <h1>
              <ArrowLeftIcon
                className="back-btn fadeIn"
                onClick={() => {
                  forceUpdate();
                  setOtpCode(Array(6).fill(""));
                  setOtpError(false);
                  setOtpErrorMsg("");
                  setSocialProcessStarted([false, false, false]);
                  setPhoneNumber(countryCode);
                  setTempEmailUserData(null);
                  setExistingPhone("");
                  setPhoneExists(false);
                  setSkillSubjects([]);
                  setSkillCategory("");
                  setCategorySubjectCount(null);
                  setGradeSubjectCount(null);

                  if (activeTab === 4 || activeTab == 3) {
                    return setActiveTab(0);
                  }

                  if (tempEmailUserData && activeTab > 1) {
                    tempEmailUserData.delete();
                    // deleteUserFromDatabase(tempEmailUserData?.uid);
                  }
                  setTempEmailUserData(null);

                  tempPhoneUserData && tempPhoneUserData.delete();
                  setTempPhoneUserData(null);

                  if (activeTab === 2 || activeTab === 3) {
                    return setActiveTab(0);
                  }

                  setActiveTab(c => c - 1);
                }}
              />
              <span>I would like to</span>
            </h1>
            {/*<div className="avatar-rounded social-accounts">*/}
            {/*  <div>*/}
            {/*    <PersonRoundedIcon />*/}
            {/*    <EmailRoundedIcon className="email-svg" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="social-accounts">
              <button
                className="google"
                onClick={() => {
                  setEmailError(null);
                  if (socialProcessStarted.every((k) => k === false)) {
                    setSocialProcessStarted([false, true, false]);
                    submit(
                      googleProvider,
                      tempPhoneUserData,
                      setEmailError,
                      setSocialProcessStarted,
                      setExistingAccountError,
                      handleAuth,
                      setData
                    ).then();
                  } else {
                    setEmailError("One Process has already started");
                    setTimeout(() => setEmailError(""), 4000);
                  }
                }}
              >
                <img src={Google} draggable={false} alt="g" />
                <h6>
                  Continue with Google{" "}
                </h6>
                {socialProcessStarted[1] && (<Lottie
                  style={{
                    margin: "unset",
                    flex: "0 1 20px",
                    marginLeft: "10px",
                    width: "100%"
                  }}
                  options={{
                    animationData: circularProgress,
                    loop: true,
                  }}
                />)}
              </button>
              {/*<button*/}
              {/*  className="facebook"*/}
              {/*  onClick={() => {*/}
              {/*    setEmailError(null);*/}
              {/*    if (socialProcessStarted.every((item) => item === false)) {*/}
              {/*      setSocialProcessStarted([true, false, false]);*/}
              {/*      submit(*/}
              {/*        facebookProvider,*/}
              {/*        tempPhoneUserData,*/}
              {/*        setEmailError,*/}
              {/*        setSocialProcessStarted,*/}
              {/*        setExistingAccountError,*/}
              {/*        handleAuth,*/}
              {/*        setData*/}
              {/*      ).then();*/}
              {/*    } else {*/}
              {/*      setEmailError("One Process has already started");*/}
              {/*      setTimeout(() => setEmailError(""), 4000);*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <img src={Facebook} draggable={false} alt="fb" />*/}
              {/*  <h6>*/}
              {/*    Continue with Facebook*/}
              {/*  </h6>*/}
              {/*  {socialProcessStarted[0] && (<Lottie*/}
              {/*    style={{*/}
              {/*      margin: "unset",*/}
              {/*      flex: "0 1 20px",*/}
              {/*      marginLeft: "10px",*/}
              {/*      width: '20px'*/}
              {/*    }}*/}
              {/*    options={{*/}
              {/*      animationData: circularProgress,*/}
              {/*      loop: true,*/}
              {/*    }}*/}
              {/*  />)}*/}
              {/*</button>*/}
              {isAppleDevice && <button
                className="apple"
                onClick={() => {
                  if (socialProcessStarted.every((item) => item === false)) {
                    setSocialProcessStarted([false, false, true]);
                    submit(
                      appleProvider,
                      tempPhoneUserData,
                      setEmailError,
                      setSocialProcessStarted,
                      setExistingAccountError,
                      handleAuth,
                      setData
                    ).then();
                  } else {
                    setEmailError("One Process has already started");
                    setTimeout(() => setEmailError(""), 4000);
                  }
                }}
              >
                <img src={Apple} draggable={false} alt="fb" />
                <h6>
                  Continue with Apple
                </h6>
                {socialProcessStarted[2] && (<Lottie
                  style={{
                    margin: "unset",
                    flex: "0 1 20px",
                    marginLeft: "10px",
                    width: "100%"
                  }}
                  options={{
                    animationData: circularProgress,
                    loop: true,
                  }}
                />)}
              </button>}
            </div>
            <img className="rocket-launch" src={rocketLaunch} alt=""/>
            {emailError && (
              <div className="error-message fadeIn">{emailError}</div>
            )}
            <div className="social-agreements">
              <h6>By continuing, you agree to our</h6>
              <div>
                <h5
                  onClick={() => {
                    setDocumentToShow(termsOfService);
                    setShowPDF(true);
                  }}
                >
                  Terms of Service
                </h5>{" "}
                <h5
                  onClick={() => {
                    setDocumentToShow(privacyPolicy);
                    setShowPDF(true);
                  }}
                >
                  Privacy Policy
                </h5>
              </div>
            </div>
          </div>

          {askForName ? <div className="user-auth-inner">
            <h1>
              <span>My Name is</span>
            </h1>
            {/*<div className="avatar-rounded social-accounts">*/}
            {/*  <div>*/}
            {/*    <PersonRoundedIcon />*/}
            {/*    <EmailRoundedIcon className="email-svg" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <input onChange={e => setFullName(e.target.value)} value={fullName} className="enter-full-name" type="text" autoComplete={"off"} name="fullName"/>
            <button
              onClick={async () => {
                if(submittingName || !askForName || !isNameValid(fullName)) return;
                setIsSubmittingName(true);
                await db.collection('users')
                  .doc(askForName)
                  .set({
                    name: fullName?.trim()
                  }, {merge: true});

                setAskForName(false);
              }}
              className={!isNameValid(fullName) ? 'disabled' : ""}
              disabled={!isNameValid(fullName)}
            >
              {submittingName ? (
                <Lottie
                  options={{ animationData: circularProgress, loop: true }}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div> :

          <div className="skills-modal-inner user-auth-inner">
            <div className="skills-modal-inner-group">
              <div className="grades-list-wrapper">
                <h1>
                  <ArrowLeftIcon
                    className="back-btn fadeIn"
                    onClick={() => {

                      forceUpdate();
                      setOtpCode(Array(6).fill(""));
                      setOtpError(false);
                      setOtpErrorMsg("");
                      setSocialProcessStarted([false, false, false]);
                      setPhoneNumber(countryCode);
                      setTempEmailUserData(null);
                      setExistingPhone("");
                      setPhoneExists(false);
                      setSkillSubjects([]);
                      setSkillCategory("");
                      setCategorySubjectCount(null);
                      setGradeSubjectCount(null);

                      if (activeTab === 4 || activeTab == 3) {
                        return setActiveTab(0);
                      }

                      if (tempEmailUserData && activeTab > 1) {
                        tempEmailUserData.delete();
                        deleteUserFromDatabase(tempEmailUserData?.uid);
                      }
                      setTempEmailUserData(null);

                      tempPhoneUserData && tempPhoneUserData.delete();
                      setTempPhoneUserData(null);

                      if (activeTab === 2 || activeTab === 3) {
                        return setActiveTab(0);
                      }

                      setActiveTab(c => c - 1);
                    }}
                  />
                  <span>Select Grade</span>
                </h1>
                <div className="grades-list">
                  {allSkills ? (
                    gradesList.map((item) => (
                      <ButtonBase
                        key={item.value}
                        onClick={() => {
                          setSkillGrade(item.value);
                          item.value !== skillGrade && setSkillCategory("");
                        }}
                        className={
                          item.value === skillGrade ? "chip selected" : "chip"
                        }
                      >
                        {item.name}{" "}
                        {gradeSubjectCount &&
                          gradeSubjectCount?.[item.value] > 0 &&
                          `• ${gradeSubjectCount?.[item.value]}`}
                      </ButtonBase>
                    ))
                  ) : (
                    <div>
                      <ButtonBase disableRipple className="chip-loading">
                        <h1></h1>
                      </ButtonBase>
                      <ButtonBase disableRipple className="chip-loading">
                        <h1></h1>
                      </ButtonBase>
                    </div>
                  )}
                </div>
              </div>
              {skillGrade && (
                <div className="categories-list-wrapper fadeIn">
                  <h1>Select Category</h1>
                  <div className="categories-list">
                    {allSkills?.[skillGrade]?.categories.map((item, i) => (
                      <ButtonBase
                        onClick={() => setSkillCategory(item)}
                        className={
                          item === skillCategory ? "chip selected" : "chip"
                        }
                        key={i}
                      >
                        {item}{" "}
                        {categorySubjectCount &&
                          categorySubjectCount[`${skillGrade}_${item}`] > 0 &&
                          `• ${categorySubjectCount[`${skillGrade}_${item}`]}`}
                      </ButtonBase>
                    ))}
                  </div>
                </div>
              )}
              {skillCategory &&
                (!allSkills[skillGrade]?.skip_subjects[
                    categoryIdx[skillCategory]
                    ] ||
                  skillCategory === "Maths") && (
                  <div className="subjects-list-wrapper">
                    <h1>Select Subjects</h1>
                    <div className="subjects-list fadeIn">
                      <FormGroup>
                        {allSkills[skillGrade].subjects[skillCategory].map(
                          (item) => (
                            <FormControlLabel
                              checked={
                                skillSubjects?.filter(
                                  (category) =>
                                    category?.id ===
                                    getSubjectId(item, skillGrade)
                                )[0]?.id === getSubjectId(item, skillGrade)
                              }
                              control={
                                <Checkbox
                                  onChange={(e) => selectSubject(e, item)}
                                  color="primary"
                                  key={item + skillGrade}
                                />
                              }
                              label={item}
                              key={item + skillGrade}
                            />
                          )
                        )}
                      </FormGroup>
                    </div>
                  </div>
                )}
            </div>
            <div className="continue-btn">
              <p className="skill-condition">*Atleast 1 skill is required</p>
              <ButtonBase
                onClick={() => {
                  // setActiveTab(activeTab + 1);
                  setSkillCategory("");
                  finalSubmission();
                }}
                disabled={isBtnDisabled}
              >
                Submit Skills
              </ButtonBase>
            </div>
          </div> }
          <div className="final-tab">
            <h1>
              <span>Congratulations!</span>
              <ExitToApp onClick={() => {
                forceUpdate();
                setOtpCode(Array(6).fill(""));
                setOtpError(false);
                setOtpErrorMsg("");
                setSocialProcessStarted([false, false, false]);
                setPhoneNumber(countryCode);
                setTempEmailUserData(null);
                setExistingPhone("");
                setPhoneExists(false);
                setSkillSubjects([]);
                setSkillCategory("");
                setCategorySubjectCount(null);
                setGradeSubjectCount(null);

                setActiveTab(0);

              }} />
            </h1>
            <p>Our verification agent will contact you soon.</p>
            <div className="image">
              <img src={woman} alt=""/>
            </div>
            <div className="image-placeholder">
              <svg width="428" height="83" viewBox="0 0 428 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M427.5 19C260 -25.5 93.6667 21.8333 0 46.5V83C106 51.4 281.5 34.5 427.5 83V19Z" fill="#7C0709"/>
              </svg>
            </div>
            <div className="bottom-part">
              <SwipeableViews
                axis={"x"}
                scrolling={"true"}
                index={activeVideoTab}
                onChangeIndex={(e) => setActiveVideoTab(e)}
                style={{marginTop: '10px'}}
              >
                {!videoList ? <div className="recommended-video">
                  <div className="shine"/>
                </div> :
                videoList.map((item, index) => (
                  <VimeoPlayer item={item} index={index} isVisible={activeVideoTab === index} setEnableSwitch={setEnableSwitch} />
                ))}
              </SwipeableViews>

              <div className="bottom">
                <img src={whiteLogo} alt=""/>
                <div className="bottom-group">
                  <span onClick={() => {
                    setDocumentToShow(termsOfService);
                    setShowPDF(true);
                  }}>Terms of Service</span>
                  <div className="separator" />
                  <span onClick={() => {
                    setDocumentToShow(privacyPolicy);
                    setShowPDF(true);
                  }}>Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </SwipeableViews>
      </div>
      {/*<div className={"mobile-user-auth phone " + (activeTab >= 2 ? "available" : "") + (openPhone ? ' active' : '')}>*/}
      {/*  <h1 className="mobile-user-auth-phone-heading">Enter Phone Number</h1>*/}
      {/*  <div className="mobile-user-auth-phone-image">*/}
      {/*    <img src={phoneScreenMascot} alt=""/>*/}
      {/*    <p>You'll receive a 6 digit code to verify next.</p>*/}
      {/*  </div>*/}
      {/*  <div className="mobile-user-auth-phone">*/}
      {/*    <div className="enter-phone-number">*/}
      {/*      <h4>Enter Phone Number</h4>*/}
      {/*      <PhoneInput*/}
      {/*        country="in"*/}
      {/*        value={phoneNumber}*/}
      {/*        isValid={(_, country) => {*/}
      {/*          setCountryCode(country?.dialCode);*/}
      {/*          return true;*/}
      {/*        }}*/}
      {/*        onChange={(phone) => {*/}
      {/*          setPhoneNumber("+" + phone);*/}
      {/*          setPhoneNumberError("");*/}
      {/*        }}*/}
      {/*        placeholder="Phone Number"*/}
      {/*        preferredCountries={["us", "ae", "sg", "my", "in"]}*/}
      {/*        inputProps={{ autoFocus: true }}*/}
      {/*      />*/}
      {/*      <button>*/}
      {/*        Send OTP*/}
      {/*      </button>*/}
      {/*    </div>*/}
      {/*    <div className="phone-number-buttons">*/}
      {/*      <div className="number-button"><span>1</span></div>*/}
      {/*      <div className="number-button"><span>2</span></div>*/}
      {/*      <div className="number-button"><span>3</span></div>*/}
      {/*      <div className="number-button"><span>4</span></div>*/}
      {/*      <div className="number-button"><span>5</span></div>*/}
      {/*      <div className="number-button"><span>6</span></div>*/}
      {/*      <div className="number-button"><span>7</span></div>*/}
      {/*      <div className="number-button"><span>8</span></div>*/}
      {/*      <div className="number-button"><span>9</span></div>*/}
      {/*      <div className="number-button" />*/}
      {/*      <div className="number-button"><span>0</span></div>*/}
      {/*      <div className="number-button"><span><ArrowBack /></span></div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Dialog
        open={showPDF}
        onClose={() => {
          setShowPDF(false);
        }}
      >
        {showPDF && (
          <PdfPreview
            pdf={documentToShow}
            onClose={() => {
              setShowPDF(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
}
