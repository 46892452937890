import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import "../../../assets/bootstrap/bootstrap-grid.css";

import { BlazeExternalSidebar, BlazeExternalReservations } from "../../index";
import { UserContext, BlazeExternalContext } from "../../../context";

import {
  getBlazeExternalCompleted,
  getInstructorActivity,
} from "../../../database";
import "./style.scss";
import {db} from "../../../firebase_config";
import {useHistory, useLocation} from "react-router-dom";
import {sortedISOString} from "../../../helpers";

export default function BlazeExternalPage({onlySidebar, ...props}) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [hasReservations, setHasReservations] = useState(false);
  const [acceptedSessions, setAcceptedSessions] = useState(null);
  const [completedSessions, setCompletedSessions] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [_initialized, _setInitialized] = useState(false);
  const [targetedItem, setTargetedItem] = useState(null);
  const [noMoreAcceptedSessions, setNoMoreAcceptedSessions] = useState(false);
  const [noMoreCompletedSessions, setNoMoreCompletedSessions] = useState(false);

  const [, setIsTabActive] = useState(true);
  const history = useHistory();
  const reloaded = useRef(true);

  const [user] = useContext(UserContext).user;
  const [, setAcceptedBlazeSessionList] = useContext(UserContext).acceptedBlazeSessionList;
  const [sessionSelected, setSessionSelected] =
    useContext(BlazeExternalContext).sessionSelected;
  const [, setInstructorActivity] =
    useContext(BlazeExternalContext).instructorActivity;
  const [openPages, setOpenPages] = useContext(BlazeExternalContext).openPages;
  const [sessionId, setSessionId] = useContext(BlazeExternalContext).sessionId;
  const [, setActiveTab] = useContext(BlazeExternalContext).tabIdx;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const pathname = window.location.pathname;
  const location = useLocation();

  useEffect(() => {
    setAcceptedBlazeSessionList(acceptedSessions);
  }, [acceptedSessions]);

  const fetchMoreAcceptedSessions = useCallback(async (preCompletedSessions) => {
    let compSessions = preCompletedSessions ?? acceptedSessions;
    console.log('compSessions - ', compSessions);
    if (!compSessions || !compSessions.length > 0 || !user?.uid) {
      setInitialized(true);
      setNoMoreAcceptedSessions(true);
      return;
    }
    return db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .where("session_status", "==", "accepted")
      .where("instructor_id", "==", user?.uid)
      .orderBy("last_message_ts", "desc")
      .startAfter(compSessions.at(-1).item)
      .limit(10)
      .get()
      .then((snapshot) => {
        const requests = [];
        snapshot.docs.map((item) =>
          requests.push({item, ref: item.ref, ...item.data()})
        );
        console.log('requests - ', requests);
        setAcceptedSessions(c => {
          let a = [...c];
          a = a.filter(c => !requests.find(b => b.id === c.id));
          return [...a, ...requests]
        });
        setInitialized(true);
        setNoMoreAcceptedSessions(!(requests.length > 0 && requests.length >= 10));
      });
  }, [acceptedSessions, user?.uid])

  const fetchMoreCompletedSessions = useCallback(async (preCompletedSessions) => {
    let compSessions = preCompletedSessions ?? completedSessions;
    if (!compSessions || !compSessions.length > 0 || !user?.uid) {
      setInitialized(true);
      setNoMoreCompletedSessions(true);
      return;
    }
    return db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .where("session_status", "==", "completed")
      .where("instructor_id", "==", user?.uid)
      .orderBy("completed_ts", "desc")
      .startAfter(compSessions.at(-1).item)
      .limit(10)
      .get()
      .then((snapshot) => {
        const requests = [];
        snapshot.docs.map((item) =>
          requests.push({item, ref: item.ref, ...item.data()})
        );
        setCompletedSessions(c => sortedISOString([...c, ...requests], 'completed_ts'));
        _setInitialized(true);
        setNoMoreCompletedSessions(!(requests.length > 0 && requests.length >= 10));
      });
  }, [completedSessions, user?.uid]);

  const getBlazeAccepted = async (endAtId) => {
    let snapshot = null;
    if(endAtId) {
      snapshot = await db.collection("blaze_dev/collections/blaze_sessions")
        .doc(endAtId).get();
      if(snapshot.data().session_status !== 'accepted') {
        snapshot = null;
      }
    }
    const snapShotCB = (snapshot, fetchMore) => {
      const requests = [];
      snapshot.docs.map((item) =>
        requests.push({ref: item.ref, item, ...item.data()})
      );
      // setLoadingStatus(false);
      console.log('requests - ', requests, endAtId);
      setAcceptedSessions(requests);
      if(endAtId) setTargetedItem(endAtId);
      if(fetchMore && requests.length < 7) {
        return fetchMoreAcceptedSessions(requests);
      }
      setInitialized(true);
    }
    const ref = db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .where("session_status", "==", "accepted")
      .where("instructor_id", "==", user?.uid)
      .orderBy("last_message_ts", "desc")
    // .orderBy('id')

    if(snapshot) {
      return ref
        .endAt(snapshot)
        .onSnapshot((ss) => snapShotCB(ss, true));
    } else {
      return ref
        .limit(10)
        .onSnapshot(snapShotCB);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isNotMySession = searchParams.get('is_not_my_session');
    if (isNotMySession !== 'true') return;

    const pathName = location.pathname.split("/");
    const _sessionId = pathName[3];

    if (_sessionId) {
      db.collection('blaze_dev/collections/blaze_sessions')
        .doc(_sessionId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // if (session.session_status === 'completed') {
            //   setActiveTab(1);
            // } else {
            //   setActiveTab(0);
            // }
            setIsChatOpen(true);
            setOpenPages(false);
            setSessionId(_sessionId);
            setSessionSelected({
              ref: doc.ref, doc, ...doc.data()
            });
            props?.handleDrawerToggle && props.handleDrawerToggle();
          } else {
            isSmallScreen && history.push('/sessions');
          }
        })
    }

  }, [location.pathname]);

  useEffect(() => {

    return () => {
      setActiveTab(0);
      setSessionId(null);
      setSessionSelected(null);
    }
  }, [])

  const getBlazeCompleted = async (endAtId) => {
    let snapshot = null;
    if(endAtId) {
      snapshot = await db.collection("blaze_dev/collections/blaze_sessions")
        .doc(endAtId).get();
    }
    const snapShotCB = (snapshot, fetchMore) => {
      const requests = [];
      snapshot.docs.map((item) =>
        requests.push({ref: item.ref, item, ...item.data()})
      );
      // setLoadingStatus(false);
      console.log('requests - ', requests);
      setCompletedSessions(sortedISOString([...requests], 'completed_ts'));
      if(endAtId) setTargetedItem(endAtId);
      if(fetchMore && requests.length < 7) {
        return fetchMoreCompletedSessions(requests);
      }
      _setInitialized(true);
    }
    const ref = db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .where("session_status", "==", "completed")
      .where("instructor_id", "==", user?.uid)
      .orderBy("completed_ts", "desc")
    // .orderBy('id')

    if(snapshot) {
      return ref
        .endAt(snapshot)
        .onSnapshot((ss) => snapShotCB(ss, true));
    } else {
      return ref
        .limit(10)
        .onSnapshot(snapShotCB);
    }
  };

  console.log('sessionSelected 010101 - ', sessionId);

  // const getAcceptedRequests = async () => {
  //   return getTutorAccepted({
  //     instructorId: user?.uid,
  //     limit: 10,
  //     callback: (_accepted) => setAcceptedSessions(_accepted),
  //   });
  // };

  const getCompletedRequests = async () => {
    const _completed = await getBlazeExternalCompleted({
      instructorId: user?.uid,
    });

    setCompletedSessions(sortedISOString([..._completed], 'completed_ts'));
  };

  useEffect(() => {
    let _sessionId = pathname.split("/")[3];

    if(_sessionId === 'no_sessions') {
      _sessionId = undefined;
    }

    const prom = getBlazeAccepted();
    getBlazeCompleted();

    return () => {
      prom.then(c => c());
    }
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    console.log('pathname - ', pathname);
    let _sessionId = pathname.split("/")[3];

    const searchParams = new URLSearchParams(location.search);
    const isNotMySession = searchParams.get('is_not_my_session');
    if (isNotMySession === 'true') return;

    if(_sessionId === 'no_sessions') {
      _sessionId = undefined;
    }

    console.log('_sessionId - ', _sessionId, acceptedSessions, completedSessions, reloaded.current);

    if(pathname.includes('/sessions') || !reloaded.current) return;

    // if(acceptedSessions && completedSessions) reloaded.current = false;

    if (_sessionId) {
      if(acceptedSessions && completedSessions) {
        let session = acceptedSessions?.filter(
          (session) => session.id === _sessionId
        )[0];

        if(!session) {
          session = completedSessions?.filter(
            (session) => session.id === _sessionId
          )[0];
          if(session) setActiveTab(1);
        } else {
          setActiveTab(0);
        }

        if (session) {
          setIsChatOpen(true);
          setOpenPages(false);
          setSessionId(_sessionId);
          setSessionSelected(session);
          props?.handleDrawerToggle && props.handleDrawerToggle();
        } else {
          isSmallScreen && history.push('/sessions');
        }
      }
    } else {
      // if(_sessionId && isSmallScreen) {
      //   return history.push('/sessions');
      // }
      console.log('acceptedSessions, completedSessions - ', acceptedSessions, completedSessions);
      if (!_sessionId && acceptedSessions && acceptedSessions.length > 0) {
        setSessionSelected(acceptedSessions[0]);
        setSessionId(acceptedSessions[0].id);
        setIsChatOpen(true);

        console.log('setting from here -- -- - ');
        window.history.replaceState(
          "",
          "",
          `/blaze/chat/${acceptedSessions[0]?.id}`
        );
        return setActiveTab(0);
      }

      if (!_sessionId && acceptedSessions && acceptedSessions.length === 0 && completedSessions && completedSessions.length > 0) {
        setSessionSelected(completedSessions[0]);
        setSessionId(completedSessions[0].id);
        setIsChatOpen(true);

        console.log('setting from here -- -- - ');
        window.history.replaceState(
          "",
          "",
          `/blaze/chat/${completedSessions[0]?.id}`
        );
        return setActiveTab(1);
      }

      if(completedSessions && acceptedSessions && completedSessions.length === 0 && acceptedSessions.length === 0) {
        window.history.replaceState(
          "",
          "",
          `/blaze/chat/no_sessions`
        );
        return setActiveTab(0)
      }
    }
  }, [acceptedSessions, completedSessions, location.pathname, isSmallScreen]);

  const onVisibilityChange = (e) => {
    if(document.visibilityState === 'visible') {
      setIsTabActive(true);
    } else if(document.visibilityState === 'hidden') {
      setIsTabActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  // useEffect(() => {
  //   console.log('newMessage - ', newMessage, isTabActive, location.pathname, sessionSelected?.id);
  //   if(newMessage && isSmallScreen && (location.pathname !== ('/blaze/chat/' + newMessage) || !isTabActive)) {
  //     let audio = new Audio(newMsgAudio);
  //     audio.play();
  //     setNewMessage(false);
  //   }
  //   if (!isSmallScreen && newMessage && (sessionSelected?.id !== newMessage || !isTabActive)) {
  //     let audio = new Audio(newMsgAudio);
  //     audio.play();
  //     setNewMessage(false);
  //   }
  // }, [newMessage, sessionSelected, isSmallScreen, location, isTabActive]);

  useEffect(() => {
    if(!user || !user?.uid) return;
    const blazeTotalMessagesCount = (userID) => {
      // const doc = db
      //   .collection("user_notifications")
      //   .doc("instructor")
      //   .collection("user_notifications")
      //   .doc(userID);

      // let prevValues = {};
      // rdb.ref('/users/' + userID + '/globals/unread_blaze_message_count').on('value', (snapshot) => {
      //   console.log('snapshot.val() = ', snapshot);
      //   for(let i in snapshot.val()) {
      //     let val = snapshot.val()[i];
      //     const prevVal = prevValues[i];
      //     if(val > 0 && prevVal !== val) {
      //       console.log('val - ', val, prevVal, i);
      //       setNewMessage(i);
      //       setTimeout(() => setNewMessage(false), 100);
      //     }
      //   }
      //   prevValues = snapshot.val();
      //   if(snapshot.val()) setBlazeUnreadCount(snapshot.val());
      // })

      // doc.onSnapshot((docSnapshot) => {
      //   if (typeof docSnapshot.data() !== "undefined") {
      //     const { unread_blaze_message_count } = docSnapshot.data();
      //
      //     if (
      //       unread_blaze_message_count > 0 &&
      //       unread_blaze_message_count !== messageCount
      //     ) {
      //       setNewMessage(true);
      //       setTimeout(() => setNewMessage(false), 100);
      //     } else {
      //       setNewMessage(false);
      //     }
      //
      //     setMessageCount(unread_blaze_message_count);
      //     localStorage.setItem(
      //       "unreadMessageCount",
      //       unread_blaze_message_count
      //     );
      //   } else {
      //     setMessageCount(0);
      //     localStorage.setItem("unreadMessageCount", 0);
      //   }
      // });
    };
    blazeTotalMessagesCount(user?.uid);
  }, [user]);

  useEffect(() => {
    user?.uid && getInstructorActivityFn();
  }, [user?.uid]);

  const getInstructorActivityFn = () => {
    getInstructorActivity({
      instructorId: user?.uid,
      callback: (data) => {
        if (data)
          setInstructorActivity({
            isEngaged: data?.call_state?.is_engaged,
            meetingId: data?.active_call_id ?? data?.call_state?.call_id,
            sessionId: data?.active_call_session_id ?? data?.call_state?.session_id,
          });
      },
    });
  };

  console.log('onlySidebar - ', onlySidebar);

  return (
    <div className="blaze__page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          My Sessions{" "}
          {sessionSelected?.topic ? " | " + sessionSelected?.topic : ""} -
          PuStack Tutor
        </title>
      </Helmet>
      <div className="row">
        {((isSmallScreen ? !isChatOpen && !openPages : true) || onlySidebar) && (
          <div className="blaze__sidebar__wrapper">
            <BlazeExternalSidebar
              acceptedSessions={acceptedSessions}
              completedSessions={completedSessions}
              initialized={initialized}
              targetedItem={targetedItem}
              toggleViews={() => setHasReservations(!hasReservations)}
              fetchMoreAcceptedSessions={fetchMoreAcceptedSessions}
              noMoreAcceptedSessions={noMoreAcceptedSessions}
              _initialized={_initialized}
              fetchMoreCompletedSessions={fetchMoreCompletedSessions}
              noMoreCompletedSessions={noMoreCompletedSessions}
              closeDrawer={() => null}
              setIsChatOpen={setIsChatOpen}
            />
          </div>
        )}

        {isChatOpen && !onlySidebar && sessionSelected && (
          <div className="col col-md-12 col-xs-12" id="blaze__content">
            <BlazeExternalReservations
              setIsChatOpen={setIsChatOpen}
              setCompletedSessions={setCompletedSessions}
              acceptedSessions={acceptedSessions}
              completedSessions={completedSessions}
              key={sessionSelected?.id}
            />
          </div>
        )}
      </div>
    </div>
  );
}
