import React, {useEffect, useMemo, useRef, useState} from "react";
import PlayArrowRounded from "@material-ui/icons/PlayArrowRounded";
import Vimeo from "@u-wave/react-vimeo";
import circularProgress from "../../../assets/lottie/circularProgress.json";
import Lottie from "lottie-react-web";
import {getVimeoURL} from "../../../helpers/global";

export default function VimeoPlayer({item, isVisible, setEnableSwitch, index, showText}) {
  const vimeoRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState({loading: false, link: null, play: false});

  useEffect(() => {
    const handler = function (event) {
      console.log("on webkitfullscreenchange");
    }
    if(!isVisible) {
      setUrl(c => ({loading: false, link: c.link, play: false}));
      if(vimeoRef.current && vimeoRef.current.player) vimeoRef.current.player.pause();
    } else {
      document.addEventListener("webkitfullscreenchange", handler);
    }

    return () => {
      document.removeEventListener("webkitfullscreenchange", handler);
    }
  }, [isVisible]);

  return (
    <div className="recommended-video" key={item.url + '-' + index}>
      <div className={"thumbnail" + (playing ? ' playing' : '')} onClick={async () => {
        if(url.link) {
          setUrl(c => ({loading: true, link: c.link, play: true}));
          return vimeoRef.current.player.play();
        }
        if(!item.video_id) return () => {};
        setUrl({loading: true, link: null, play: false});
        setEnableSwitch(false);
        getVimeoURL(item.video_id)
          .then(({url}) => {
            setTimeout(() => {
              setUrl({loading: true, link: url, play: isVisible});
              if(isVisible) vimeoRef.current.player.play();
              setEnableSwitch(true);
            }, 200)
          })
      }}>
        <img src={item.thumbnail_url} alt=""/>
        <div className={"play-icon" + (url.loading ? ' loader' : '')}>
          {!url.loading ? <PlayArrowRounded/> :
          <Lottie
            options={{
              animationData: circularProgress,
              loop: true,
            }}
          />}
        </div>
      </div>
      <div style={{width: '100%', aspectRatio: '16 / 9', pointerEvents: 'none'}} />
      {(url.link) && <Vimeo
        video={url.link}
        style={{display: url.play ? 'block' : 'none'}}
        paused={!isVisible || !playing}
        controls={true}
        ref={vimeoRef}
        onPlaying={() => {
          setUrl(c => ({loading: false, link: c.link, play: true}));
          setPlaying(true)
        }}
        onPause={() => {
          if(!document.fullscreen) {
            setUrl(c => ({loading: false, link: c.link, play: false}));
          }
          setPlaying(false);
        }}
      />}
      {showText && <>
        <div className="video-detail">
          <h1>{item.title}</h1>
          <p>
            {item.description}
          </p>
        </div>
      </>}
    </div>
  )
}
