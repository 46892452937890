import React, {useContext, useEffect, useRef, useState} from 'react';
import {getBlazeRequests, getInstructorSkills} from "../../database";
import BlazeCardLoader from "../../components/blazeExternal/blazeCardLoader";
import Lottie from "lottie-react-web";
import EmptyBox from "../../assets/lottie/empty-box.json";
import {BlazeRequestCard, Navbar} from "../../containers";
import {UserContext} from "../../context";

export default function RequestedSessions() {
  const [requestedSessions, setRequestedSessions] = useState();
  const _requestedSessions = useRef([]);
  const [instructorSkills] = useContext(UserContext).instructorSkills;
  const [sessionSelected, setSessionSelected] = useState(null);

  const getNewRequests = () => {
    if (instructorSkills?.length > 0) {
      let skillBatches = [],
        batchSize = 10;

      for (let i = 0; i < instructorSkills.length; i += batchSize) {
        skillBatches.push(
          instructorSkills.slice(i, i + batchSize)?.map((skill) => skill?.id)
        );
      }

      _requestedSessions.current = [];

      skillBatches.map((batch) => {
        getBlazeRequests({
          instructorSkills: batch,
          callback: (_requests) => {
            _requestedSessions.current = [
              ..._requestedSessions.current,
              ..._requests,
            ];
            setRequestedSessions(
              _requestedSessions.current
                .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
                .sort((a, b) => b.requested_ts - a.requested_ts)
            );
          },
        });
      });
    }
  };

  useEffect(() => {
    getNewRequests();
  }, [instructorSkills]);

  return (
    <div className="blaze-mobile">
      <Navbar onlyBottom={true} />
      <h1>Explore</h1>
      <div className="outstanding-sessions-list">
        {requestedSessions === null &&
          instructorSkills?.length !== 0 &&
          Array(6)
            .fill(0)
            .map((_) => <BlazeCardLoader />)}

        {(instructorSkills?.length === 0 || !requestedSessions || requestedSessions?.length === 0) && (
          <div className="no__sessions fadeIn">
            <Lottie
              options={{ animationData: EmptyBox, loop: false }}
              speed={0.45}
              style={{width: '60%', height: 'unset'}}
            />
            <h4 style={{ textAlign: "center" }}>
              There are no requested sessions
            </h4>
          </div>
        )}

        {instructorSkills?.length !== 0 && requestedSessions?.map((session) => (
          <BlazeRequestCard
            key={session?.id}
            sessionData={session}
            topic={session?.topic}
            skill={session?.skill}
            sessionId={session?.id}
            reference={session?.ref}
            studentName={session?.student_name}
            studentId={session?.student_id}
            setSessionSelected={setSessionSelected}
            gradient={session?.subject_color_gradient}
            studentImage={session?.student_profile_pic}
            isSessionSelected={sessionSelected === session?.id}
          />
        ))}
      </div>
    </div>
  )
}
