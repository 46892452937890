import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import Modal from "react-modal";
import { format } from "date-fns";
import Linkify from "react-linkify";
import CancelIcon from "@material-ui/icons/Cancel";
import PdfPreview from "./../../newsfeed/pdf-preview";
import DoneIcon from "@material-ui/icons/DoneRounded";
import DoneAllIcon from "@material-ui/icons/DoneAllRounded";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import MissedVideoCallRoundedIcon from "@material-ui/icons/MissedVideoCallRounded";
import differenceInCalendarWeeks from "date-fns/differenceInCalendarWeeks";
import { UserContext } from "../../../context";
import pdfIcon from "../../../assets/images/pdf.svg";
import {defaultPic} from "../../../assets";
import circularProgress from "../../../assets/lottie/circularProgress.json";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Lottie from "lottie-react-web";
import arrowDown from "../../../assets/images/double_arrow_down.svg";
import {randomAvatar} from "../../../helpers";
import {getAvatarImage} from "../../../context/global/user-context";
import Avatar from "../../global/Avatar";
import {
  ChapterListCard,
  ClassroomPlayer,
} from "../../../components";
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";

/**
 * Example url to reproduce the issue - http://localhost:3001/blaze/chat/gxTBsSHKtQwJmMkJTVpj?is_not_my_session=true
 * TODO: Fix the chat grouping for the sessions that is not associated with the userId
 */

/**
 *
 * @param chats
 * @param moreChat
 * @param getMoreChatsFn
 * @param sessionId
 * @param senderName
 * @param senderImage
 * @param senderId
 * @param midCall
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReservationChats({ chats, moreChat, getMoreChatsFn, sessionId, senderName, senderImage, senderId, midCall = false }) {
  const [user] = useContext(UserContext).user;
  const [imagePreview, setimagePreview] = useState(false);
  const [dialogImage, setDialogImage] = useState("");
  const [timeId, setTimeId] = useState("");
  const [autoScroll, setAutoScroll] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });

  const endScroll = useRef();
  const observer = useRef();
  const firstChild = useRef();
  const fetchedLength = useRef();

  const enableScroll = useRef(true);
  const onCallFirstMount = useRef(midCall);

  const handleClose = () => {
    setimagePreview(false);
  };

  useEffect(() => {
    enableScroll.current && endScroll.current.scrollIntoView({ behavior: onCallFirstMount.current ? "auto" : "smooth" });
    onCallFirstMount.current = false;
  }, [chats]);

  const lastChatRef = useCallback(function (node) {
    if (node !== null) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (moreChat && enableScroll.current && !isFetching) {
            setIsFetching(true);
            setAutoScroll(false);
            enableScroll.current = false;

            if (chats[0]) {
              getMoreChatsFn(
                chats,
                setIsFetching,
                () => {
                  enableScroll.current = true;
                  setAutoScroll(true)
                },
                firstChild,
                fetchedLength
              );
            } else {
              setIsFetching(false);
              setAutoScroll(true);
              enableScroll.current = true;
            }
          }
        }
      });
      if (node) observer.current.observe(node);
    }
  });

  const formattedDate = (timestamp) => {
    if (timestamp !== null && typeof timestamp !== "undefined") {
      const _chatDate = +new Date(timestamp).getDate();

      const _date = +new Date().getDate();
      const _weekDiff = differenceInCalendarWeeks(
        new Date(),
        new Date(timestamp)
      );

      if (_chatDate === _date) {
        return (
          format(new Date(timestamp), "h") +
          ":" +
          format(new Date(timestamp), "mm") +
          " " +
          format(new Date(timestamp), "aaa")
        );
      } else if (_chatDate < _date || _weekDiff === 0) {
        return (
          format(new Date(timestamp), "cccc") +
          ", " +
          format(new Date(timestamp), "h") +
          ":" +
          format(new Date(timestamp), "mm") +
          " " +
          format(new Date(timestamp), "aaa")
        );
      } else if (_weekDiff > 0) {
        return (
          format(new Date(timestamp), "MMMM") +
          " " +
          _chatDate +
          ", " +
          format(new Date(timestamp), "yyyy") +
          " at " +
          format(new Date(timestamp), "h") +
          ":" +
          format(new Date(timestamp), "mm") +
          String(format(new Date(timestamp), "aaa")).toLowerCase()
        );
      } else {
        return (
          format(new Date(timestamp), "h") +
          ":" +
          format(new Date(timestamp), "mm") +
          " " +
          format(new Date(timestamp), "aaa")
        );
      }
    } else {
      return (
        format(new Date(), "h") +
        ":" +
        format(new Date(), "mm") +
        " " +
        format(new Date(), "aaa")
      );
    }
  };

  const showProfileImage = (position) =>
    position === "bottom" || position === "none";

  const senderPicRef = useRef(null);
  const receiverPicRef = useRef(null);
  useEffect(() => {
    senderPicRef.current = randomAvatar(senderName, 'flex-shrink-0')
  }, [senderName]);
  useEffect(() => {
    receiverPicRef.current = randomAvatar(user?.name, 'flex-shrink-0')
  }, [user?.name]);

  return (
    <>
      <div className="user__chat__window">
        <div className="lastChatRef" ref={lastChatRef}>
          {moreChat && <Lottie options={{animationData: circularProgress, loop: true}} style={{padding: 0, width: '30px'}}/>}
        </div>
        {chats.map((chat, index) => {
          if (chat?.type === "text") {
            return (
              <>
                {timeId === chat?.timestamp && (
                  <h6 className="reservation__timestamp">
                    {formattedDate(chat?.timestamp)}
                  </h6>
                )}
                {index === fetchedLength?.current && (
                  <div
                    ref={firstChild}
                    style={{ marginBottom: !enableScroll.current ? "15px" : "0px" }}
                  />
                )}
                <div
                  key={chat?.sent_on}
                  className={
                    chat?.isByUser
                      ? "reservation__comment__wrapper receiver"
                      : "reservation__comment__wrapper sender"
                  }
                >
                  { (!chat?.isByUser || !midCall) && <div
                    className="comment__tile"
                    style={{opacity: showProfileImage(chat?.position) ? 1 : 0}}
                  >
                    <Avatar
                      src={chat?.isByUser && chat?.sender_id !== 'pustack' ? user?.profile_url : chat?.sender_pic}
                      name={chat?.isByUser ? user?.name : senderName}
                      uid={chat?.isByUser ? user?.uid : senderId}
                      alt="Pustack User"
                    />
                  </div> }

                  <div
                    className={`comment__content ${chat?.position}`}
                    onClick={() =>
                      setTimeId(
                        timeId === chat?.timestamp ? null : chat?.timestamp
                      )
                    }
                  >
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => {
                        console.log('decoratedHref, decoratedText - ', decoratedHref, decoratedText);

                        if(decoratedHref.toString().includes('.pdf')) {
                          return <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={'#'}
                            key={key}
                            style={{color: 'dodgerblue', textDecoration: 'underline'}}
                            onClick={(e) => {
                              e.preventDefault();
                              setPdfUrl(decoratedText);
                            }}
                          >
                            {decoratedText}
                          </a>
                        }

                        return (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={decoratedHref}
                            key={key}
                            style={{color: 'dodgerblue', textDecoration: 'underline'}}
                          >
                            {decoratedText}
                          </a>
                        )
                      }}
                    >
                      {chat.message}
                    </Linkify>
                    {chat?.isByUser && (
                      <div className="received__check">
                        {!chat?.hasRead ? (
                          <DoneIcon className="sent-check" />
                        ) : (
                          <DoneAllIcon className="received-check" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          } else if (chat?.type === "document") {
            return (
              <>
                {timeId === chat?.timestamp && (
                  <h6 className="reservation__timestamp">
                    {formattedDate(chat?.timestamp)}
                  </h6>
                )}
                {index === fetchedLength?.current && <div ref={firstChild} />}
                <div
                  key={chat?.sent_on}
                  className={
                    chat?.isByUser
                      ? "reservation__comment__wrapper receiver"
                      : "reservation__comment__wrapper sender"
                  }
                >
                  { (!chat?.isByUser || !midCall) &&
                    <div
                      className="comment__tile flex-shrink-0"
                      style={{ opacity: showProfileImage(chat?.position) ? 1 : 0 }}
                    >

                      <Avatar
                        src={chat?.isByUser ? user?.profile_url : chat?.sender_pic}
                        name={chat?.isByUser ? user?.name : senderName}
                        uid={chat?.isByUser ? user?.uid : senderId}
                        alt="Pustack User"
                      />
                    </div>
                  }

                  <div
                    className={`comment__content attachment ${chat?.position}`}
                    onClick={() => {
                      setTimeId(
                        timeId === chat?.timestamp ? null : chat?.timestamp
                      );
                      setPdfUrl(chat?.attachment?.url);
                    }}
                  >
                    <img
                      src={pdfIcon}
                      alt="PustackDocument"
                      className="attachment__icon"
                    />
                    <div className="file__name">Tap to view</div>

                    {chat?.isByUser && (
                      <div className="received__check">
                        {!chat?.hasRead ? (
                          <DoneIcon className="sent-check" />
                        ) : (
                          <DoneAllIcon className="received-check" />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            );
          } else if (chat?.type === "call_event") {
            return (
              <>
                {timeId === chat?.timestamp && (
                  <h6 className="reservation__timestamp">
                    {formattedDate(chat?.timestamp)}
                  </h6>
                )}
                {index === fetchedLength?.current && <div ref={firstChild} />}
                <div
                  key={chat?.sent_on}
                  className="reservation__comment__wrapper call__detail"
                  onClick={() =>
                    setTimeId(timeId === chat?.timestamp ? null : chat?.timestamp)
                  }
                >
                  {chat?.event_type === "completed" ? (
                    <div className="call__duration">
                      <span>
                        <CallRoundedIcon />
                        {chat?.message}
                      </span>
                    </div>
                  ) : (
                    <div className="missed__call">
                      <span>
                        <MissedVideoCallRoundedIcon />
                        {chat?.message} • {format(new Date(chat?.timestamp), "p")}
                      </span>
                    </div>
                  )}
                </div>
              </>
            );
          } else if (chat?.type === "image") {
            return (
              <>
                {timeId === chat?.timestamp && (
                  <h6 className="reservation__timestamp">
                    {formattedDate(chat?.timestamp)}
                  </h6>
                )}
                {index === fetchedLength?.current && <div ref={firstChild} />}
                <div
                  className={`reservation__comment__wrapper ${
                    chat?.isByUser ? "receiver" : "sender"
                  }`}
                  key={chat?.sent_on}
                >
                  { (!chat?.isByUser || !midCall) && <div
                    className="comment__tile"
                    style={{opacity: showProfileImage(chat?.position) ? 1 : 0}}
                  >

                    <Avatar
                      src={chat?.isByUser ? user?.profile_url : chat?.sender_pic}
                      name={chat?.isByUser ? user?.name : senderName}
                      uid={chat?.isByUser ? user?.uid : senderId}
                      alt="Pustack User"
                    />
                  </div>}

                  <div
                    key={index}
                    className={`comment__content image ${chat?.position}`}
                    onClick={() =>
                      setTimeId(
                        timeId === chat?.timestamp ? null : chat?.timestamp
                      )
                    }
                  >
                    <img
                      src={chat?.attachment?.url}
                      alt="PBA"
                      className="comment__image"
                      onClick={() => {
                        setimagePreview(true);
                        setDialogImage(chat?.attachment?.url);
                      }}
                    />

                    {chat?.isByUser && (
                      <div className="received__check">
                        {!chat?.hasRead ? (
                          <DoneIcon className="sent-check" />
                        ) : (
                          <DoneAllIcon className="received-check" />
                        )}
                      </div>
                    )}
                  </div>

                  <Modal
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    // onClose={handleClose}
                    ariaHideApp={false}
                    onRequestClose={handleClose}
                    overlayClassName="new-post-modal-overlay"
                    isOpen={imagePreview}
                    className="doubtTile__imagePreviewDiv__wrapper"
                  >
                    <div style={{
                      height: '100vh',
                      width: '100vw',
                      background: "transparent"
                    }} onClick={handleClose} />
                    <div className="doubtTile__imagePreviewDiv">
                      <CancelIcon onClick={handleClose} className="close-btn" />
                      <img
                        src={dialogImage}
                        className="imagePreviewDialog_image"
                        // onClick={handleClose}
                        alt={`${chat?.attachment?.url}`}
                        key={chat?.attachment?.url}
                      />
                    </div>
                  </Modal>
                </div>
              </>
            );
          } else if (chat?.type === 'lecture') {
            const meta = chat?.attachment?._meta;
            const searchParams = new URLSearchParams('');
            console.log('meta - ', meta);
            // I want to check if the meta.lecture_item_id is present or not
            let url = '#not-found';
            if(meta.lecture_item_id || meta.lecture_item_id_id) {
              searchParams.append('subject', meta.subject_id.split('_').at(-1))
              searchParams.append('chapter', meta.chapter_id)
              searchParams.append('lecture_item_id', (meta.lecture_item_id ?? meta.lecture_item_id_id));
              if(meta.lecture_header_item_id) searchParams.append('lecture_header_item_id', meta.lecture_header_item_id)
              searchParams.append('open_specific', 'true');
              url = `/classroom?` + searchParams.toString()
            } else if(meta.exam_item_id) {
              searchParams.append('subject', meta.category_id);
              searchParams.append('practice', meta.exam_id);
              searchParams.append('exam_item_id', meta.exam_item_id);
              if(meta.exam_header_item_id) searchParams.append('exam_header_item_id', meta.exam_header_item_id)
              searchParams.append('open_specific', 'true');
              url = `/practice?` + searchParams.toString()
            } else if(meta.tip_item_id) {
              searchParams.append('subject', meta.category_id);
              searchParams.append('tip', meta.tip_item_id);
              searchParams.append('open_specific', 'true');
              url = `/tips?` + searchParams.toString()
            }

            console.log('url123123 - ', url);

            return <>
              {(chat?.id ? timeId === chat?.id : timeId === chat?.timestamp) && (
                <h6 className="reservation__timestamp">
                  {formattedDate(chat?.timestamp)}
                </h6>
              )}
              {index === fetchedLength?.current && <div ref={firstChild} />}
              <div
                className={`reservation__comment__wrapper ${
                  chat?.isByUser ? "receiver" : "sender"
                }`}
                key={chat?.sent_on}
              >
                { (!chat?.isByUser || !midCall) && <div
                  className="comment__tile 1"
                  style={{opacity: showProfileImage(chat?.position) ? 1 : 0}}
                >
                  <Avatar width={100} height={100}
                          src={chat?.isByUser ? user?.profile_url : chat?.sender_pic}
                          name={chat?.isByUser ? user?.name : senderName}
                          uid={chat?.isByUser ? user?.uid : senderId}
                          alt="Pustack User"
                  />
                </div>}

                <div
                  key={index}
                  className={`comment__content image ${chat?.position}`}
                  style={{zoom: midCall ? 0.7 : 1}}
                  onClick={() =>
                    setTimeId(
                      chat?.id ? chat?.id : chat?.timestamp
                    )
                  }
                >
                  <Link
                    // href={path ? path() : `${pathName}?subject=${code}&chapter=${chapter_id}`}
                    to={url}
                    target={midCall ? "_blank" : "_self"}
                    className="chapter__thumbnail"
                    style={{padding: 0, width: isSmallScreen ? '200px' : '350px', display: 'block'}}
                  >
                    <div className="chapter__thumbnail__wrapper">
                      <img height={'100%'} width={'100%'}
                             src={
                               meta?.illustration_art
                             }
                             alt="Chapter"
                             style={{ backgroundColor: meta.chapter_hex_color }}
                             loading="lazy"
                      />

                      <div className="chapter__thumb__overlay" style={{
                        top: 0,
                        left: 0,
                        bottom: 'unset',
                        right: 'unset',
                        opacity: 1,
                        // background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 55.74%, rgba(0, 0, 0, 0.62) 100%)',
                        background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 36.07%, rgba(0, 0, 0, 0.87) 100%)',
                        width: '100%',
                        height: '100%'
                      }}>
                        <PlayArrowIcon style={{opacity: 1, fontSize: isSmallScreen ? '2rem' : '4.5rem'}}/>
                        <h2 style={{
                          position: 'absolute',
                          bottom: isSmallScreen ? 5 : 10,
                          color: 'white',
                          fontWeight: 400,
                          left: 10,
                        }}>{meta?.lecture_name}</h2>
                      </div>
                    </div>
                  </Link>
                  {/*<Plyr*/}
                  {/*	style={{width: '350px'}}*/}
                  {/*	width={350}*/}
                  {/*	source={{*/}
                  {/*		type: "video",*/}
                  {/*		// @ts-ignore*/}
                  {/*		sources: [{ src: chat?.attachment.url, provider: 'youtube' }],*/}
                  {/*	}}*/}
                  {/*/>*/}
                  {/*<Image width={100} height={100}*/}
                  {/*       src={chat?.attachment?.url}*/}
                  {/*       alt="PBA"*/}
                  {/*       className="comment__image"*/}
                  {/*       onClick={() => {*/}
                  {/*	       setimagePreview(true);*/}
                  {/*	       setDialogImage(chat?.attachment?.url);*/}
                  {/*       }}*/}
                  {/*/>*/}
                  {chat?.isByUser && (
                    <div className="received__check">
                      {chat?.hasRead ? (
                        <DoneAllIcon className="received-check fadeIn" />
                      ) : (
                        <DoneIcon className="sent-check fadeIn" />
                      )}
                    </div>
                  )}
                </div>

              </div>
            </>
          } else if (chat?.type === 'chapter') {
            const meta = chat?.attachment?._meta;
            return <>
              {(chat?.id ? timeId === chat?.id : timeId === chat?.timestamp) && (
                <h6 className="reservation__timestamp">
                  {formattedDate(chat?.timestamp)}
                </h6>
              )}
              {index === fetchedLength?.current && <div ref={firstChild} />}
              <div
                className={`reservation__comment__wrapper ${
                  chat?.isByUser ? "receiver" : "sender"
                }`}
                key={chat?.sent_on}
              >
                { (!chat?.isByUser || !midCall) && <div
                  className="comment__tile 1"
                  style={{opacity: showProfileImage(chat?.position) ? 1 : 0}}
                >
                  <Avatar width={100} height={100}
                          src={chat?.isByUser && chat?.sender_id !== 'pustack' ? user?.profile_url : chat?.sender_pic}
                          name={chat?.isByUser ? user?.name : senderName}
                          uid={chat?.isByUser ? user?.uid : senderId}
                          alt="Pustack User"
                  />
                </div>}

                <div
                  key={index}
                  className={`comment__content image ${chat?.position}`}
                  style={{zoom: midCall ? 0.7 : 1}}
                >
                  <Link
                    // href={path ? path() : `${pathName}?subject=${code}&chapter=${chapter_id}`}
                    to={`/classroom?subject=maths&chapter=${meta?.chapter_id}`}
                    target={midCall ? "_blank" : "_self"}
                    className="chapter__thumbnail"
                    style={{padding: 0, width: isSmallScreen ? '200px' : '350px', display: 'block'}}
                  >
                    <div className="chapter__thumbnail__wrapper">
                      <img height={'100%'} width={'100%'}
                           src={
                             meta?.illustration_art
                           }
                           alt="Chapter"
                           style={{ backgroundColor: meta?.hex_color }}
                           loading="lazy"
                      />

                      <div className="chapter__thumb__overlay" style={{
                        top: 0,
                        left: 0,
                        bottom: 'unset',
                        right: 'unset',
                        opacity: 1,
                        // background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 55.74%, rgba(0, 0, 0, 0.62) 100%)',
                        background: 'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 36.07%, rgba(0, 0, 0, 0.87) 100%)',
                        width: '100%',
                        height: '100%'
                      }}>
                        <PlayArrowIcon style={{opacity: 1, fontSize: isSmallScreen ? '2rem' : '4.5rem'}}/>
                        <h2 style={{
                          position: 'absolute',
                          bottom: isSmallScreen ? 5 : 10,
                          color: 'white',
                          fontWeight: 400,
                          left: 10,
                        }}>{meta?.chapter_name}</h2>
                      </div>
                    </div>
                  </Link>
                  {/*<ChapterListCard*/}
                  {/*  serial={meta?.serial_order}*/}
                  {/*  chapter_id={meta?.chapter_id}*/}
                  {/*  illustration_art={meta?.illustration_art}*/}
                  {/*  chapter_name={meta?.chapter_name}*/}
                  {/*  hex_color={meta?.hex_color}*/}
                  {/*  lecture_item_count={meta?.lecture_item_count}*/}
                  {/*  description={meta?.description}*/}
                  {/*  code={'maths'}*/}
                  {/*  className={'chats'}*/}
                  {/*  type={'LECTURES'}*/}
                  {/*  newTab={midCall}*/}
                  {/*/>*/}
                </div>
              </div>
            </>
          }
        })}

        <Modal
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setPdfUrl(null)}
          ariaHideApp={false}
          className="carousel-video-modal"
          overlayClassName="new-post-modal-overlay"
          isOpen={!!pdfUrl}
        >
          <PdfPreview pdf={pdfUrl} onClose={() => setPdfUrl(null)} />
        </Modal>

        <div className="scroll__to__end" ref={endScroll}></div>
      </div>
      {/*<div className="go-down">*/}
      {/*  <svg*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*    xmlSpace="preserve"*/}
      {/*    width={26}*/}
      {/*    height={26}*/}
      {/*  >*/}
      {/*    <g fill="#231F20">*/}
      {/*      <path d="M.046 2.582 2.13.498l10.837 10.836L23.803.498l2.084 2.084-12.92 12.92z" />*/}
      {/*      <path d="m.046 13.582 2.084-2.084 10.837 10.836 10.836-10.836 2.084 2.084-12.92 12.92z" />*/}
      {/*    </g>*/}
      {/*  </svg>*/}
      {/*  <span>2</span>*/}
      {/*</div>*/}
    </>

  );
}
