import React from "react";
import Plyr from "plyr";
import VisibilitySensor from "react-visibility-sensor";
import "react-visibility-sensor";
import "./style.scss";

class YoutubeEmbed extends React.Component {
  componentDidMount() {
    this.player = new Plyr(".youtube-player", {});
    this.player.source = {
      type: "video",
      sources: [
        {
          src: 'https://player.vimeo.com/video/686155431?h=2d4d53a393',
          provider: this.props.provider ?? "youtube",
        },
      ],
    };

    this.player.on("play", () => {
      this.props.muteFloating && this.props.muteFloating();
      try {
        var _videos = document.querySelectorAll("video");
        _videos.forEach((vid) => vid.pause());

        const _youtube = Array.from(
          document.querySelectorAll(`.plyr--youtube`)
        ).map((p) => p);

        _youtube.forEach((e) => {
          var _btn = e.querySelector('.plyr__controls > [data-plyr="play"]');
          if (
            _btn?.classList?.contains("plyr__control--pressed") &&
            !(
              e.querySelector(".plyr__poster").getAttribute("style") ===
                `background-image: url("https://i.ytimg.com/vi/${this.props.body.video_id}/maxresdefault.jpg");` ||
              e.querySelector(".plyr__poster").getAttribute("style") ===
                `background-image: url("https://i.ytimg.com/vi/${this.props.body.video_id}/sddefault.jpg");`
            )
          ) {
            _btn.click();
          }
        });
      } catch (error) {
        console.error(`facing error ${error} while youtube embed`);
      }
    });

    this.player.on("enterfullscreen", () => {
      this.props.muteFloating && this.props.muteFloating();
      this.player.play();
    });

    this._ismounted = true;
  }

  componentDidUpdate() {
    if (this.props?.shouldPause) {
      this.player.pause();
    }
  }

  componentWillUnmount() {
    this.player.destroy();
    this._ismounted = false;
  }

  render() {
    return (
      <VisibilitySensor
        onChange={(visible) => {
          if (!visible) {
            setTimeout(() => {
              if (!this.player.fullscreen.active) {
                this.player.pause();
              }
            });
          }
        }}
        key={this.props.body.video_id}
      >
        {this.props.body.video_id !== null ? (
          <div
            className="youtube-player-wrapper"
            style={{ marginTop: "8px" }}
            key={this.props.body.doubtId}
          >
            <video className="youtube-player plyr__video-embed" />
          </div>
        ) : (
          ""
        )}
      </VisibilitySensor>
    );
  }
}

export default YoutubeEmbed;
