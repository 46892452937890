import React, {useContext, useState} from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import {UserContext} from "../../../context";
import {auth, db} from "../../../firebase_config";
import ExitToApp from "@material-ui/icons/ExitToApp";

function Loader({className, style = {}}) {
  return <div className={"loader-37 flex-1 text-zinc-900 dark:text-white " + (className ? className : '')} style={style} />
}
export default function AskNameModal() {
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState('');
  const [user, setUser] = useContext(UserContext).user;
  const [openModal, setOpenNameModal] = useContext(UserContext).openNameModal;

  const handleChangeName = async () => {
    if(!user?.uid || messageText.trim().length === 0) return;
    await db.collection('users')
      .doc(user.uid)
      .set({
        name: messageText.trim()
      }, {merge: true})
    setMessageText('');
    setOpenNameModal(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      // className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-xl p-4"
      open={openModal}
      onClose={() => {
        setOpenNameModal(false)
      }}
      closeAfterTransition
      disableBackdropClick
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="w-80 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white dark:bg-zinc-900 rounded-xl p-4">
        <div className={"flex justify-between"}>
          <p className="divide-y divide-gray-100 text-zinc-900 dark:text-white font-medium font-sans">My name is</p>
          <button className="cursor-pointer text-red-700 text-sm" onClick={() => {
            auth.signOut();
          }}><ExitToApp /></button>
        </div>
        <hr className="h-px mt-3 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
        <input type="text" value={messageText} onChange={e => setMessageText(e.target.value)} id="first_name"
               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
               placeholder="Name" required />
        <button disabled={messageText.trim().length === 0} className={"mt-4 grant-button justify-center rounded-lg bg-gray-400 text-white w-full h-10 grant-button flex w-full items-center disabled:opacity-50 disabled:cursor-not-allowed "} onClick={handleChangeName}>
          {loading ? <Loader/> : (
            <>
              <span className="font-medium text-white text-md">Submit</span>
            </>
          )}
        </button>
      </div>
    </Modal>
  )
}
