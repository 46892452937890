import {useState, useEffect, useRef} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useMediaQuery } from "react-responsive";
import axios from 'axios';
import {firebaseFunctionBaseURL} from "../../../helpers/constants";

const contextBody = (auth) => ({context: {auth}});

const getRTCToken = (auth, account, channelName) => axios.post(firebaseFunctionBaseURL + 'getRTCToken' + `?account=${account}&channelName=${channelName}`, contextBody(auth));


export default function useAgora(client) {
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState(undefined);

  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const joiningRef = useRef(false);

  const isSmall = useMediaQuery({ query: "(max-width: 600px)" });

  async function createLocalTracks() {
    let encoderConfig = { width: 1280, height: 720 };

    if (isSmall) {
      // encoderConfig = { width: 720, height: 1280 };
    }
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks("", { encoderConfig });

    setLocalVideoTrack(cameraTrack);
    setLocalAudioTrack(microphoneTrack);
    return [microphoneTrack, cameraTrack];
  }

  async function join(appid, channel, token, uid, enableAudio, enableVideo) {
    if(joiningRef.current) return;
    joiningRef.current = true;
    try {
      if (!client) return;

      const [microphoneTrack, cameraTrack] = await createLocalTracks();


      const res = await getRTCToken(true, uid, channel);

      const token1 = res.data.key;

      if(['CONNECTING', 'CONNECTED'].includes(client.connectionState)) {
        await client.leave();
      }
      await client.join(appid, channel, token1 || null, uid);

      await cameraTrack.setMuted(!enableVideo)
      await microphoneTrack.setMuted(!enableAudio)

      await client.publish([microphoneTrack, cameraTrack]);


      window.client = client;
      window.videoTrack = cameraTrack;

      setJoinState(true);
      joiningRef.current = false;
    } catch (e) {
      console.log('e - ', e);
    }
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
    joiningRef.current = false;
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers.filter(c => c.uid !== client._uid));

    const handleUserPublished = async (user, mediaType) => {
      try {
        await client.subscribe(user, mediaType);
        // toggle rerender while state of remoteUsers changed.
        setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers.filter(c => c.uid !== client._uid)));
      } catch {}
    };

    const handleUserUnpublished = (user) => {
      try {
        setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers.filter(c => c.uid !== client._uid)));
      } catch {}
    };

    const handleUserJoined = (user) => {
      try {
        console.log('user joined - ', user, client);
        setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers.filter(c => c.uid !== client._uid)));
      } catch {}
    };

    const handleUserLeft = (user) => {
      try {
        setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers.filter(c => c.uid !== client._uid)));
      } catch {}
    };

    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-joined", handleUserJoined);
    client.on("user-left", handleUserLeft);

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-joined", handleUserJoined);
      client.off("user-left", handleUserLeft);
    };
  }, [client]);

  const toggleAudio = (status) => {
    try {
      localAudioTrack.setMuted(!status);
    } catch (error) {}
  };
  const toggleVideo = (status) => {
    try {
      localVideoTrack.setMuted(!status);
    } catch (error) {}
  };

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    toggleAudio,
    toggleVideo,
  };
}
