import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import StarRatings from "react-star-ratings";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  AddCircleOutline,
  EditRounded,
  Delete,
  CheckCircleOutlineRounded,
} from "@material-ui/icons";
import { showSnackbar, starPath } from "../../../helpers";
import { ThemeContext, UserContext } from "../../../context";
import {
  addInstructorSkill,
  deleteInstructorSkills,
  getOverallSkills,
} from "../../../database";

import "./style.scss";
import Lottie from "lottie-react-web";
import EmptyBox from "../../../assets/lottie/empty-box.json";

const SkillsList = ({ instructorSkills, ratings }) => {
  const [disabled, setDisabled] = useState(true);
  const [skillGrade, setSkillGrade] = useState("");
  const [allSkills, setAllSkills] = useState(null);
  const [skillSubject, setSkillSubject] = useState("");
  const [skillCategory, setSkillCategory] = useState("");
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [skillsList, setSkillsList] = useState(null);
  const [edittingList, setEdittingList] = useState(false);

  const gradesList = [
    { value: "class_9", name: "Class 9" },
    { value: "class_10", name: "Class 10" },
  ];
  console.log('gradesList - ', gradesList);
  const categoryIdx = {
    Maths: 0,
    Science: 1,
    SST: 2,
    English: 3,
    General: 4,
  };

  const [user] = useContext(UserContext).user;
  const [isDark] = useContext(ThemeContext).theme;

  useEffect(() => {
    getOverallSkillsFn();
  }, []);

  useEffect(() => {
    const res = isBtnDisabled();
    setDisabled(res);
  }, [skillCategory, skillGrade, skillSubject]);

  const getOverallSkillsFn = async () => {
    const res = await getOverallSkills();
    setAllSkills(res);
  };

  const handleSkillModalClose = () => {
    setOpenSkillModal(false);
    setSkillCategory("");
    setSkillSubject("");
    setSkillGrade("");
  };

  const isBtnDisabled = () => {
    if (skillCategory === "Maths") return false;
    else if (skillCategory !== "Maths" && skillSubject) return false;

    return true;
  };

  const handleAddSkill = async () => {
    let _skillId = "";
    if (skillCategory === "Maths") {
      _skillId = skillGrade + "_maths";
    } else {
      _skillId =
        skillGrade +
        "_" +
        skillCategory.toLowerCase() +
        "_" +
        skillSubject.replace(" ", "_").toLowerCase();
    }

    if (_skillId) {
      try {
        let skillToAdd = {
          category: skillCategory,
          grade: skillGrade,
          id: _skillId,
          is_skippable:
            allSkills[skillGrade]?.skip_subjects[categoryIdx[skillCategory]],
          reason: null,
          status: "requested",
          subject: skillCategory === "Maths" ? null : skillSubject,
        };

        const res = await addInstructorSkill({
          instructorId: user?.uid,
          skillId: _skillId,
          skill: skillToAdd,
        });

        if (res) {
          handleSkillModalClose();
        } else console.log("failed");
      } catch (er) {
        console.log(er);
      }
    }
  };

  const addSkillToDelete = (skill) => {
    setSkillsToDelete([...skillsToDelete, skill.id]);
  };

  const cancelSkillDelete = (skill) => {
    setSkillsToDelete([...skillsToDelete.filter((item) => item !== skill.id)]);
  };

  const isSelectedToDelete = (skillId) => {
    return skillsToDelete.filter((item) => item === skillId).length === 1;
  };

  const handleSkillsDelete = async () => {
    const res = await deleteInstructorSkills({
      instructorId: user?.uid,
      skillsToDelete,
    });
    if (res) {
      showSnackbar(
        skillsToDelete?.length > 1
          ? "Skills have been deleted"
          : "Skill has been deleted",
        "success"
      );

      setSkillsToDelete([]);
    } else {
      showSnackbar("Could not delete the selected skill(s)", "error");
      setSkillsToDelete([]);
    }
  };

  const isSkillAlreadyAdded = (subject) => {
    return (
      instructorSkills?.filter((item) => item.subject === subject).length === 1
    );
  };

  const isMathsSkillAdded = () => {
    return (
      instructorSkills?.filter((item) => item.category === "Maths").length === 1
    );
  };

  const getAverage = (arr) => {
    if (arr) {
      const sum = arr.reduce((a, b) => a + b, 0);
      return sum / arr.length || 0;
    }
    return 0;
  };

  useEffect(() => {
    if (ratings && instructorSkills) {
      let _subjectsRating = {};
      for (let key in ratings?.skills) {
        const splitted = key.split("_");
        let sub = splitted[splitted.length - 1];

        _subjectsRating[sub] = {
          average: [
            ...(_subjectsRating[sub]?.average || []),
            ratings?.skills[key].average,
          ],
          grades: [
            ...(_subjectsRating[sub]?.grades || []),
            Number(splitted[1]),
          ],
        };
      }

      let _subjects = [];
      let _instructorSkills = [...instructorSkills];

      _instructorSkills = _instructorSkills?.map((item) => {
        const splitted = item.id.split("_");
        let sub = splitted[splitted.length - 1];

        if (_subjects.includes(sub)) {
          _instructorSkills = _instructorSkills.filter((t) => t.id !== item.id);
          return;
        }

        _subjects.push(sub);

        item["rating"] = getAverage(_subjectsRating[sub]?.average);
        item["grades"] = _subjectsRating[sub]?.grades?.sort((a, b) => a - b);

        return item;
      });

      setSkillsList(_instructorSkills);
    }
  }, [ratings, instructorSkills]);

  return (
    <div className="tutor-settings-body">
      <div className="settings-skills-wrapper">
        <div className="skills-head">
          <h1>Skills Ratings</h1>
          {skillsList?.length > 0 &&
            (edittingList ? (
              <div className="edit-section">
                {skillsToDelete?.length > 0 ? (
                  <button
                    className="delete-btn popIn"
                    onClick={handleSkillsDelete}
                  >
                    Delete
                  </button>
                ) : (
                  <button
                    className="add-skill"
                    onClick={() => setOpenSkillModal(true)}
                  >
                    <AddCircleOutline />
                  </button>
                )}
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setSkillsToDelete([]);
                    setEdittingList(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button onClick={() => setEdittingList(true)}>
                <EditRounded />
                Edit
              </button>
            ))}
        </div>
        <div>
          {skillsList?.length === 0 && (
            <div className="no-skills">
              <h1>No skills have been added</h1>

              <button onClick={() => setOpenSkillModal(true)}>
                Add Skills
              </button>
            </div>
          )}
          <table className="skills-table">
            {skillsList?.map(
              (item, i) =>
                item && (
                  <tr
                    className="skills-row fadeIn"
                    style={{ animationDelay: `${0.15 * (i + 1)}s` }}
                    key={item?.id}
                  >
                    <td
                      className={isSelectedToDelete(item?.id) && "fadeOutItem"}
                    >
                      {item.status === "requested" ? (
                        <div className="requested-status" />
                      ) : (
                        <CheckCircleOutlineRounded className="check-circle" />
                      )}
                    </td>
                    <td
                      className={isSelectedToDelete(item?.id) && "fadeOutItem"}
                    >
                      <h1>{item?.subject ? item?.subject : item?.category}</h1>
                    </td>

                    <td
                      className={isSelectedToDelete(item?.id) && "fadeOutItem"}
                    >
                      <h1>
                        {item?.grades
                          ? "Class " + item?.grades.join(", ")
                          : item?.grade?.replace("_", " ")}
                      </h1>
                    </td>
                    <td
                      className={isSelectedToDelete(item?.id) && "fadeOutItem"}
                    >
                      <div className="skill-rating">
                        <StarRatings
                          rating={item?.rating > 0 ? item.rating : 0}
                          starRatedColor="#fec107"
                          starHoverColor="#fec107"
                          numberOfStars={5}
                          starDimension="15px"
                          starSpacing="1px"
                          name="rating"
                          svgIconPath={starPath}
                          svgIconViewBox="0 0 207.802 207.748"
                        />
                      </div>
                    </td>

                    <td
                      className={isSelectedToDelete(item?.id) && "fadeOutItem"}
                    >
                      <div className={edittingList ? "show-bin" : "hide-bin"}>
                        <button>
                          <Delete
                            onClick={() =>
                              !isSelectedToDelete(item?.id) &&
                              addSkillToDelete(item)
                            }
                          />
                        </button>
                      </div>
                    </td>

                    {isSelectedToDelete(item?.id) && (
                      <td className="popIn cancel-delete">
                        <CancelIcon onClick={() => cancelSkillDelete(item)} />
                      </td>
                    )}
                  </tr>
                )
            )}
          </table>
        </div>
      </div>

      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleSkillModalClose}
        ariaHideApp={false}
        overlayClassName="new-post-modal-overlay"
        isOpen={openSkillModal}
        className={isDark ? "skills-modal dark" : "skills-modal"}
      >
        {openSkillModal && (
          <div className="skills-modal-inner">
            <div className="modal-head">
              <h1>Add Skills</h1>
              <CloseIcon onClick={handleSkillModalClose} />
            </div>

            <div className="grades-list-wrapper">
              <h1>Select Grade</h1>
              <div className="grades-list">
                {gradesList.map((item) => (
                  <span
                    key={item.value}
                    onClick={() => {
                      setSkillGrade(item.value);
                      setSkillCategory("");
                      setSkillSubject("");
                    }}
                    className={
                      item.value === skillGrade ? "chip selected" : "chip"
                    }
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            {skillGrade && (
              <div className="categories-list-wrapper">
                <h1>Select Category</h1>
                <div className="categories-list">
                  {allSkills[skillGrade]?.categories.map(
                    (item, i) =>
                      !(isMathsSkillAdded() && item === "Maths") && (
                        <span
                          key={i}
                          onClick={() => {
                            setSkillCategory(item);
                            setSkillSubject("");
                          }}
                          className={
                            item === skillCategory ? "chip selected" : "chip"
                          }
                        >
                          {item}
                        </span>
                      )
                  )}
                </div>
              </div>
            )}
            {skillCategory &&
              !allSkills[skillGrade]?.skip_subjects[
                categoryIdx[skillCategory]
              ] && (
                <div className="subjects-list-wrapper">
                  <h1>Select Subject</h1>
                  <div className="subjects-list">
                    {allSkills[skillGrade].subjects[skillCategory].map(
                      (item, i) =>
                        !isSkillAlreadyAdded(item) && (
                          <span
                            key={i}
                            className={
                              item === skillSubject ? "chip selected" : "chip"
                            }
                            onClick={() => setSkillSubject(item)}
                          >
                            {item}
                          </span>
                        )
                    )}
                  </div>
                </div>
              )}

            <button
              className="add-skill-btn"
              disabled={disabled}
              type="submit"
              onClick={() => handleAddSkill()}
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SkillsList;
