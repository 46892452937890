import React, { useState } from "react";
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import DurationDropdown from "../durationDropdown";
import DateRangeIcon from "@material-ui/icons/DateRangeRounded";

import "./style.scss";
import {useMediaQuery} from "react-responsive";

const DetailedChart = ({
  dataKey,
  miniChartData,
  durationObj,
  setDurationObj,
  className = ''
}) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 500px)" });
  const strokes = {
    hours: "#8884d8",
    sessions: "#11993f",
    earnings: "#ffc658",
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let count = payload[0].payload[dataKey];
      if (dataKey === "hours") {
        count = Math.round(count * 10) / 10 + " hours";
      } else if (dataKey === "sessions") {
        count = count + " sessions";
      } else {
        count = "₹ " + count;
      }
      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          <p className="intro">{count}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={"tutor-chart-main " + className} key={dataKey}>
      <div className="tutor-chart-main-inner">
        <div className="tutor-chart">
          <h1>{dataKey === "hours" ? "Call Duration" : dataKey}</h1>
          <div className="tutor-chart-timeline">
            <DateRangeIcon className="date-range-icon" />{" "}
            <DurationDropdown
              durationObj={durationObj}
              setDurationObj={setDurationObj}
            />
          </div>

          <ResponsiveContainer
            width="100%"
            height="85%"
            className="tutor-chart-container"
          >
            <AreaChart
              width={500}
              height={400}
              data={miniChartData}
              margin={{
                top: 25,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <defs>
                <linearGradient id={dataKey} x1="0" y1="0" x2="0" y2="0">
                  <stop
                    offset="5%"
                    stopColor={strokes[dataKey]}
                    stopOpacity={0.15}
                  />
                  <stop
                    offset="95%"
                    stopColor={strokes[dataKey]}
                    stopOpacity={0.15}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDashArray="1 1"
                vertical={false}
                style={{ stroke: "rgba(128, 128, 128, 0.05" }}
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                style={{ stroke: "rgba(128, 128, 128, 0.1" }}
                hide
              />
              <YAxis axisLine={false} tickLine={false} mirror style={{fontSize: isSmallScreen ? '12px' : '15px', zIndex: 23}} />
              <Tooltip
                content={<CustomTooltip />}
                cursor={true}
                position={{ x: "auto", y: 0 }}
              />
              <Area
                type={"monotone"}
                dataKey={dataKey}
                stackId="1"
                stroke={strokes[dataKey]}
                strokeWidth={4}
                fill={`url(#${dataKey})`}
                activeDot={{ strokeWidth: 0, r: 0 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DetailedChart;
