import firebase, { database } from "firebase";
import "firebase/firestore";
import { v4 as uuid } from "uuid";
import {
  db,
  functions,
  storage,
  asiaFunctions,
  rdb,
} from "../../firebase_config";
import { castIndianTime, getIndianTime, showSnackbar } from "../../helpers";
import Axios from "axios";
import {
  fetchIndianTime,
  transformDateTimeObject,
} from "../../helpers/functions/getIndianTime";
import axios from "axios";
import { firebaseFunctionBaseURL } from "../../helpers/constants";
import { toDate } from "date-fns-tz";
import { defaultPic } from "../../assets";

export async function getBlazeSessions({
  user_id,
  type,
  timestamp,
  limit,
  callback,
}) {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("student_id", "==", user_id)
    .where("session_status", "==", type)
    .orderBy(timestamp, "desc")
    .limit(limit)
    .onSnapshot((snapshot) => {
      let sessions = [];

      if (snapshot.docs.length === 0) {
      } else {
        for (let i = 0; i < snapshot.docs.length; i++) {
          let _currentDocument = snapshot.docs[i].data();

          sessions.push({
            ..._currentDocument,
            reference: snapshot.docs[i].ref,
          });
        }
      }

      callback(sessions);
    });
}

export const getBlazeCallHistory = ({ reference, callback }) => {
  if (!reference) return;

  reference
    .collection("calls")
    .orderBy("sent_on", "asc")
    .onSnapshot((e) => {
      let _data = [];

      for (let i = 0; i < e.docs.length; i++) {
        let _current = e.docs[i].data();

        let _currentCall = null;

        _currentCall = {
          type: "call_detail",
          status: _current?.status,
          timestamp: _current?.sent_on,
          duration: _current?.active_call_duration,
          student_platform: _current?.student_platform,
          instructor_platform: _current?.instructor_platform,
        };

        _data.push(_currentCall);
      }
      callback(_data);
    });
};

export async function getLatestBlazeReservationChats({
  reference,
  user_id,
  doc,
  callback,
}) {
  const querySnapshot = await reference.collection("chats").limit(1).get();

  console.log("querySnapshot.empty - ", querySnapshot.empty);

  if (querySnapshot.empty) {
    return callback(false);
  }

  if (typeof reference !== "undefined" && doc) {
    reference
      .collection("chats")
      .orderBy("sent_on", "asc")
      .where("sent_on", ">", doc?.timestamp)
      .onSnapshot((e) => {
        let docs = e.docs;

        console.log("e.docChanges() - ", e.docChanges());

        let modified = false;

        if (e.docChanges().length > 0) {
          docs = e.docChanges().map((c) => c.doc);
          if (e.docChanges()[0].type === "modified") {
            modified = true;
          }
        }

        let _data = [];

        for (let i = 0; i < docs.length; i++) {
          let _current = docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser: user_id === _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
              receiver_id: _current?.receiver_id,
              chat_id: _current?.chat_id,
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
              chat_id: _current?.chat_id,
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
                chat_id: _current?.chat_id,
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
                chat_id: _current?.chat_id,
              };
            } else if (_current?.attachment?.attachment_type === "chapter") {
              _currentChat = {
                type: "chapter",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            }
          }
          _data.push(_currentChat);
        }
        callback(_data, modified);
      });
  } else callback([]);
}

export async function getBlazeReservationChats({ reference, user_id, limit }) {
  if (typeof reference !== "undefined") {
    return await reference
      .collection("chats")
      .orderBy("sent_on", "desc")
      .limit(limit)
      .get()
      .then((e) => {
        let _data = [];

        for (let i = 0; i < e.docs.length; i++) {
          let _current = e.docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser:
                _current?.sender_id === "pustack"
                  ? true
                  : user_id === _current?.sender_id,
              sender_id: _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
              };
            } else if (_current?.attachment?.attachment_type === "lecture") {
              _currentChat = {
                type: "lecture",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "chapter") {
              _currentChat = {
                type: "chapter",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta,
                },
                timestamp: _current?.sent_on,
                isByUser:
                  _current?.sender_id === "pustack"
                    ? true
                    : user_id === _current?.sender_id,
                message: _current?.message,
                sender_id: _current?.sender_id,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            }
          }

          _data.push(_currentChat);
        }
        return _data.reverse();
      })
      .catch((err) => console.log(err));
  } else return [];
}

export async function getMoreBlazeReservationChats({
  reference,
  user_id,
  doc,
}) {
  if (typeof reference !== "undefined") {
    return await reference
      .collection("chats")
      .orderBy("sent_on", "desc")
      .where("sent_on", "<", doc?.timestamp)
      .limit(10)
      .get()
      .then((e) => {
        let _data = [];

        for (let i = 0; i < e.docs.length; i++) {
          let _current = e.docs[i].data();

          let _currentChat = null;

          if (_current?.message_type === "text") {
            _currentChat = {
              type: _current?.message_type,
              attachment: _current?.attachment,
              timestamp: _current?.sent_on,
              message: _current?.message,
              isByUser: user_id === _current?.sender_id,
              sender_pic: _current?.sender_profile_picture,
            };
          }
          if (_current?.message_type === "call_event") {
            _currentChat = {
              type: _current?.message_type,
              event_type: _current?.event_type,
              timestamp: _current?.sent_on,
              message: _current?.event_description,
            };
          }
          if (_current?.message_type === "attachment") {
            if (_current?.attachment?.attachment_type === "image") {
              _currentChat = {
                type: "image",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
              };
            } else if (_current?.attachment?.attachment_type === "pdf") {
              _currentChat = {
                type: "document",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
              };
            } else if (_current?.attachment?.attachment_type === "lecture") {
              _currentChat = {
                type: "lecture",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            } else if (_current?.attachment?.attachment_type === "chapter") {
              _currentChat = {
                type: "chapter",
                attachment: {
                  url: _current?.attachment?.attachment_url,
                  name: _current?.attachment?.attachment_name,
                  _meta: _current?.attachment?._meta,
                },
                timestamp: _current?.sent_on,
                isByUser: user_id === _current?.sender_id,
                message: _current?.message,
                sender_pic: _current?.sender_profile_picture,
                receiver_id: _current?.receiver_id,
              };
            }
          }

          _data.push(_currentChat);
        }
        return _data.reverse();
      })
      .catch((err) => console.log(err));
  } else return null;
}

const uploadImage = async (file, sessionId, userId) => {
  var path = `blaze_sessions/${sessionId}/from-${userId}/${uuid()}.png`;
  var _url = await storage
    .ref()
    .child(path)
    .put(await fetch(file.url).then((r) => r.blob()))
    .then(async (snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => url);
    });

  return _url;
};

const uploadPdf = async (file, sessionId, userId) => {
  var path = `blaze_sessions/${sessionId}/from-${userId}/${uuid()}.png`;
  var _url = await storage
    .ref()
    .child(path)
    .put(await fetch(file.url).then((r) => r.blob()))
    .then(async (snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => url);
    });

  return _url;
};

export async function sendBlazeChat({
  user,
  text,
  reference,
  images = [],
  pdfs = [],
  session_id,
  receiver_id,
  session_topic,
  student_grade,
  type,
  disableNotification = false,
  attachmentObj = undefined,
}) {
  let _imageUrl, _pdfUrl;

  let _attachment;
  const ist = await castIndianTime(true);
  const dateTimeObject = transformDateTimeObject(ist);

  if (images.length > 0) {
    _imageUrl = await uploadImage(images[0], session_id, user?.uid).then(
      (url) => url
    );

    _attachment = {
      attachment_name: images[0].name,
      attachment_type: "image",
      attachment_url: _imageUrl,
    };
  }
  if (pdfs.length > 0) {
    _pdfUrl = await uploadPdf(pdfs[0], session_id, user?.uid).then(
      (url) => url
    );

    _attachment = {
      attachment_name: pdfs[0].name,
      attachment_type: "pdf",
      attachment_url: _pdfUrl,
    };
  }

  if (attachmentObj) {
    _attachment = attachmentObj;
  }

  const ref = reference.collection("chats").doc();

  const chatObject = {
    message: text,
    message_type: type,
    attachment: _attachment ?? null,
    sender_id: user?.uid,
    id: ref.id,
    sender_name: "Prof. " + user?.name?.split(" ")[0],
    sender_profile_picture: user?.profile_url ?? defaultPic,
    receiver_id: receiver_id,
    session_topic,
    sent_on: ist.serverISOString,
  };

  let deliverBlazeMessageNotification = functions.httpsCallable(
    "deliverBlazeMessageNotification"
  );

  chatObject.chat_id = ref.id;

  await ref.set(chatObject);
  await reference
    .set(
      {
        last_message_ts: ist.serverISOString,
        last_message_sender_id: user?.uid,
      },
      { merge: true }
    )
    .catch((err) => console.log(err));

  if (disableNotification) return;

  if (user?.is_external_instructor) {
    if (receiver_id) {
      deliverBlazeMessageNotification({
        receiver_id: receiver_id,
        message: chatObject,
        is_receiver_instructor: false,
        session_id: session_id,
        session_topic,
      }).catch((err) => console.log(err));

      reference
        .collection("chat_meta")
        .doc(receiver_id)
        .set(
          {
            unread_count: firebase.firestore.FieldValue.increment(1),
            last_message: chatObject,
          },
          { merge: true }
        );

      // db.collection("user_notifications")
      //   .doc(student_grade)
      //   .collection("user_notifications")
      //   .doc(receiver_id)
      //   .set(
      //     {
      //       unread_blaze_message_count:
      //         firebase.firestore.FieldValue.increment(1),
      //     },
      //     { merge: true }
      //   );
    }
  } else {
    if (receiver_id) {
      deliverBlazeMessageNotification({
        receiver_id: receiver_id,
        message: chatObject,
        session_id: session_id,
        session_topic,
      }).catch((err) => console.log(err));

      reference
        .collection("chat_meta")
        .doc(receiver_id)
        .set(
          {
            unread_count: firebase.firestore.FieldValue.increment(1),
            last_message: chatObject,
          },
          { merge: true }
        );

      // db.collection("user_notifications")
      //   .doc("instructor")
      //   .collection("user_notifications")
      //   .doc(receiver_id)
      //   .set(
      //     {
      //       unread_blaze_message_count:
      //         firebase.firestore.FieldValue.increment(1),
      //     },
      //     { merge: true }
      //   );
    }
  }
  await rdb
    .ref(
      "/users/" +
        receiver_id +
        "/pustack_app/unread_blaze_message_count/" +
        session_id
    )
    .set(database.ServerValue.increment(1));

  return ref.id;
}

export const blazeDecreaseMessageCount = (
  reference,
  count,
  grade,
  userId,
  isExternalInstructor,
  receiverId,
  sessionId
) => {
  console.log("count - ", count);
  // const decrement = firebase.database.ServerValue.increment(-1 * count);
  console.log(
    "totalMessagesUnreadCountRef - ",
    reference,
    count,
    grade,
    userId,
    isExternalInstructor,
    receiverId,
    sessionId
  );

  if (reference !== null) {
    // let totalMessagesUnreadCountRef = db
    //   .collection("user_notifications")
    //   .doc(grade)
    //   .collection("user_notifications")
    //   .doc(userId);
    let totalMessagesUnreadCountRef = rdb.ref(
      "/users/" +
        userId +
        "/pustack_tutor_app/unread_blaze_message_count/" +
        sessionId
    );

    console.log("totalMessagesUnreadCountRef - ", totalMessagesUnreadCountRef);
    totalMessagesUnreadCountRef.set(0);

    reference.collection("chat_meta").doc(userId).set(
      {
        unread_count: 0,
      },
      { merge: true }
    );
  }

  navigator.serviceWorker.getRegistrations().then((registrations) => {
    const reg = registrations.find(
      (c) =>
        c.active.scriptURL ===
        "https://tutor.pustack.com/firebase-messaging-sw.js"
    );
    reg.getNotifications({ tag: sessionId }).then((notifications) => {
      notifications.forEach((c) => c.close());
    });
  });

  if (userId && sessionId) {
    const dismissBlazeMessageNotification = asiaFunctions.httpsCallable(
      "dismissBlazeMessageNotification"
    );

    dismissBlazeMessageNotification({
      uid: userId,
      is_receiver_instructor: true,
      session_id: sessionId,
    });
  }
};

export const blazeInstructorMetaDetails = async (instructorId) => {
  if (typeof instructorId !== "undefined") {
    const metaRef = db
      .collection("users")
      .doc(instructorId)
      .collection("meta")
      .doc(instructorId);

    return await metaRef.get().then((data) => data.data());
  }

  return null;
};

export const blazeUnreadMesagesNotification = ({
  userId,
  grade,
  isExternal,
  callback,
}) => {
  db.collection("user_notifications")
    .doc(isExternal ? "instructor" : grade)
    .collection("user_notifications")
    .doc(userId)
    .onSnapshot((snapshot) => {
      if (snapshot.exists) {
        let _data = snapshot.data();
        if (_data["unread_blaze_message_count"] > 0) {
          return callback(true);
        }

        callback(false);
      }
    });
};

export const blazeReservationMeta = async ({ grade }) => {
  return await db
    .collection("chapter_cards")
    .doc(grade)
    .get()
    .then((doc) => {
      const _data = doc.data();

      return _data["subject_chapter_map"];
    })
    .catch((_) => {});
};

export const subjectColorsMeta = async ({ grade }) => {
  return await db
    .collection("chapter_cards")
    .doc(grade)
    .get()
    .then((doc) => {
      const _data = doc.data();

      return _data["subject_color_map"];
    })
    .catch((_) => {});
};

export const getStudentActiveSessionDetails = ({ studentId, callback }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .onSnapshot((response) => {
      if (response.exists) {
        callback(response.data());
      } else {
        callback(null);
      }
    });
};

export const getRtmToken = async ({ sessionId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .doc(sessionId)
    .get()
    .then((response) => {
      if (response.exists) {
        return response.data()?.rtm_token;
      } else return null;
    })
    .catch(() => null);
};

export const endSession = async ({
  sessionId,
  completedTs,
  studentId,
  instructorId,
  instructorName,
  instructorProfilePic,
  topic,
  skill,
  ratingTs,
  rating = null,
  is_out_reach,
}) => {
  const snapshot = await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .get();

  const data = snapshot.data();
  if (snapshot.exists && data.call_state?.session_id === sessionId) {
    return showSnackbar("Cannot end a session during a call", "error");
  }

  if (completedTs) {
    if (instructorId && !ratingTs && !is_out_reach) {
      await db
        .collection("blaze_dev")
        .doc("collections")
        .collection("students")
        .doc(studentId)
        .set(
          {
            pending_rating_list: firebase.firestore.FieldValue.arrayUnion({
              instructor_id: instructorId,
              instructor_name: instructorName,
              instructor_profile_pic: instructorProfilePic,
              session_id: sessionId,
              topic: topic,
              skill: skill,
              rating_ts: null,
              session_end_ts: completedTs,
            }),
          },
          { merge: true }
        );
    }

    if (rating) {
      Axios.post(
        "https://us-central1-avian-display-193502.cloudfunctions.net/updateSkillRating",
        {
          rating: rating,
          skill: skill,
          instructorId: instructorId,
          sessionId: sessionId,
          studentId: studentId,
          context: {
            auth: studentId,
          },
        }
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    return await db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .set(
        {
          session_status: "completed",
          completed_ts: completedTs,
          rating: rating,
          rating_ts: ratingTs,
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  } else {
    if (rating) {
      Axios.post(
        "https://us-central1-avian-display-193502.cloudfunctions.net/updateSkillRating",
        {
          rating: rating,
          skill: skill,
          instructorId: instructorId,
          sessionId: sessionId,
          studentId: studentId,
          context: {
            auth: studentId,
          },
        }
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }

    return await db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .set(
        {
          rating: rating,
          rating_ts: ratingTs,
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  }
};

export const pendingSessionList = async ({ studentId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data().pending_rating_list || [];
      }
    })
    .catch(() => []);
};

export const updatePendingRatingList = ({ session, completedTs }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(session?.student_id)
    .set(
      {
        pending_rating_list: firebase.firestore.FieldValue.arrayUnion({
          completed_ts: completedTs,
          instructor_id: session?.instructor_id,
          instructor_name: session?.instructor_name,
          session_id: session?.id,
          topic: session?.topic,
          session_end_ts: completedTs,
        }),
        session_count: firebase.firestore.FieldValue.increment(1),
      },
      { merge: true }
    );
};

export const updateInstructorRating = ({ instructorId, topic, rating }) => {
  db.collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .collection("stats")
    .doc("topic_wise_rating")
    .get()
    .then((doc) => {
      let topicArray = [rating];
      if (doc.exists) {
        let ratingMap = doc.data().rating_map;
        if (ratingMap) {
          topicArray = ratingMap[topic];

          if (topicArray) topicArray.push(rating);
          else topicArray = [rating];
        }
      }

      db.collection("blaze_dev")
        .doc("collections")
        .collection("instructors")
        .doc(instructorId)
        .collection("stats")
        .doc("topic_wise_rating")
        .set(
          {
            rating_map: {
              [topic]: topicArray,
            },
          },
          { merge: true }
        );
    })
    .catch((err) => console.log(err));
};

export const getInstructorRatings = async ({ instructorId }) => {
  if (instructorId) {
    return await db
      .collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("stats")
      .doc("skill_wise_rating")
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data().average_rating;
        }
      });
  }
};

export const listenToUnreadMessages = ({ sessionId, userId, callback }) => {
  if (sessionId) {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)
      .collection("chat_meta")
      .doc(userId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          callback(doc.data().unread_count);
        }
      });
  }
};

export const listenForUnreadCounts = async (userId, callback) => {
  const querySnapshot = await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("blaze_sessions")
    .where("instructor_id", "==", userId)
    .where("completed_ts", "==", null)
    .get();

  querySnapshot.forEach((doc) => {
    doc.ref
      .collection("chat_meta")
      .doc(userId)
      .onSnapshot((snapshot) => {
        if (snapshot.data().unread_count > 0) {
          callback({
            [doc.id]: snapshot.data().unread_count,
          });
        }
      });
  });
};

export const listenToOutstandingSession = ({ sessionId, callback }) => {
  if (sessionId) {
    let unsubscribe = db
      .collection("blaze_dev")
      .doc("collections")
      .collection("blaze_sessions")
      .doc(sessionId)

      .onSnapshot((doc) => {
        if (doc.exists) {
          callback(doc.data(), unsubscribe);
        }
      });
  }
};

export const isStudentEngaged = async ({ studentId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("students")
    .doc(studentId)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        const ist = await castIndianTime(true);
        const isEngaged = doc.data().call_state?.is_engaged;
        const expiresOn = doc.data().call_state?.expires_on;
        const sessionId =
          doc.data().active_call_session_id ??
          doc.data().call_state?.session_id;
        const meetingId =
          doc.data().active_call_id ?? doc.data().call_state?.call_id;

        // const isStudentEngaged = isEngaged && ist.serverISOString > expiresOn;

        if (ist.serverISOString < expiresOn) return true;

        if (!isEngaged) return false;

        // if (isStudentEngaged) {
        return await db
          .collection("blaze_dev")
          .doc("collections")
          .collection("blaze_sessions")
          .doc(sessionId)
          .collection("calls")
          .doc(meetingId)
          .get()
          .then((doc1) => {
            if (doc1.exists) {
              let status = doc1.data().status;

              if (status === "engaged") {
                return true;
              }
            }
            return false;
          })
          .catch(() => true);
        // }
      } else return false;
    })
    .catch(() => true);
};

export const getReceiverUnreadCount = ({ sessionId, receiverId, callback }) => {
  rdb
    .ref("/users/" + receiverId + "/pustack_app/unread_blaze_message_count")
    .on("value", (snapshot) => {
      console.log("snapshot.val() = ", snapshot);
      const values = snapshot.val() ?? {};
      callback(values[sessionId] ?? 0);
    });
  // db.collection("blaze_dev")
  //   .doc("collections")
  //   .collection("blaze_sessions")
  //   .doc(sessionId)
  //   .collection("chat_meta")
  //   .doc(receiverId)
  //   .onSnapshot((doc) => {
  //     if (doc.exists) {
  //       callback(doc.data().unread_count);
  //     } else {
  //       callback(0);
  //     }
  //   });
};

export const isNotValidDateHash = (dateHash) => {
  function isNotValidHashValue(val) {
    return isNaN(val);
  }
  return (
    !dateHash ||
    isNotValidHashValue(dateHash.year) ||
    isNotValidHashValue(dateHash.month) ||
    isNotValidHashValue(dateHash.date)
  );
};

export const formatDateDoc = (date, dontIncMonth, withoutDate) => {
  if (isNotValidDateHash(date))
    throw new Error("Date is not in the correct hash {date, month, year}");
  if (withoutDate) return `${date.year}_${date.month + (dontIncMonth ? 0 : 1)}`;
  return `${date.year}_${date.month + (dontIncMonth ? 0 : 1)}_${date.date}`;
};

/**
 * @deprecated Use queryStudentEngagement function instead
 * @internal queryStudentEngagement
 * @param studentId
 * @description Will return the remaining minutes of particular student for today.
 */
export const usedMinutesForToday = async (studentId) => {
  const ist = await fetchIndianTime();

  return db
    .collection("/blaze_dev/collections/students/" + studentId + "/engagement")
    .doc(formatDateDoc(ist, false, true))
    .get()
    .then((c) => {
      if (c.exists) {
        const d = c.data();
        if (!d.daily_engagement[formatDateDoc(ist)]) return 0;
        return Object.keys(d.daily_engagement[formatDateDoc(ist)]).reduce(
          (acc, cur) => {
            acc += d.daily_engagement[formatDateDoc(ist)][cur].call_duration;
            return acc;
          },
          0
        );
      }
      console.log("Document not found in used Minutes.");
      return null;
    });
};

/**
 *
 * @param studentId
 * @returns {Promise<{upper_limit: number, total_call_duration: number, is_eligible: number}>}
 */
export const queryStudentEngagement = async (studentId) => {
  const res = await axios.post(
    firebaseFunctionBaseURL + "queryBlazeStudentEngagement",
    {
      student_id: studentId,
    }
  );

  return res.data.response;
};

/**
 *
 * @param studentId
 * @returns {Promise<{upper_limit: number, total_call_duration: number, is_eligible: number}>}
 */
export const queryStudentAvailableDoubtCount = async (studentId) => {
  const res = await axios.post(
    firebaseFunctionBaseURL + "queryAvailableDoubtCount",
    {
      student_id: studentId,
    }
  );

  return res.data.response;
};
