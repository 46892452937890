import React, { useState, useContext, useCallback, useRef } from "react";
import Lottie from "lottie-react-web";
import Tab from "@material-ui/core/Tab";
import { Menu } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import StarRatings from "react-star-ratings";
import { starPath } from "../../../helpers";
import BlazeExternalCard from "../blazeCard";
import { makeStyles } from "@material-ui/core/styles";
import EmptyBox from "../../../assets/lottie/empty-box.json";
import { BlazeExternalContext, ThemeContext } from "../../../context";
import BlazeCardLoader from "../../../components/blazeExternal/blazeCardLoader";
import "./style.scss";
import InfiniteScroll from "../../../components/global/infinite-scroll";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: { color: theme.palette.common.black },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.75)",
    color: "rgba(255,255,255,1)",
    maxWidth: 260,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13.5),
    padding: ".5rem .75rem",
  },
}));

export default function BlazeExternalSidebar({
  initialized,
  _initialized,
  acceptedSessions,
  targetedItem,
  fetchMoreAcceptedSessions,
  noMoreAcceptedSessions,
  fetchMoreCompletedSessions,
  noMoreCompletedSessions,
  completedSessions,
  closeDrawer,
  setIsChatOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isDark] = useContext(ThemeContext).theme;
  const [, setOpenPages] = useContext(BlazeExternalContext).openPages;
  const [activeTab, setActiveTab] = useContext(BlazeExternalContext).tabIdx;
  const [sessionId, setSessionId] = useContext(BlazeExternalContext).sessionId;
  const [, setSessionSelected] =
    useContext(BlazeExternalContext).sessionSelected;
  const targetedRef = useRef(null);


  const targetRefCB = useCallback((node, _sessionId) => {
    if(!_sessionId) targetedRef.current = null;
    console.log('')
    // if(_sessionId === sessionId) {
    //   targetedRef.current = node;
    //   let timeout = setTimeout(() => {
    //     if(node) {
    //       console.log('node - ', node);
    //       node.scrollIntoView({
    //         behavior: 'smooth',
    //         block: 'center'
    //       });
    //     }
    //   }, 500)
    //
    //   return () => {
    //     clearTimeout(timeout);
    //   }
    // }
  }, [sessionId]);

  const handleTabChange = (_, value) => {
    if (value === 1) {
      if (acceptedSessions?.length === 0) {
        setSessionSelected(completedSessions[0]);
        setSessionId(completedSessions[0]?.id);
      }
    }
    setActiveTab(value);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  return (
    <div className={isDark ? "blaze__sidebar dark" : "blaze__sidebar"}>
      <div
        className={
          isDark
            ? "sidebar__header__wrapper sidebar__header__wrapper__dark"
            : "sidebar__header__wrapper"
        }
      >
        <Menu
          id={isDark ? "dark-menu" : "light-menu"}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseAnchor}
          style={{ top: "0px" }}
          className="menu-primary performance__menu"
        >
          <div className="blaze__performance__body">
            <h1 className="performance__title">Performance</h1>
            <div className="blaze__performance__summary">
              <StarRatings
                rating={5}
                starRatedColor="#F49483"
                starHoverColor="#F49483"
                // changeRating={changeRating}
                numberOfStars={5}
                starDimension="20px"
                starSpacing="2px"
                name="rating"
                svgIconPath={starPath}
                svgIconViewBox="0 0 207.802 207.748"
              />
              <h1>Excellent</h1>
            </div>

            <div className="all__topics">
              {Array(5)
                .fill(1)
                .map((_, i) => (
                  <div className="topic__container">
                    <h2>Topic {i + 1}</h2>
                    <StarRatings
                      rating={5}
                      starRatedColor="#F49483"
                      starHoverColor="#F49483"
                      // changeRating={changeRating}
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="2px"
                      name="rating"
                      svgIconPath={starPath}
                      svgIconViewBox="0 0 207.802 207.748"
                    />
                  </div>
                ))}
            </div>
          </div>
        </Menu>

        <div className="blaze__tabs">
          <Tabs
            style={{ minWidth: 72 }}
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              style={{ minWidth: 72 }}
              label="Accepted"
            />
            <Tab
              disableRipple
              disableFocusRipple
              disableTouchRipple
              style={{ minWidth: 72 }}
              label="Completed"
            />
          </Tabs>
        </div>
      </div>

      <div style={{ paddingTop: "3.5rem" }}></div>

      {activeTab === 0 && acceptedSessions !== null && (
        <InfiniteScroll
          initialized={initialized}
          className="blaze__sidebar__content"
          fetchMoreFn={fetchMoreAcceptedSessions}
          noMore={noMoreAcceptedSessions}
          textStyle={{fontSize: '14px'}}
          targetedRef={targetedRef}
          noMoreText={''}
        >
          {acceptedSessions?.map((session) => (
            <BlazeExternalCard
              onClick={() => {
                closeDrawer();
                setIsChatOpen(true);
                setOpenPages(false);
                setSessionId(session?.id);
                setSessionSelected(session);
              }}
              // ref={n => targetRefCB(n, session?.id)}
              isSessionSelected={sessionId === session?.id}
              sessionId={session?.id}
              key={session?.id}
              topic={session?.topic}
              type={"accepted"}
              skill={session?.skill}
              studentName={session?.student_name}
              studentImage={session?.student_profile_pic}
              studentId={session?.student_id}
              gradient={session?.subject_color_gradient}
            />
          ))}
        </InfiniteScroll>
      )}

      {activeTab === 1 && completedSessions !== null && (
        <InfiniteScroll
          initialized={_initialized}
          className="blaze__sidebar__content"
          fetchMoreFn={fetchMoreCompletedSessions}
          noMore={noMoreCompletedSessions}
          textStyle={{fontSize: '14px'}}
          targetedRef={targetedRef}
          noMoreText={''}
        >
          {completedSessions?.map((session) => (
            <BlazeExternalCard
              onClick={() => {
                closeDrawer();
                setIsChatOpen(true);
                setOpenPages(false);
                setSessionId(session?.id);
                setSessionSelected(session);

                console.log('completedSessions - ', completedSessions);
              }}
              isSessionSelected={sessionId === session?.id}
              sessionId={session?.id}
              key={session?.id}
              type={"completed"}
              rating={session?.rating}
              topic={session?.topic}
              skill={session?.skill}
              studentName={session?.student_name}
              studentId={session?.student_id}
              studentImage={session?.student_profile_pic}
              gradient={session?.subject_color_gradient}
            />
          ))}
        </InfiniteScroll>
        // <div className="blaze__sidebar__content">
        //   {completedSessions?.map((session) => (
        //     <BlazeExternalCard
        //       onClick={() => {
        //         closeDrawer();
        //         setIsChatOpen(true);
        //         setOpenPages(false);
        //         setSessionId(session?.id);
        //         setSessionSelected(session);
        //
        //         console.log('completedSessions - ', completedSessions);
        //       }}
        //       isSessionSelected={sessionId === session?.id}
        //       sessionId={session?.id}
        //       key={session?.id}
        //       type={"completed"}
        //       rating={session?.rating}
        //       topic={session?.topic}
        //       skill={session?.skill}
        //       studentName={session?.student_name}
        //       studentImage={session?.student_profile_pic}
        //       gradient={session?.subject_color_gradient}
        //     />
        //   ))}
        // </div>
      )}
      {activeTab === 0 && acceptedSessions === null && (
        <div className="blaze__sidebar__content">
          {Array(5)
            .fill(0)
            .map((_) => (
              <BlazeCardLoader />
            ))}
        </div>
      )}

      {activeTab === 0 && acceptedSessions?.length === 0 && (
        <div className="no__sessions">
          <Lottie
            options={{ animationData: EmptyBox, loop: false }}
            speed={0.45}
          />
          <h4 style={{ textAlign: "center" }}>
            There are no accepted sessions
          </h4>
        </div>
      )}
      {activeTab === 1 && completedSessions?.length === 0 && (
        <div className="no__sessions">
          <Lottie
            options={{ animationData: EmptyBox, loop: false }}
            speed={0.45}
          />
          <h4 style={{ textAlign: "center" }}>
            There are no completed sessions
          </h4>
        </div>
      )}
    </div>
  );
}
