function getDateInYYYYMMDDFormat() {
  var dateObj = new Date();
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  let newdate = year + "-" + month + "-" + day;
  return newdate;
}

const loadingWrapper = () => {
  document.querySelector(".loading__wrapper").style.display = "flex";
  document.body.style.position = "fixed";
  document.body.style.top = `-${window.scrollY}px`;

  setTimeout(() => {
    document.querySelector(".loading__wrapper").style.display = "none";

    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }, 3000);
};

export { getDateInYYYYMMDDFormat, loadingWrapper };
