import React, { useContext, useEffect, useState } from "react";
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import Modal from "react-modal";
import Vimeo from '@u-wave/react-vimeo';
import { format } from "date-fns";
import Lottie from "lottie-react-web";
import { UserContext } from "../../context";
import { getYoutubeID } from "../../helpers";
import { InfoRounded } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";
import { DurationDropdown } from "../../components";
import nullPayment from "../../assets/images/payroll.png";
import Cupertino from "../../assets/lottie/iosLoading.json";
import AccessTime from "@material-ui/icons/WatchLaterRounded";
import AccountSettings from "../global/navbar/account-settings";
import DateRangeIcon from "@material-ui/icons/DateRangeRounded";
import AddAPhotoIcon from "@material-ui/icons/AddAPhotoRounded";
import FavoriteRounded from "@material-ui/icons/FavoriteRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import ArrowRight from "@material-ui/icons/KeyboardArrowRightRounded";
import YoutubeEmbed from "../../components/doubts_forum/youtube-embed";
import WalletIcon from "@material-ui/icons/AccountBalanceWalletRounded";
import ArrowLeft from "@material-ui/icons/KeyboardArrowLeftRounded";
import nounBook from "../../assets/images/classroom/noun_book_5021.svg";
import CheckCircleIcon from "../../assets/images/icons/check-circle.svg";
import aadhaarPlaceholder from "../../assets/images/aadhaar-placeholder.png";
import payoutIcon from "../../assets/images/cashout.png";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";
import ViewStreamIcon from "@material-ui/icons/ViewStreamRounded";
import VideoLibraryIcon from "@material-ui/icons/VideoLibraryRounded";
import { showSnackbar } from "../../components/doubts_forum/snackbar/functions";
import {
  PosReceipt,
  BarCode,
  upiIcon,
  bankIcon,
  DotsLoading,
  CheckSuccess,
} from "../../assets";
import {
  getInstructorPayouts,
  getInstructorActivity,
  releasePayout,
  createPayoutContact,
  createFundAccount,
  getActiveFunds,
  updateFundAccount,
  updatePanDetails,
  getInstructorLifetimeEngagement,
} from "../../database";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";

import "./styles.scss";
import { updateAadhaarDetails } from "../../database/banking/index";
import {fetchIndianTime} from "../../helpers/functions/getIndianTime";
import {getVimeoURL} from '../../helpers/global';
import {db} from "../../firebase_config";
import {useHistory} from "react-router-dom";
import axios from "axios";
import VimeoPlayer from "../../components/global/vimeo-player";
import SkillRowShimmer from "../../components/blazeExternal/dashboardSkillsList/skillRowShimmer";

const PayoutIcon = () => (
  <img alt="cashout" className="payout-icon" src={payoutIcon} />
);

const DocumentScannerRoundedIcon = () => (
  <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-1cn3yto">
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="DocumentScannerRoundedIcon"
    >
      <path d="M3 6c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h3c.55 0 1 .45 1 1s-.45 1-1 1H4v2c0 .55-.45 1-1 1zm14-4c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1zM3 18c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1H4v-2c0-.55-.45-1-1-1zm14 4c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1v2h-2c-.55 0-1 .45-1 1zm2-4c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v12zM9 9c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1zm0 3c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1zm0 3c0 .55.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1h-4c-.55 0-1 .45-1 1z"></path>
    </svg>
  </div>
);

const AnalyticsRoundedIcon = () => (
  <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-1cn3yto">
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="AnalyticsRoundedIcon"
    >
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM8 17c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1zm4 0c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm0-5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm4 5c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v8c0 .55-.45 1-1 1z"></path>
    </svg>
  </div>
);

const TutorBanking = () => {
  const [dataKey] = useState("earnings");
  const [activeTab, setActiveTab] = useState(0);
  const [chartData, setChartData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [vpaDetails, setVpaDetails] = useState(null);
  const [payoutList, setPayoutList] = useState(null);
  const [activeTabVideo, _setActiveTabVideo] = useState(0);
  const [pendingEarnings, setPendingEarnings] = useState(0);
  const [lifetimeEarnings, setLifetimeEarnings] = useState(0);
  const [days30ChartData, setDays30ChartData] = useState(null);
  const [tutorEarnings] = useContext(UserContext).tutorEarnings;
  const [processingPayout, setProcessingPayout] = useState(false);
  const [isUpdatingVPADetails, setIsUpdatingVPADetails] = useState(false);
  const [isUpdatingBankDetails, setIsUpdatingBankDetails] = useState(false);
  const [isUpdatingAadhaarDetails, setIsUpdatingAadhaarDetails] =
    useState(false);
  const [isUpdatingPanDetails, setIsUpdatingPanDetails] = useState(false);
  const [hasUpdatedVPADetails, setHasUpdatedVPADetails] = useState(false);
  const [hasUpdatedBankDetails, setHasUpdatedBankDetails] = useState(false);
  const [hasUpdatedAadhaarDetails, setHasUpdatedAadhaarDetails] =
    useState(false);
  const [hasUpdatedPanDetails, setHasUpdatedPanDetails] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [bankIfscCode, setBankIfscCode] = useState(null);
  const [bankAccountNumber, setBankAccountNumber] = useState(null);
  const [bankInstructorName, setBankInstructorName] = useState(null);
  const [panNumber, setPanNumber] = useState(null);
  const [panNumber2, setPanNumber2] = useState(null);
  const [upiAddress, setUpiAddress] = useState(null);
  const [aadhaarImage, setAadhaarImage] = useState(null);
  const [aadhaarNumber, setAadharNumber] = useState(null);
  const [aadhaarDetails, setAadhaarDetails] = useState(null);
  const history = useHistory();
  const [videoList] = useContext(UserContext).earningVideos;
  const [enableSwitch, setEnableSwitch] = useState(true);

  const setActiveTabVideo = (val) => {
    if(!enableSwitch) return;
    _setActiveTabVideo(val);
  }

  const [durationObj, setDurationObj] = useState({
    name: "Last 30 days",
    index: 0,
    month: null,
    year: null,
  });

  const [payoutDetails, setPayoutDetails] = useState({
    orderStatus: "",
    orderId: "",
    amount: 0,
    timestamp: 0,
  });

  const [user] = useContext(UserContext).user;

  const strokes = { earnings: "#2972b3" };

  const formatDate = (date) => {
    const splitted = date?.split("_");
    const year = splitted[0];
    const month = splitted[1] - 1;
    const d = splitted[2];
    return format(new Date(year, month, d), "MMM, do yyyy");
  };

  const last30Days = async (inHash) => {
    const today = await fetchIndianTime();

    let dates = [];
    for (let i = 0; i < 31; i++) {
      const priorDate = new Date().setDate(today.getDate() - i);
      const date = new Date(priorDate).getDate();
      const month = new Date(priorDate).getMonth() + 1;
      const year = new Date(priorDate).getFullYear();
      if(inHash) dates.push({year, month, date});
      else dates.push(year + "_" + month + "_" + date);
    }

    return dates.reverse();
  };

  const getDaysInMonth = (month, year) => {
    let date = new Date(year, month - 1, 1);
    let days = [];
    while (date.getMonth() === month - 1) {
      days.push(`${year}_${month}_${new Date(date).getDate()}`);
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let count = "₹ " + payload[0].payload[dataKey];

      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          <p className="intro">{count}</p>
        </div>
      );
    }

    return null;
  };

  const getInstructorDetailsFn = () => {
    getInstructorActivity({
      instructorId: user?.uid,
      callback: (data) => {
        if (data) {
          setContactId(data?.contact_id);
          setPanNumber(data?.user_document?.pan?.pan_card_number);
          setPanNumber2(data?.user_document?.pan?.pan_card_number);

          setAadhaarDetails(data?.user_document?.aadhaar);
          setAadhaarImage(data?.user_document?.aadhaar?.aadhaar_card_image);
          setAadharNumber(data?.user_document?.aadhaar?.aadhaar_card_number);
        }
      },
    });
  };

  const handlePayout = async () => {

    // if (!pendingEarnings || pendingEarnings < 100) {
    //   return showSnackbar("Pending payment too low to be processed", "info");
    // }
    if (contactId && !processingPayout) {
      setProcessingPayout(true);
      if (contactId) {
        const response = await releasePayout({
          userId: user?.uid,
          contactId: bankDetails ? bankDetails?.id : vpaDetails?.id,
        });

        response?.error
          ? showSnackbar(response.error.description, "warning")
          : showSnackbar("Released Pending Earnings", "success");

        setProcessingPayout(false);
      } else {
        setProcessingPayout(false);
        showSnackbar("Add bank details", "info");
      }
    } else {
      showSnackbar("Add bank details", "info");
      setProcessingPayout(false);
    }
  };

  useEffect(() => {
    setChartDataFn();
  }, [tutorEarnings]);

  const setChartDataFn = async () => {
    if (tutorEarnings?.length > 0) {
      let _earnings = 0;
      let _pendingEarnings = 0;

      const _last30Days = await last30Days();
      let _daySessionsCount = [];
      let _dayEarnings = [];

      let _dailyEarnings = {};

      let _chartData = [];

      tutorEarnings.map((order) => {
        const dailyEarnings = order?.daily_earnings;
        _dailyEarnings = { ..._dailyEarnings, ...dailyEarnings };

        for (let k in dailyEarnings) {
          _daySessionsCount.push({
            date: k,
            sessions: Object.keys(dailyEarnings[k]).length,
          });

          let dayWiseData = dailyEarnings[k];

          let oneDayEarning = 0;

          for (let session in dayWiseData) {
            _earnings += dayWiseData[session]?.amount || 0;
            oneDayEarning += dayWiseData[session]?.amount || 0;

            if (
              dayWiseData[session]?.status &&
              dayWiseData[session]?.status !== "accounted" &&
              dayWiseData[session]?.status !== "processing"
            ) {
              _pendingEarnings += dayWiseData[session]?.amount;
            }
          }
          _dayEarnings.push({ date: k, earnings: oneDayEarning });
        }
      });

      for (let i = 0; i < 31; i++) {
        let date = _last30Days[i];

        if (_dailyEarnings[date]) {
          const dayEarnings = _dayEarnings.filter((d) => d.date === date)[0]
            .earnings;

          const daySessions = _daySessionsCount.filter(
            (d) => d.date === date
          )[0].sessions;

          _chartData.push({
            date: formatDate(date),
            hours: dayEarnings / (100 * 60),
            sessions: daySessions,
            earnings: dayEarnings,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setChartData(_chartData);
      setDays30ChartData(_chartData);

      setLifetimeEarnings(_earnings);

      setPendingEarnings(_pendingEarnings);
    }
  };

  useEffect(() => {
    if (tutorEarnings?.length > 0 && durationObj.month) {
      const _daysInMonth = getDaysInMonth(durationObj.month, durationObj.year);
      let _daySessionsCount = [];
      let _dayEarnings = [];

      let _dailyEarnings = {};

      let _chartData = [];

      tutorEarnings.map((order) => {
        if (order.id === durationObj.year + "_" + durationObj.month) {
          _dailyEarnings = order?.daily_earnings;
        }

        for (let k in _dailyEarnings) {
          _daySessionsCount.push({
            date: k,
            sessions: Object.keys(_dailyEarnings[k]).length,
          });

          let dayWiseData = _dailyEarnings[k];

          let oneDayEarning = 0;

          for (let session in dayWiseData) {
            oneDayEarning += dayWiseData[session]?.amount || 0;
          }
          _dayEarnings.push({ date: k, earnings: oneDayEarning });
        }
      });

      for (let i = 0; i < _daysInMonth.length; i++) {
        let date = _daysInMonth[i];

        if (_dailyEarnings[date]) {
          const dayEarnings = _dayEarnings.filter((d) => d.date === date)[0]
            .earnings;

          const daySessions = _daySessionsCount.filter(
            (d) => d.date === date
          )[0].sessions;

          _chartData.push({
            date: formatDate(date),
            hours: dayEarnings / (100 * 60),
            sessions: daySessions,
            earnings: dayEarnings,
          });
        } else {
          _chartData.push({
            date: formatDate(date),
            hours: 0,
            sessions: 0,
            earnings: 0,
          });
        }
      }

      setChartData(_chartData);
    }
    if (durationObj.index === 0 && days30ChartData) {
      setChartData(days30ChartData);
    }
    if (durationObj.index === 6) {
      getInstructorLifetimeEngagementFn();
    }
  }, [durationObj, days30ChartData, tutorEarnings]);

  const getInstructorLifetimeEngagementFn = async () => {
    const lifetimeData = await getInstructorLifetimeEngagement({
      instructorId: user?.uid,
    });

    const sortFn = (a, b) => {
      let id1 = a[0].split("_");
      let id2 = b[0].split("_");

      if (Number(id1[0]) >= Number(id2[0])) {
        if (Number(id1[1]) >= Number(id2[1])) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    };

    const sorted = lifetimeData.map((yearData) => {
      let _yearData = Object.entries(yearData);
      let newData = _yearData.slice(0, _yearData.length - 1).sort(sortFn);

      return newData;
    });
    let _chartData = [];

    sorted.map((year) =>
      year.map((month) => {
        let id = month[0].split("_");

        _chartData.push({
          date: format(
            new Date(Number(id[0]), Number(id[1]) - 1, 1),
            "MMMM, YYY"
          ),
          hours: month[1].call_duration,
          sessions: month[1].session_count,
          earnings: month[1].estimated_earning,
        });
      })
    );

    setChartData(_chartData);
  };

  useEffect(() => {
    if (user?.uid && user?.is_external_instructor) {
      getActiveFunds({
        instructorId: user?.uid,
        callback: (data) => {
          const _bankDetails = data?.filter(
            (f) => f?.account_type === "bank_account" && f?.active
          )[0];
          setBankDetails(_bankDetails || null);
          setBankIfscCode(_bankDetails?.bank_account?.ifsc || null);
          setBankAccountNumber(
            _bankDetails?.bank_account?.account_number || null
          );
          setBankInstructorName(_bankDetails?.bank_account?.name || null);

          const _vpaDetails = data?.filter(
            (f) => f?.account_type === "vpa" && f?.active
          )[0];

          setVpaDetails(_vpaDetails || null);
          setUpiAddress(_vpaDetails?.vpa?.address || null);
        },
      });

      getInstructorDetailsFn();

      getInstructorPayouts({
        instructorId: user?.uid,
        callback: (data) => setPayoutList(data),
      });
    }
  }, [user?.uid, user?.is_external_instructor]);

  let statuses = {
    processed: { title: "success", icon: <img src={CheckCircleIcon} /> },
    processing: { title: "processing", icon: <HourglassEmptyRoundedIcon /> },
    reversed: { title: "error", icon: <CancelRoundedIcon /> },
  };

  let statuses2 = {
    processed: "Success",
    processing: "Processing",
    reversed: "Failure",
  };

  const PayoutStatus = ({ order }) => (
    <h4 className="payout-status">
      <span className={order?.status}>
        {statuses[order?.status].icon} {statuses[order?.status].title}
      </span>{" "}
      <span>• {format(new Date(order?.notes?.payout_ts), "p")}</span>
    </h4>
  );

  const changeAadharNumber = (text) => {
    let _aadhaarNumber = text.replaceAll(" ", "");

    if (!isNaN(Number(_aadhaarNumber)) && _aadhaarNumber.length <= 12) {
      setAadharNumber(_aadhaarNumber?.match(/.{1,4}/g)?.join(" "));
    }
    if (isNaN(Number(_aadhaarNumber)) && text.length === 1) setAadharNumber("");
  };

  const aadhaarImageChangeHandler = (e) => {
    const { files } = e.target;

    let _images = {
      url: URL.createObjectURL(files[0]),
      ext: files[0].name.split(".").slice(-1)[0],
      name: files[0].name,
    };

    setAadhaarImage(_images?.url);
  };

  const isBankDetailsChanged =
    bankInstructorName !== (bankDetails?.bank_account?.name || null) ||
    bankIfscCode !== (bankDetails?.bank_account?.ifsc || null) ||
    bankAccountNumber !== (bankDetails?.bank_account?.account_number || null);

  const isVPAChanged = upiAddress !== (vpaDetails?.vpa?.address || null);

  const isAadhaarDetailsChanged =
    aadhaarImage !== (aadhaarDetails?.aadhaar_card_image || null) ||
    aadhaarNumber !== (aadhaarDetails?.aadhaar_card_number || null);

  const isPanDetailsChanged = panNumber !== panNumber2;

  const handleBankDetailsChange = async () => {
    if (!isUpdatingBankDetails) {
      setIsUpdatingBankDetails(true);
      if (bankIfscCode.length !== 11) {
        showSnackbar("IFSC Code is invalid", "warning");
        return setIsUpdatingBankDetails(false);
      }
      if (bankDetails?.id) {
        let response = await updateFundAccount({
          uid: user?.uid,
          fund_data: {
            name: bankInstructorName,
            ifsc: bankIfscCode,
            account_number: bankAccountNumber,
          },
          contact_id: contactId,
          account_type: "bank_account",
          current_fund_account_id: bankDetails?.id,
        });

        if (response.success) {
          setIsUpdatingBankDetails(false);
          setHasUpdatedBankDetails(true);
          return setTimeout(() => setHasUpdatedBankDetails(false), 2000);
        }
      } else {
        if (contactId) {
          let response = await createFundAccount({
            uid: user?.uid,
            contact_id: contactId,
            fund_data: {
              name: bankInstructorName,
              ifsc: bankIfscCode,
              account_number: bankAccountNumber,
            },
            account_type: "bank_account",
          });

          if (response.success) {
            setIsUpdatingBankDetails(false);
            setHasUpdatedBankDetails(true);
            return setTimeout(() => setHasUpdatedBankDetails(false), 2000);
          }
        } else {
          const res = await createPayoutContact({
            name: user?.name,
            email: user?.email,
            contact: user?.phone_number,
            uid: user?.uid,
          });

          //res.success: <contactId>

          if (res.success) {
            const response = await createFundAccount({
              uid: user?.uid,
              contact_id: res.success,
              fund_data: {
                name: bankInstructorName,
                ifsc: bankIfscCode,
                account_number: bankAccountNumber,
              },
              account_type: "bank_account",
            });

            if (response.success) {
              setIsUpdatingBankDetails(false);
              setHasUpdatedBankDetails(true);
              return setTimeout(() => setHasUpdatedBankDetails(false), 2000);
            }
          }
        }
      }
      showSnackbar("Some error occured", "warning");
      return setIsUpdatingBankDetails(false);
    }
  };
  const handleVPADetailsChange = async () => {
    if (!isUpdatingVPADetails) {
      setIsUpdatingVPADetails(true);
      if (vpaDetails?.id) {
        let response = await updateFundAccount({
          uid: user?.uid,
          fund_data: { address: upiAddress },
          contact_id: contactId,
          account_type: "vpa",
          current_fund_account_id: vpaDetails?.id,
        });

        if (response.success) {
          setIsUpdatingVPADetails(false);
          setHasUpdatedVPADetails(true);
          return setTimeout(() => setHasUpdatedVPADetails(false), 2000);
        }
      } else {
        if (contactId) {
          let response = await createFundAccount({
            uid: user?.uid,
            contact_id: contactId,
            fund_data: { address: upiAddress },
            account_type: "vpa",
          });

          if (response.success) {
            setIsUpdatingVPADetails(false);
            setHasUpdatedVPADetails(true);
            return setTimeout(() => setHasUpdatedVPADetails(false), 2000);
          }
        } else {
          const res = await createPayoutContact({
            name: user?.name,
            email: user?.email,
            contact: user?.phone_number,
            uid: user?.uid,
          });

          //res.success: <contactId>

          if (res.success) {
            const response = await createFundAccount({
              uid: user?.uid,
              contact_id: res.success,
              fund_data: { address: upiAddress },
              account_type: "vpa",
            });

            if (response.success) {
              setIsUpdatingVPADetails(false);
              setHasUpdatedVPADetails(true);
              return setTimeout(() => setHasUpdatedVPADetails(false), 2000);
            }
          }
        }
      }
      showSnackbar("Some error occured", "warning");
      return setIsUpdatingVPADetails(false);
    }
  };
  const handleAadhaarDetailsChange = async () => {
    if (!isUpdatingAadhaarDetails) {
      setIsUpdatingAadhaarDetails(true);

      if (!aadhaarNumber || aadhaarNumber.replaceAll(" ", "").length !== 12) {
        showSnackbar("AADHAAR Number is invalid", "warning");
        return setIsUpdatingAadhaarDetails(false);
      }

      const isUpdated = await updateAadhaarDetails({
        aadhaarNumber: aadhaarNumber,
        aadhaarImage:
          aadhaarImage !== (aadhaarDetails?.aadhaar_card_image || null)
            ? aadhaarImage
            : null,
        instructorId: user?.uid,
      });

      setIsUpdatingAadhaarDetails(false);

      if (isUpdated) {
        setHasUpdatedAadhaarDetails(true);
        return setTimeout(() => setHasUpdatedAadhaarDetails(false), 2000);
      }
      showSnackbar("Some error occured", "warning");
    }
  };

  const handlePanDetailsChange = async () => {
    if (panNumber.length < 10) {
      return showSnackbar("PAN Number is invalid");
    }

    if (!isUpdatingPanDetails) {
      setIsUpdatingPanDetails(true);
      const isUpdated = await updatePanDetails({
        panNumber,
        instructorId: user?.uid,
      });

      setIsUpdatingPanDetails(false);

      if (isUpdated) {
        setHasUpdatedPanDetails(true);
        return setTimeout(() => setHasUpdatedPanDetails(false), 2000);
      }
      showSnackbar("Some error occured", "warning");
    }
  };

  const DotsLoadingLottie = () => (
    <div className="dots-loading">
      <Lottie
        options={{
          animationData: DotsLoading,
          loop: true,
        }}
      />
    </div>
  );

  const CheckSuccessLottie = () => (
    <Lottie
      options={{
        animationData: CheckSuccess,
        loop: false,
      }}
    />
  );

  const moveForward = () =>
    activeTabVideo < 2 && setActiveTabVideo(activeTabVideo + 1);
  const moveBackward = () =>
    activeTabVideo > 0 && setActiveTabVideo(activeTabVideo - 1);

  return (
    <div>
      <div className="tutor-banking-navbar">
        <div className="tutor-banking-title">
          <h1>Earnings</h1>
        </div>
        <div>
          <AccountSettings profileVisibility={true} />
        </div>
      </div>
      <div className="tutor-earnings-wrapper">
        <div className="tutor-banking-wrapper">
          <div className="tutor-month">
            <div className="tutor-hours">
              <div className="tutor-container">
                <h4>
                  <AccessTime />
                  <span>Pending Payment</span>
                </h4>
                <h2>₹ {pendingEarnings?.toLocaleString("en-IN")}</h2>

                <button onClick={handlePayout}>
                  {processingPayout ? (
                    <div className="cupertino-loading">
                      <span>Get Payment</span>
                      <Lottie
                        options={{ animationData: Cupertino, loop: true }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span>Get Payment</span>
                      <PayoutIcon />
                    </div>
                  )}
                </button>
              </div>
            </div>
            <div className="tutor-sessions">
              <div className="tutor-container">
                <h4>
                  <FavoriteRounded />
                  <span>Lifetime Earnings</span>
                </h4>
                <h2>₹ {lifetimeEarnings?.toLocaleString("en-IN")}</h2>
              </div>
            </div>
          </div>
          <div className="tutor-banking-main">
            <div className="tutor-banking-main-inner">
              <div className="tutor-banking-details">
                <div className="tutor-banking-chart" id="monthlyEarnings">
                  <div className="banking-timeline">
                    <h1>
                      <AnalyticsRoundedIcon />
                      <span>Earnings Analytics</span>
                    </h1>
                    <div className="chart-dropdown">
                      <DateRangeIcon className="date-range-icon" />{" "}
                      <DurationDropdown
                        durationObj={durationObj}
                        setDurationObj={setDurationObj}
                      />
                    </div>
                  </div>
                  <ResponsiveContainer
                    width="100%"
                    height="85%"
                    className="main-chart"
                  >
                    <AreaChart
                      width={500}
                      height={400}
                      margin={{
                        top: 25,
                        left: 0,
                        right: 10,
                        bottom: 10,
                      }}
                      data={chartData || []}
                    >
                      <defs>
                        <linearGradient
                          id={dataKey}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="0"
                        >
                          <stop
                            offset="5%"
                            stopColor={strokes[dataKey]}
                            stopOpacity={0.15}
                          />
                          <stop
                            offset="95%"
                            stopColor={strokes[dataKey]}
                            stopOpacity={0.15}
                          />
                        </linearGradient>
                      </defs>
                      <CartesianGrid
                        strokeDashArray="3 3"
                        vertical={false}
                        style={{ stroke: "rgba(128, 128, 128, 0.2" }}
                      />
                      <XAxis
                        dataKey="date"
                        tickLine={false}
                        style={{ stroke: "rgba(128, 128, 128, 0.5" }}
                        hide
                      />
                      <YAxis
                        axisLine={false}
                        tickLine={false}
                        style={{ fontSize: 10 }}
                      />
                      <Tooltip
                        content={<CustomTooltip />}
                        cursor={true}
                        position={{ x: "auto", y: 0 }}
                      />
                      <Area
                        stackId="1"
                        type="monotone"
                        strokeWidth={4}
                        dataKey={dataKey}
                        fill={`url(#${dataKey})`}
                        stroke={strokes[dataKey]}
                        activeDot={{ strokeWidth: 0, r: 0 }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
                <div className="tutor-earnings">
                  <div className="tutor-container">
                    <div className="banking-head">
                      <h4>
                        <WalletIcon />
                        <span>Banking Info</span>
                      </h4>
                    </div>
                    <div className="breadcrumb__wrapper">
                      <div className="breadcrumb">
                        <button
                          onClick={() => setActiveTab(0)}
                          className="breadcrumb__step breadcrumb__step--active"
                        >
                          <CreditCardRoundedIcon />
                          <span>Bank Details</span>
                        </button>
                        <button
                          onClick={() => setActiveTab(1)}
                          className={
                            activeTab === 1
                              ? "breadcrumb__step breadcrumb__step--active"
                              : "breadcrumb__step"
                          }
                        >
                          <DocumentScannerRoundedIcon />
                          <span>KYC Details</span>
                        </button>
                        <button className="breadcrumb__step" />
                      </div>
                    </div>
                    <div className="finance-details">
                      <SwipeableViews
                        axis="x"
                        index={activeTab}
                        onChangeIndex={(e) => setActiveTab(e)}
                        scrolling={true}
                      >
                        <div className="bank-details-tab">
                          <div className="account-tab">
                            <div className="tab-head">
                              <div>Bank Account</div>
                              <div className="done-btn">
                                {isBankDetailsChanged &&
                                  !isUpdatingBankDetails &&
                                  !hasUpdatedBankDetails && (
                                    <button onClick={handleBankDetailsChange}>
                                      Done
                                    </button>
                                  )}
                                {isUpdatingBankDetails && <DotsLoadingLottie />}
                                {hasUpdatedBankDetails && (
                                  <CheckSuccessLottie />
                                )}
                              </div>
                            </div>
                            <div className="account-row holder">
                              <h2>Name</h2>
                              <input
                                type="text"
                                placeholder="Enter Full Name"
                                value={bankInstructorName}
                                onChange={(e) =>
                                  setBankInstructorName(e.target.value)
                                }
                              />
                            </div>
                            <div className="account-row holder">
                              <h2>IFSC Code</h2>
                              <input
                                type="text"
                                placeholder="Enter IFSC Code"
                                value={bankIfscCode}
                                onChange={(e) => {
                                  if (e.target.value.length <= 11) {
                                    setBankIfscCode(e.target.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="account-row holder">
                              <h2>Account Number</h2>
                              <input
                                type="number"
                                placeholder="Enter Account No."
                                value={bankAccountNumber}
                                onChange={(e) =>
                                  setBankAccountNumber(
                                    e.target.value.toUpperCase()
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="upi-tab">
                            <div className="tab-head">
                              <div>UPI Account</div>
                              <div className="done-btn">
                                {isVPAChanged &&
                                  !isUpdatingVPADetails &&
                                  !hasUpdatedVPADetails && (
                                    <button onClick={handleVPADetailsChange}>
                                      Done
                                    </button>
                                  )}
                                {isUpdatingVPADetails && <DotsLoadingLottie />}
                                {hasUpdatedVPADetails && <CheckSuccessLottie />}
                              </div>
                            </div>
                            <div className="upi-row">
                              <h2>UPI Address</h2>
                              <input
                                type="text"
                                placeholder="Enter UPI Address"
                                value={upiAddress}
                                onChange={(e) => setUpiAddress(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="bank-details-tab">
                          <div className="account-tab">
                            <div className="tab-head">
                              <div>AADHAAR Details</div>
                              <div className="done-btn">
                                {isAadhaarDetailsChanged &&
                                  !isUpdatingAadhaarDetails &&
                                  !hasUpdatedAadhaarDetails && (
                                    <button
                                      onClick={handleAadhaarDetailsChange}
                                    >
                                      Done
                                    </button>
                                  )}
                                {isUpdatingAadhaarDetails && (
                                  <DotsLoadingLottie />
                                )}
                                {hasUpdatedAadhaarDetails && (
                                  <CheckSuccessLottie />
                                )}
                              </div>
                            </div>
                            <div className="aadhaar-image">
                              <label htmlFor="aadhaarImage">
                                <img
                                  src={
                                    aadhaarImage
                                      ? aadhaarImage
                                      : aadhaarPlaceholder
                                  }
                                  alt="aadhaar"
                                  draggable={false}
                                />
                                <div className="aadhaar-image-overlay" />
                                <AddAPhotoIcon />
                              </label>
                              <div>
                                <input
                                  accept="image/*"
                                  type="file"
                                  id="aadhaarImage"
                                  onChange={aadhaarImageChangeHandler}
                                  style={{ display: "none" }}
                                />
                                <label
                                  className="upload-desc"
                                  htmlFor="aadhaarImage"
                                >
                                  <InfoRounded /> Upload front side
                                </label>
                              </div>
                            </div>
                            <div className="account-row holder">
                              <h2>Aadhaar Number</h2>
                              <input
                                type="text"
                                value={aadhaarNumber}
                                placeholder="Enter Aadhaar No."
                                onChange={(e) =>
                                  changeAadharNumber(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="upi-tab">
                            <div className="tab-head">
                              <div>PAN Details</div>
                              <div className="done-btn">
                                {isPanDetailsChanged &&
                                  !hasUpdatedPanDetails &&
                                  !isUpdatingPanDetails && (
                                    <button onClick={handlePanDetailsChange}>
                                      Done
                                    </button>
                                  )}
                                {isUpdatingPanDetails && <DotsLoadingLottie />}
                                {hasUpdatedPanDetails && <CheckSuccessLottie />}
                              </div>
                            </div>
                            <div className="upi-row">
                              <h2>PAN Number</h2>
                              <input
                                type="text"
                                value={panNumber}
                                placeholder="Enter PAN No."
                                onChange={(e) => {
                                  if (e.target.value.length <= 10) {
                                    setPanNumber(e.target.value.toUpperCase());
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </SwipeableViews>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tutor-payout-info-container">
          <div className={"tutor-payment-list" + (payoutList?.length > 0 ? '' : ' no-payroll')}>
            <h1>
              <div>
                <ViewStreamIcon /> <span>Payment Details</span>
              </div>
              {payoutList && payoutList.length > 4 && <span onClick={() => history.push('/earnings/payouts')}>View Details</span>}
            </h1>
            <div className="list-wrapper">
              {payoutList?.map((order, i) => (
                <div
                  className="payment-row"
                  key={i}
                  style={{ animationDelay: `${0.05 * i}s` }}
                  onClick={() => {
                    setOpenModal(true);
                    setPayoutDetails({
                      amount: order.amount,
                      orderStatus: order.status,
                      orderId: order.id,
                      timestamp: new Date(order.notes?.payout_ts),
                    });
                  }}
                >
                  <div className="payment-details">
                    <div className="upi-icon">
                      {order.mode === "UPI" ? (
                        <img src={upiIcon} alt="upi" />
                      ) : (
                        <img src={bankIcon} alt="bank" />
                      )}
                    </div>
                    <div className="payout-head">
                      <h2>
                        {format(new Date(order?.notes?.payout_ts), "MMM-d")}{" "}
                        Payment
                      </h2>
                      <PayoutStatus order={order} />
                    </div>
                  </div>
                  <h3>₹ {order.amount}</h3>
                  <button>
                    <ArrowRight />
                  </button>
                </div>
              ))}
              {payoutList === null && (
                <div className="no-payroll banking">
                  <img src={nullPayment} />{" "}
                </div>
              )}
            </div>
          </div>
          <div className="tutor-payment-list tutor-help">
            <div className="tutor-payment-head">
              <h1>
                <VideoLibraryIcon /> <span>Earnings Guide</span>
              </h1>
              <div className="toggle-btns">
                <button
                  onClick={moveBackward}
                  className={activeTabVideo === 0 && "fadeOut"}
                >
                  <ArrowLeft />
                </button>
                <div className="slide-number">
                  <h6>{activeTabVideo + 1}</h6>
                  <h5> / 3</h5>
                </div>
                <button
                  onClick={moveForward}
                  className={activeTabVideo === 2 && "fadeOut"}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
            {!videoList ? <SkillRowShimmer /> : (
              <div className="list-wrapper payout-help">
                <SwipeableViews
                  axis={"x"}
                  scrolling={true}
                  index={activeTabVideo}
                  onChangeIndex={(e) => setActiveTabVideo(e)}
                >
                  {videoList.map((item, index) => (
                    <VimeoPlayer item={item} index={index} isVisible={activeTabVideo === index} setEnableSwitch={setEnableSwitch} showText={true} />
                  ))}
                </SwipeableViews>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        className="pustack-care-modal pustack-payout"
        shouldCloseOnOverlayClick={true}
        overlayClassName="new-post-modal-overlay"
        onRequestClose={() => setOpenModal(false)}
      >
        <div className="pos-full-receipt fadeIn">
          <img
            src={PosReceipt}
            className="pos-receipt"
            alt="receipt"
            draggable={false}
          />
          <div className="pos-receipt-details">
            <div className="receipt-head">
              <img src={nounBook} alt="logo" />
              <h1>PuStack Payout</h1>
            </div>

            <div className="receipt-details">
              <div className="receipt-line">
                <h2>Amount Released</h2>
                <h4>₹ {payoutDetails.amount}</h4>
              </div>
              <div className="receipt-line">
                <h2>Date &amp; Time</h2>
                <h4>{format(payoutDetails.timestamp, "MMM do yyyy, p")}</h4>
              </div>
              <div className="receipt-line">
                <h2>Status</h2>
                <h4>{statuses2[payoutDetails.orderStatus]}</h4>
              </div>
            </div>

            <div className="receipt-bar">
              <img src={BarCode} alt="barcode" draggable={false} />
              <h4>{payoutDetails.orderId}</h4>
              <p>Thank you for your service! 😄</p>
            </div>
          </div>

          <div className="order-completed-btns">
            <button
              className="close-receipt"
              onClick={() => setOpenModal(false)}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TutorBanking;
