import React, { useRef, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Avatar from "../../../components/global/Avatar";

const MediaPlayer = ({ videoTrack, audioTrack, type, platform, profilePic, name, uid }) => {
  const container = useRef();
  const [newHeight, setNewHeight] = useState(undefined);
  const [newWidth, setNewWidth] = useState(undefined);

  const isDesktop = useMediaQuery({ query: "(min-width: 501px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 500px)" });

  useEffect(() => {
    if (!container.current) return;

    try {
      videoTrack.play(container.current);
    } catch (err) {}

    return () => videoTrack?.stop();
  }, [container, videoTrack]);


  useEffect(() => {
    try {
      audioTrack.play();
    } catch (err) {
      audioTrack && audioTrack.stop();
    }

    return () => audioTrack?.stop();
  }, [audioTrack]);

  const isPortrait = (videoTrack ? (videoTrack._videoWidth / videoTrack._videoHeight) : (16 / 9)) < 1;

  return videoTrack ? (
    <div
      ref={container}
      className={`video-player ${type} fadeIn ${isPortrait ? "portrait" : "landscape"} ${platform}`}
    />
  ) : (
    <div className="student__video__bg">
      <Avatar
        src={profilePic}
        name={name}
        uid={uid}
        alt="student bg"
        className="student__bg"
      />
      <Avatar
        src={profilePic}
        name={name}
        uid={uid}
        alt="student"
        className="student__placeholder"
      />
    </div>
  );
};

export default MediaPlayer;
