import React from "react";

const CloseFull = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="CloseFullscreenIcon"
    >
      <path d="M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22z"></path>
    </svg>
  );
};

export default CloseFull;
