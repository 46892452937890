export const formatCallDuration = (duration) => {
  let sec_num = Number(duration);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return Number(hours) > 0
    ? hours + ":" + minutes + ":" + seconds
    : minutes + ":" + seconds;
};

export const formatCallDurationShort = (duration) => {
  let sec_num = Number(duration);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  return Number(hours) > 0
    ? hours + "h " + minutes + "m " + seconds + "s"
    : Number(minutes) > 0
    ? minutes + "m " + seconds + "s"
    : seconds + "s";
};
