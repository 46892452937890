import React, {Suspense, useEffect, useMemo, useRef, useState} from 'react';
import {getAvatarImage} from "../../../context/global/user-context";
import {defaultPic} from "../../../assets";
import {capitalize, humanizeTime} from "../../../helpers";
import "./styles.scss";
import moment from "moment";

export default function Avatar({src, lastSeen, isOnline, name, uid, alt, className, ...props}) {
  // const src = useMemo(() => {
  //   console.log("_src - ", (_src === defaultPic || !_src) ? getAvatarImage(capitalize(name), uid) : _src)
  //   return (_src === defaultPic || !_src) ? getAvatarImage(capitalize(name), uid) : _src;
  // }, [name, _src, uid])

  // useEffect(() => {
  //   console.log('changed - ', imageRef.current.src);
  //   imageRef.current.src = (src === defaultPic || !src) ? getAvatarImage(capitalize(name), uid) : src
  // }, [name, src, uid])

  const wrapper = (children) => (lastSeen || isOnline ? (
    <div style={{flexShrink: 0, position: 'relative'}}>
      {children}
    </div>
  ) : <>{children}</>)

  return (
      wrapper(<>
        <img
          className={'app-avatar object-cover ' + (className ?? "")}
          src={(src === defaultPic || !src) ? getAvatarImage(capitalize(name), uid) : src}
          alt={alt}
          key={name}
          {...props}
        />
        {(lastSeen && !isOnline) && <div className='last-seen-app-avatar'>
          {humanizeTime((moment() - moment(lastSeen.last_seen)) / 1000, true)}
        </div>}
        {isOnline && <div className='online-app-avatar' />}
      </>)
  )
}
