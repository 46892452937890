import React, {useContext, useEffect, useState} from 'react';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import {UserContext} from "../context";
import {randomAvatar} from "../helpers";
import {db} from "../firebase_config";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {getAvatarImage} from "../context/global/user-context";
import Avatar from "./global/Avatar";

export const NotificationOn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={512}
    height={512}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    fill={'#1dda45'}
    viewBox="0 0 425.6 425.6"
    {...props}
  >
    <path
      d="M382.933 175.467H425.6C422.4 107.093 388.693 46.933 337.6 8l-30.4 30.4c43.307 31.04 72.427 80.533 75.733 137.067zM118.507 38.507 88 8C36.907 46.933 3.2 107.093 0 175.467h42.667c3.306-56.534 32.426-106.027 75.84-136.96zM340.8 186.133c0-65.6-34.88-120.32-96-134.827V36.8c0-17.707-14.293-32-32-32s-32 14.293-32 32v14.507c-61.12 14.507-96 69.227-96 134.827V292.8l-42.667 42.667V356.8h341.333v-21.333L340.8 292.8V186.133zM212.8 420.8c2.987 0 5.867-.32 8.64-.853 13.867-2.88 25.28-12.48 30.72-25.173 2.133-5.12 3.307-10.667 3.307-16.64h-85.333c-.001 23.573 19.093 42.666 42.666 42.666z"
      data-original="#000000"
    />
  </svg>
);

export const NotificationOff = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={512}
    height={512}
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    fill={'red'}
    viewBox="0 0 475.6 475.6"
    {...props}
  >
    <path
      d="m41.37 64.05 22.624-22.624L470.59 448.02l-22.624 22.624zM256 480c31.8 0 60.6-18.9 73.3-48H182.7c12.7 29.1 41.5 48 73.3 48zM112 227.5V288l-48 64v48h268.1L115.9 183.8c-2.7 14.4-4 29-3.9 43.7zM448 352l-48-64v-60.5C400 157 372.6 95.6 304 80l-8-48h-80l-8 48c-15 3.3-29.3 9.4-42 18.2l282 282z"
      data-original="#000000"
    />
  </svg>
)

const BlazeCardBgDark = ({ color1, color2, sessionId }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="750"
      height="366"
      viewBox="0 0 750 366"
    >
      <defs>
        <radialGradient
          id={"radial-gradient" + sessionId}
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0" stopColor={color1} />
          <stop offset="1" stopColor={color2} stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ffa000" />
          <stop offset="1" stopColor="#fdb946" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="radial-gradient-3"
          cx="0.5"
          cy="0.5"
          r="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#1976d2" />
          <stop offset="1" stopColor="#2d8ff2" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip-blaze-card-bg-dark">
          <rect width="750" height="366" />
        </clipPath>
      </defs>
      <g id="blaze-card-bg-dark" clipPath="url(#clip-blaze-card-bg-dark)">
        <ellipse
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="215.5"
          cy="204.5"
          rx="215.5"
          ry="204.5"
          transform="translate(470 90)"
          fill={`url(#radial-gradient${sessionId})`}
        />
        <circle
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="101.5"
          cy="101.5"
          r="101.5"
          transform="translate(262 225)"
          fill="url(#radial-gradient-2)"
        />
        <circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          cx="132"
          cy="132"
          r="132"
          transform="translate(287 -81)"
          fill="url(#radial-gradient-3)"
        />
      </g>
    </svg>
  );
};

export default function UserListItem({item, permissionStatus, containerClassName, _src = null, title, subTitle, onItemClick, notGradient, timeSpent}) {
  // const [, setFormUser] = useContext(UserContext).formUser;
  const [, setMainActiveTab] = useContext(UserContext).activeTab;
  const [src, setSrc] = useState(_src);

  useEffect(() => {
    if(!src) {
      db.collection('users')
        .doc(item.uid)
        .get()
        .then(snapshot => {
          if(!snapshot.exists) return;
          const data = snapshot.data();
          setSrc(data.profile_url);
        })
    }
  }, [item.uid, src]);

  if(notGradient) {
    return (
      <div className={"grid py-3 border-b-1 grid-cols-[50px_minmax(100px,_1fr)_50px] items-center justify-items-center cursor-pointer " + (containerClassName ? containerClassName : '')} onClick={() => {
        if(onItemClick) {
          return onItemClick(item);
        }
        // setFormUser(() => {
        //   setMainActiveTab(1);
        //   return item;
        // });
      }}>
        <Avatar
          className="w-10 h-10 rounded-full"
          src={src}
          name={title}
          uid={item.uid}
          alt="Pustack User"
        />
        <div className="font-medium text-zinc-900 dark:text-white justify-self-start px-2">
          <div className="capitalize flex items-center gap-2">
            <span>{title}</span>
            {/*<NotificationOn style={{width: '16px', height: '16px''}} />*/}
            {permissionStatus === 'granted' && <NotificationOn style={{width: '16px', height: '16px'}}/>}
            {permissionStatus === 'denied' && <NotificationOff style={{width: '16px', height: '16px'}}/>}
          </div>
          <div className="text-sm font-normal text-gray-400 dark:text-gray-400">{subTitle}</div>
        </div>
        <ArrowForwardIosIcon className="text-gray-500" style={{fontSize: '18px'}} />
      </div>
    )
  }

  return (
    <div className={"pustack-give-gradient-list-item cursor-pointer " + (containerClassName ? containerClassName : '')} onClick={() => {
      if(onItemClick) {
        return onItemClick(item);
      }
      // setFormUser(() => {
      //   setMainActiveTab(1);
      //   return item;
      // });
    }}>
      <div className="pustack-give-gradient-list-item-bg">
        {/*<BlazeCardBgDark*/}
        {/*  color1={'#00b899'}*/}
        {/*  color2={'#58e0bb'}*/}
        {/*  sessionId={'fsdf'}*/}
        {/*/>*/}
      </div>
      <div className="pustack-give-gradient-list-item-content px-4 justify-items-center items-center">
        {src ? <div className="pustack-give-gradient-list-item-image">
          <img src={src} alt="" onLoadedData={(e) => {
            console.log('item - ', item.name);
            console.log('loaded - ', e);
          }}/>
        </div> : randomAvatar(title)}
        <div className="font-medium dark:text-white justify-self-start px-2 justify-self-start">
          <div className="capitalize flex items-center gap-2">
            <span>{title}</span>
            {/*<NotificationOn style={{width: '16px', height: '16px''}} />*/}
            {permissionStatus === 'granted' && <NotificationOn style={{width: '16px', height: '16px'}}/>}
            {permissionStatus === 'denied' && <NotificationOff style={{width: '16px', height: '16px'}}/>}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-500">{subTitle}</div>
        </div>
        <button
          className="pustack-give-gradient-list-item-expand-btn bg-white dark:bg-zinc-900 text-zinc-900 dark:text-white"
          onClick={() => {}}
        >
          <KeyboardArrowDownRoundedIcon />
        </button>
      </div>
    </div>
  )
}
