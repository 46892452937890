import React, {useState} from 'react';
import "./style.scss";

export default function TabSlider({onItemClick, items}) {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="tab-slider-container">
      <div style={{
        left: (activeIndex * 70 === 0 ? 2 : activeIndex * 70) + ("px")
      }} className="tab-slider" />
      {items.map((item, index) => (
        <div className={"tab-slider-item " + (index + 1) + (activeIndex === index ? ' active' : '')} onClick={() => {
          setActiveIndex(index);
          onItemClick(item.value)
        }}>
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  )
}
