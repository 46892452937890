import { db, functions, storage } from "../../firebase_config";

export const releasePayout = async ({ userId, contactId }) => {
  const releaseUnaccountedEarnings = functions.httpsCallable(
    "releaseUnaccountedEarnings"
  );

  console.log({ userId, contactId });

  try {
    let response = await releaseUnaccountedEarnings({
      uid: userId,
      fund_account_id: contactId,
    });

    return response.data;
  } catch (err) {
    return { error: "Server Error" };
  }
};

export const createPayoutContact = async ({ name, email, contact, uid }) => {
  const fn = functions.httpsCallable("createPayoutContact");

  try {
    let response = await fn({ name, email, contact, uid });

    return response.data;
  } catch (err) {
    return { error: "Server Error" };
  }
};

export const createFundAccount = async ({
  uid,
  contact_id,
  fund_data,
  account_type,
}) => {
  const fn = functions.httpsCallable("createFundAccount");

  try {
    let response = await fn({
      uid,
      contact_id,
      account_type,
      [account_type]: fund_data,
    });
    return response.data;
  } catch (err) {
    return { error: "Server Error" };
  }
};

export const updateFundAccount = async ({
  uid,
  fund_data,
  contact_id,
  account_type,
  current_fund_account_id,
}) => {
  const fn = functions.httpsCallable("updateFundAccount");

  try {
    let response = await fn({
      uid,
      current_fund_account_id,
      contact_id,
      account_type,
      [account_type]: fund_data,
    });
    return response.data;
  } catch (err) {
    return { error: "Server Error" };
  }
};

export const getActiveFunds = ({ instructorId, callback }) => {
  try {
    db.collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .collection("funds")
      .where("active", "==", true)
      .onSnapshot((data) => {
        if (data.docs.length > 0) {
          const len = data.docs.length;
          let arr = [];
          for (let i = 0; i < len; i++) {
            arr.push(data.docs[i].data());
          }

          callback(arr);
        } else return [];
      });
  } catch (err) {
    return [];
  }
};

export const updatePanDetails = async ({ panNumber, instructorId }) => {
  return await db
    .collection("blaze_dev")
    .doc("collections")
    .collection("instructors")
    .doc(instructorId)
    .set(
      {
        user_document: { pan: { pan_card_number: panNumber } },
      },
      { merge: true }
    )
    .then(() => true)
    .catch(() => false);
};

const uploadImage = async ({ file, userId }) => {
  let path = `users/user_profile/${userId}/tutor_documents`;
  let _url = await storage
    .ref()
    .child(path)
    .put(await fetch(file).then((r) => r.blob()))
    .then(async (snapshot) => {
      return snapshot.ref.getDownloadURL().then((url) => url);
    });

  return _url;
};

export const updateAadhaarDetails = async ({
  aadhaarNumber,
  aadhaarImage,
  instructorId,
}) => {
  if (aadhaarImage) {
    const image_url = await uploadImage({ file: aadhaarImage, userId: instructorId });

    return await db
      .collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .set(
        {
          user_document: {
            aadhaar: {
              aadhaar_card_number: aadhaarNumber,
              aadhaar_card_image: image_url,
            },
          },
        },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  } else {
    return await db
      .collection("blaze_dev")
      .doc("collections")
      .collection("instructors")
      .doc(instructorId)
      .set(
        { user_document: { aadhaar: { aadhaar_card_number: aadhaarNumber } } },
        { merge: true }
      )
      .then(() => true)
      .catch(() => false);
  }
};
