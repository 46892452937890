import React, { useContext } from "react";
import ContentLoader from "react-content-loader";
import { ThemeContext } from "./../../../context";

export default function SkillRowShimmer() {
  const [isDark] = useContext(ThemeContext).theme;

  return (
    <div className="poll__shimmer">
      <ContentLoader
        width={"100%"}
        height={300}
        backgroundColor={isDark ? "#282828" : "#f5f5f5"}
        foregroundColor={isDark ? "#343434" : "#dbdbdb"}
      >
        <rect x="0" y="0" rx="6" ry="6" width="100%" height="120" />
        <rect x="0" y="154" rx="6" ry="6" width="100%" height="20" />
        <rect x="0" y="191" rx="6" ry="6" width="100%" height="20" />
        <rect x="0" y="228" rx="6" ry="6" width="100%" height="20" />
        <rect x="0" y="265" rx="6" ry="6" width="100%" height="20" />
      </ContentLoader>
    </div>
  );
}
